var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqDetailsWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":40}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfq.trackingHistory.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":41},"end":{"line":1,"column":84}}}))
    + "\">\n  <div class=\"rfq-message-info noprint\">\n    <p>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.trackings.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":37}}}))
    + "</p>\n    <div class=\"rfq-message-info-detail\">\n    </div>\n  </div>\n</div>";
},"useData":true});