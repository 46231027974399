var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"product-list-item-container\">\n    <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"imageUrl") : depth0), depth0))
    + "\" alt=\"\">\n    <div class=\"description-container\">\n      <div class=\"product-description\">\n        <p class=\"product-name\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productName") : depth0), depth0))
    + "</p>\n        <p class=\"product-sku\">\n          <span class=\"product-options-label\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias3,"rfq.form.item.sku",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":46},"end":{"line":10,"column":74}}}))
    + ":</span>\n          <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sku") : depth0), depth0))
    + "</span>\n        </p>\n        <div class=\"product-options\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,(depth0 != null ? lookupProperty(depth0,"options") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":10},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n\n      <div class=\"product-actions\">\n        <div class=\"price-num-container\">\n          <input class=\"form-input product-qty-edit\" type=\"text\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"quantity") : depth0), depth0))
    + "\" data-change=\"handleQuantityChange\"\n          data-product-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\" data-variant-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"variantId") : depth0), depth0))
    + "\" data-order-id="
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + ">\n          <p class=\"base-price-container\">\n            <span>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"formatBasePrice") : depth0), depth0))
    + "</span>\n          </p>\n        </div>\n        <input class=\"form-input offered-price\" type=\"text\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"offeredPrice") : depth0), depth0))
    + "\" data-productId=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"\n          data-variantId=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"variantId") : depth0), depth0))
    + "\">\n        <div class=\"product-bottom\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"isEditable") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":10},"end":{"line":41,"column":17}}})) != null ? stack1 : "")
    + "          <i class=\"fa fa-trash g-alink\" data-product-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"\n          data-variant-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"variantId") : depth0), depth0))
    + "\" data-order-id="
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " data-click=\"handleDeleteProduct\"></i>\n        </div>\n      </div>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <p>\n            <span class=\"product-options-label\">\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"optionName") : depth0), depth0))
    + ":\n            </span>\n            <span>\n              "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"optionLabel") : depth0), depth0))
    + "\n            </span>\n          </p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\"fa fa fa-pencil-square\" data-product-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"\n          data-variant-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"variantId") : depth0), depth0))
    + "\" data-order-id="
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + " data-click=\"handleEditOptions\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "  <p class=\"text-center\">"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.produckts.noStock",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":50,"column":25},"end":{"line":50,"column":57}}}))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqProductWrapper",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":43}}}))
    + "\">\n  <h4>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.toolbar.unit",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":36}}}))
    + "</h4>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"productList") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"productList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"==",0,{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":2},"end":{"line":51,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});