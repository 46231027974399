import { isEqual, isEmpty, capitalize } from 'lodash'
import stencilUtils from '../../common/utils/stencilUtils'
import handleStencilSearchInfo from '../../common/utils/handleStencilSearchInfo'
import BasePage from '../../common/BasePage'
import containers from '../../containers'

import buttonGroup from './button-group.html'
import quoteForm from './quote-form.html'
import formBody from './form-body.html'
import formProducts from './form-products.html'
import formMessage from './form-message.html'
import quoteSuccess from './quote-success.html'
import selectAddress from './select-address.html'
import editOptions from './edit-options.html'
import attachFile from './attach-file.html'
import searchProducts from './search-products.html'
import { getCurrency } from '../../common/utils/currencyFormat'

import B3Modifiers from '../../components/B3Modifiers'
import {
  isAllRequiredOptionFilled,
} from '../../components'

import mobileMyQuoteButton from './mobileMyQuoteButton.html'
import myQuoteButton from './myQuoteButton.html'
import { DEFAULT_IMAGE_URL } from '../../hbs/helpers/getImage'

const partialTypes = {
  rectangles: 'set-rectangle',
  swatch: 'swatch',
  date: 'date',
  checkbox: 'input-checkbox',
  file: 'input-file',
  numbers_only_text: 'input-numbers',
  text: 'input-text',
  product_list_with_images: 'product-list',
  radio_buttons: 'set-radio',
  dropdown: 'set-select',
  multi_line_text: 'textarea',
}

class Rfq extends BasePage {
  name = 'Rfq';

  constructor() {
    super()
    const stagedProductList = localStorage.getItem('B3CreateQuoteProductList')
    const stagedFormData = localStorage.getItem('B3CreateQuoteFormData')
    const stagedSwitchStatus = sessionStorage.getItem('quoteSwitchStatus')
    const stagedAttachment = localStorage.getItem('B3AttachmentList')
    const quoteOtherConfigs = sessionStorage.getItem('quoteOtherConfigs')

    this.state = {
      timer: null,
      isAdding: false,
      currency: {},
      addressList: [],
      productList: stagedProductList ? JSON.parse(stagedProductList) : [],
      formData: stagedFormData ? JSON.parse(stagedFormData) : this.basicFormData,
      $ref: null,
      isBackendUser: false,
      subtotal: '',
      discount: '',
      grandTotal: '',
      quoteFormStatus: 0,
      switchStatus: stagedSwitchStatus ? JSON.parse(stagedSwitchStatus) : [],
      quoteOtherConfigs: quoteOtherConfigs ? JSON.parse(quoteOtherConfigs) : [],
      checkboxOptionLabels: ['YES', 'NO'],
      countries: null,
      defaultBilling: stagedFormData ? JSON.parse(stagedFormData).billingAddress : null,
      isGettingPreFillInfo: false,
      addressId: '',
      q: '',
      searchProducts: null,
      searchProductInfo: null,
      searchProductQ: '',
      isShowSearchProduct: false,
      isSearchProduct: false,
      selectAddressType: '',
      isCopyShipping: false,
      isSize: false,
      isSetColor: false,
      isCanSearchAddress: true,
      currentProduct: [],
      currentProductOrderId: '',
      originalOptions: [],
      newOptionsData: null,
      attachment: stagedAttachment ? JSON.parse(stagedAttachment) : [],
      addressPagination: {
        offset: 0,
        limit: 100,
        totalCount: 0,
      },
      currentProductInfo: {
        can_purchase: true,
      },
      MutationObserver: window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver,
      productTypes: ['customItems', 'digitalItems', 'giftCertificates', 'physicalItems'],
      showInclusiveTaxPrice: JSON.parse(sessionStorage.getItem('showInclusiveTaxPrice')) || false,
      enteredInclusive: false,
    }

    this.tpls = {
      buttonGroup,
      quoteForm,
      formBody,
      formProducts,
      formMessage,
      attachFile,
      quoteSuccess,
      selectAddress,
      editOptions,
      mobileMyQuoteButton,
      myQuoteButton,
      searchProducts,
    }

    this.isAllRequiredOptionFilled = isAllRequiredOptionFilled
    this.getCalculatedPrice = this.utils.getCalculatedPrice
    this.getCalculatedParams = this.utils.getCalculatedParams
    this.getModifierAdjuster = this.utils.getModifierAdjuster
    this.getNewBulkPrice = this.utils.getNewBulkPrice

    this.B3Modifiers = B3Modifiers
  }

  async init() {
    const { switchStatus, quoteOtherConfigs } = this.state
    const {
      B3Storage,
    } = this.utils
    this.clearQuoteProductsWhenSignOut()
    this.goToQuoteCheckout()
    this.QuoteCheckoutAddLoginPrompt()
    if (!switchStatus.length || !quoteOtherConfigs.length) await this.getQuoteConfig()
    const B3StorefrontConfig = JSON.parse(B3Storage.B3StorefrontConfig.value)
    if (B3StorefrontConfig.quotes) {
      this.renderMyQuoteButton()
    }

    if (this.isQuickView) {
      this.checkingQuickViewRender()
    } else {
      if (!this.isCart) {
        await this.getProductPurchasability(this.isQuickView)
      }
      this.renderButton()
      this.renderPDPQuickViewQuoteButton()
    }

    const reRender = () => {
      this.renderButton()
    }
    stencilUtils.hooks.on('product-option-change', reRender)

    document.querySelector(`${this.actionWarper} [data-quantity-change]`)?.addEventListener('click', () => reRender)
    this.getCurrentCurrency()
    this.triggerQuoteNumber()
  }

  get isCart() {
    const { template } = this.context

    return template === 'pages/cart'
  }

  get isPDP() {
    const { template } = this.context

    return template === 'pages/product'
  }

  get isQuickView() {
    return !this.isCart && !this.isPDP
  }

  get actionWarper() {
    let className = ''
    if (this.isCart) {
      className = containers['cartActions.container']
    } else if (this.isPDP) {
      className = containers['addToCart.container']
    } else {
      className = containers['addToCart.quickView.container']
    }

    return className
  }

  get basicFormData() {
    const {
      B3Storage: { B3CompanyName },
    } = this.utils

    let name = ''
    let email = ''
    let companyName = ''
    let phoneNumber = ''
    if (this.isLogin) {
      const { customer } = this.context
      name = customer.name
      email = customer.email
      phoneNumber = customer.phone
      companyName = this.isB2BUser
        ? B3CompanyName.value
        : customer.shipping_address.company
    }

    return {
      referenceNumber: '',
      contactInfo: {
        name,
        email,
        companyName,
        phoneNumber,
      },
      quoteTitle: '',
      shippingAddress: {
        country: '',
        state: '',
        city: '',
        zipCode: '',
        address: '',
        apartment: '',
      },
      billingAddress: {
        country: '',
        state: '',
        city: '',
        zipCode: '',
        address: '',
        apartment: '',
      },
      expiredAt: '',
      message: '',
    }
  }

  get rfqForm() {
    const {
      $ref, formData, productList,
    } = this.state
    const {
      DateTime,
      B3Storage: { B3Email, B3CompanyId },
    } = this.utils

    const storeHash = this.context.settings.store_hash
    const userEmail = B3Email.value ?? ''
    const companyId = B3CompanyId.value ? B3CompanyId.value : null
    const subtotal = productList
      .reduce((result, { basePrice, quantity }) => (result += +basePrice * +quantity), 0)
      .toFixed(2)
    const grandTotal = productList
      .reduce((result, { offeredPrice, quantity }) => (result += +offeredPrice * +quantity), 0)
      .toFixed(2)

    const discount = 0.00
    const { expiredAt } = formData
    return {
      $ref,
      data: {
        isBackendUser: 0,
        storeHash,
        userEmail,
        companyId,
        subtotal,
        grandTotal,
        discount,
        currency: {
          ...this.getCurrentCurrency(),
        },
        productList,
        ...{
          ...formData,
          expiredAt: DateTime.displayParse(expiredAt),
        },
      },
    }
  }

  async getQuoteConfig() {
    const { store_hash: storeHash } = this.context.settings
    const { switchStatus, quoteOtherConfigs } = await this.api.getQuoteConfig({ storeHash })
    this.setState({ switchStatus, quoteOtherConfigs })
    sessionStorage.setItem('quoteSwitchStatus', JSON.stringify(switchStatus))
    sessionStorage.setItem('quoteOtherConfigs', JSON.stringify(quoteOtherConfigs))
  }

  isSwitchStatusEnabled(key) {
    const { switchStatus } = this.state
    return switchStatus.find(item => key === item.key)?.isEnabled === '1'
  }

  renderButton(isPDPQuickViewModal) {
    const {
      B3Storage,
    } = this.utils
    const B3StorefrontConfig = JSON.parse(B3Storage.B3StorefrontConfig.value)
    if (!B3StorefrontConfig.quotes) return
    const {
      utils: {
        renderTemplate,
      },
      state: {
        isAdding,
        isGettingPreFillInfo,
        currentProductInfo: {
          can_purchase,
        },
      },
      actionWarper,
      isCart,
      isLogin,
      isB2BUser,
      isB2CUser,
      isPDP,
      isQuickView,
    } = this
    if (!can_purchase) return
    const { B3RoleId, B3CompanyId } = this.utils.B3Storage
    const {
      constants: {
        B3Role: {
          SALESREP,
        },
      },
    } = this.utils

    if (isLogin) {
      if (SALESREP === B3RoleId.value && !B3CompanyId.value && !this.isSwitchStatusEnabled('quote_for_individual_customer')) return
      if (isB2BUser && !this.isSwitchStatusEnabled('quote_customer')) return
      if (isB2CUser && !this.isSwitchStatusEnabled('quote_for_individual_customer')) return
    }
    if (!isLogin && !this.isSwitchStatusEnabled('quote_for_guest')) return

    if ((isPDP || isQuickView) && !this.isSwitchStatusEnabled('quote_on_product_page')) return
    if (isCart && !this.isSwitchStatusEnabled('quote_on_cart_page')) return

    const containerSelector = isPDPQuickViewModal ? containers['addToCart.quickView.container'] : actionWarper
    const $buttonGroup = document.querySelectorAll(
      '.rfq-button-container',
    )
    if ($buttonGroup.length) {
      $buttonGroup.forEach(node => {
        node.remove()
      })
    }

    renderTemplate({
      hbsTemplate: this.tpls.buttonGroup,
      containerSelector,
      templateConfig: {
        isAdding,
        isCart,
        isNotQuickView: isPDPQuickViewModal ? false : !isQuickView,
        isGettingPreFillInfo,
      },
      insertType: 'beforeend',
    })
    const $addToCartBtns = isPDPQuickViewModal ? document.querySelectorAll(containers['addToCart.button.container']) : [document.querySelector(containers['addToCart.button.container'])]
    const $addToQuote = document.querySelector('.add-to-quote')
    if ($addToQuote) {
      $addToCartBtns.forEach($addToCartBtn => {
        $addToQuote.disabled = $addToCartBtn.disabled
      })
    }
    if ($addToCartBtns) {
      this.handleJudgeIsCanAddToQuote()
    }
    this.handleAddToQuoteClick()
  }

  handleJudgeIsCanAddToQuote = () => {
    const { MutationObserver } = this.state
    const $addToCartBtns = document.querySelectorAll(containers['addToCart.button.container'])
    const $addToQuote = document.querySelector('.add-to-quote')
    const config = { attributes: true }
    const callback = attributes => {
      attributes.forEach(attribute => {
        if (attribute.attributeName === 'disabled') {
          $addToQuote.disabled = attribute.target.disabled
        }
      })
    }
    const observer = new MutationObserver(callback)
    $addToCartBtns.forEach($addToCartBtn => {
      observer.observe($addToCartBtn, config)
    })

    if (!$addToCartBtns) observer.disconnect()
  }

  checkingQuickViewRender() {
    const checkIsQuickView = node => {
      if (!node || node.nodeName === 'BODY') return false
      if (node.classList.contains('quickview')) return true
      return checkIsQuickView(node.parentNode)
    }
    document.body.addEventListener('click', e => {
      clearInterval(this.state.timer)
      if (checkIsQuickView(e.target)) {
        this.state.timer = setInterval(() => {
          const $actionWarper = document.querySelector(this.actionWarper)
          if ($actionWarper) {
            clearInterval(this.state.timer)
          }
        }, 200)

        setTimeout(async () => {
          const quickViewModal = document.querySelector('.productView--quickView')
          if (quickViewModal) {
            await this.getProductPurchasability(this.isQuickView)
            this.renderButton('isPDPQuickViewModal')
          }
        }, 3000)
      }
    })
  }

  handleOptionsAndModifiers = (options = [], state) => {
    if (!options.length) return []
    const newOptions = options.map(item => {
      const newItem = {
        ...item,
        state,
        display_name: item.displayName,
        values: item.optionValues || [],
        type: capitalize(item?.type || ''),
        partial: partialTypes?.[item.type],
      }

      if (item.type === 'checkbox' && item.optionValues) {
        const checkedItem = item.optionValues.find(value => value.label === 'Yes' || value?.valueData?.checkedValue)

        newItem.value = checkedItem.id
      }

      return newItem
    })

    return newOptions
  }

  getBulkLoadProducts = async productIdInfo => {
    const allProducts = []
    window.B3Spinner.show()
    try {
      const {
        currency: {
          currencyCode,
        },
      } = this.state

      const { currency_code } = getCurrency()
      const { store_hash: storeHash } = this.context.settings

      const {
        B3Storage: { B3CompanyId },
      } = this.utils
      const companyId = B3CompanyId.value ? B3CompanyId.value : null

      const params = {
        storeHash,
        currencyCode: currency_code || currencyCode,
        companyId,
        productList: productIdInfo,
      }

      if (this.isLogin) {
        const { customer_group_id: customerGroupId } = this.context.customer

        if (customerGroupId) params.customerGroupId = customerGroupId
      }

      const { productList } = await this.api.getProductsBulkLoad(params)
      if (productList.length) {
        productList.forEach(product => {
          const { modifiers, optionsV3 } = product

          const newV3Options = this.handleOptionsAndModifiers(optionsV3, 'variant_option')
          const newModifiers = this.handleOptionsAndModifiers(modifiers, 'modifier')

          allProducts.push({
            ...product,
            id: product.productId,
            allOptions: [...newV3Options, ...newModifiers],
          })
        })
      }
      return allProducts
    } catch (error) {
      console.error(error)
    } finally {
      window.B3Spinner.hide()
    }

    return allProducts
  }

  async handleAddAll(e) {
    e.preventDefault()
    if (this.isOpenSigleLimitError('quote')) {
      this.sigleLimitErrorDialog('quote')
      return
    }
    const products = await this.getProducts()

    if (!products.length) {
      this.utils.Alert.error(this.text['validation.noProductsAdded'])
      localStorage.removeItem('cartToQuoteId')
      return
    }

    products.forEach(this.assignProductToList.bind(this))
    this.renderButton()
    this.handleShowViewMyQuotes()
  }

  async handleAdd(e, isPDPQuickViewModal) {
    e.preventDefault()
    const isRfqSearch = false
    const product = await this.getProduct(isRfqSearch, isPDPQuickViewModal)
    const { productList } = this.state
    const { currency: productCurrencyCode } = productList[0] || {}
    if (productCurrencyCode && productCurrencyCode !== product.currency) {
      this.utils.Alert.error(this.locales.tips['addToQuoteDiffCurrency'])
      return
    }
    this.assignProductToList(product)
    this.renderButton(isPDPQuickViewModal)

    this.handleShowViewMyQuotes()
  }

  getCurrentCurrency() {
    const { productList } = this.state
    const currencyInfo = sessionStorage.getItem('currencyInfo')
    const { currency: productCurrencyCode } = productList[0] || { currencyCode: null }
    const { currencies } = currencyInfo ? JSON.parse(currencyInfo) : { currencies: {} }
    const matchCurrency = currencies.find(({ currency_code, is_default }) => (productCurrencyCode ? currency_code === productCurrencyCode : is_default)) || {}
    const {
      currency_exchange_rate: currencyExchangeRate,
      decimal_places: decimalPlaces,
      decimal_token: decimalToken,
      thousands_token: thousandsToken,
      token,
      token_location: location,
      currency_code: currencyCode,
    } = matchCurrency

    const currency = {
      currencyExchangeRate,
      decimalPlaces,
      decimalToken,
      thousandsToken,
      token,
      location,
      currencyCode,
    }

    this.setState(
      { currency },
    )
    return currency
  }

  assignProductToList(product) {
    const { productList } = this.state

    const stagedIndex = productList.findIndex(
      ({
        variantId: stagedVariantId,
        productId: stagedProductId,
        options: stagedOptions,
      }) => {
        const {
          variantId,
          productId,
          options,
        } = product

        if (variantId) {
          return (
            stagedVariantId === variantId && isEqual(stagedOptions, options)
          )
        }
        return (
          productId === stagedProductId
          && isEqual(stagedOptions, options)
          && !stagedVariantId
        )
      },
    )

    if (stagedIndex !== -1) {
      const [stagedProduct] = productList.splice(stagedIndex, 1)
      product.quantity += stagedProduct.quantity

      const priceInfo = this.getCalculatedAndBulkPrice(product.productPriceInfo, product.quantity)

      product.basePrice = priceInfo.asEntered || product.basePrice
      product.offeredPrice = priceInfo.asEntered || product.offeredPrice
      product.taxPrice = priceInfo.taxPrice || product.taxPrice
    }
    productList.push(product)
    this.setState({
      productList: [...productList],
    })
    localStorage.setItem(
      'B3CreateQuoteProductList',
      JSON.stringify(productList),
    )
  }

  async getProductPurchasability(isPDPQuickViewModal) {
    const {
      utils: { normalizeFormData },
    } = this
    const $form = document.querySelector(isPDPQuickViewModal ? containers['addToCart.quickView.container'] : this.actionWarper)?.parentNode

    const formData = Array.from(normalizeFormData(new FormData($form)))
    let productId = ''
    formData.forEach(([key, value]) => {
      if (key === 'product_id') {
        productId = value
      }
    })
    const productPromise = this.getProductInfo(productId)
    const [productPromiseResult] = await Promise.all([productPromise])
    this.setState({ currentProductInfo: productPromiseResult })
    if (!productPromiseResult.can_purchase) return
    this.renderButton()
  }

  async getProductsPrice(productInfoArr, currencyCode) {
    const channelId = window.jsContext?.channelId
    const customGroupId = window.jsContext?.customer?.customer_group_id
    const storeHash = window.jsContext?.store_hash

    const productsData = await this.api.getProductsPrice({
      storeHash,
      channel_id: channelId || 1,
      currency_code: currencyCode,
      items: productInfoArr,
      customer_group_id: customGroupId || 0,
    })

    return productsData
  }

  getCalculatedAndBulkPrice(productPriceInfo, quantity) {
    const {
      calculated_price: {
        tax_exclusive: taxExclusivePrice,
        tax_inclusive: taxInclusivePrice,
        as_entered: enteredPrice,
        entered_inclusive: enteredInclusive,
      },
      bulk_pricing: bulkPricing,
    } = productPriceInfo

    let asEntered = enteredPrice

    const tax = taxInclusivePrice - taxExclusivePrice
    const taxRate = +tax / taxExclusivePrice

    let itemTotalTaxPrice = 0
    let singlePrice = 0
    if (bulkPricing.length) {
      const basePrice = enteredPrice
      asEntered = this.getNewBulkPrice(bulkPricing, +asEntered, quantity, basePrice)
    }

    if (enteredInclusive) {
      itemTotalTaxPrice = asEntered
      singlePrice = asEntered / (1 + taxRate)
    } else {
      singlePrice = asEntered
      itemTotalTaxPrice = asEntered * (1 + taxRate)
    }

    const taxPrice = singlePrice * taxRate
    const itemPrice = !enteredInclusive ? singlePrice : itemTotalTaxPrice

    this.setState({
      enteredInclusive: enteredInclusive || false,
    })
    return {
      asEntered: +itemPrice.toFixed(2),
      taxPrice: +taxPrice.toFixed(2),
      bulkPricing,
      realPriceExclusive: singlePrice,
      realPriceInclusive: itemTotalTaxPrice,
    }
  }

  async getProduct(isRfqSearch, isPDPQuickViewModal, productId, hasOptions) {
    const {
      utils: { normalizeFormData },
      isPDP,
    } = this
    let container
    let $form
    let sku
    if (isRfqSearch) {
      const isHasOptions = hasOptions === 'hasOptions'
      $form = isHasOptions ? document.querySelector('[data-option-form]') : document.querySelector(`#search-product-form-${productId}`)
      const optionSku = isHasOptions && document.querySelector('.edit-product-sku')?.innerHTML
      sku = optionSku || this.state.searchProductInfo.sku
    } else {
      container = isPDPQuickViewModal ? '#modal' : isPDP ? '' : '#modal'
      $form = document.querySelector(isPDPQuickViewModal ? containers['addToCart.quickView.container'] : this.actionWarper)?.parentNode
      sku = this.utils.trim(
        document.querySelector(`${container} [data-product-sku]`)?.innerHTML ?? '',
      )
    }

    const formData = Array.from(normalizeFormData(new FormData($form)))

    const product = {
      sku,
      basePrice: 0,
      discount: 0,
      offeredPrice: 0,
      quantity: 0,
      productId: '',
      variantId: '',
      imageUrl: '',
      productName: '',
      options: [],
    }

    const optionList = this.getProductOptionList(formData, product)

    if (!isRfqSearch) {
      this.setState({
        isAdding: true,
      })
      this.renderButton(isPDPQuickViewModal)
    }

    const productPromise = this.getProductInfo(product.productId)
    const pricePromise = this.api.getPrice(product.productId, optionList)

    const [productPromiseResult, pricePromiseResult] = await Promise.all([
      productPromise,
      pricePromise,
    ])

    if (!isRfqSearch) {
      this.setState({
        isAdding: false,
      })
      this.renderButton(isPDPQuickViewModal)
    }

    const {
      title: productName = '',
      main_image = {
        data: '',
      },
      options: baseOptions,
    } = productPromiseResult ?? {}
    const imageUrl = main_image?.data.replace('{:size}', 'original') ?? ''

    const canAddToQuote = this.isAllRequiredOptionFilled(baseOptions, optionList)

    if (!canAddToQuote) {
      return Promise.reject()
    }

    const {
      v3_variant_id: variantId,
      price,
      sku: variantSku,
    } = pricePromiseResult ?? {}

    if (!variantSku) {
      this.utils.Alert.error(this.text['validation.productWithoutSKU'])
      return Promise.reject()
    }

    const priceContainer = price?.with_tax || price?.without_tax || {}

    const { value: basePrice, currency } = priceContainer

    const {
      modifiers,
      hasPriceList,
      variantPrice,
      bulkPrices,
    } = await this.loadProduct(product.productId, variantId)
    let options = this.getProductOptions(optionList, baseOptions)

    options = this.getModifierAdjuster(options, modifiers)

    const calculatedPrice = this.getCalculatedPrice(product.quantity, bulkPrices, variantPrice, hasPriceList, options)

    const productAllOptions = {
      baseOptions,
    }
    const checkedProductInfo = {
      options,
      productId: product.productId,
      variantId,
    }

    const productInfo = this.getCalculatedParams(checkedProductInfo, productAllOptions)

    const proxyPriceInfo = await this.getProductsPrice(productInfo, currency)

    const productData = proxyPriceInfo[0]

    const priceInfo = this.getCalculatedAndBulkPrice(productData, product.quantity)

    return {
      ...product,
      variantId,
      basePrice: priceInfo.asEntered || basePrice,
      currency,
      variantPrice: priceInfo.asEntered || variantPrice,
      offeredPrice: priceInfo.asEntered || calculatedPrice,
      productName,
      imageUrl,
      options,
      hasPriceList,
      bulkPrices: priceInfo.bulkPricing || bulkPrices,
      taxPrice: priceInfo.taxPrice,
      productPriceInfo: productData,
      realPrice: priceInfo,
    }
  }

  getProductInfo(productId) {
    return new Promise((resolve, reject) => {
      stencilUtils.api.product.getById(
        productId,
        {
          template: 'b3/b3json',
        },
        (err, response) => {
          if (err) reject(err)
          resolve(JSON.parse(response)?.product ?? {})
        },
      )
    })
  }

  getCart() {
    return new Promise((resolve, reject) => {
      stencilUtils.api.cart.getCart(
        {
          includeOptions: true,
        },
        (err, response) => {
          if (err) reject(err)
          resolve(response)
        },
      )
    })
  }

  async getProducts() {
    this.setState({
      isAdding: true,
    })
    this.renderButton()
    const { productTypes } = this.state

    const cart = await this.getCart()
    const { lineItems, id: cartId } = cart
    const currency = cart.currency.code

    const cartProductsList = []

    productTypes.forEach(productType => {
      if (lineItems[productType].length > 0) {
         lineItems[productType].forEach(product => {
          if (!product.parentId) {
            cartProductsList.push(product)
          }
         })
      }
    })

    const productsWithSKU = cartProductsList.filter(({ sku }) => sku !== '' && sku !== null && sku !== undefined)
    localStorage.setItem(
      'cartToQuoteId',
      JSON.stringify(cartId),
    )

    const productIdInfo = []
    let cartProducts = productsWithSKU.map(
      ({
        sku,
        salePrice: basePrice,
        imageUrl,
        productId,
        name: productName,
        quantity,
        variantId,
        options,
      }) => {
        productIdInfo.push({
          productId: +productId,
          variantId: +variantId,
        })

        return {
          sku,
          basePrice,
          discount: 0,
          offeredPrice: basePrice,
          quantity,
          productId,
          variantId,
          imageUrl,
          productName,
          currency,
          options: options.map(
            ({
              name: optionName,
              nameId: optionId,
              value: optionLabel,
              valueId: optionValue,
            }) => ({
              optionId,
              optionValue: optionValue || optionLabel,
              optionName,
              optionLabel,
            }),
          ),
        }
      },
    )

    if (productIdInfo.length) {
      const baseProducts = await this.getBulkLoadProducts(productIdInfo)

      this.setState({
        isAdding: false,
      })
      const productInfoArr = []
      cartProducts.forEach(product => {
        const {
          productId,
          variantId,
          options,
        } = product
        const currentProduct = baseProducts.find(baseProduct => +baseProduct.id === +productId)

        const productAllOptions = {
          baseOptions: currentProduct.allOptions,
        }
        const checkedProductInfo = {
          options,
          productId,
          variantId,
        }

        const productInfo = this.getCalculatedParams(checkedProductInfo, productAllOptions)
        if (productInfo.length > 0) {
          productInfoArr.push(productInfo[0])
        }
      })

      const proxyPriceInfo = await this.getProductsPrice(productInfoArr, currency)

      const productsData = proxyPriceInfo
      this.renderButton()

      cartProducts = cartProducts.map(product => {
        const {
          productId,
          options,
          variantId,
          quantity,
          basePrice,
          offeredPrice,
        } = product
        const matchedProduct = baseProducts.find(baseProduct => +baseProduct.id === +productId)
        // const hasUnValidOption = options.some(({ optionValue, optionLabel }) => !optionLabel || !optionValue)
        const productPriceInfo = productsData.find(product => +product.variant_id === variantId)

        const priceInfo = this.getCalculatedAndBulkPrice(productPriceInfo, quantity)

        product.basePrice = priceInfo.asEntered || basePrice
        product.offeredPrice = priceInfo.asEntered || offeredPrice
        product.taxPrice = priceInfo.taxPrice
        product.productPriceInfo = productPriceInfo
        product.realPrice = priceInfo

        if (matchedProduct && matchedProduct.allOptions) {
          return {
            ...product,
            options: this.getProductOptions(options, matchedProduct.allOptions, 'isCartProduct'),
          }
        }

        return product
      })
    }

    this.setState({
      isAdding: false,
    })
    this.renderButton()

    return cartProducts
  }

  toggleClasses = (el, showClass, hideClass) => {
    el.classList.add(showClass)
    el.classList.remove(hideClass)
  }

  detectSubmitButtonEnabled = () => {
    const { productList } = this.state
    const { $ref } = this.rfqForm
    const $submit = $ref.querySelector('.button.submit')
    productList.length
      ? $submit.removeAttribute('disabled')
      : $submit.setAttribute('disabled', true)
  };

  async renderForm() {
    if (this.isOpenSigleLimitError('quote')) {
      this.sigleLimitErrorDialog('quote')
      return
    }
    await this.preFillFormInfo()

    const { renderTemplate } = this.utils

    const $modalBackground = document.querySelector('.modal-background')
    const $productModal = document.querySelector('#modal')
    if ($modalBackground && $productModal) {
      $modalBackground.style.display = 'none'
      $productModal.removeAttribute('style')
      $productModal.classList.remove('modal--large', 'open')
    }

    if (this.rfqForm.$ref) {
      this.rfqForm.$ref.show()
      return
    }

    renderTemplate({
      hbsTemplate: this.tpls.quoteForm,
      containerSelector: 'body',
      templateConfig: {
        productList: this.state.productList,
      },
      insertType: 'beforeend',
    })

    this.handleRenderSearchProducts()

    const $ref = document.querySelector('.rfq-form-wrapper')
    if ($ref && !$ref.show) {
      const [$mask, $content] = $ref.children
      const $body = document.body
      const $editContainer = $ref.querySelector('.quote-edit-container')
      const $successContainer = $ref.querySelector('.quote-success-container')
      this.handleRfqClose()

      $ref.show = () => {
        this.toggleClasses($mask, 'fadeIn', 'fadeOut')
        this.toggleClasses($content, 'show', 'hide')
        this.toggleClasses($editContainer, 'show', 'hide')
        this.toggleClasses($successContainer, 'hide', 'show')
        $body.style.overflowY = 'hidden'
        this.renderFormBody()
        this.initAddressInfo()
        this.renderProductList()
        this.renderMessage()
        if (this.isB2BUser) {
          this.renderAttachFiles()
        }
        this.detectSubmitButtonEnabled()
      }

      $ref.hide = () => {
        this.toggleClasses($mask, 'fadeOut', 'fadeIn')
        this.toggleClasses($content, 'hide', 'show')
        $body.style.overflowY = 'auto'
      }
    }

    this.handleCloseSearchProductList()
    this.setState({
      $ref,
    })
    $ref.show()
  }

  hideForm() {
    this.editB3Modifiers?.listenOptionChange('off')

    const { $ref } = this.rfqForm
    const $loading = $ref.querySelector('.loadingOverlay')
    $loading.classList.remove('show')
    const $addressBookContainer = document.querySelector('.address-book-container')
    const $editOptionsContainer = document.querySelector('.edit-options-container')
    const $addressMask = document.querySelector('.edit-mask')
    if ($addressBookContainer.classList.contains('show') || $editOptionsContainer.classList.contains('show')) {
      this.toggleClasses($addressMask, 'fadeOut', 'fadeIn')
      this.setState({ addressId: '' })
      this.handleBackToQuoteForm()
      this.initAddressInfo()
      return
    }
    const { quoteFormStatus } = this.state
    this.rfqForm.$ref.hide()
    this.handleClearSearchProduct()
    if (quoteFormStatus === 1) {
      this.setState({
        productList: [],
        formData: this.basicFormData,
        quoteFormStatus: 0,
      })

      localStorage.removeItem('B3CreateQuoteProductList')
      localStorage.removeItem('B3CreateQuoteFormData')
    }
  }

  handleBackToQuoteForm() {
    const $addressBookContainer = document.querySelector('.address-book-container')
    const $quoteEitContainer = document.querySelector('.quote-edit-container')
    const $addressMask = document.querySelector('.edit-mask')
    const $editOptionsContainer = document.querySelector('.edit-options-container')
    this.toggleClasses($addressMask, 'fadeOut', 'fadeIn')
    this.toggleClasses($quoteEitContainer, 'show', 'hide')
    this.toggleClasses($addressBookContainer, 'hide', 'show')
    this.toggleClasses($editOptionsContainer, 'hide', 'show')
    this.setState({
      selectAddressType: '',
    })
  }

  async getCountries() {
    try {
      let countries = sessionStorage.getItem('countries')
      if (!countries) {
        const countrieslist = await this.api.getCountries(this.context.settings.store_hash)
        countries = JSON.stringify(countrieslist.list)
        sessionStorage.setItem('countries', countries)
      }
      this.setState({
        countries: JSON.parse(countries).map(item => ({
          ...item,
          countryData: JSON.stringify(item),
        })),
      })
    } catch {
      this.utils.Alert.error(this.locales.tips.globalError)
    }
  }

  handleSelectStateByCountry(country) {
    const { countries } = this.state

    return countries.filter(currentCountry => (currentCountry.countryName === country || currentCountry.countryCode === country))[0]?.states || []
  }

  renderFormBody(stateStatus = {}, fieldInfo) {
    if (fieldInfo) {
      this.handleFieldInfo(fieldInfo)
      return
    }

    const {
      formData: {
        referenceNumber,
        contactInfo: {
          name, email, companyName, phoneNumber,
        },
        quoteTitle,
        shippingAddress: {
          country,
          state,
          city,
          zipCode,
          address,
          apartment,
          firstName,
          lastName,
          label,
          phoneNumber: addressPhoneNumber,
        },
        // expiredAt,
        billingAddress: {
          country: billingCountry,
          state: billingState,
          city: billingCity,
          zipCode: billingZipCode,
          address: billingAddress,
          apartment: billingApartment,
          firstName: billingFirstName,
          lastName: billingLastName,
          label: billingLabel,
          phoneNumber: billingPhoneNum,
        },
      },
      countries,
      isCopyShipping,
    } = this.state
    const { isB2CUser, isB2BUser } = this

    const $bodyContainer = this.rfqForm.$ref.querySelector('.body-container')

    if (country || billingCountry) {
      const shippingStates = this.handleSelectStateByCountry(country)
      const billingStates = this.handleSelectStateByCountry(billingCountry)

      stateStatus = {
        stateIsSelect: shippingStates.length,
        stateSelectOptions: shippingStates,
        billingIsSelect: billingStates.length,
        billingStates,
      }
    }

    const {
      stateIsSelect,
      stateSelectOptions,
      billingStates,
      billingIsSelect,
    } = stateStatus

    const formFields = [
      {
        type: 'title',
        title: this.text['rfq.form.title.quoteInfo'],
      },
      {
        label: this.text['rfq.form.field.contactEmail'],
        name: 'contactInfo.email',
        required: true,
        value: email,
      },
      {
        label: this.text['rfq.form.field.contactName'],
        name: 'contactInfo.name',
        required: true,
        value: name,
      },
      {
        label: this.text['rfq.form.field.companyName'],
        name: 'contactInfo.companyName',
        value: companyName,
      },
      {
        label: this.text['rfq.form.field.phoneNumber'],
        name: 'contactInfo.phoneNumber',
        value: phoneNumber,
      },
      {
        label: this.text['rfq.form.field.quoteTitle'],
        name: 'quoteTitle',
        value: quoteTitle,
      },
      {
        label: this.text['rfq.form.field.referenceNumber'],
        name: 'referenceNumber',
        value: referenceNumber,
      },
      { type: 'divide' },
      {
        type: 'title',
        title: this.text['rfq.form.title.addressInfo'],
        address: (isB2BUser || isB2CUser),
        name: 'shipping',
      },
      {
        label: this.text['rfq.form.title.addressLabel'],
        name: 'shippingAddress.label',
        value: label,
        isB2BField: true,
        isBlock: true,
      },
      {
        label: this.text['rfq.form.field.firstName'],
        name: 'shippingAddress.firstName',
        value: firstName,
      },
      {
        label: this.text['rfq.form.field.lastName'],
        name: 'shippingAddress.lastName',
        value: lastName,
      },
      {
        label: this.text['rfq.form.field.address'],
        name: 'shippingAddress.address',
        value: address,
      },
      {
        label: this.text['rfq.form.field.apartment'],
        name: 'shippingAddress.apartment',
        value: apartment,
      },
      {
        label: this.text['rfq.form.field.city'],
        name: 'shippingAddress.city',
        value: city,
      },
      {
        label: this.text['rfq.form.field.country'],
        name: 'shippingAddress.country',
        value: country,
        isSelect: true,
        selectOptions: countries,
        type: 'selectCountry',
      },
      {
        label: this.text['rfq.form.field.state'],
        name: 'shippingAddress.state',
        value: state,
        isSelect: stateIsSelect || false,
        selectOptions: stateSelectOptions || [],
        type: 'select',
      },
      {
        label: this.text['rfq.form.field.zipCode'],
        name: 'shippingAddress.zipCode',
        value: zipCode,
      },
      {
        label: this.text['rfq.form.field.phoneNumber'],
        name: 'shippingAddress.phoneNumber',
        value: addressPhoneNumber,
      },
      {
        type: 'checkbox',
        label: this.text['rfq.form.field.sameAddressTip'],
        isCopyShipping,
      },
      { type: 'divide' },
      {
        type: 'title',
        title: this.text['rfq.form.title.billingAddressInfo'],
        address: (isB2BUser || isB2CUser),
        name: 'billing',
      },
      {
        label: this.text['rfq.form.title.addressLabel'],
        name: 'billingAddress.label',
        value: billingLabel,
        isB2BField: true,
        isBlock: true,
      },
      {
        label: this.text['rfq.form.field.firstName'],
        name: 'billingAddress.firstName',
        value: billingFirstName,
      },
      {
        label: this.text['rfq.form.field.lastName'],
        name: 'billingAddress.lastName',
        value: billingLastName,
      },
      {
        label: this.text['rfq.form.field.address'],
        name: 'billingAddress.address',
        value: billingAddress,
      },
      {
        label: this.text['rfq.form.field.apartment'],
        name: 'billingAddress.apartment',
        value: billingApartment,
      },
      {
        label: this.text['rfq.form.field.city'],
        name: 'billingAddress.city',
        value: billingCity,
      },
      {
        label: this.text['rfq.form.field.country'],
        name: 'billingAddress.country',
        value: billingCountry,
        isSelect: true,
        selectOptions: countries,
        type: 'selectCountry',
      },
      {
        label: this.text['rfq.form.field.state'],
        name: 'billingAddress.state',
        value: billingState,
        isSelect: billingIsSelect || false,
        selectOptions: billingStates || [],
        type: 'select',
      },
      {
        label: this.text['rfq.form.field.zipCode'],
        name: 'billingAddress.zipCode',
        value: billingZipCode,
      },
      {
        label: this.text['rfq.form.field.phoneNumber'],
        name: 'billingAddress.phoneNumber',
        value: billingPhoneNum,
      },
      { type: 'divide' },
    ]

    const $formBody = this.tpls.formBody({
      formFields: this.isB2BUser ? formFields : formFields.filter(({ isB2BField }) => !isB2BField),
      isLogin: this.isLogin,
    })
    $bodyContainer.innerHTML = $formBody

    $bodyContainer
      .querySelectorAll('[data-blur]')
      .forEach(el => el.addEventListener('change', this.handleInputFieldBlur))

    $bodyContainer
      .querySelectorAll('[data-select-change]')
      .forEach(el => el.addEventListener('change', this.handleInputFieldBlur))
    this.handleGetCountryInput()
  }

  handleFieldInfo({ fieldName, fieldValue }) {
    const {
      isCopyShipping,
    } = this.state

    if (isCopyShipping) {
      const needChangeFieldName = fieldName.replace('shippingAddress', 'billingAddress')
      document.querySelector(`[name="${needChangeFieldName}"]`).value = fieldValue
    }

    if (fieldName.includes('billingAddress')) {
      document.querySelector('#copy-address').checked = false
    }
  }

  handlePreventDefault(e) {
    e.preventDefault()
  }

  setOptionsSelected($el, value, type) {
    if (value) {
      for (let i = 0; i < $el.options.length; i += 1) {
        const isSelect = type === 'country' ? (value === $el.options[i].value || value === $el.options[i]?.dataset?.code) : (value === $el.options[i].value)
        if (isSelect) {
          $el.options[i].selected = 'selected'
        } else {
          $el.options[i].selected = ''
        }
      }
    }
  }

  handleInputFieldBlur = async e => {
    const {
      name: field,
      value,
      dataset: { required, label },
    } = e.target

    const { formData } = this.state

    const isFieldValid = await this.validateField({
      field,
      label,
      value,
      required,
      el: e.target,
    })

    if (!isFieldValid) return

    const fieldKeys = field.includes('.') ? field.split('.') : [field]

    let fieldValue = formData

    fieldKeys.forEach((key, i) => {
      if (i === fieldKeys.length - 1) fieldValue[key] = value
      else fieldValue = fieldValue[key]
    })

    if (field === 'shippingAddress.country') {
      const $stateEl = document.getElementsByName('shippingAddress.state')[0]
      formData.state = ''
      if ($stateEl) {
        $stateEl.value = ''
      }
    }
    if (field === 'billingAddress.country') {
      const $stateEl = document.getElementsByName('billingAddress.state')[0]
      if ($stateEl) {
        $stateEl.value = ''
      }
    }

    if (fieldKeys[0] === 'billingAddress') {
      this.setState({
        isCopyShipping: false,
      })
    }

    localStorage.setItem('B3CreateQuoteFormData', JSON.stringify(formData))
    // this.handleCopyAddressFromShipping()
    this.handleGetCountryInput()
    setTimeout(() => {
      this.renderFormBody(null, {
        fieldName: field,
        fieldValue: value,
      })
    }, 150)
  };

  async validateField({
    field, label, value, required, el,
  }) {
    const { re } = this.utils
    const { store_hash: storeHash } = this.context.settings

    let isFieldValid = true

    const validationSchema = {
      'contactInfo.email': async email => {
        if (!re.email.test(email)) return this.locales.validation['emailIncorrect']
        if (!this.isLogin) {
          let isEmailExist = true
          try {
            await this.api.detectUserEmailExist({
              email,
              storeHash,
              SHOWERROR: false,
            })
          } catch ({ code }) {
            if (code === 40010) isEmailExist = false
          }
          return isEmailExist ? this.locales.validation['emailExist'] : null
        }
        return null
      },
      'contactInfo.phoneNumber': value => {
        if (!re.phone.test(value)) return this.locales.validation['phoneIncorrect']
        return null
      },
    }

    const requiredErrorMessage = this.utils.text('rfq.form.label.isRequired', {
      hash: {
        label,
      },
    })

    if (required && !validationSchema[field]) {
      this.toggleFieldStatus(el, !!value, requiredErrorMessage)
      isFieldValid = !!value
    } else {
      const validate = validationSchema[field] ?? (() => null)
      const unValidMessage = await validate(value)
      this.toggleFieldStatus(el, !unValidMessage, unValidMessage)
      isFieldValid = !unValidMessage
    }

    return isFieldValid
  }

  toggleFieldStatus(el, status, errorMessage) {
    const $parent = el.parentNode
    const $error = `<span class="form-inlineMessage">${errorMessage}</span>`

    $parent.querySelector('.form-inlineMessage')?.remove()
    if (status) {
      this.toggleClasses($parent, 'form-field--success', 'form-field--error')
    } else {
      this.toggleClasses($parent, 'form-field--error', 'form-field--success')
      $parent.insertAdjacentHTML('beforeend', $error)
    }
  }

  bindDatePicker($container) {
    const { DateTime, getStoreZoneDate } = this.utils
    const $end = $container.querySelector('[name="expiredAt"]')

    const setDatePicker = $el => {
      window
        .B3DatePicker($el, {
          min: getStoreZoneDate(),
          mode: 'dp-modal',
          format(date) {
            return window.B3DisplayFormat(date)
          },
          parse(dateStr) {
            const date = new Date(DateTime.displayParse(dateStr))
            return isNaN(date) ? new Date() : date
          },
        })
        .on({
          close: () => {
            $el.blur()
          },
        })
    }

    setDatePicker($end, 'endDateAt')
  }

  handleSetProduct() {
    const { productList } = this.state
    if (productList.length === 0) {
      const stagedProductList = localStorage.getItem('B3CreateQuoteProductList')
      this.setState({
        productList: stagedProductList ? JSON.parse(stagedProductList) : [],
      })
    }
  }

  handleCloseSearchProductList() {
    const $formContainer = document.querySelector('.form-container')
    $formContainer.addEventListener('click', e => {
      const { className } = e.target
      const $searchResultsList = document.querySelector('.search-results-list')
      if ($searchResultsList) {
        const targetName = 'form-search-products-item'
        const isRfqSearchProductsList = className === targetName || e.target.parentNode.className === targetName || e.target.parentNode.parentNode.className === targetName || e.target.parentNode.parentNode.parentNode.className === targetName
        const isSearchInput = className === 'form-products-input'
        const isSearchBtn = className === 'button button--search' || className === 'searchBtn-container' || className === 'fa fa-search'
        if (!isRfqSearchProductsList && !isSearchInput && !isSearchBtn) {
          this.handleClearSearchProduct()
        }

        if (isSearchInput) {
          $searchResultsList.parentNode.removeChild($searchResultsList)
        }

        if (isSearchBtn) {
          this.handleSearchProducts()
        }
      }
    })
  }

  handleShowViewMyQuotes(searchProduct) {
    const viewMyQuote = {
      showCancelButton: !searchProduct,
      cancelButtonText: searchProduct ? '' : `<a href="javascript:void(0);" data-click="renderForm" style="color: #5161bc">${this.text['rfq.button.viewQuote']}</a>`,
      cancelButtonColor: 'white',
      customClass: 'add-quote-success-container',
    }

    this.utils.Alert.success(this.locales.tips['addToQuoteSuccess'], viewMyQuote)

    if (searchProduct) {
      this.renderForm()
      this.handleClearSearchProduct()
      this.renderProductList()
      this.triggerQuoteNumber()
    }
  }

  handleRenderSearchProducts() {
    const {
      searchProducts,
      searchProductQ,
      isShowSearchProduct,
      showInclusiveTaxPrice = false,
    } = this.state
    const $searchProductContainer = document.querySelector('.products-search-container')

    const $searchProducts = this.tpls.searchProducts({
      isShowSearchProduct,
      searchProducts,
      q: searchProductQ,
      showIncTaxPrice: showInclusiveTaxPrice,
    })
    $searchProductContainer.innerHTML = $searchProducts
    const $searchProductsBtn = document.querySelector('#search-products-input')
    const $searchProductList = document.querySelector('.search-results-list')

    $searchProductsBtn.addEventListener('keydown', this.handleSearchProductsEnter)
    if ($searchProductList) {
      $searchProductList.querySelectorAll('.form-search-products-item').forEach(el => el.addEventListener('click', e => this.handleSelectSearchProduct(e)))
    }
  }

  handleSearchProductsEnter = e => {
    if (e.keyCode === 13) {
      this.handleSearchProducts()
    }
  }

  handleClearSearchProduct() {
    this.setState({
      searchProductQ: '',
      searchProducts: [],
      isShowSearchProduct: false,
    })

    this.handleRenderSearchProducts()
  }

  async handleSelectSearchProduct(e) {
    const { $ref } = this.rfqForm
    const { hasOptions, productId } = e?.target?.dataset
    const productPromise = this.getProductInfo(productId)
    const productQty = document.querySelector(`#qty-${productId}`)
    const $loading = $ref.querySelector('.loadingOverlay')
    const height = document.querySelector('.form-container').clientHeight + 50
    $loading.style.height = `${height}px`
    $loading.classList.add('show')

    try {
      const result = await Promise.resolve(productPromise)
      productQty.value = result.min_purchase_quantity === 0 ? 1 : result.min_purchase_quantity

      if (!result.can_purchase) {
        this.utils.Alert.error(this.text['rfq.form.edit.options.selectTip'])
        $loading.classList.remove('show')
        return
      }
      const isHasOptions = hasOptions === 'true' || result.options.length !== 0

      this.setState({
        searchProductInfo: result,
      })

      if (isHasOptions) {
        this.handleSelectSearchProductsOptions(productId)
      } else {
        this.handleAddNoOptionsProduct(productId)
      }
    } catch (e) {
      console.error(e)
    }
  }

  async handleAddNoOptionsProduct(productId) {
    const { $ref } = this.rfqForm
    const $loading = $ref.querySelector('.loadingOverlay')
    try {
      const product = await this.getProduct(true, null, productId)
      const { productList } = this.state
      const { currency: productCurrencyCode } = productList[0] || {}
      if (productCurrencyCode && productCurrencyCode !== product.currency) {
        this.utils.Alert.error(this.locales.tips['addToQuoteDiffCurrency'])
        return
      }

      this.assignProductToList(product)
      this.handleShowViewMyQuotes(true)
    } catch (err) {
      console.error(err)
    } finally {
      $loading.classList.remove('show')
    }
  }

  handleSelectSearchProductsOptions(productId) {
    const { $ref } = this.rfqForm
    const $loading = $ref.querySelector('.loadingOverlay')
    const productQty = document.querySelector(`#qty-${productId}`).value
    const { searchProductInfo, showInclusiveTaxPrice } = this.state
    const {
      main_image,
      options,
      title,
      sku,
      price,
    } = searchProductInfo
    const imageUrl = main_image?.data.replace('{:size}', 'original') ?? ''
    const productOptionInfo = {
      productName: title,
      price: showInclusiveTaxPrice ? price?.with_tax?.formatted : price?.without_tax?.formatted,
      sku,
      productId,
      imageUrl,
      options,
      productQty,
    }
    const $editOptionsContainer = document.querySelector('.edit-options-container')
    const $addressMask = document.querySelector('.edit-mask')
    this.toggleClasses($editOptionsContainer, 'show', 'hide')
    this.toggleClasses($addressMask, 'fadeIn', 'fadeOut')

    $editOptionsContainer.innerHTML = this.tpls.editOptions({
      ...productOptionInfo,
      imageUrl: imageUrl || DEFAULT_IMAGE_URL,
      moduleTitle: this.text['rfq.form.search.selectOptions.title'],
      isEdit: false,
      isSearchProduct: true,
    })

    this.editB3Modifiers = new this.B3Modifiers({
      cb: data => {
        // handle unavailable product
        const $saveProductBtn = document.querySelector('.cancel-save-options')
        const $ProductTip = document.querySelector('.rfq-productTip-container')
        if ($saveProductBtn) {
          $saveProductBtn.disabled = !data.purchasable || !data.sku
        }
        if ($ProductTip) {
          $ProductTip.style.display = (data.purchasable && data.sku) ? 'none' : 'block'
        }
      },
    })

    this.editB3Modifiers.render('.edit-options-container [data-product-option-change]', 'beforeend', options)
    this.editB3Modifiers?.listenOptionChange()
    this.editB3Modifiers.optionChangeCallback()
    $loading.classList.remove('show')
  }

  async handleSaveSearchProducts() {
    const { $ref } = this.rfqForm
    const $loading = $ref.querySelector('.loadingOverlay')
    const height = document.querySelector('.form-container').clientHeight + 50
    $loading.style.height = `${height}px`
    $loading.classList.add('show')
    const productId = document.querySelector('[name="product_id"]').value
    try {
      const product = await this.getProduct(true, null, productId, 'hasOptions')
      const { productList } = this.state
      const { currency: productCurrencyCode } = productList[0] || {}
      if (productCurrencyCode && productCurrencyCode !== product.currency) {
        this.utils.Alert.error(this.locales.tips['addToQuoteDiffCurrency'])
        return
      }

      this.assignProductToList(product)

      this.handleShowViewMyQuotes(true)
      this.hideForm()
    } catch (err) {
      console.error(err)
    } finally {
      $loading.classList.remove('show')
    }
  }

  handleSearchProducts() {
    const { $ref } = this.rfqForm
    const q = document.querySelector('#search-products-input')?.value
    const $loading = $ref.querySelector('.loadingOverlay')
    const height = document.querySelector('.form-container').clientHeight + 50
    $loading.style.height = `${height}px`

    this.setState({
      searchProductQ: q,
    })
    if (q.length > 0) {
      $loading.classList.add('show')
      stencilUtils.api.search.search(q, {
        template: 'b3/b3json',
      }, async (err, response) => {
        if (err) return null
        const newResponse = handleStencilSearchInfo(response)
        const {
          product_results: {
            products = [],
          },
        } = JSON.parse(newResponse)
        const productsList = products.map(product => {
          const imageUrl = product.image ? product.image.data.replace('{:size}', 'original') : DEFAULT_IMAGE_URL
          product.imageUrl = imageUrl

          return product
        })

        const simpleProducts = productsList.filter(({ has_options }) => !has_options)

        const variants = productsList.filter(({ has_options }) => has_options)

        const validatedSimpleProducts = simpleProducts.filter(({ sku }) => sku !== '' && sku !== null && sku !== undefined)

        let visibleProducts = []

        visibleProducts = variants.concat(validatedSimpleProducts)

        if (this.isB2BUser && this.rfqForm.data.companyId) {
          const variantSkus = validatedSimpleProducts.map(({ sku }) => sku)

          const productsByPost = await this.api.getProductsBySkuQuickByPost({ variantSkus })

          const purchasableProducts = productsList.filter(({ sku }) => {
            const product = productsByPost.find(({ baseSku: baseSkuByPost }) => baseSkuByPost === sku)

            return product ? !(product.purchasingDisabled * 1) : product
          })

          visibleProducts = variants.concat(purchasableProducts)
        }

        $loading.classList.remove('show')

        this.setState({
          isShowSearchProduct: true,
          searchProducts: visibleProducts,
        })
        this.handleRenderSearchProducts()
      })
    }
  }

  renderProductList() {
    this.handleSetProduct()
    const { productList, currency, showInclusiveTaxPrice } = this.state
    const { currencyFormat } = this.utils
    const $productsContainer = this.rfqForm.$ref.querySelector(
      '.products-container',
    )

    const products = productList.map(item => {
      const {
        options,
        imageUrl,
        realPrice: {
          realPriceExclusive,
          realPriceInclusive,
        },
      } = item
      const newOfferedPrice = showInclusiveTaxPrice ? realPriceInclusive : realPriceExclusive

      return {
        formatBasePrice: currencyFormat(newOfferedPrice, false, false, currency),
        isEditable: options.length > 0,
        ...item,
        imageUrl: imageUrl || DEFAULT_IMAGE_URL,
      }
    })

    const $formProducts = this.tpls.formProducts({
      productList: products,
    })

    $productsContainer.innerHTML = $formProducts
  }

  handleSearchAddressEnter = e => {
    if (e.keyCode === 13) {
      this.handleSearchAddress()
    }
  }

  async initAddressInfo() {
    const {
      isB2CUser,
      isB2BUser,
      context: {
        settings: { store_hash: storeHash },
      },
    } = this
    const customerId = this.context.customer?.id
    const { B3CompanyId } = this.utils.B3Storage
    const companyId = B3CompanyId.value
    const {
      addressPagination: { offset, limit },
      q,
    } = this.state
    if (!(isB2BUser || isB2CUser)) {
      this.renderAddressListTpls()
      return
    }
    window.B3Spinner.show()
    try {
      if (isB2CUser) {
        const { pagination, list } = await this.api.getBcAddressBook({ storeHash, customerId })
        const addressList = list.map(item => this.formatAddress(item))
        this.setState({
          addressPagination: pagination,
          addressList,
          isCanSearchAddress: !isB2CUser,
        })
      }
      if (isB2BUser && companyId) {
        const { pagination, list } = await this.api.getAddressBookBySearch(companyId, {
          offset,
          limit,
          q,
        })
        const addressList = list.map(item => this.formatAddress(item))
        this.setState({
          addressPagination: pagination,
          addressList,
          q: '',
        })
      } else if (isB2BUser && !companyId) {
        const { pagination, list } = await this.api.getBcAddressBook({ storeHash, customerId })
        const addressList = list.map(item => this.formatAddress(item))
        this.setState({
          addressPagination: pagination,
          addressList,
          isCanSearchAddress: false,
        })
      }
      this.renderAddressListTpls()
    } catch {
      this.utils.Alert.error(this.locales.tips.globalError)
    }
    window.B3Spinner.hide()
  }

  renderAddressListTpls() {
    const {
      addressList,
      isCanSearchAddress,
      selectAddressType,
    } = this.state
    const newAddressList = addressList.filter(item => (item.isShipping === '1' && selectAddressType === 'shipping')
      || (item.isBilling === '1' && selectAddressType === 'billing'))

    const $addressBookContainer = document.querySelector('.address-book-container')
    $addressBookContainer.innerHTML = this.tpls.selectAddress({ isCanSearchAddress, addressList: newAddressList })
    if (!newAddressList.length && $addressBookContainer && !$addressBookContainer.show) {
      const $addressListContainer = document.querySelector('.no-data')
      $addressListContainer.innerHTML = `<p class="no-saved-address">${this.text['rfq.form.address.noData']}</p>`
      return
    }
    if (isCanSearchAddress) {
      const searchInput = document.querySelector('#search-address-input')
      searchInput.addEventListener('keydown', this.handleSearchAddressEnter)
    }
  }

  handleSearchAddress() {
    const q = document.querySelector('#search-address-input')?.value
    this.setState({
      q,
      addressId: '',
      addressList: [],
    })
    this.initAddressInfo()
  }

  handleClearAddress() {
    this.setState({
      addressId: '',
      addressList: [],
      q: '',
    })
    this.initAddressInfo()
  }

  handleAddressItemClick(e) {
    const addressId = e.target.dataset.value
    const { selectAddressType } = this.state
    this.setState({
      addressId,
    })
    const {
      addressList,
      formData,
      formData: {
        shippingAddress,
        billingAddress,
      },
    } = this.state
    const selectedAddress = addressList.find(address => +addressId === +address.addressId)
    let newFormData

    if (selectedAddress) {
      if (selectAddressType === 'shipping') {
        newFormData = {
          ...formData,
          shippingAddress: {
            ...shippingAddress,
            ...selectedAddress,
          },
        }
        this.setState({
          formData: newFormData,
        })
      } else {
        newFormData = {
          ...formData,
          billingAddress: {
            ...billingAddress,
            ...selectedAddress,
          },
        }
        this.setState({
          formData: newFormData,
          isCopyShipping: false,
        })
      }
    }

    localStorage.setItem('B3CreateQuoteFormData', JSON.stringify(newFormData))

    this.handleBackToQuoteForm()
    this.handleCopyAddressFromShipping()
    this.renderFormBody()
  }

  handleSaveAddress() {
    const {
      addressList,
      addressId,
      formData,
      formData: { shippingAddress },
    } = this.state
    const selectedAddress = addressList.find(address => addressId === address.addressId)
    if (selectedAddress) {
      this.setState({
        addressId: '',
        addressList: [],
        formData: {
          ...formData,
          shippingAddress: {
            ...shippingAddress,
            ...selectedAddress,
          },
        },
      })
      this.handleBackToQuoteForm()
      this.renderAddressListTpls()
      this.renderFormBody()
    }
  }

  formatAddress(addressOption) {
    const companyName = addressOption.companyName || ''
    const {
      addressId,
      addressLine1,
      addressLine2,
      phoneNumber,
      label,
      country: {
        countryName: country,
      },
      state: {
        stateName: state,
      },
      addressLine1: address,
      addressLine2: apartment,
      city,
      zipCode,
      firstName,
      lastName,
      isShipping,
      isBilling,
    } = addressOption
    const addressInfo = `${city} ${city ? ',' : ''} ${state}${state ? ',' : ''} ${zipCode} ${zipCode && country ? '/' : ''} ${country}`
    return {
      addressId,
      address,
      apartment,
      country,
      city,
      state,
      zipCode,
      addressLine1,
      addressLine2,
      label,
      phoneNumber,
      firstName,
      lastName,
      companyName,
      addressInfo,
      isShipping,
      isBilling,
    }
  }

  handleAddressPaginationChange = async (page, q = '') => {
    const {
      addressPagination,
      addressPagination: { limit },
    } = this.state

    this.setState({
      addressPagination: {
        ...addressPagination,
        offset: (page - 1) * limit,
      },
    })

    const overlayClass = 'loadingOverlay'

    this.utils.renderTemplate({
      containerSelector: '.modal-body',
      template: `<div class='${overlayClass}' style='display: block;'/>`,
    })

    this.initAddressInfo(q)
  }

  handleGetSpecifiedProductIndex(productList, product) {
    const stagedIndex = productList.findIndex(
      ({
        variantId: stagedVariantId,
        productId: stagedProductId,
        options: stagedOptions,
      }) => {
        const { variantId, productId, options } = product

        if (variantId) {
          return (
            stagedVariantId === variantId && isEqual(stagedOptions, options)
          )
        }
        return (
          productId === stagedProductId
          && isEqual(stagedOptions, options)
          && !stagedVariantId
        )
      },
    )

    return stagedIndex
  }

  handleQuantityChange(e) {
    const {
      dataset: { orderId },
      value,
    } = e.target
    const { productList } = this.state
    const product = productList[orderId]

    const productIndex = this.handleGetSpecifiedProductIndex(productList, product)

    if (/^\d+$/.test(value)) {
      const quantity = Math.max(0, value || 0)
      const product = productList[productIndex]
      const {
        variantPrice,
        offeredPrice,
        basePrice,
        productPriceInfo,
      } = product
      const priceInfo = this.getCalculatedAndBulkPrice(productPriceInfo, quantity)

      productList.splice(productIndex, 1, {
        ...product,
        quantity,
        basePrice: priceInfo.asEntered || basePrice,
        offeredPrice: priceInfo.asEntered || offeredPrice,
        variantPrice: priceInfo.asEntered || variantPrice,
        taxPrice: priceInfo.taxPrice || product.taxPrice,
        realPrice: priceInfo,
      })
      this.setState({
        productList: [...productList],
      })
      localStorage.setItem(
        'B3CreateQuoteProductList',
        JSON.stringify(productList),
      )
      this.renderProductList()
    }

    this.triggerQuoteNumber()
  }

  async handleDeleteProduct(e) {
    try {
      const result = await this.utils.Alert.warning(this.text['global.alert.warning.delete'], {
        showCancelButton: true,
        confirmButtonText: this.text['global.alert.buttonText.delete'],
      })

      if (!result.value) return
      const {
        dataset: { orderId },
      } = e.target
      const { productList } = this.state
      const product = productList[orderId]

      const productIndex = this.handleGetSpecifiedProductIndex(productList, product)

      productList.splice(productIndex, 1)

      this.setState({
        productList: [...productList],
      })
      localStorage.setItem(
        'B3CreateQuoteProductList',
        JSON.stringify(productList),
      )
      this.renderProductList()
      this.triggerQuoteNumber()
      this.detectSubmitButtonEnabled()
    } catch (err) {
      this.utils.Alert.error(err)
    }
  }

  renderMessage() {
    const {
      formData: { message },
    } = this.state

    const $messageContainer = this.rfqForm.$ref.querySelector(
      '.message-container',
    )

    $messageContainer.innerHTML = this.tpls.formMessage({
      message,
    })
  }

  renderAttachFiles() {
    const { attachment } = this.state
    const $attachFileContainer = document.querySelector('.attach-file-container')

    const attachments = []
    if (!isEmpty(attachment)) {
      attachment.forEach(item => {
        attachments.push({
          ...item,
          isImage: !!item.fileType.includes('image'),
        })
      })
    }

    $attachFileContainer.innerHTML = this.tpls.attachFile({
      attachments,
    })

    $attachFileContainer.querySelector('[data-upload-attachment]').addEventListener('change', e => {
      this.handleUploadAttachment(e)
    })
    $attachFileContainer.querySelectorAll('[data-delete-file]').forEach(el => el.addEventListener('click', e => {
      this.handleDeleteFile(e)
    }))
  }

  handleAttachClick() {
    document.querySelector('.upload-attachment').click()
  }

  async handleUploadAttachmentFiles(file) {
    const { attachment } = this.state
    const newAttachment = attachment
    const data = new FormData()
    data.append('mediaFile', file)
    data.append('requestType', 'quoteAttachedFile')

    try {
      const fileData = await this.api.uploadAttachmentFiles(data)

      newAttachment.push(fileData)

      this.setState({
        attachment: newAttachment,
      })

      localStorage.setItem(
        'B3AttachmentList',
        JSON.stringify(newAttachment),
      )
    } catch (e) {
      console.error(e)
    }

    this.renderAttachFiles()
  }

  handleUploadAttachment(e) {
    const { attachment } = this.state
    const newFile = e.target.files[0]

    if (!newFile) return

    if (attachment.length >= 3) {
      this.utils.Alert.error(this.text['rfq.form.attach.file.limitNumTip'])
    } else {
      if (!this.utils.JudgeFileType(newFile.type)) {
        this.utils.Alert.error(this.text['rfq.form.attach.file.formatSupportTip'])
        return
      }

      if (newFile.size >= (1024 * 1024 * 2)) {
        this.utils.Alert.error(this.text['rfq.form.attach.file.fileSizeTip'])
        return
      }

      const sameTypeFile = attachment.filter(item => newFile.type.includes(item.fileType))

      if (sameTypeFile.find(item => item.fileName === newFile.name)) {
        const fileNameArr = newFile.name.split('.')
        const newFileArr = []
        if (fileNameArr.length > 2) {
          const fileSuffix = fileNameArr.pop()
          const fileName = fileNameArr.join('.')
          newFileArr.push(fileName, fileSuffix)
        } else {
          fileNameArr.forEach(item => {
            newFileArr.push(item)
          })
        }
        const nameArr = newFileArr[0].trim().split('(')
        const name = (nameArr.length > 1 && (typeof +newFileArr[0][newFileArr[0].length - 2]) === 'number') ? nameArr[0] : newFileArr[0]
        const nameLength = name.length
        const sameNameFile = sameTypeFile.filter(item => item.fileName.slice(0, nameLength) === name)

        if (sameNameFile.find(item => item.fileName === newFile.name)) {
          const fileIndex = sameNameFile.map(item => {
            const index = +item.fileName.slice(newFileArr[0].length + 1, newFileArr[0].length + 2)

            if (isNaN(index)) {
              return 0
            }
            return index
          })

          const newFileIndex = [];
          [0, 1, 2].forEach(item => {
            if (!fileIndex.includes(item)) {
              newFileIndex.push(item)
            }
          })

          const newFileName = `${newFileArr[0]}(${newFileIndex[0]}).${newFileArr[1]}`

          const newNameFile = new File([newFile], newFileName, {
            type: newFile.type,
          })

          this.handleUploadAttachmentFiles(newNameFile)
        } else {
          this.handleUploadAttachmentFiles(newFile)
        }
      } else {
        this.handleUploadAttachmentFiles(newFile)
      }
    }
  }

  handleDeleteFile(e) {
    const { attachment } = this.state
    const { id } = e.target

    const newAttachment = []
    attachment.forEach(item => {
      if (!(item.fileName === id)) {
        newAttachment.push(item)
      }
    })

    this.setState({
      attachment: newAttachment,
    })

    localStorage.setItem(
      'B3AttachmentList',
      JSON.stringify(newAttachment),
    )

    this.renderAttachFiles()
  }

  handleMessageChange(e) {
    const { formData } = this.state
    const newFormData = {
      ...formData,
      message: e.target.value,
    }

    this.setState({
      formData: newFormData,
    })
    localStorage.setItem('B3CreateQuoteFormData', JSON.stringify(newFormData))
  }

  async handleSubmit() {
    const { $ref, data, data: { productList } } = this.rfqForm
    const { attachment, quoteOtherConfigs, enteredInclusive } = this.state

    const $formFields = $ref.querySelectorAll(
      '.body-container .form-field [name]',
    )
    const $loading = $ref.querySelector('.loadingOverlay')

    const visibleFields = Array.from($formFields).filter(
      el => el.getAttribute('disabled') === null,
    )
    visibleFields.forEach(el => el.dispatchEvent(new Event('blur')))

    const requiredFields = visibleFields.filter(({ dataset: { required } }) => required)

    const isRequiredFilled = requiredFields.every(({ value }) => !!value)

    if (!isRequiredFilled) {
      requiredFields.forEach(field => {
        const { label } = field.dataset
        if (field.value !== '') return

        const requiredErrorMessage = this.utils.text('rfq.form.label.isRequired', {
          hash: {
            label,
          },
        })
        this.toggleFieldStatus(field, false, requiredErrorMessage)
      })
      return
    }
    if (!this.rfqForm.data.productList.length) return

    const height = $ref.querySelector('.form-container').clientHeight + 50
    $loading.style.height = `${height}px`
    $loading.classList.add('show')

    let taxTotal = 0
    let totalPrice = 0
    const newProductListData = productList.map(product => {
      const {
        options,
        taxPrice,
        basePrice,
        quantity,
      } = product

      const newOptions = options.filter(option => option.optionLabel !== '')
      taxTotal += taxPrice * quantity
      totalPrice += enteredInclusive ? basePrice * quantity : (basePrice + taxPrice) * quantity
      return {
        ...product,
        options: newOptions,
      }
    })

    const legalTerms = quoteOtherConfigs.filter(config => config.key === 'defaultTermsAndConditions')[0]?.value

    const rfqFormData = {
      ...data,
      productList: newProductListData,
      attachFiles: attachment,
      bcCustomerId: this.context.customer?.id ?? undefined, // create quote
      channelId: this.context.channelId ?? undefined,
      legalTerms,
      taxTotal: taxTotal.toFixed(2),
      totalAmount: totalPrice.toFixed(2),
    }
    try {
      const {
        quoteUrl,
      } = await this.api.createRfq(rfqFormData)
      this.setState({
        quoteFormStatus: 1,
      })

      const cartId = localStorage.getItem('cartToQuoteId')
      if (cartId) {
        await this.deleteCarts()
        localStorage.removeItem('cartToQuoteId')
      }

      const $editContainer = $ref.querySelector('.form-container')
      const $successContainer = $ref.querySelector('.quote-success-container')

      $successContainer.innerHTML = ''
      $successContainer.insertAdjacentHTML('beforeend', this.tpls.quoteSuccess({ quoteUrl }))

      this.toggleClasses($editContainer, 'hide', 'show')
      this.toggleClasses($successContainer, 'show', 'hide')
      const { formData } = this.state
      this.setState({
        productList: [],
        formData: {
          ...formData,
          quoteTitle: '',
          referenceNumber: '',
          message: '',
        },
        attachment: [],
      })

      if (window?.location?.pathname === '/cart.php' && cartId) {
        window.location.reload()
      }
      localStorage.removeItem('B3CreateQuoteProductList')
      localStorage.removeItem('B3CreateQuoteFormData')
      localStorage.removeItem('B3AttachmentList')
      sessionStorage.removeItem('quoteOtherConfigs')
    } finally {
      $loading.classList.remove('show')
    }

    this.triggerQuoteNumber()
  }

  hideAddSuccessTipPage() {
    const $quoteSuccessContainer = document.querySelector('.quote-success-container')
    const $mask = document.querySelector('.rfq-form-wrapper .mask')

    this.toggleClasses($quoteSuccessContainer, 'hide', 'show')
    this.toggleClasses($mask, 'fadeOut', 'fadeIn')
    this.rfqForm.$ref.hide()
  }

  async preFillFormInfo() {
    const {
      B3RoleId: {
        value: roleId,
      },
    } = this.utils.B3Storage
    const {
      countries,
      defaultBilling,
    } = this.state

    this.setState({
      isGettingPreFillInfo: true,
    })
    this.renderButton()
    !countries && await this.getCountries()

    const {
      constants: {
        B3Role: {
          SALESREP,
        },
      },
    } = this.utils
    if (roleId && ((roleId === SALESREP && this.isCompanyApproved) || roleId !== SALESREP)) {
      !defaultBilling && await this.getDefaultAddresses()
    }

    this.setState({
      isGettingPreFillInfo: false,
    })
    this.renderButton()
  }

  async getDefaultAddresses() {
    const {
      B3CompanyId,
    } = this.utils.B3Storage
    const companyId = B3CompanyId.value

    try {
      const {
        billing,
        shipping,
      } = await this.api.getDefaultAddressesByCompanyId(companyId)

      this.setState({
        defaultBilling: billing,
      })

      if (Object.keys(billing).length || Object.keys(shipping).length) {
        const {
          formData,
          formData: { shippingAddress, billingAddress },
        } = this.state
        const {
          city,
          zipCode,
          addressLine1,
          addressLine2,
        } = billing
        const {
          city: shippingCity,
          zipCode: shippingZipCode,
          addressLine1: shippingAddressLine1,
          addressLine2: shippingAddressLine2,
        } = shipping
        this.setState({
          formData: {
            ...formData,
            shippingAddress: {
              ...shippingAddress,
              ...shipping,
              address: shippingAddressLine1,
              apartment: shippingAddressLine2,
              shippingCity,
              shippingZipCode,
              country: shipping?.country?.countryName,
              state: shipping?.state?.stateName,
            },
            billingAddress: {
              ...billingAddress,
              ...billing,
              address: addressLine1,
              apartment: addressLine2,
              city,
              zipCode,
              country: billing?.country?.countryName,
              state: billing?.state?.stateName,
            },
          },
        })
      }
    } catch (err) {
      this.utils.Alert.error(err)
    }
  }

  handleGetCountryInput() {
    const $countryEl = document.getElementById('select_shippingAddress.country')
    const $stateEl = document.getElementById('select_shippingAddress.state')
    const $billingCountryEl = document.getElementById('select_billingAddress.country')
    const $billingStateEl = document.getElementById('select_billingAddress.state')
    const {
      formData: {
        shippingAddress: {
          country,
          state,
        },
        billingAddress: {
          country: billingCountry,
          state: billingState,
        },
      },
    } = this.state

    this.handleCountryChange('shipping', $countryEl, $stateEl, country, state)
    this.handleCountryChange('billing', $billingCountryEl, $billingStateEl, billingCountry, billingState)
  }

  handleCountryChange(type, $countryEl, $stateEl, country, state) {
    if ($countryEl && country) {
      $countryEl.value = country
    }

    if ($stateEl && state) {
      $stateEl.value = state
    }

    if ($countryEl) this.setOptionsSelected($countryEl, country, 'country')
    if ($stateEl) this.setOptionsSelected($stateEl, state, 'state')

    $countryEl.addEventListener('change', () => {
      const {
        countries,
        formData,
        formData: {
          shippingAddress,
          billingAddress,
        },
      } = this.state
      const selectedIndex = $countryEl.selectedIndex - 1
      const { countryName, countryCode } = countries[selectedIndex < 0 ? 0 : selectedIndex]
      if (type === 'shipping') {
        this.setState({
          formData: {
            ...formData,
            shippingAddress: {
              ...shippingAddress,
              country: countryName || countryCode,
            },
          },
        })
      } else {
        this.setState({
          formData: {
            ...formData,
            billingAddress: {
              ...billingAddress,
              country: countryName || countryCode,
            },
          },
        })
      }

      this.handleStateChange(selectedIndex, type)
    })
  }

  handleIsSelectCheckbox() {
    const {
      isCopyShipping,
    } = this.state

    this.setState({
      isCopyShipping: !isCopyShipping,
    })

    this.handleCopyAddressFromShipping()
  }

  handleCopyAddressFromShipping() {
    const {
      formData: {
        shippingAddress,
      },
      formData,
      isCopyShipping,
    } = this.state

    if (isCopyShipping) {
      const newFormData = {
        ...formData,
        billingAddress: {
          ...shippingAddress,
        },
      }

      this.setState({
        formData: newFormData,
      })

      setTimeout(() => {
        this.renderFormBody()
      }, 150)
      localStorage.setItem('B3CreateQuoteFormData', JSON.stringify(newFormData))
    }
  }

  handleStateChange(selectedIndex, type) {
    const {
      countries,
      formData,
      formData: {
        shippingAddress,
        billingAddress,
      },
    } = this.state

    const { states } = countries[selectedIndex]

    if (type === 'shipping') {
      this.setState({
        formData: {
          ...formData,
          shippingAddress: {
            ...shippingAddress,
            state: '',
          },
        },
      })
      this.renderFormBody({
        stateIsSelect: true,
        stateSelectOptions: states,
      })
    } else {
      this.setState({
        formData: {
          ...formData,
          billingAddress: {
            ...billingAddress,
            state: '',
          },
        },
      })
      this.renderFormBody({
        stateIsSelect: true,
        billingStates: states,
      })
    }
  }

  handleRfqClose() {
    const $mask = document.querySelector('.rfq-form-wrapper .mask')
    const $addressMask = document.querySelector('.edit-mask')
    $mask && $mask.addEventListener('click', () => {
      this.hideForm()
      this.hideAddSuccessTipPage()
    })

    $addressMask && $addressMask.addEventListener('click', () => {
      this.handleBackToQuoteForm()
      this.editB3Modifiers?.listenOptionChange('off')
    })
  }

  handleSelectAddress = async e => {
    const { name } = e.target
    this.setState({
      selectAddressType: name,
    })

    const $addressBookContainer = document.querySelector('.address-book-container')
    const $addressMask = document.querySelector('.edit-mask')

    this.toggleClasses($addressBookContainer, 'show', 'hide')
    this.toggleClasses($addressMask, 'fadeIn', 'fadeOut')
    this.initAddressInfo()
  }

  getProductOptionList = (formData, currentProduct) => {
    const dateSource = {}
    const optionList = []

    formData.forEach(([key, value]) => {
      switch (key) {
        case 'product_id': {
          currentProduct.productId = value
          break
        }
        case 'qty[]':
          currentProduct.quantity = +value
          break
        default: {
          const isAttr = key.includes('attribute')
          const attributeIndex = optionList.findIndex(
            ({ option_id }) => option_id === key,
          )
          let option = {
            option_id: key,
            option_value: value,
          }

          let isDateAssembleComplete = true
          // handle Date modifier like: attribute[116][month]
          if (key.match(/\[([a-z]+)\]/g)?.[0]) {
            dateSource[key.match(/\[([a-z]+)\]/g)[0]] = value
            const year = dateSource['[year]']
            const month = dateSource['[month]']
            const day = dateSource['[day]']
            isDateAssembleComplete = year && month && day
            if (isDateAssembleComplete) {
              option = {
                option_id: `attribute${key.match(/\[([0-9]+)\]/g)[0]}`,
                option_value: new Date(`${year}-${month}-${day}`).getTime() / 1000,
              }
            }
          }
          if (isDateAssembleComplete) {
            isAttr && (attributeIndex !== -1
              ? optionList.splice(attributeIndex, 1, option)
              : optionList.push(option))
          }
        }
      }
    })

    return optionList
  }

  getProductOptions = (optionList, originalOptions, isCartProduct) => {
    if (!Array.isArray(optionList) || !optionList.length) {
      return []
    }

    if (!Array.isArray(originalOptions) || !originalOptions.length) {
      return []
    }

    return optionList.map(
      option => {
        let optionId = ''
        let optionValue = ''

        if (isCartProduct) {
          optionValue = option.optionValue
          optionId = option.optionId
        } else {
          optionValue = option.option_value
          optionId = +option.option_id.replace(/\w+\[(\d*)\]/, '$1')
        }

        const {
          display_name: optionName = '',
          values,
          value,
          partial,
        } = originalOptions.find(option => +option.id === +optionId) ?? {}

        let optionLabel = ''
        switch (partial) {
          case 'input-checkbox':
            {
              const {
                checkboxOptionLabels: [YES, NO],
              } = this.state
              optionLabel = +value === +optionValue ? YES : NO
            }
            break
          case 'input-text':
          case 'input-numbers':
          case 'textarea': optionLabel = optionValue
            break
          case 'date':
            optionLabel = window.B3DisplayFormat(new Date(optionValue * 1000))
            break
          default: {
            optionLabel = values?.find(value => value.id === +optionValue)?.label ?? ''
          }
        }

        return {
          optionId,
          optionValue,
          optionName,
          optionLabel,
          type: partial,
        }
      },
    )
  }

  async handleEditOptions(e) {
    const { productList } = this.state
    const { $ref } = this.rfqForm
    const { dataset: { productId, orderId } } = e.target
    const $editOptionsContainer = document.querySelector('.edit-options-container')
    const $addressMask = document.querySelector('.edit-mask')

    const productPromise = this.getProductInfo(productId)
    const $loading = $ref.querySelector('.loadingOverlay')
    const height = document.querySelector('.form-container').clientHeight + 50
    $loading.style.height = `${height}px`
    $loading.classList.add('show')

    try {
      const { options } = await Promise.resolve(productPromise)
      const currentProduct = productList[orderId]

      this.toggleClasses($editOptionsContainer, 'show', 'hide')
      this.toggleClasses($addressMask, 'fadeIn', 'fadeOut')

      this.setState({
        currentProduct,
        currentProductOrderId: orderId,
        originalOptions: options,
      })
      this.renderEditOptions()
    } finally {
      $loading.classList.remove('show')
    }
  }

  renderEditOptions() {
    const {
      originalOptions,
      currentProduct,
      showInclusiveTaxPrice,
      currency,
    } = this.state
    const { currencyFormat } = this.utils
    const {
      imageUrl,
      productPriceInfo: {
        calculated_price: {
          tax_exclusive: taxExclusive,
          tax_inclusive: taxInclusive,
        },
      },
    } = currentProduct

    const allOptions = originalOptions.map(item => {
      const selectedOption = currentProduct.options.find((currentItem => currentItem.optionId === item.id)) ?? null
      const values = item?.values ?? []
      if (selectedOption) {
        const { optionValue } = selectedOption
        const { partial } = item

        if (values.length > 0) {
          values.map(value => {
            value.selected = +selectedOption.optionValue === value.id
            return value
          })
        }

        if (item.prefill === '') {
          item.prefill = selectedOption.optionValue
        }

        // refill
        if (['input-text', 'textarea', 'input-numbers'].includes(partial)) {
          item.prefill = selectedOption.optionValue
        } else if (partial === 'input-checkbox') {
          item.value = selectedOption.optionValue
          item.checked = selectedOption.optionLabel === 'YES'
        } else if (partial === 'date') {
          item.selected_date = {
            day: new Date(optionValue * 1000).getDate(),
            month: new Date(optionValue * 1000).getMonth() + 1,
            year: new Date(optionValue * 1000).getFullYear(),
          }
        }
      }

      return ({
        selectedOption,
        ...item,
      })
    })

    const $editOptionsContainer = document.querySelector('.edit-options-container')

    $editOptionsContainer.innerHTML = this.tpls.editOptions({
      ...currentProduct,
      imageUrl: imageUrl || DEFAULT_IMAGE_URL,
      moduleTitle: this.text['rfq.form.edit.options'],
      isEdit: true,
      price: currencyFormat(showInclusiveTaxPrice ? taxInclusive : taxExclusive, false, false, currency),
    })

    this.editB3Modifiers = new this.B3Modifiers({
      cb: data => {
        // handle unavailable product
        const $saveProductBtn = document.querySelector('.cancel-save-options')
        const $ProductTip = document.querySelector('.rfq-productTip-container')
        if ($saveProductBtn) {
          $saveProductBtn.disabled = !data.purchasable
        }
        if ($ProductTip) {
          $ProductTip.style.display = data.purchasable ? 'none' : 'block'
        }
      },
    })

    this.editB3Modifiers.render('.edit-options-container [data-product-option-change]', 'beforeend', allOptions)
    this.editB3Modifiers?.listenOptionChange()
  }

  async handleSaveNewOptions() {
    const {
      utils: { normalizeFormData },
    } = this
    const { $ref } = this.rfqForm
    const $loading = $ref.querySelector('.loadingOverlay')
    const height = document.querySelector('.form-container').clientHeight + 50
    $loading.style.height = `${height}px`
    const {
      currentProduct,
      productList,
      currentProductOrderId,
      currentProduct: { productId },
      originalOptions,
    } = this.state

    try {
      $loading.classList.add('show')
      const $form = document.querySelector('[data-option-form]')
      const formData = Array.from(normalizeFormData(new FormData($form)))

      const optionList = this.getProductOptionList(formData, currentProduct)

      const canAddToQuote = this.isAllRequiredOptionFilled(originalOptions, optionList)
      if (!canAddToQuote) return

      const $saveBtn = document.querySelector('.cancel-save-options')
      $saveBtn.setAttribute('disabled', 'true')

      const pricePromiseResult = await this.api.getPrice(productId, optionList)

      const {
        sku,
        v3_variant_id: variantId,
        price,
      } = pricePromiseResult ?? {}

      const priceContainer = price?.with_tax || price?.without_tax || {}

      const { value: basePrice, currency } = priceContainer

      const newOptions = this.getProductOptions(optionList, originalOptions)

      const {
        options: oldOptions,
        quantity,
      } = productList[currentProductOrderId]

      const {
        modifiers,
        hasPriceList,
        variantPrice,
        bulkPrices,
      } = await this.loadProduct(productId, variantId)

      const options1 = this.getModifierAdjuster(newOptions, modifiers)

      const calculatedPrice = this.getCalculatedPrice(quantity, bulkPrices, variantPrice, hasPriceList, options1)

      const productAllOptions = {
        baseOptions: originalOptions,
      }
      const checkedProductInfo = {
        options: options1,
        productId,
        variantId,
      }

      const productInfo = this.getCalculatedParams(checkedProductInfo, productAllOptions)

      const proxyPriceInfo = await this.getProductsPrice(productInfo, currency)
      const productData = proxyPriceInfo[0]
      const priceInfo = this.getCalculatedAndBulkPrice(productData, currentProduct.quantity)

      const updatedProduct = {
        ...currentProduct,
        sku,
        variantId: variantId || '',
        basePrice: priceInfo.asEntered || basePrice,
        currency,
        offeredPrice: priceInfo.asEntered || calculatedPrice,
        options: newOptions,
        bulkPrices: priceInfo.bulkPricing || bulkPrices,
        variantPrice: priceInfo.asEntered || variantPrice,
        taxPrice: priceInfo.taxPrice,
        productPriceInfo: productData,
        realPrice: priceInfo,
      }

      if (!isEqual(newOptions, oldOptions)) {
        this.handleUpdatedProduct(productList, updatedProduct, currentProductOrderId)
      } else {
        localStorage.setItem(
          'B3CreateQuoteProductList',
          JSON.stringify(productList),
        )
      }
      $saveBtn.setAttribute('disabled', 'false')
    } catch (error) {
      console.error(error)
    } finally {
      $loading.classList.remove('show')
    }

    this.hideForm()
    this.renderProductList()
  }

  async loadProduct(productId, variantId) {
    try {
      window.B3Spinner.show()
      const {
        currency: {
          currencyCode,
        },
      } = this.state

      // current selected code
      const { currency_code } = getCurrency()

      const { store_hash: storeHash } = this.context.settings

      const {
        B3Storage: { B3CompanyId },
      } = this.utils
      const companyId = B3CompanyId.value ? B3CompanyId.value : null

      const params = {
        storeHash,
        currencyCode: currency_code || currencyCode,
        companyId,
        productList: [
          {
            productId: +productId,
            variantId,
          },
        ],
      }

      if (this.isLogin) {
        const { customer_group_id: customerGroupId } = this.context.customer

        if (customerGroupId) params.customerGroupId = customerGroupId
      }
      const { productList } = await this.api.getProductsBulkLoad(params)

      const {
        modifiers,
        hasPriceList,
        calculatedPrice: variantPrice,
        bulkPrices,
      } = productList.find(product => product.productId === +productId) || {}

      return {
        modifiers,
        hasPriceList,
        variantPrice,
        bulkPrices,
      }
    } catch (err) {
      console.error(err)
    } finally {
      window.B3Spinner.hide()
    }
  }

  handleUpdatedProduct(productList, product, orderId) {
    const stagedIndex = this.handleGetSpecifiedProductIndex(productList, product)

    if (stagedIndex !== -1) {
      const stagedProduct = productList[stagedIndex]
      product.quantity += stagedProduct.quantity

      productList.splice(stagedIndex, 1, product)
      productList.splice(+orderId, 1)
    }

    if (stagedIndex === -1) {
      productList.splice(+orderId, 1, product)
    }

    this.setState({
      productList: [...productList],
    })

    localStorage.setItem(
      'B3CreateQuoteProductList',
      JSON.stringify(productList),
    )
  }

  setTitle(title) {
    const $title = document.querySelector('.title-container h3')
    $title.innerHTML = title
  }

  renderMyQuoteButton() {
    const {
      myQuote: {
        buttonContainer,
        mobileContainer,
        name,
      },
    } = this.doms
    if (!this.isSwitchStatusEnabled('quote_customer')) return
    const key = this.isB2BUser && this.isLogin ? 'quote_customer' : (this.isB2CUser && this.isLogin ? 'quote_for_individual_customer' : 'quote_for_guest')
    const isRenderBtn = this.isSwitchStatusEnabled(key)
    if (!isRenderBtn) return

    const btnConfig = {
      ButtonText: name,
    }

    this.removeMyQuoteButton()

    const containerSelector = this.isMobile ? mobileContainer : buttonContainer
    const $myQuoteContainer = document.querySelector(containerSelector)
    const hbsTemplate = this.isMobile ? this.tpls.mobileMyQuoteButton(btnConfig) : this.tpls.myQuoteButton(btnConfig)

    const template = (new DOMParser()).parseFromString(hbsTemplate, 'text/html').body.childNodes[0]
    if ($myQuoteContainer) {
      $myQuoteContainer.insertBefore(template, $myQuoteContainer.childNodes[2])
    }

    const mobileMyQuoteButton = document.querySelector('#m-myquote-entry')

    if (this.isMobile && mobileMyQuoteButton) {
      mobileMyQuoteButton.addEventListener('click', () => {
        this.renderForm()
      })
    }
  }

  removeMyQuoteButton() {
    const mobileButton = document.querySelector('#m-myquote-entry')
    const pcButton = document.querySelector('#my-quote-entry')

    if (mobileButton) {
      mobileButton.remove()
    }

    if (pcButton) {
      pcButton.remove()
    }
  }

  renderPDPQuickViewQuoteButton() {
    document.querySelector('body').addEventListener('click', e => {
      if (e.target.parentElement.className === 'modal-close') {
        this.renderButton()
        stencilUtils.hooks.on('product-option-change',
          () => {
            this.renderButton()
          })
      }

      let interval
      if (interval) clearInterval(interval)

      const checkQuickview = node => {
        if (!node || node.nodeName === 'BODY') return false
        if (node.classList.contains('quickview')) return true
        return checkQuickview(node.parentNode)
      }

      if (checkQuickview(e.target)) {
        interval = setInterval(() => {
          const quickView = document.querySelectorAll('#modal .productView--quickView')
          if (quickView.length > 0) {
            const $actionWarper = document.querySelector(this.actionWarper)
            if ($actionWarper) {
              this.renderButton('isPDPQuickViewModal')
              stencilUtils.hooks.on('product-option-change',
                () => {
                  this.renderButton('isPDPQuickViewModal')
                })
            }
            clearInterval(interval)
          }
        }, 200)
      }
    })
  }

  handleAddToQuoteClick() {
    const $addToQuoteBtn = document.querySelectorAll('.add-to-quote')

    const addToQuote = ev => {
      if (this.isOpenSigleLimitError('quote')) {
        this.sigleLimitErrorDialog('quote')
        ev.preventDefault()
        return
      }
      const { classList } = ev.target
      this.handleAdd(ev, !Array.from(classList).includes('not-quick-view'))
    }

    $addToQuoteBtn.forEach(ele => {
      ele.removeEventListener('click', addToQuote)
      ele.addEventListener('click', addToQuote)
    })
    this.triggerQuoteNumber()
  }

  triggerQuoteNumber() {
    const quoteQty = document.querySelector('.quote-quantity')
    if (!quoteQty) return
    const { productList } = this.state
    const quoteQuantity = productList.reduce((result, item) => {
      result += item.quantity
      return result
    }, 0)
    quoteQty.innerHTML = quoteQuantity
    if (quoteQuantity > 0) {
      quoteQty.classList.add('countPill--positive')
    } else {
      quoteQty.classList.remove('countPill--negative')
      quoteQty.classList.remove('countPill--positive')
    }
  }

  // When logging out, clear the quote draft data  --> ticket BUN-795
  clearQuoteProductsWhenSignOut = () => {
    const btns = document.querySelectorAll(containers['logout.button.container'])
    btns.forEach(ele => {
      ele.addEventListener('click', () => {
        // localStorage.removeItem('B3CreateQuoteProductList')  // BUN-1696
        localStorage.removeItem('cartToQuoteId')
      })
    })
  }

  // bun-2485 When you are not logged in and there is an isCheckout link, you need to add a prompt to the login page.
  QuoteCheckoutAddLoginPrompt = () => {
    const { location } = window
    const QuoteCheckoutUrl = sessionStorage.getItem('QuoteCheckoutUrl')
    if (location.pathname === '/login.php' && !this.isLogin && QuoteCheckoutUrl) {
      const $loginRow = document.querySelector('.login-row')
      const $loginPromptContent = document.querySelector('.login-prompt__content')

      if ($loginPromptContent) {
        $loginPromptContent.remove()
      }

      if ($loginRow) {
        const $loginPrompt = document.createElement('div')
        $loginPrompt.classList.add('login-prompt')
        $loginPrompt.innerHTML = `<div class="login-prompt__content">
                                    <div class="login-prompt__title">
                                        <span>${this.text['quote.checkout.addPrompt']}</span>
                                    </div>
                                  </div>`

        $loginRow.parentNode.insertBefore($loginPrompt, $loginRow)
      }
    }
  }

  goToQuoteCheckout() {
    const QuoteCheckoutUrl = JSON.parse(sessionStorage.getItem('QuoteCheckoutUrl'))

    if (QuoteCheckoutUrl && this.isLogin && !location.href.includes('/quote')) {
      location.href = QuoteCheckoutUrl
    }
  }
}

export default Rfq
