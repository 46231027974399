const env = {
  dev: {
    socketUrl: 'wss://dev-v2.bundleb2b.net/ws',
  },
  'local.dev': {
    socketUrl: 'wss://dev-v2.bundleb2b.net/ws',
  },
  staging: {
    socketUrl: 'wss://staging-v2.bundleb2b.net/ws',
  },
  product: {
    socketUrl: 'wss://api.bundleb2b.net/ws',
  },
}

export default env[process.env.APP_ENV]
