import BasePage from '../../../common/BasePage'

import receipt from './receipt.html'
import receiptLines from './receiptLines.html'

export default class InvoiceReceipt extends BasePage {
  constructor() {
    super()
    this.name = 'InvoiceReceipt'

    this.tpls = {
      receipt,
      receiptLines,
    }

    this.state = {
      receiptDetail: null,
      receiptLines: null,
    }
  }

  get isInPage() {
    return this.utils.leftIncludes(window.location.pathname, this.currentPageDom.url)
  }

  get currentPageDom() {
    return this.doms[this.name.replace(/^[A-Z]/, A => A.toLowerCase())]
  }

  get receiptId() {
    return this.utils.urlHelper.searchParams.get('id')
  }

  init() {
    if (!this.isB2BUser) return

    if (!this.isInPage) return

    this.render()
  }

  async render() {
    await this.getInvoiceReceipt()
    await this.getInvoiceReceiptLines()
  }

  async getInvoiceReceipt() {
    try {
      const { data: receiptDetail } = await this.api.getInvoiceReceipt(this.receiptId)
      this.setState({ receiptDetail })
      this.renderTemplate('receipt', { ...receiptDetail })
    } catch (e) {
      console.error(e)
    }
  }

  async getInvoiceReceiptLines() {
    try {
      const { data: receiptLines } = await this.api.getLinesInReceipt(this.receiptId, {
        limit: 1000,
        offset: 0,
      })
      this.setState({ receiptLines })
      this.renderTemplate('receiptLines', { receiptLines }, '.invoice-receipt-lines')
    } catch (e) {
      console.error(e)
    }
  }

  renderTemplate(tpl, config, dom) {
    this.utils.renderTemplate({
      hbsTemplate: this.tpls[tpl],
      containerSelector: dom || this.currentPageDom.container,
      templateConfig: {
        ...config,
      },
      insertType: 'beforeend',
    })
  }
}
