var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table\n  class=\"search-product-table "
    + alias4(((helper = (helper = lookupProperty(helpers,"productId") || (depth0 != null ? lookupProperty(depth0,"productId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"productId","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":45}}}) : helper)))
    + " product_search_result_table-"
    + alias4(((helper = (helper = lookupProperty(helpers,"productId") || (depth0 != null ? lookupProperty(depth0,"productId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"productId","hash":{},"data":data,"loc":{"start":{"line":2,"column":74},"end":{"line":2,"column":89}}}) : helper)))
    + "\"\n  product-search-result-table\n  style=\"margin-bottom: 1.5rem;\"\n>\n  <tbody class=\"loading-span product-"
    + alias4(((helper = (helper = lookupProperty(helpers,"productId") || (depth0 != null ? lookupProperty(depth0,"productId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"productId","hash":{},"data":data,"loc":{"start":{"line":6,"column":37},"end":{"line":6,"column":50}}}) : helper)))
    + "\"></tbody>\n</table>\n";
},"useData":true});