var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "      <div class=\"rfq-message-input\">\n        <textarea class=\"form-input\" cols=\"30\" rows=\"3\" placeholder=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.message.input.placeholder",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":11,"column":69},"end":{"line":11,"column":109}}}))
    + "\"></textarea>\n      </div>\n      <div class=\"rfq-send-message-btn-wrap\">\n        <input class=\"button button--search form-prefixPostfix-button--postfix rfq-send-message-btn\" type=\"submit\"\n          value=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.send.message.btn",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":15,"column":17},"end":{"line":15,"column":48}}}))
    + "\">\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"noprint "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqDetailsWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":48}}}))
    + "\">\n  <div class=\"rfq-trackingHistory-container "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfq.trackingHistory.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":44},"end":{"line":2,"column":87}}}))
    + "\">\n    <p class=\"rfq-send-message-title\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.message.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":66}}}))
    + "</p>\n    <div class=\"rfq-send-message\">\n      <div class=\"rfq-message-info\">\n        <div class=\"rfq-message-info-detail\">\n        </div>\n      </div>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldShowSendMessageBtn") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n";
},"useData":true});