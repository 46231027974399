import BasePage from '../../common/BasePage'
import files from './files.html'

export default class AccountSetting extends BasePage {
  constructor() {
    super()
    this.name = 'AccountSetting'
    this.state = {
      path: 'pages/account/edit',
    }
    this.tpls = {
      files,
    }
  }

  get inPage() {
    return this.context.template === this.state.path || this.context.template === this.state.path
  }

  getRole(type) {
    const gRoleDefine = {
      0: this.text['global.user.admin.label'],
      1: this.text['global.user.senior.label'],
      2: this.text['global.user.junior.label'],
      3: this.text['global.user.superAdmin.label'],
    }
    return gRoleDefine[type]
  }

  async init() {
    if (this.inPage) {
      const {
        B3RoleId,
        B3CompanyName,
      } = this.utils.B3Storage

      if (!this.isB2BUser) {
        return
      }

      const companyEl = document.querySelector('#account_companyname')

      this.setState({
        companyName: B3CompanyName?.value,
        B3Role: this.getRole(B3RoleId.value),
      })

      if (B3CompanyName) {
        companyEl.value = this.state.companyName
        companyEl.setAttribute('disabled', 'true')
      }

      this.utils.renderTemplate({
        hbsTemplate: this.tpls.files,
        containerSelector: '.form[data-edit-account-form] [data-type="EmailAddress"]',
        templateConfig: {
          ...this.state,
        },
        insertType: 'beforebegin',
      })
    }
  }
}
