var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"b2bWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":33}}}))
    + " invoices-payment "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoicePayment",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":51},"end":{"line":1,"column":79}}}))
    + "\" id=\"invoices-payment\">\n  <div class=\"payment-header "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.payment.header",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":29},"end":{"line":2,"column":67}}}))
    + "\"></div>\n  <div class=\"payment-table "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.payment.table",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":28},"end":{"line":3,"column":65}}}))
    + "\"></div>\n  <div class=\"payment-select "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.payment.select",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":67}}}))
    + "\"></div>\n</div>";
},"useData":true});