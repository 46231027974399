import axios from 'axios'
import {
  debounce,
  noop,
} from 'lodash'
import ResponsiveTable from '@uidax/responsive-table'
import classes from './jss'
import doms from './doms'
import text from './text'
import * as api from './api'
import * as utils from './utils'
import * as locales from './locales'
import themeConfig from '../themeConfig'
import { leftIncludes } from './utils/util'
import { B3Role } from './utils/constants'
import triggerCartNumber from './api/triggerCartNumber'
import getCart from './api/getCart'
import { B3Socket, isEnabledSocket } from './socket'
import { B3LimiteErrorText } from './limitation'

export default class BasePage {
  static notify(dispatch) {
    const callee = key => {
      window.B2BPages.forEach(instance => {
        const callback = instance.watch?.[key]
        if (callback instanceof Function) callback.call(instance)
      })
    }
    if (typeof dispatch === 'string') callee(dispatch)
  }

  constructor() {
    this.api = api || {}
    this.utils = utils || {}
    this.context = window.jsContext || {}
    this.classes = classes || {}
    this.doms = doms || {}
    this.text = text || {}
    this.locales = locales
    this.state = {}
    this.watch = {}
    this.mobileTable = null

    const {
      B3RoleId,
    } = utils.B3Storage
    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = utils
    if (B3RoleId.value === JUNIOR) {
      if (window.location.pathname === '/cart.php') {
        this.deleteCarts()
        window.location.href = '/account.php?action=order_status'
      }
    }
  }

  setState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  get shouldMount() {
    return true
  }

  get isMobile() {
    return 'ontouchstart' in document && window.screen.width < 801
  }

  get isLogin() {
    return !!this.context.customer
  }

  get isB2CUser() {
    return !!this.utils.B3Storage.B3IsB2CUser.value
  }

  get isB2BUser() {
    const {
      B3CompanyId,
      B3CompanyStatus,
      B3RoleId,
    } = this.utils.B3Storage

    return !!(
      (
        B3CompanyId.value
        && B3CompanyStatus.value === '1'
      )
      || B3RoleId.value === '3'
    )
  }

  get isCompanyApproved() {
    const {
      B3CompanyStatus: B3CompanyState,
    } = this.utils.B3Storage

    return Boolean(B3CompanyState.value === '1')
  }

  get shouldShowAddressBook() {
    const {
      B3RoleId,
      B3CompanyStatus,
    } = this.utils.B3Storage
    const {
      ADMIN,
      SENIOR,
      JUNIOR,
      SALESREP,
    } = B3Role

    const roleId = B3RoleId.value
    const companyStatus = B3CompanyStatus.value

    return (
      [ADMIN, SENIOR, JUNIOR].includes(roleId)
      || (roleId === SALESREP && !!companyStatus)
    )
  }

  static async mount() {
    // TODO: public logic
    const instance = new this()

    await instance.initWs(instance)
    const jsRewrites = themeConfig.js ?? {}
    const jsRewrite = jsRewrites[instance.name.toLowerCase()]
    const forbidden = jsRewrite?.overwrite ?? false
    const callback = jsRewrite?.callback ?? noop
    const beforeMount = jsRewrite?.beforeMount ?? noop
    const mounted = jsRewrite?.mounted ?? noop
    try {
      if (instance.shouldMount && !forbidden) {
        window.B3Storage = utils.B3Storage
        await beforeMount(instance)
        const isLogin = !!window.jsContext.customer
        if (!isLogin && (utils.B3Storage.B3B2BToken.value || utils.B3Storage.B3Email.value)) {
          utils.B3Storage.clear()
        }
        await instance.init()
        window.B2BPages = window.B2BPages ?? []
        window.B2BPages.push(instance)
        instance.clickDelegate()
        mounted(instance)
      }
    } catch (e) {
      console.error(e)
    }

    callback(instance)
  }

  async initWs(instance) {
    const errorText = new B3LimiteErrorText()
    if (isEnabledSocket(instance)) {
      const socketInstance = new B3Socket(instance)
      await socketInstance.init(instance)
      instance.limitErrorDialog = () => errorText.init(true)
      instance.isOpenLimitErrorDialog = socketInstance.limitation.isOpenLimitErrorDialog
    }

    instance.sigleLimitErrorDialog = type => errorText.init(false, type)
    instance.isOpenSigleLimitError = type => errorText.judgeSingleLimit(type)
  }

  initMobileTable(hiddenLabels) {
    if (!this.mobileTable) {
      this.mobileTable = new ResponsiveTable({
        tableSelector: '.responsive-table',
        breakPoint: '551',
        hiddenLabels,
      })
    }
  }

  async setB3Token(bcToken) {
    const {
      B3B2BToken,
      B3IsB2CUser,
      B3B2BSocktId,
      B3AppPermissions,
    } = this.utils.B3Storage
    let token
    let socketId

    try {
      const {
        customer: {
          id: customerId,
        },
        store_hash: storeHash,
        channelId,
      } = this.context

      const {
        data: resp,
      } = await axios.post(`${themeConfig.apiBaseUrl}/api/v2/login`, {
        bcToken,
        customerId,
        storeHash,
        channelId,
      })
      if (resp.code === 200) {
        token = resp.data.token
        socketId = resp.data.socketId
        const { appPermissions } = resp.data
        B3B2BToken.setValue(token)
        B3B2BSocktId.setValue(socketId)
        appPermissions && B3AppPermissions.setValue(JSON.stringify(appPermissions))
      } else B3IsB2CUser.setValue(true)
    } catch (error) {
      //
    }

    return token
  }

  async addCreditTip() {
    const {
      B3CompanyId,
    } = this.utils.B3Storage

    const resp = await this.api.getCompanyCredit(B3CompanyId.value)

    const {
      creditEnabled,
      creditHold,
    } = resp
    const str = `<div class="b2b_tip_container" role="alert">
      <div class="b2b_tip_warning">
        <svg class="b2b_tip_warning_icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InfoOutlinedIcon">
          <path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20, 12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10, 10 0 0,0 12,2M11,17H13V11H11V17Z">
          </path>
        </svg>
      </div>
      <div class="b2b_tip_content">${this.text['global.alert.warning.companyCreditHold']}</div>
      <div class="b2b_tip_close_container">
        <button class="b2b_tip_close_btn" tabindex="0" type="button" aria-label="Close" title="Close">
          <svg class="b2b_tip_close_icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CloseIcon">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z">
            </path>
          </svg>
          <span class="MuiTouchRipple-root css-w0pj6f"></span>
          </button>
      </div>
    </div>
    `
    const b2b_tip_container = document.querySelector('.b2b_tip_container')

    const {
      Isb2bCreditColse,
    } = this.utils.B3Storage

    if (creditEnabled && creditHold) {
      const $b2bWrap = document.querySelector('.navBar--sub.navBar--account')
      if (!$b2bWrap || b2b_tip_container || !!Isb2bCreditColse?.value) return
      $b2bWrap.insertAdjacentHTML('afterend', str)
      const b2b_tip_close_btn = document.querySelector('.b2b_tip_close_btn')
      b2b_tip_close_btn.addEventListener('click', () => {
        const remove_b2b_tip_container = document.querySelector('.b2b_tip_container')
        remove_b2b_tip_container.remove()
        Isb2bCreditColse.setValue(true)
      })
    }
  }

  async setUserRole() {
    const {
      B3RoleId,
      B3UserId,
      B3Email,
      decodeStorage,
    } = this.utils.B3Storage
    const customerId = this.context.customer.id
    const {
      roleId,
      userId,
      email,
    } = await this.api.getUserRole(customerId)

    if (!roleId) return

    B3RoleId.setValue(roleId)
    B3UserId.setValue(userId)
    B3Email.setValue(email)

    const localUserId = decodeStorage(localStorage.getItem('B3UserId'))
    const shouldKeepMasquerade = window.b3themeConfig?.keepSuperAdminMasquerade ?? false
    if (localUserId && localUserId === userId && shouldKeepMasquerade) {
      const {
        B3CompanyId,
        B3CompanyStatus,
        B3CompanyName,
        B3isSetSalesRep,
      } = this.utils.B3Storage
      B3CompanyId.setValue(decodeStorage(localStorage.getItem('B3CompanyId')))
      B3CompanyName.setValue(decodeStorage(localStorage.getItem('B3CompanyName')))
      B3CompanyStatus.setValue(decodeStorage(localStorage.getItem('B3CompanyStatus')))
      B3isSetSalesRep.setValue(decodeStorage(localStorage.getItem('B3isSetSalesRep')))
    } else {
      localStorage.removeItem('B3CompanyId')
      localStorage.removeItem('B3CompanyName')
      localStorage.removeItem('B3UserId')
      localStorage.removeItem('B3CompanyStatus')
      localStorage.removeItem('B3isSetSalesRep')
    }
  }

  async getCompanyInfo(userId) {
    const {
      B3CompanyId,
      B3CompanyStatus,
      B3CompanyName,
    } = this.utils.B3Storage
    const resp = await this.api.getCompany(userId)

    B3CompanyId.setValue(resp.companyId)
    B3CompanyStatus.setValue(resp.companyStatus)
    B3CompanyName.setValue(resp.companyName)
    return resp
  }

  async setSalesRep(userId, isQuotePage) {
    const customerId = this.context.customer.id

    try {
      const salesRepInformation = await this.api.getSelerep(customerId)
      sessionStorage.setItem('salesRepInformation', JSON.stringify(salesRepInformation))
      const {
        companyId: masqueradeCompanyId,
      } = salesRepInformation
      if (masqueradeCompanyId) await this.api.endMasqueradeCompany(userId, masqueradeCompanyId)
    } catch {
      //
    } finally {
      this.utils.B3Storage.B3isSetSalesRep.setValue('1')
      const proxyCompanyId = this.utils.urlHelper.searchParams.get('companyId')
      const dashboardUrl = (proxyCompanyId?.length > 0 && window.location.pathname === '/dashboard/') ? `${this.doms.dashboard.url}?companyId=${proxyCompanyId}` : this.doms.dashboard.url

      !isQuotePage && this.utils.urlHelper.redirect(dashboardUrl)
    }
  }

  handleInputSearch = debounce((callback = noop) => callback(), 800)

  handleEndMasqueradeCompany = async (isRedirect = true) => {
    const {
      B3Storage: {
        B3CompanyId,
        B3UserId,
        B3CompanyStatus,
        B3CompanyName,
      },
      urlHelper,
    } = this.utils

    const {
      dashboard: {
        url: dashboardUrl,
      },
    } = this.doms

    if (!B3CompanyId.value) return

    window.B3Spinner.show()
    try {
      await this.api.endMasqueradeCompany(B3UserId.value, B3CompanyId.value)
      await this.deleteCarts()

      B3CompanyId.removeValue()
      B3CompanyStatus.removeValue()
      B3CompanyName.removeValue()

      localStorage.removeItem('B3UserId')
      localStorage.removeItem('B3isSetSalesRep')
      localStorage.removeItem('B3CompanyId')
      localStorage.removeItem('B3CompanyName')
      localStorage.removeItem('B3CompanyStatus')

      sessionStorage.removeItem('B3CompanyInfo')
      sessionStorage.removeItem('quotePreviewData')
      sessionStorage.removeItem('salesRepInformation')
      sessionStorage.removeItem('beginInCreateQuote')
      const isInDashboard = leftIncludes(window.location.pathname, dashboardUrl)
      if (!isInDashboard && isRedirect) {
        urlHelper.redirect(dashboardUrl)
      } else {
        BasePage.notify('endMasqueradeCompany')

        window.B3Spinner.show()
        window.location.reload()
      }
    } catch (error) {
      this.utils.Alert.error(error.message)
    }
    window.B3Spinner.hide()
  }

  clickDelegate() {
    ['click', 'input', 'change', 'keyup'].forEach(item => {
      document.querySelector('body').addEventListener(item, e => {
        const { stop } = e.target.dataset
        const ev = e.target.dataset[item]
        let shouldRun = true

        if (item === 'keyup' && e.target.dataset.enter && e.keyCode !== 13) shouldRun = false

        if (ev && typeof this[ev] === 'function' && shouldRun) {
          this[ev](e)
          return !stop
        }
        return true
      })
    })
  }

  async getAddressBookPermission() {
    const {
      B3AddressBook,
    } = this.utils.B3Storage

    window.B3Spinner.show()
    try {
      const {
        isAllow,
        isEnabled,
      } = await this.api.getAddressPermission()

      B3AddressBook.isAllow.setValue(isAllow)
      B3AddressBook.isEnabled.setValue(isEnabled)
    } catch {
      this.utils.Alert.error(locales.tips.globalError)
    }
    window.B3Spinner.hide()
  }

  async deleteCarts(cartId) {
    try {
      let data = null
      if (!cartId) {
        data = await getCart()
        if (!Array.isArray(data) || !data.length) return
      }
      await axios.delete(`/api/storefront/carts/${cartId ?? data[0].id}`)
      triggerCartNumber()
    } catch (error) {
      console.error(error)
    }
  }

  async juniorDeleteCarts(cartId) {
    try {
      await axios.delete(`/api/storefront/carts/${cartId}`)
    } catch (error) {
      console.error(error)
    }
  }

  hideThemePayments = () => {
    const themePaymentsDom = '.cart-additionalCheckoutButtons, .previewCart-additionalCheckoutButtons, .previewCartCheckout-additionalCheckoutButtons, [data-content-region="cart_below_totals"], .add-to-cart-wallet-buttons, [data-content-region="product_below_price"]'

    if (this.isB2BUser) {
      const styleElement = document.createElement('style')
      styleElement.id = 'hide-theme-payments-b2bId'

      styleElement.innerHTML = `
          ${themePaymentsDom} {
            display: none !important;
          }
        `
      document.head.appendChild(styleElement)
    }
  }
}
