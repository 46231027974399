import axios from 'axios'
import themeConfig from '../../themeConfig'

export default function (companyInfo, createAccount, guestAttachFiles = {}) {
  if (createAccount) {
    return axios.post(`${themeConfig.apiBaseUrl}/api/v2/frontend/companies`,
      {
        ...companyInfo,
        ...guestAttachFiles,
      })
      .then(response => {
        const res = response.data
        this.utils.B3Storage.TPACompanyInfo.removeValue()
        if (res.code !== 200) {
          return this.utils.Alert.error(res.message)
        }

        const type = (res.code === 200) ? 'success' : 'error'
        let text
        let directUrl

        const autoApproval = (res.data.companyAutoApprove === '1')

        if (res.code === 200) {
          text = autoApproval ? this.locales.tips.createCompanyApproved : this.locales.tips.createCompanyChecking
          sessionStorage.removeItem('B3IsB2CUser')
          directUrl = '/'
        } else {
          text = res.data.message
          directUrl = '/trade-professional-application/'
        }
        window.B3Spinner.hide()
        return this.utils.Alert[type](text, {
          allowOutsideClick: false,
          showCancelButton: false,
        }).then(() => {
          if (autoApproval) {
            this.utils.B3Storage.B3IsB2CUser.removeValue()
          }
          window.location.href = directUrl
        })
      })
      .catch(() => {
        //
        window.B3Spinner.hide()
      })
  }

  return this.api.createCompany(companyInfo).then(res => {
    const autoApproval = (res.companyAutoApprove === '1')

    const text = autoApproval ? this.locales.tips.createCompanyApproved : this.locales.tips.createCompanyChecking
    window.B3Spinner.hide()
    return this.utils.Alert.success(text, {
      allowOutsideClick: false,
      showCancelButton: false,
    }).then(() => {
      if (autoApproval) {
        this.utils.B3Storage.B3IsB2CUser.removeValue()
        window.location.href = '/'
        return
      }
      window.location.reload()
    })
  }).catch(error => {
    //
    this.utils.Alert.error(error.message)
    window.B3Spinner.hide()
  })
}
