import formTemplate from './form.html'
import * as utils from '../../common/utils'
import { getStorefrontExtraFields } from '../../common/api'

class B3ExtraField {
  constructor() {
    const commonRules = {
      fieldName: {
        validate: (val, { isRequired }) => !isRequired || (isRequired && !!val),
        errorTip: ({ fieldName }) => utils.text('validation.extraFields.fieldNameIsRequired', {
          hash: {
            fieldName,
          },
        }),
      },
    }
    this.FIELD_TYPE = [
      {
        type: "TEXT",
        rules: {
          ...commonRules,
          maximumLength: {
            validate: (val, { maximumLength }) => maximumLength === '' || val.length <= maximumLength,
            errorTip: ({ fieldName, maximumLength }) => utils.text('validation.extraFields.limitedTextMaxLength', {
              hash: {
                fieldName,
                maximumLength,
              },
            }),
          },
        },
      },
      {
        type: "TEXTAREA",
        rules: {
          ...commonRules,
        },
      },
      {
        type: "NUMBER",
        rules: {
          ...commonRules,
          maximumValue: {
            validate: (val, { maximumValue }) => maximumValue === '' || +val <= maximumValue,
            errorTip: ({ fieldName, maximumValue }) => utils.text('validation.extraFields.limitedNumberHighestValue', {
              hash: {
                fieldName,
                maximumValue,
              },
            }),
          },
        },
      },
      {
        type: "DROPDOWN",
        rules: {
          ...commonRules,
        },
      }
    ]
    this.tpls = {
      formTemplate,
    }
    this.utils = utils || {}
    this.getStorefrontExtraFields = getStorefrontExtraFields
    this.extraFields = null
    this.isCompanyCanSubmit = true
    this.isCompanyUserCanSubmit = true
  }

  get extraFieldConfigList() {
    return this.extraFields || this.options.extraFields || []
  }

  get visibleToEnduserExtraFieldConfigList() {
    return this.extraFieldConfigList.filter((field) => field.visibleToEnduser)
  }

  async init(options) {
    this.options = options
    await this.beforeRender()
    this.render()
    return this
  }

  async beforeRender() {
    await this.getExtraFieldConfigList()
    this.handleExtraFields()
  }

  async getExtraFieldConfigList() {
    try {
      const {
        moduleType,
      } = this.options
      if (!moduleType) return

      window.B3Spinner.show()
      const {
        store_hash: storeHash,
      } = window.jsContext

      const { list: extraFieldConfigList } = await getStorefrontExtraFields({ storeHash }, moduleType)
      window.B3Spinner.hide()
      if (!Array.isArray(extraFieldConfigList) || !extraFieldConfigList.length) return

      this.extraFields = extraFieldConfigList

      this.renderDefaultValues()
    } catch (error) {
      this.utils.Alert.error(error.message)
    }
    window.B3Spinner.hide()
  }

  renderDefaultValues() {
    const {
      extraFieldsValue,
    } = this.options
    if (!extraFieldsValue) return

    const extraFieldsValueMap = extraFieldsValue?.reduce((currentVal, { fieldName, fieldValue }) => {
      currentVal[fieldName] = fieldValue
      return currentVal
    }, {})

    this.extraFields = this.extraFields.map((field) => ({
      ...field,
      defaultValue: extraFieldsValueMap[field.fieldName]
    }))
  }

  handleExtraFields() {
    this.extraFieldConfigList.forEach((extraField, index) => {
      const {
        fieldName,
      } = extraField
      extraField.inputId = `B3ExtraField-${fieldName.replace(/[`~!@#$%^&*()<>?:"{}|,.\/;'\\[\]·！@#￥%……&*（）—+={}|《》？：“”【】、；‘，。、’\s]/g, '')}` //uniqueId
    })

    return this.extraFieldConfigList
  }

  render() {
    const {
      utils: {
        renderTemplate,
      },
      tpls: {
        formTemplate,
      },
    } = this
    const {
      containerSelector,
      insertType,
    } = this.options

    if (!containerSelector) return

    renderTemplate({
      containerSelector,
      hbsTemplate: formTemplate,
      templateConfig: {
        extraFields: this.visibleToEnduserExtraFieldConfigList,
      },
      insertType: insertType || 'beforebegin',
    })

    this.bindFieldEvents()
  }

  bindFieldEvents() {
    this.visibleToEnduserExtraFieldConfigList.forEach(extraField => {
      const {
        inputId,
        fieldType,
        defaultValue,
      } = extraField
      const $field = document.querySelector(`#${inputId}`)

      if (!$field) return

      // handle select default values
      if (this.FIELD_TYPE[fieldType].type === 'DROPDOWN') {
        $field.value = defaultValue
      }

      $field.addEventListener('change', (e) => {
        $field.setAttribute('value', e.target.value)
        this.validationField(extraField)
      })
    })
  }

  validationField = (extraField) => {
    const {
      FIELD_TYPE,
    } = this
    const {
      fieldType,
      inputId,
    } = extraField
    const $field = document.querySelector(`#${inputId}`)
    if (!$field) return true

    const $parent = $field.parentNode
    const {
      value,
    } = $field

    const span = $parent.querySelector('.form-inlineMessage')
    if (span) span.remove()

    const { rules } = FIELD_TYPE[fieldType]
    let isShowError = false
    let errorMessage = ''
    const fieldRules = Object.keys(rules)
    for (let i = 0; i < fieldRules.length; i++) {
      const { validate, errorTip } = rules[fieldRules[i]]
      isShowError = !validate(value, extraField)
      if (isShowError) {
        errorMessage = errorTip(extraField)
        break
      }
    }

    if (isShowError) {
      const $error = `<span class="form-inlineMessage">${errorMessage}</span>`
      $parent.classList.add('form-field--error')
      $parent.classList.remove('form-field--success')
      $parent.insertAdjacentHTML('beforeend', $error)
    } else {
      $parent.classList.add('form-field--success')
      $parent.classList.remove('form-field--error')
    }

    return !isShowError
  }

  validationFieldAll = () => {
    return this.extraFieldConfigList.every(extraField => this.validationField(extraField))
  }

  getExtraFieldsValue = () => {
    const {
      moduleType,
    } = this.options
    const extraFieldsValue = []
    this.isCompanyCanSubmit = true
    this.isCompanyUserCanSubmit = true
    this.extraFieldConfigList.forEach(extraField => {
      const {
        inputId,
        fieldName,
        visibleToEnduser,
        isRequired,
      } = extraField
      let fieldValue = null

      this.validationField(extraField)
      if (isRequired && !document.querySelector(`#${inputId}`)?.value && moduleType === 'company') {
        this.isCompanyCanSubmit = false
      }
      if (isRequired && !document.querySelector(`#${inputId}`)?.value && moduleType === 'user') {
        this.isCompanyUserCanSubmit = false
      }

      if (visibleToEnduser) {
        fieldValue = document.querySelector(`#${inputId}`)?.value

        extraFieldsValue.push({
          fieldName,
          fieldValue,
        })
      }
    })

    return extraFieldsValue
  }

  // render detail fields
  static renderExtraFieldsHtml({ extraFields, templateOption, separator = ':' }) {
    const {
      containerSelector,
      insertType,
      fieldNameTag,
      fieldValueTag,
      fieldNameClass,
      fieldValueClass,
      wrapperTag,
      wrapperTagClass,
    } = templateOption

    const html = extraFields.reduce((html, { fieldName, fieldValue }) => {
      const mClassName = `B3ExtraField-${fieldName.replace(' ', '')}`
      const fieldNameHtml =
        `<${fieldNameTag} class="${fieldNameClass || ''} ${mClassName}">
          ${fieldName}${separator || ''}
        </${fieldNameTag}>`

      const fieldValueHtml =
        `<${fieldValueTag} class="${fieldValueClass || ''} ${mClassName}">
          ${fieldValue}
        </${fieldValueTag}>`

      return html +=
        `${wrapperTag ? `<${wrapperTag} class="${mClassName}__wrapper"> ${wrapperTagClass || ''}` : ''}
          ${fieldNameHtml}${fieldValueHtml}
        ${wrapperTag ? `</${wrapperTag}>` : ''}`
    }, '')

    const containerEl = document.querySelector(containerSelector)
    if (containerEl) containerEl.insertAdjacentHTML(insertType || 'beforeend', html)
  }
}

window.B3ExtraField = B3ExtraField
