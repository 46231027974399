import stencilUtils from '../../common/utils/stencilUtils'
import BasePage from '../../common/BasePage'
import containers from '../../containers'
import { isAllRequiredOptionFilled, serialize, getProductOptionList } from '../../components'
import * as utils from '../../common/utils'

class B2Bpdp extends BasePage {
  constructor() {
    super()
    this.name = 'B2Bpdp'
    this.state = {
      status: this.statusValue,
      productInfo: null,
    }

    this.stencilUtils = stencilUtils
    this.isAllRequiredOptionFilled = isAllRequiredOptionFilled
    this.serialize = serialize
    this.getProductOptionList = getProductOptionList
  }

  get B3StorefrontConfig() {
    const {
      B3Storage,
    } = this.utils
    return JSON.parse(B3Storage.B3StorefrontConfig.value)
  }

  get statusValue() {
    const { B3RoleId } = this.utils.B3Storage
    return B3RoleId.value === '2' ? '2' : '0'
  }

  get isInpage() {
    return this.utils.leftIncludes(this.context.template, 'pages/product')
  }

  shoppingListBtn(quickView) {
    return `<form action="" class="form form-wishlist form-action" data-shoppinglist-add method="post">
    <a aria-controls="${quickView ? 'quick-shoppinglist-dropdown' : 'shoppinglist-dropdown'}"
    aria-expanded="false" class="button dropdown-menu-button ${this.classes['pdp.button.addToShoppingList']}"
    data-dropdown="${quickView ? 'quick-shoppinglist-dropdown' : 'shoppinglist-dropdown'}">
        <span>${this.text['order.addTo.shoppingListBtn']}</span>
        <i aria-hidden="true" class="icon ${this.classes['pdp.button.addToShoppingList.icon']}">
            <svg>
                <use xlink:href="#icon-chevron-down" />
            </svg>
        </i>
    </a>
    <ul aria-hidden="true" class="dropdown-menu" data-dropdown-content id="${quickView ? 'quick-shoppinglist-dropdown' : 'shoppinglist-dropdown'}" tabindex="-1">
    </ul>
    </form>`
  }

  async init() {
    const {
      B3CompanyStatus,
    } = this.utils.B3Storage

    if (this.isB2BUser) {
      this.quickview()
    }

    if (!this.isB2BUser || B3CompanyStatus.value !== '1' || !this.isInpage) return

    this.getProductInfo(this.initPage)
  }

  initPage() {
    const { shoppingLists } = this.B3StorefrontConfig

    const isLogin = !!window.jsContext.customer
    if (isLogin) {
      this.hideWishlist()
      if (shoppingLists) {
        this.addShoppingListBtn()
        this.showShoppingList()

        if (this.isB2BUser) {
          document.querySelector('.form-wishlist').style.width = '100%'
        }
      }
    }
    // this.bindEvents()
  }

  quickview() {
    const {
      B3CompanyStatus,
    } = this.utils.B3Storage
    const { shoppingLists } = this.B3StorefrontConfig
    const quickviewItems = document.querySelectorAll('.quickview')
    if (quickviewItems.length === 0) return

    document.querySelector('body').addEventListener('click', e => {
      let interval
      if (interval) clearInterval(interval)

      const checkQuickview = node => {
        if (!node || node.nodeName === 'BODY') return false
        if (node.classList.contains('quickview')) return true
        return checkQuickview(node.parentNode)
      }

      if (checkQuickview(e.target)) {
        interval = setInterval(() => {
          const quickView = document.querySelectorAll('#modal .productView--quickView')
          if (quickView.length > 0) {
            if (B3CompanyStatus.value === '1') {
              const callBack = () => {
                this.hideWishlist()
                if (shoppingLists) {
                  this.addShoppingListBtn(true)
                  this.showShoppingList(true)
                }
              }
              this.getProductInfo(callBack)
            }
            clearInterval(interval)
          }
        }, 200)
      }
    })
  }

  hideWishlist() {
    const wishListBtn = document.querySelector('[action^="/wishlist.php"]')
    if (wishListBtn) wishListBtn.remove()
  }

  addShoppingListBtn(quickView) {
    const el = quickView ? document.querySelector('#modal').querySelector(containers['pdp.shoppinglist.container']) : document.querySelector(containers['pdp.shoppinglist.container'])
    const shoppingListContainer = document.createElement('div')

    shoppingListContainer.classList.add('form-action')
    shoppingListContainer.innerHTML = this.shoppingListBtn(quickView || null)
    el.append(shoppingListContainer)
  }

  async showShoppingList(quickView) {
    const { status } = this.state
    const lists = await this.api.getShoppingLists({ status })
    let frag = ''

    lists.list.forEach(item => {
      frag += `<li><button data-click='addToShoppingList' data-stop type="button" class="button" add-to-list
      data-list-id="${item.id}" data-list-status="${item.status}" >${this.utils.text('shopping.list.addToBtn', {
        hash: {
          name: item.name,
        },
      })}</button></li>`
    })
    frag += `<li data-list-id>
              <a id="createShoppingList" class="button">${this.text['shopping.list.creatNewListBtn']}</a>
              <div class="creat-list-group" style="display: none; padding: 1rem;align-items: center;">
                <input class="form-input" style="flex: 1;"/>
                <div style="display: flex;flex-flow: column; margin-left: 10px;">
                  <button id="add-new-shopping-list-cancel"  class="button button--small cancel">${this.text['order.showShoppingList.cancel.button']}</button>
                  <button id="add-new-shopping-list-confirm"  class="button button--small button--primary confirm" add-to-list style="margin-left: 0;">${this.text['order.showShoppingList.confirm.button']}</button>
                </div>
              </div>
            </li>`

    const dropDownContainer = quickView ? document.querySelector('#modal #quick-shoppinglist-dropdown') : document.querySelector('#shoppinglist-dropdown')
    dropDownContainer.innerHTML = utils.b3FilterXSS(frag)
    const createBtn = dropDownContainer.querySelector('#createShoppingList')
    const $createListGroup = dropDownContainer.querySelector('.creat-list-group')
    const $cancelCreateBtn = $createListGroup.querySelector('.creat-list-group .cancel')
    const $confirm = $createListGroup.querySelector('.creat-list-group .confirm')
    const $input = $createListGroup.querySelector('.form-input')

    createBtn.addEventListener('click', e => {
      if (this.isOpenSigleLimitError('shippingList')) {
        this.sigleLimitErrorDialog('shippingList')
        e.preventDefault()
        e.stopPropagation()
        return
      }
      e.stopPropagation()
      createBtn.style.display = 'none'
      $createListGroup.style.display = 'flex'
    })

    $input.addEventListener('click', e => e.stopPropagation())

    const handleCancel = () => {
      createBtn.style.display = 'block'
      $createListGroup.style.display = 'none'
      $input.value = ''
    }

    $cancelCreateBtn.addEventListener('click', async e => {
      e.stopPropagation()
      e.preventDefault()
      handleCancel()
    })

    $confirm.addEventListener('click', async e => {
      e.stopPropagation()
      e.preventDefault()
      $confirm.disabled = true
      if (!$input.value) {
        this.utils.Alert.error(this.locales.validation.emptyShoppingListName)
        $confirm.disabled = false
        return
      }
      const createShopingList = async (hasVariants, status) => {
        if (hasVariants && !status) return
        const shopplistId = await this.createShopingList($input.value)
        $confirm.setAttribute('data-list-id', shopplistId)
        return shopplistId
      }
      await this.addToShoppingList(e, quickView, createShopingList)
      $confirm.removeAttribute('data-list-id')
      dropDownContainer.classList.remove('is-open')
      handleCancel()
      $confirm.disabled = false
    })

    if (quickView) {
      const $container = document.querySelector('.productView--quickView')
      const addToShoppingBtn = $container.querySelectorAll('[add-to-list]')

      addToShoppingBtn.forEach(item => {
        item.addEventListener('click', e => {
          if (!e.target.dataset.listId) return
          this.addToShoppingList(e, true)
        })
      })
    }
  }

  async createShopingList(name) {
    const {
      B3RoleId,
    } = this.utils.B3Storage

    let status = '30'

    const {
      constants: {
        B3Role: {
          ADMIN, SENIOR, SALESREP,
        },
      },
    } = this.utils

    if ([ADMIN, SENIOR, SALESREP].includes(B3RoleId.value)) {
      status = '0'
    }

    const {
      shopplistId,
    } = await this.api.createShopingList({
      name,
      description: '',
      status,
    })

    return shopplistId
  }

  filterEmptyFilesFromForm(formData) {
    try {
      Array.from(formData).forEach(item => {
        const key = item[0]
        const val = item[1]
        if (val instanceof File && !val.name && !val.size) {
          formData.delete(key)
        }
      })
    } catch (e) {
      // console.error(e)
    }
    return formData
  }

  bindEvents() {
    document.querySelector('body').addEventListener('click', e => {
      this.addToShoppingList(e)
    })
  }

  async addToShoppingList(e, quickView, beforeAdd) {
    if (e.target.hasAttribute('add-to-list')) {
      e.preventDefault()
      window.B3Spinner.show()
      const container = quickView ? '#modal ' : ''
      let status = false
      let shoppingListId = e.target.getAttribute('data-list-id')
      let hasVariants = false
      try {
        const productId = document.querySelector(`${container}input[name=product_id]`).value
        const qty = document.querySelector(`${container}[name='qty[]']`)?.value ?? 1
        const sku = this.utils.trim(document.querySelector(`${container} [data-product-sku]`)?.innerHTML ?? '')

        if (!shoppingListId && !beforeAdd) return
        const data = await this.api.getVariantsByProductId({ productId })
        hasVariants = (data.length > 0)

        let variantId

        if (hasVariants) {
          data.forEach(item => {
            if (item.sku === sku) {
              variantId = item.variantId
              status = true
            }
          })
        }

        if (beforeAdd instanceof Function) shoppingListId = await beforeAdd(hasVariants, status)

        if (!shoppingListId) return

        const optionList = this.getProductFormOptions(container)

        const optionListMap = {}
        optionList.forEach(option => {
          const {
            option_id,
            option_value,
          } = option

          optionListMap[option_id.match(/\d+/g)[0]] = option_value
        })

        const {
          productInfo: {
            options,
          },
        } = this.state

        const canAddToSl = this.isAllRequiredOptionFilled(options, optionList)

        if (!canAddToSl) return false

        for (let i = 0; i < options.length; i += 1) {
          const {
            id, values, required, noValue, value,
          } = options[i]

          if (!required) continue

          status = optionListMap[id]
          if (!values && noValue) {
            status = +optionListMap[id] === +value
          }

          if (!status) break
        }

        if (!hasVariants || status) {
          const params = {
            id: shoppingListId,
            items: [
              {
                productId,
                variantId,
                qty,
                optionList,
              },
            ],
          }
          try {
            const { list } = await this.api.getInventory({ products: [{ productId, variantId }] })
            if (list[0].purchasingDisabled) {
              this.utils.Alert.error(this.locales.tips.buyAgainFailedNoLongerForSale)
              window.B3Spinner.hide()
              return
            }
            await this.api.addProductToShoppingList(params)

            const showShoppingListTips = {
              showConfirmButton: false,
              timer: 3000,
              onAfterClose: () => {
                location.reload()
              },
            }
            this.utils.Alert.success(this.locales.tips.addToShoppingListSuccess, showShoppingListTips)
          } catch {
            //
          }
        }
      } catch (error) {
        this.utils.Alert.error(this.locales.tips.globalError)
      } finally {
        window.B3Spinner.hide()
        if (hasVariants && !status) {
          this.utils.Alert.error(this.locales.validation.cannotAddToShoppingList)
        }
      }
      window.B3Spinner.hide()
    }
  }

  getProductInfo(callBack) {
    const productId = document.querySelector('#modal input[name=product_id]')?.value || document.querySelector('input[name=product_id]')?.value

    if (!productId) return
    this.stencilUtils.api.product.getById(productId, {
      template: 'b3/b3json',
    }, (err, response) => {
      if (err) return
      const {
        product: productInfo,
      } = JSON.parse(response)

      this.setState({
        productInfo,
      })

      callBack && callBack.call(this)
    })
  }

  getProductFormOptions(container) {
    const form = document.querySelector(`${container}form[data-cart-item-add]`)
    const optionList = this.getProductOptionList(this.serialize(form, 'isBCForm'))

    return optionList
  }
}
export default B2Bpdp
