var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th class=\"t-align-left pointer "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sortBy") : depth0), depth0))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"dashboard.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":33,"column":55},"end":{"line":33,"column":87}}}))
    + "\" data-sort-th data-order-by=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"orderBy") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "<span\r\n                class=\"filter-icon\"></span></th>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <div class=\"bottom-end-masquerade "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td.button.end.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":44,"column":38},"end":{"line":44,"column":85}}}))
    + "\"><a href=\"javascript:void(0);\"\r\n        class=\"button button--primary "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td.button.end",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":45,"column":38},"end":{"line":45,"column":75}}}))
    + "\" end-masquerade>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.masquerade.end.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":45,"column":92},"end":{"line":45,"column":136}}}))
    + "</a></div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"saleRep "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"Dashboard",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":43}}}))
    + "\">\r\n  <div class=\"b2b-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"b2bWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":23},"end":{"line":2,"column":44}}}))
    + "\">\r\n    <div class=\"sale-info-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.panel.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":70}}}))
    + "\">\r\n      <div class=\"sale-rep-user-info "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":79}}}))
    + "\">\r\n        <p class=\"sale-user-name "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.name.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":5,"column":33},"end":{"line":5,"column":80}}}))
    + "\">\r\n          <span class=\"user-label "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.name.label",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":6,"column":34},"end":{"line":6,"column":77}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.user.name.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":79},"end":{"line":6,"column":117}}}))
    + "</span>\r\n          <span class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.name.value",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":66}}}))
    + "\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "</span>\r\n        </p>\r\n        <p class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.email.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":66}}}))
    + "\">\r\n          <span class=\"user-label "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.email.label",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":10,"column":34},"end":{"line":10,"column":78}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.user.email.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":80},"end":{"line":10,"column":119}}}))
    + "</span>\r\n          <span class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.userInfo.email.value",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":67}}}))
    + "\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"userInfo") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + "</span>\r\n        </p>\r\n      </div>\r\n      <div class=\"sale-reo-list-record "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.companyInfo.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":14,"column":39},"end":{"line":14,"column":84}}}))
    + "\">\r\n        <p class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.companyInfo.title",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":59}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.company.counts",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":15,"column":61},"end":{"line":15,"column":98}}}))
    + "</p>\r\n        <p class=\"record-total "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.companyInfo.num",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":16,"column":31},"end":{"line":16,"column":70}}}))
    + "\">"
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"pagination") : depth0)) != null ? lookupProperty(stack1,"perCount") : stack1), depth0))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <!-- <div class='pagination-wrap'>pagination</div> -->\r\n    <div class=\"clearfix "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.actions.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":20,"column":25},"end":{"line":20,"column":66}}}))
    + "\">\r\n      <div class=\"search "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.search.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":21,"column":25},"end":{"line":21,"column":65}}}))
    + "\">\r\n        <input type=\"text\" class=\"form-input "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.search.input",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":22,"column":45},"end":{"line":22,"column":81}}}))
    + "\" placeholder=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.search.placeholder",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":22,"column":96},"end":{"line":22,"column":137}}}))
    + "\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"filters") : depth0)) != null ? lookupProperty(stack1,"q") : stack1), depth0))
    + "\">\r\n      </div>\r\n      <div class=\"pagination "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.pagination.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":24,"column":29},"end":{"line":24,"column":73}}}))
    + "\">\r\n        <ul class=\"pagination-list\" id=\"pagination\"></ul>\r\n      </div>\r\n    </div>\r\n    <div class=\"table-wrap\">\r\n      <table class=\"responsive-table table-active-user "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.table",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":29,"column":55},"end":{"line":29,"column":84}}}))
    + "\" id=\"sale-rep-table\">\r\n        <thead class=\"sort-thead\">\r\n          <tr>\r\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,alias3((depth0 != null ? lookupProperty(depth0,"filterMaps") : depth0), depth0),{"name":"filterMaps","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":35,"column":27}}})) != null ? stack1 : "")
    + "            <th class=\"t-align-c "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":36,"column":33},"end":{"line":36,"column":65}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.action.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":36,"column":67},"end":{"line":36,"column":102}}}))
    + "</th>\r\n          </tr>\r\n        </thead>\r\n        <tbody>\r\n        </tbody>\r\n      </table>\r\n    </div>\r\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isShowEndMasquerade") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "")
    + "  </div>\r\n</div>\r\n";
},"useData":true});