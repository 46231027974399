var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n <span class=\"b3config__tpaAttachFileLabel\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attachFileLabel") : depth0), depth0))
    + "</span>\n <span class=\"b3config__tpaAttachFileDes\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"attachFileDes") : depth0), depth0))
    + "</span>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"attachment-file-item\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":16,"column":6},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "      <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileName") : depth0), depth0))
    + "</p>\n      <i class=\"fa fa-trash g-alink\" data-fileName="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileName") : depth0), depth0))
    + " id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileName") : depth0), depth0))
    + "\"  data-delete-file></i>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"fa fa-file-image-o\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <i class=\"fa fa-file\"></i>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fa fa-paperclip\" data-click=\"handleAttachClick\">\n  <input class=\"upload-attachment\" type=\"file\" name=\"file\" data-upload-attachment>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.attach.file.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":82},"end":{"line":2,"column":119}}}))
    + "</input>\n</i>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"showB3themeConfig") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":9,"column":7}}})) != null ? stack1 : "")
    + "<p class=\"attachment-file-tip-text\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.attach.file.limitNumTipText",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":36},"end":{"line":10,"column":83}}}))
    + "</p>\n<p class=\"attachment-file-tip-text\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.attach.file.fileSizeTipText",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":11,"column":36},"end":{"line":11,"column":83}}}))
    + "</p>\n<p class=\"attachment-file-tip-text\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.attach.file.formatSupportTipText",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":12,"column":36},"end":{"line":12,"column":88}}}))
    + "</p>\n<div class=\"attachment-file-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"attachments") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":2},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "</div>\n\n";
},"useData":true});