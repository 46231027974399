var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr>\n      <td>"
    + alias2(__default(require("../../../hbs/helpers/displayFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"createdAt") : depth0),{"name":"displayFormat","hash":{},"data":data,"loc":{"start":{"line":15,"column":10},"end":{"line":15,"column":37}}}))
    + "</td>\n      <td>"
    + alias2(__default(require("../../../hbs/helpers/currencyCodeFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"currencyCodeFormat","hash":{},"data":data,"loc":{"start":{"line":16,"column":10},"end":{"line":16,"column":39}}}))
    + "</td>\n      <td>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"transactionType") : depth0), depth0))
    + "</td>\n      <td>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"paymentType") : depth0), depth0))
    + "</td>\n      <td>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"referenceNumber") : depth0), depth0))
    + "</td>\n    </tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"invoice-detail-subTitle "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceDetail.subTitle",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":72}}}))
    + "\"> "
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.payments.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":1,"column":114}}}))
    + " </div>\n<table class=\"invoice-table responsive-table "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceDetail.info.table",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":83}}}))
    + "\">\n  <thead>\n    <tr>\n      <th>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.payments.dateReceived",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":5,"column":10},"end":{"line":5,"column":56}}}))
    + "</th>\n      <th>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.payments.amount",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":50}}}))
    + "</th>\n      <th>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.payments.transactionType",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":10},"end":{"line":7,"column":59}}}))
    + "</th>\n      <th>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.payments.paymentType",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":55}}}))
    + "</th>\n      <th>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.payments.reference",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":9,"column":10},"end":{"line":9,"column":53}}}))
    + "</th>\n    </tr>\n  </thead>\n  <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"payments") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":21,"column":13}}})) != null ? stack1 : "")
    + "  </tbody>\n</table>\n";
},"useData":true});