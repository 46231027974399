var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"modal-header "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.header",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":70}}}))
    + "\">\n  <h2 class=\"modal-header-title "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.header.title",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":83}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.createModal.title.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":85},"end":{"line":2,"column":130}}}))
    + "</h2>\n  <a href=\"#\" class=\"modal-close "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.header.close",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":84}}}))
    + "\" aria-label=\"close\" role=\"button\">\n    &#215;\n  </a>\n</div>\n<div class=\"modal-body "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.body",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":66}}}))
    + "\">\n  <form class=\"form\" id=\"new_shopping_list_form\" action=\"\" method=\"post\" data-product=${JSON.stringify(data)}>\n    <fieldset class=\"form-fieldset "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":88}}}))
    + "\">\n      <div class=\"form-field "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":10,"column":29},"end":{"line":10,"column":77}}}))
    + "\">\n        <label class=\"form-label "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item.label",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":87}}}))
    + "\" for=\"list_name\">\n          "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.createModal.name.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":12,"column":54}}}))
    + "\n          <small class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item.required",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":13,"column":24},"end":{"line":13,"column":81}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":13,"column":83},"end":{"line":13,"column":117}}}))
    + "</small>\n        </label>\n        <input maxlength=\"200\" class=\"form-input "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item.input",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":15,"column":49},"end":{"line":15,"column":103}}}))
    + "\" type=\"text\" name=\"list_name\" id=\"list_name\">\n        <span style=\"display: none;color: #ff0000;\">\n          "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.createModal.name.required",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":17,"column":10},"end":{"line":17,"column":57}}}))
    + "\n        </span>\n      </div>\n      <div class=\"form-field "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":20,"column":29},"end":{"line":20,"column":77}}}))
    + "\">\n        <label class=\"form-label "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item.label",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":21,"column":33},"end":{"line":21,"column":87}}}))
    + "\" for=\"list_comment\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.createModal.description.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":21,"column":108},"end":{"line":21,"column":159}}}))
    + "</label>\n        <textarea maxlength=\"200\" class=\"form-input "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.form.item.textarea",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":22,"column":52},"end":{"line":22,"column":109}}}))
    + "\" name=\"list_comment\" id=\"list_comment\" cols=\"30\" rows=\"3\"></textarea>\n      </div>\n\n      <div class=\"form-actions text-left "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.actions.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":25,"column":41},"end":{"line":25,"column":97}}}))
    + "\">\n        <input type=\"submit\" class=\"button button--primary "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.button.save",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":26,"column":59},"end":{"line":26,"column":109}}}))
    + "\" value=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.modal.save",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":26,"column":118},"end":{"line":26,"column":150}}}))
    + "\" id=\"add_new_shoppingList\">\n\n        <a href=\"#\" class=\"button  modal-close modal-close--button "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"global.shoppingListModal.button.cancel",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":28,"column":67},"end":{"line":28,"column":119}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.modal.cancel",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":28,"column":121},"end":{"line":28,"column":155}}}))
    + "</a>\n      </div>\n\n    </fieldset>\n  </form>\n</div>\n";
},"useData":true});