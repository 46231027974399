import text from '../../text'

export const QUOTE_STATUS_MAP = {
  1: text['quotes.list.status.open'],
  4: text['quotes.list.status.ordered'],
  5: text['quotes.list.status.expired'],
}

export const FILTER_FIELDS = [
  {
    label: 'quotes.list.quoteTitle',
    value: 'quoteTitle',
    type: 'text',
  },
  {
    label: 'quotes.list.salesRep',
    value: 'salesRep',
    type: 'text',
  },
  {
    label: 'quotes.list.createdBy',
    value: 'createdBy',
    type: 'text',
  },
  {
    label: 'quotes.list.status',
    value: 'status',
    palceholder: 'address.filters.country.palceholder',
    type: 'select',
  },
  {
    label: 'quotes.filters.dateCreatedBeginAt',
    value: 'dateCreatedBeginAt',
    type: 'date',
    pairfields: 'dateCreatedBeginAt|dateCreatedEndAt',
  },
  {
    type: 'palceholder',
    text: 'rfq.form.to',
  },
  {
    value: 'dateCreatedEndAt',
    type: 'date',
    pairfields: 'dateCreatedBeginAt|dateCreatedEndAt',
  },
  {
    label: 'quotes.filters.dateUpdatedBeginAt',
    value: 'dateUpdatedBeginAt',
    type: 'date',
    pairfields: 'dateUpdatedBeginAt|dateUpdatedEndAt',
  },
  {
    type: 'palceholder',
    text: 'rfq.form.to',
  },
  {
    value: 'dateUpdatedEndAt',
    type: 'date',
    pairfields: 'dateUpdatedBeginAt|dateUpdatedEndAt',
  },
  {
    label: 'quotes.filters.dateExpiredBeginAt',
    value: 'dateExpiredBeginAt',
    type: 'date',
    pairfields: 'dateExpiredBeginAt|dateExpiredEndAt',
  },
  {
    type: 'palceholder',
    text: 'rfq.form.to',
  },
  {
    value: 'dateExpiredEndAt',
    type: 'date',
    pairfields: 'dateExpiredBeginAt|dateExpiredEndAt',
  },
]
