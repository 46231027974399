import './B3Spinner'
import './B3Paginator'
import './B3DatePicker'
import './B3Modal'
import './B3DateFormat'
import './B3LanguageService'
import './B3ExtraField'
import { creatSrctipt } from '../common/utils/util'

creatSrctipt('https://use.fontawesome.com/8045190b4c.js')
