import text from '../../text'

export default {
  notPermission: text['tips.notPermission'],
  networkError: text['tips.networkError'],
  globalError: text['tips.globalError'],
  createCompanyApproved: text['tips.createCompanyApproved'],
  createCompanyChecking: text['tips.createCompanyChecking'],
  cannotEnterPage: text['tips.cannotEnterPage'],
  addProductsSuccess: text['tips.addProductsSuccess'],
  productOutOfStock: text['tips.productOutOfStock'],
  reorderFailedNoLongerForSale: text['tips.reorderFailedNoLongerForSale'],
  reorderFailedOutOfStock: text['tips.reorderFailedOutOfStock'],
  buyAgainFailedNoLongerForSale: text['tips.buyAgainFailedNoLongerForSale'],
  buyAgainFailedOutOfStock: text['tips.buyAgainFailedOutOfStock'],
  deleteUser: text['tips.deleteUser'],
  confirmDeleteUser: text['tips.confirmDeleteUser'],
  chooseShoppingList: text['tips.chooseShoppingList'],
  addToShoppingListSuccess: text['tips.addToShoppingListSuccess'],
  confirmDeleteShoppingList: text['tips.confirmDeleteShoppingList'],
  deleteShoppingListFailed: text['tips.deleteShoppingListFailed'],
  shoppingListExist: text['tips.shoppingListExist'],
  shoppingListUpdateSuccess: text['tips.shoppingListUpdateSuccess'],
  confirmDeleteShoppingListItem: text['tips.confirmDeleteShoppingListItem'],
  deleteShoppingListItemSuccess: text['tips.deleteShoppingListItemSuccess'],
  updateQtySuccess: text['tips.updateQtySuccess'],
  updateOptionsSuccess: text['tips.updateOptionsSuccess'],
  clearedShoppingList: text['tips.clearedShoppingList'],
  clearShoppingListFailed: text['tips.clearShoppingListFailed'],
  noItemList: text['tips.noItemList'],
  updateShoppingListSuccess: text['tips.updateShoppingListSuccess'],
  searchProducts: text['tips.searchProducts'],
  selectProducts: text['tips.selectProducts'],
  invalidQty: text['tips.invalidQty'],
  confirmDeleteAddress: text['tips.confirmDeleteAddress'],
  deleteAddressSuccess: text['tips.deleteAddressSuccess'],
  confirmSetShippingAddress: text['tips.confirmSetShippingAddress'],
  setShippingAddressSuccess: text['tips.setShippingAddressSuccess'],
  confirmSetBillingAddress: text['tips.confirmSetBillingAddress'],
  setBillingAddressSuccess: text['tips.setBillingAddressSuccess'],
  duplicateRfqCompany: text['tips.duplicateRfqCompany'],
  addToQuoteSuccess: text['tips.addToQuoteSuccess'],
  addToQuoteDiffCurrency: text['tips.addToQuoteDiffCurrency'],
  noPermission: text['tips.noPermissionToCheckout'],
  cannotPurchase: text['tips.productsNotAvailable'],
}
