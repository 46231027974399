export const limitConfig = [
  {
    name: 'UserManagement',
    label: 'usermanagement',
    channel: 'company_user:limitation',
  },
  {
    name: 'AddressBook',
    label: 'addressbook',
    channel: 'address:limitation',
  },
  {
    name: 'Orders',
    label: 'orders',
    channel: 'order_limitation',
  },
  {
    name: 'Quotes',
    label: 'quotes',
    channel: 'quote_limitation',
    singleChannel: 'quote_count_limitation',
  },
  {
    name: 'ShoppingLists',
    label: 'shoppingLists',
    channel: 'shopping_list_limitation',
    singleChannel: 'shopping_list_count_limitation',
  },
]

// pramary(0-80) warning(80-100) error(>=100)
export const limitsLevel = {
  PRAMARY: 'pramary',
  WARNING: 'warning',
  ERROR: 'error',
}

export const limitTextConfig = {
  quote: 'quote_count_limitation',
  shippingList: 'shopping_list_count_limitation',
  company: 'company_count_limitation',
}
