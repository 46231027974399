var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input class=\"form-checkbox\" type=\"checkbox\" data-click=\"setInvoiceList\" "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isSelectAll") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":85},"end":{"line":8,"column":118}}})) != null ? stack1 : "")
    + "\n              value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"isSelectAll") : depth0), depth0))
    + "\" id=\"checkbox-select-all\">\n            <label class=\"form-label\" for=\"checkbox-select-all\">\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <th class=\"sortable\" class=\""
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isSort") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":38},"end":{"line":14,"column":67}}})) != null ? stack1 : "")
    + "\">\n            <span "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isSort") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":92}}})) != null ? stack1 : "")
    + ">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isSort") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isSort") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":23,"column":21}}})) != null ? stack1 : "")
    + "          </th>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "sortable";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " data-click=\"handleShort\" data-sortkey='"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"sortKey") : depth0), depth0))
    + "' ";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"th-sort "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"sortBy") : depths[1]),"==",(depth0 != null ? lookupProperty(depth0,"sortKey") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":33},"end":{"line":17,"column":76}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depths[1] != null ? lookupProperty(depths[1],"orderBy") : depths[1]),"==","DESC",{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":77},"end":{"line":17,"column":116}}})) != null ? stack1 : "")
    + "\"\n              data-sort-field=\"invoiceNumber\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "active";
},"12":function(container,depth0,helpers,partials,data) {
    return "up";
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span data-click=\"handleShort\" data-sortkey='"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"sortKey") : depth0), depth0))
    + "' class=\"common-sort-icon "
    + alias1(__default(require("../../../hbs/helpers/classes.js")).call(alias2,"invoices.sort.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":21,"column":94},"end":{"line":21,"column":126}}}))
    + " "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias2,(depths[1] != null ? lookupProperty(depths[1],"sortBy") : depths[1]),"==",(depth0 != null ? lookupProperty(depth0,"sortKey") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":127},"end":{"line":22,"column":58}}})) != null ? stack1 : "")
    + "\" data-sort-th </span>\n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n            "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depths[1] != null ? lookupProperty(depths[1],"orderBy") : depths[1]),"==","ASC",{"name":"if","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":51}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    return "asc";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"Invoices",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":30,"column":19},"end":{"line":30,"column":41}}}))
    + "\">\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":31,"column":21},"end":{"line":31,"column":52}}}))
    + " invoice-select-td\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"notAllowedPay") : depth0),"!=",1,{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":42,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":44,"column":21},"end":{"line":44,"column":52}}}))
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"invoiceNumber") : depth0), depth0))
    + "</td>\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":45,"column":21},"end":{"line":45,"column":52}}}))
    + "\">"
    + alias2(__default(require("../../../hbs/helpers/displayFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayDueDate") : depth0),{"name":"displayFormat","hash":{},"data":data,"loc":{"start":{"line":45,"column":54},"end":{"line":45,"column":86}}}))
    + "</td>\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":46,"column":21},"end":{"line":46,"column":52}}}))
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"displayStatus") : depth0), depth0))
    + "</td>\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":47,"column":21},"end":{"line":47,"column":52}}}))
    + "\">"
    + alias2(__default(require("../../../hbs/helpers/currencyCodeFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"originalBalance") : depth0),{"name":"currencyCodeFormat","hash":{},"data":data,"loc":{"start":{"line":47,"column":54},"end":{"line":47,"column":92}}}))
    + "</td>\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":48,"column":21},"end":{"line":48,"column":52}}}))
    + "\">"
    + alias2(__default(require("../../../hbs/helpers/currencyCodeFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"openBalance") : depth0),{"name":"currencyCodeFormat","hash":{},"data":data,"loc":{"start":{"line":48,"column":54},"end":{"line":48,"column":88}}}))
    + " </td>\n          <td class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoices.tbody.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":49,"column":21},"end":{"line":49,"column":52}}}))
    + "\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"notAllowedPay") : depth0),"!=",1,{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":64,"column":19}}})) != null ? stack1 : "")
    + "          </td>\n        </tr>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"status") : depth0),"!=",2,{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":41,"column":19}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <input class=\"form-checkbox\" select-one-invoice id=\"checkbox-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" type=\"checkbox\" "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":104},"end":{"line":35,"column":37}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currency") : depths[1])) != null ? lookupProperty(stack1,"token") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":38},"end":{"line":36,"column":37}}})) != null ? stack1 : "")
    + " data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-code=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"currency_code") : stack1), depth0))
    + "\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"checked") : depth0), depth0))
    + "\"\n              data-click=\"setInvoiceList\">\n            <label class=\"form-label\" "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias3,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currency") : depths[1])) != null ? lookupProperty(stack1,"token") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":38},"end":{"line":39,"column":37}}})) != null ? stack1 : "")
    + " for=\"checkbox-select-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\">\n            </label>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currency") : depths[1])) != null ? lookupProperty(stack1,"token") : stack1),"!=",((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":64},"end":{"line":36,"column":29}}})) != null ? stack1 : "")
    + " ";
},"22":function(container,depth0,helpers,partials,data) {
    return "\n              disabled";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"status") : depth0),"!=",2,{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"flex-ac\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token_location") : stack1),"==","left",{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":53,"column":14},"end":{"line":55,"column":21}}})) != null ? stack1 : "")
    + "              <input "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currency") : depths[1])) != null ? lookupProperty(stack1,"token") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":21},"end":{"line":56,"column":116}}})) != null ? stack1 : "")
    + "\n                class=\"form-input form-input--small amount-input\" type=\"text\" value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"currentAmountValue") : depth0), depth0))
    + "\"\n                data-id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" data-code=\""
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"currency_code") : stack1), depth0))
    + "\" data-blur=\"handleInputBlur\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token_location") : stack1),"==","right",{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":14},"end":{"line":61,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token") : stack1), depth0))
    + "</span>\n";
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? lookupProperty(depths[1],"currency") : depths[1])) != null ? lookupProperty(stack1,"token") : stack1),"!=",((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token") : stack1),{"name":"if","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":47},"end":{"line":56,"column":108}}})) != null ? stack1 : "")
    + " ";
},"29":function(container,depth0,helpers,partials,data) {
    return " disabled";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <span>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token") : stack1), depth0))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"invoice-table-wrapper\">\n  <div class=\"table-wrap\">\n    <table invoices-payment-table class=\"invoices-payment-table responsive-table breakpoint-small\">\n      <thead>\n        <tr>\n          <th class=\"invoice-select-th\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"canSelectAll") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":11,"column":21}}})) != null ? stack1 : "")
    + "          </th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"tableHeaders") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":25,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n      </thead>\n      <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"invoiceList") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":67,"column":17}}})) != null ? stack1 : "")
    + "      </tbody>\n    </table>\n  </div>\n</div>\n<div id=\"payment-pagination\" class=\"pagination-list\"></div>\n<div class=\"invoice-pay-container\">\n  <div class=\"invoice-pay\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.total",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":74,"column":27},"end":{"line":74,"column":66}}}))
    + ":\n    <span class=\"invoice-pay-amount\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token_location") : stack1),"==","left",{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":6},"end":{"line":78,"column":13}}})) != null ? stack1 : "")
    + "      "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"paymentTotal") : depth0), depth0))
    + "\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"currency") : depth0)) != null ? lookupProperty(stack1,"token_location") : stack1),"==","right",{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":6},"end":{"line":82,"column":13}}})) != null ? stack1 : "")
    + "    </span>\n  </div>\n</div>";
},"useData":true,"useDepths":true});