var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"title-container\">\n  <h4>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.success.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":39}}}))
    + "</h4>\n  <button type=\"button\" class=\"quote-modal-close\" data-click=\"hideAddSuccessTipPage\">x</button>\n</div>\n<div class=\"text-center\">\n  <p>\n    "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.tip1",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":28}}}))
    + "\n    <a id=\"quote-url\" href=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"quoteUrl") : depth0), depth0))
    + "\" target=\\\"_blank\\\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.link",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":8,"column":60},"end":{"line":8,"column":84}}}))
    + ".</a>\n  </p>\n  <p>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.tip2",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":5},"end":{"line":10,"column":29}}}))
    + "</p>\n</div>\n";
},"useData":true});