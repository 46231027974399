var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rfq-container-info-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqAdditionalInfoWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":36},"end":{"line":2,"column":71}}}))
    + "\">\n  <p class=\"rfq-common-title rfq-additionalInfo-title\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.additionalInfo.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":55},"end":{"line":3,"column":90}}}))
    + "</p>\n  <div class=\"rfq-container-info-wrap \">\n    <div class=\"rfq-additionalInfo\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"notes") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"legalTerms") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":17,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"rfq-additionalInfo-notes\">\n        <div class=\"rfq-common-subTitle\">"
    + alias1(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.additionalInfo.notes",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":76}}}))
    + ":</div>\n        <div class=\"rfq-additionalInfo-item\"> "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"notes") : depth0), depth0))
    + " </div>\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"rfq-additionalInfo-legalTerms\">\n        <div class=\"rfq-common-subTitle\">"
    + alias1(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.additionalInfo.legalTerms",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":14,"column":41},"end":{"line":14,"column":81}}}))
    + ":</div>\n        <div class=\"rfq-additionalInfo-item\"> "
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"legalTerms") : depth0), depth0))
    + " </div>\n      </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isAdditionalInfoShow") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "");
},"useData":true});