import BasePage from '../../../common/BasePage'

import header from './header.html'
import detail from './detail.html'
import lineItems from './lineItems.html'
import shipments from './shipments.html'
import payments from './payments.html'
import termsConditions from './termsConditions.html'

import { INVOICE_STATUS_MAP } from '../constants'

export default class InvoiceDetail extends BasePage {
  constructor() {
    super()
    this.name = 'InvoiceDetail'

    this.tpls = {
      header,
      detail,
      lineItems,
      shipments,
      payments,
      termsConditions,
    }

    this.state = {
      invoiceDetail: null,
      INVOICE_STATUS_MAP,
    }
  }

  get isInPage() {
    return this.utils.leftIncludes(window.location.pathname, this.doms.invoiceDetail.url)
  }

  get currentPageDomContainer() {
    return this.doms[this.name.replace(/^[A-Z]/, A => A.toLowerCase())].container
  }

  get invoiceId() {
    return this.utils.urlHelper.searchParams.get('id')
  }

  init() {
    if (!this.isB2BUser) return

    if (!this.isInPage) return

    this.render()
  }

  render() {
    this.getInvoiceDetail()
  }

  async getInvoiceDetail() {
    try {
      const {
        data: invoiceDetail,
        data: {
          details: {
            details: {
              lineItems,
              shipments,
            },
            header: {
              billingAddress,
              shippingAddresses,
              costLines,
            },
          },
          notAllowedPay,
          status,
          invoiceNumber,
          originalBalance: {
            code,
          },
          originalBalance,
          openBalance,
          termsConditions,
        },
      } = await this.api.getInvoiceDetail(this.invoiceId)

      this.setState({ invoiceDetail })

      const {
        B3Storage: {
          B3RoleId: {
            value: B3RoleIdValue,
          },
        },
        constants: {
          B3Role: {
            ADMIN,
            SALESREP,
          },
        },
      } = this.utils

      const config = {
        allowPay: !notAllowedPay && [ADMIN, SALESREP].includes(B3RoleIdValue),
        ...this.state,
        ...invoiceDetail,
        billingAddress,
        shippingAddresses,
        costLines,
        amountPaid: {
          code,
          value: +originalBalance.value - +openBalance.value,
        },
      }

      this.renderTemplate('detail', config)

      const invoiceStatusDetail = this.getInvoiceStatusDetail(invoiceDetail)

      const headerConfig = {
        invoiceNumber,
        status: INVOICE_STATUS_MAP[status],
        ...invoiceStatusDetail,
      }

      this.renderTemplate('header', headerConfig, '.invoice-detail-header-wrap')

      this.renderLineItems(lineItems)
      this.renderTermsConditions(termsConditions)
      this.renderShipments(shipments)
      this.renderPayments()
      this.initMobileTable()

      this.renderExtraFieldsHtml()
    } catch (e) {
      console.error(e)
    }
  }

  getInvoiceStatusDetail(invoiceDetail) {
    let statusTitleClass = ''
    let statusTitleIcon = ''
    let statusTitle = ''
    let statusTitleText = ''
    let pastDueDays = 0

    // 2 === Completed; 1 === PartialPaid
    if (+invoiceDetail.status === 2) {
      statusTitleClass = 'paid-completed'
      statusTitle = 'invoicePaidCompleted.title'
      statusTitleText = 'invoicePaidCompleted'
      statusTitleIcon = 'fa-check-circle'
    } else {
      pastDueDays = this.utils.getPastDays(invoiceDetail.dueDate)
      statusTitleClass = 'paid-inCompleted'
      statusTitleIcon = 'fa-exclamation-circle'
      statusTitle = pastDueDays <= 0 ? 'invoicePaidInCompleted.title' : 'invoicePaidInCompleted.pastDue.title'
      statusTitleText = pastDueDays <= 0 ? 'invoicePaidInCompleted' : 'invoicePaidInCompleted.pastDue'
      pastDueDays = Math.abs(pastDueDays)
    }

    return {
      statusTitleClass,
      statusTitleIcon,
      statusTitle: this.utils.text(`invoiceDetail.header.tips.${statusTitle}`, { hash: { pastDueDays } }),
      statusTitleText: this.utils.text(`invoiceDetail.header.tips.${statusTitleText}`, { hash: { pastDueDays } }),
    }
  }

  // lineItems
  renderLineItems(lineItems) {
    let invoiceLineItems = lineItems
    invoiceLineItems = invoiceLineItems.map(lineItem => {
      const discountTotalValue = lineItem.unitDiscount ? lineItem.quantity * lineItem.unitDiscount.value : 0
      lineItem.discountTotal = lineItem.unitDiscount ? {
        code: lineItem.unitDiscount.code,
        value: discountTotalValue,
      } : ''
      lineItem.lineTotal = {
        code: lineItem.unitPrice.code,
        value: (+lineItem.quantity * +lineItem.unitPrice.value) - discountTotalValue,
      }

      return lineItem
    })

    this.renderTemplate('lineItems', { invoiceLineItems }, '.invoice-detail-lineItems')
  }

  // termsConditions
  renderTermsConditions(termsConditions) {
    const config = {
      termsConditions,
      isTermsConditionsNotEmpty: !!termsConditions,
    }
    this.renderTemplate('termsConditions', config, '.invoice-detail-termsConditions')
  }

  // shipments
  renderShipments(shipments) {
    const config = {
      shipments,
      isShipmentsEmpty: !Array.isArray(shipments) || !shipments.length,
    }
    this.renderTemplate('shipments', config, '.invoice-detail-shipments')
  }

  // payments
  async renderPayments() {
    try {
      const params = {
        invoiceId: this.invoiceId,
        limit: 1000,
        offset: 0,
      }
      const { data } = await this.api.getReceiptLines(params)
      this.renderTemplate('payments', { payments: data }, '.invoice-detail-payments')
    } catch (e) {
      console.error(e)
    }
  }

  printPDF() {
    const styleContent = `
      @page {
        size: auto;  /* auto is the initial value */
        margin: 0mm; /* this affects the margin in the printer settings */
      }
      @media (min-width: 801px) {
        .body {
          margin-top: 0;
        }
      }
      .body {
        margin-top: 0;
      }
      body > :not(.body) {
        display: none;
      }
      .body > :not(.container) {
        display: none;
      }
      .account > :not(.b2b-wrap) {
        display: none;
      }
      .page-heading,.breadcrumbs{
        display:none;
      }
      .navBar--account {
        display: none;
      }
      nav {
        display: none;
      }
    `

    const style = document.createElement('style')
    style.media = 'print'
    style.innerHTML = styleContent
    document.head.appendChild(style)
    window.print()
  }

  async exportPDF() {
    try {
      window.B3Spinner.show()
      const {
        data: { url },
      } = await this.api.downloadPdf(this.invoiceId)
      window.B3Spinner.hide()
      window.open(url)
    } catch (e) {
      console.error(e)
    }
  }

  renderTemplate(tpl, config, dom) {
    this.utils.renderTemplate({
      hbsTemplate: this.tpls[tpl],
      containerSelector: dom || this.currentPageDomContainer,
      templateConfig: {
        ...config,
      },
      insertType: 'beforeend',
    })
  }

  async renderExtraFieldsHtml() {
    const myB3ExtraField = await new window.B3ExtraField().init({
      moduleType: 'invoice',
    })

    const visibleKeyMap = myB3ExtraField.visibleToEnduserExtraFieldConfigList.map(extraField => extraField.fieldName)

    const { invoiceDetail } = this.state

    window.B3ExtraField.renderExtraFieldsHtml({
      extraFields: invoiceDetail.extraFields?.filter(extraField => visibleKeyMap.includes(extraField.fieldName)) || [],
      templateOption: {
        containerSelector: '.invoice-detail-generalInfo tbody',
        fieldNameTag: 'td',
        fieldValueTag: 'td',
        wrapperTag: 'tr',
      },
    })
  }
}
