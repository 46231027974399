var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- header-operation-bar -->\n<div class=\"invoice-detail-header-bar noprint\">\n  <div class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceDetail.header.button.print",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":14},"end":{"line":3,"column":61}}}))
    + "\">\n    <a class=\"print_pdf\" data-click=\"printPDF\">\n      <i class=\"fa fa-print\" data-click=\"printPDF\"></i>\n      <span data-click=\"printPDF\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.header.print",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":34},"end":{"line":6,"column":71}}}))
    + "</span>\n    </a>\n  </div>\n\n  <div class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceDetail.header.button.downloadPDF",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":10,"column":14},"end":{"line":10,"column":67}}}))
    + "\">\n    <a class=\"export_pdf\" data-click=\"exportPDF\">\n      <i class=\"fa fa-file-powerpoint-o export_pdf\" data-click=\"exportPDF\"></i>\n      <span data-click=\"exportPDF\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.header.downloadPDF",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":13,"column":35},"end":{"line":13,"column":78}}}))
    + "</span>\n    </a>\n  </div>\n\n</div>\n\n<!-- header general information -->\n<div class=\"invoice-detail-header "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceDetail.header",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":20,"column":34},"end":{"line":20,"column":68}}}))
    + "\">\n  <h2 class=\"invoice-title\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceDetail.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":21,"column":28},"end":{"line":21,"column":58}}}))
    + " &#35;"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"invoiceNumber") : depth0), depth0))
    + " <span\n      class=\"invoice-status\" data-status=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"status") : depth0), depth0))
    + "</span>\n  </h2>\n  <div class=\"invoice-status-detail "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceDetail.status.detail",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":24,"column":36},"end":{"line":24,"column":77}}}))
    + "\">\n    <div class=\"status-detail__title-wrap "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"statusTitleClass") : depth0), depth0))
    + "\">\n      <i class=\"fa "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"statusTitleIcon") : depth0), depth0))
    + "\"></i>\n      <span class=\"status-detail__title\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"statusTitle") : depth0), depth0))
    + "</span>\n    </div>\n    <span class=\"status-detail__text\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"statusTitleText") : depth0), depth0))
    + "</span>\n  </div>\n</div>\n";
},"useData":true});