import currencyFormat from '../../common/utils/currencyFormat'
import getSpecifiedCurrency from '../../common/utils/getSpecifiedCurrency'
import { isPlainObject } from 'lodash'

/**
 * @param {object} data
 * example of data structure
 * data: {
 *  code: "USD",
 *  value: "178.0000",
 *  currency_exchange_rate: 1, // default: 1 => no need calc exchange rate
 *  decimal_places: 4, // default: 4 => Do not deal with the number of decimal places, use the original data
 * }
 *
 * @return '$178.00'
 */
export default data => {
  if (!data || !isPlainObject(data)) return ''
  const { code = '', value, currency_exchange_rate = 1 } = data

  const currency = {
    ...getSpecifiedCurrency(code),
    currency_exchange_rate,
  }
  return currencyFormat(+value, true, false, currency, true)
}
