const isLoadingStencilUtils = () => {
  // hide because of pdp page error
  // const type = window.jsContext.page_type
  // if (type === 'category' || type === 'search') {
  //   return {}
  // }
  const stencilUtils = require('@bigcommerce/stencil-utils')
  return stencilUtils
}

export default isLoadingStencilUtils()
