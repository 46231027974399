var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<section class=\"account-content\">\r\n  <div class=\"alertBox alertBox--info\">\r\n    <div class=\"alertBox-column alertBox-icon\">\r\n      <icon glyph=\"ic-success\" class=\"icon\" aria-hidden=\"true\"><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\"\r\n          height=\"24\" viewBox=\"0 0 24 24\">\r\n          <path d=\"M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z\">\r\n          </path>\r\n        </svg></icon>\r\n    </div>\r\n    <p class=\"alertBox-column alertBox-message\">\r\n      <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"message","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":23}}}) : helper)))
    + "</span>\r\n    </p>\r\n  </div>\r\n</section>\r\n";
},"useData":true});