import _ from 'lodash'
import axios from 'axios'
import BasePage from '../../common/BasePage'
import themeConfig, { PAGES } from '../../themeConfig'
import * as langList from '../../common/lang'
import navigation from './navigation.html'
import navItem from './navItem.html'
import endMasquerade from './endMasquerade.html'
import mobileQuickOrderButton from './mobileQuickOrderButton.html'
import mobileMyQuoteButton from './mobileMyQuoteButton.html'
import pcQuickOrderButton from './pcQuickOrderButton.html'
import myQuoteButton from './myQuoteButton.html'
import containers from '../../containers'

_.templateSettings.interpolate = /{([\s\S]+?)}/g

export default class Login extends BasePage {
  constructor() {
    super()
    this.name = 'Login'
    this.state = {
      endMasqueradeContainerSelector: '.salerep-infobox',
      endMasqueradeSelector: '.salerep-infobox [end-masquerade]',
      isShowAddressBook: false,
      templatesWithoutNav: [
        'pages/category',
        'pages/blog',
        'pages/brand',
        'pages/brands',
        'pages/contact-us',
        'pages/product',
      ],
    }
    this.tpls = {
      navigation,
      navItem,
      endMasquerade,
      mobileQuickOrderButton,
      mobileMyQuoteButton,
      pcQuickOrderButton,
      myQuoteButton,
    }
  }

  get lang() {
    return langList[window.b3CurrentLangCode]
  }

  get navBarEls() {
    return document.querySelectorAll('.navBar.navBar--sub.navBar--account .navBar-section')
  }

  get isInPages() {
    const urlArray = [
      '/buy-again/',
      '/address-book/',
      '/quote/',
      '/quotes-list/',
      '/dashboard/',
      '/order-detail/',
      '/quick-order-pad/',
      '/shopping-list/',
      '/shopping-lists/',
      '/user-management/',
      '/invoices/',
      '/invoice-payment/',
      '/invoice-details/',
      '/invoice-payment-receipt/',
      '/account.php',
    ]
    const current = window.location.pathname
    return urlArray.includes(current)
  }

  get shouldGetUserInfo() {
    const {
      email: customEmail,
    } = this.context.customer
    const {
      B3Storage: {
        B3RoleId,
        B3CompanyStatus: customCompanyStatus,
        B3Email,
      },
      constants: {
        B3CompanyStatus: {
          APPROVED,
        },
        B3Role: {
          SALESREP,
        },
      },
    } = this.utils

    return !(
      (
        (B3RoleId.value
          && customCompanyStatus.value === APPROVED
        )
        || B3RoleId.value === SALESREP
      )
      && customEmail === B3Email.value
    )
  }

  get shouldGetCompanyInfo() {
    const {
      B3CompanyId,
      B3RoleId,
      B3CompanyStatus,
      B3Email,
    } = this.utils.B3Storage

    const {
      email,
    } = this.context.customer
    const {
      constants: {
        B3Role,
      },
    } = this.utils
    return !!(
      email !== B3Email.value
      || (
        (
          !B3CompanyStatus.value
          || !B3CompanyId.value
        )
        && B3RoleId.value !== B3Role.SALESREP
      )
    )
  }

  get isHideMasqueradedB3Navs() {
    const {
      constants: {
        B3Role: {
          SALESREP,
        },
      },
      B3Storage: {
        B3RoleId,
        B3CompanyId,
      },
    } = this.utils

    const isMasqueraded = (SALESREP === B3RoleId.value) && B3CompanyId.value

    const { pathname } = window.location

    const isQuoteDetail = this.utils.urlHelper.searchParams.has('quote-id')
    const isMasqueradedEdit = this.utils.urlHelper.searchParams.has('preview-id')

    return [
      '/create-quote/',
      '/quote-edit/',
    ].includes(pathname) || (
        pathname === '/quote-detail/' && (
          isMasqueraded
          && (
            !isQuoteDetail
            || isMasqueradedEdit
          )
        )
      )
  }

  get isInsearch() {
    return this.context.template === 'pages/search'
  }

  get isQuotePage() {
    return this.utils.urlHelper.path.has('quote')
  }

  get isInvoicesPage() {
    return this.utils.urlHelper.path.has('invoices')
  }

  get roleId() {
    return this.utils.B3Storage.B3RoleId.value
  }

  get canShowB2BNav() {
    let canShowB2BNav = !!this.roleId
    if (this.roleId === this.utils.constants.B3Role.SALESREP) {
      canShowB2BNav = this.isCompanyApproved
    }
    return canShowB2BNav
  }

  get haveIPPermission() {
    try {
      const {
        constants: {
          APP_NAME_MAP,
          B3Role,
        },
        B3Storage: {
          B3AppPermissions,
          B3RoleId,
          B3StorefrontConfig: B3StorefrontConfigs,
        },
      } = this.utils
      if (!B3AppPermissions?.value) return false

      const B3AppInvoicePermission = +JSON.parse(B3AppPermissions.value).find(app => app.appName === APP_NAME_MAP.IP)?.hasPermission === 1

      const B3StorefrontConfig = JSON.parse(B3StorefrontConfigs.value)
      const { value, enabledStatus } = B3StorefrontConfig.invoice
      const isShowInvoice = enabledStatus ? value : enabledStatus

      const isJunior = B3RoleId.value === B3Role.JUNIOR

      return B3AppInvoicePermission && isShowInvoice && !isJunior
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async init() {
    if (this.isB2CUser) return

    if (this.shouldGetUserInfo) {
      try {
        this.utils.B3Storage.clear()
        localStorage.removeItem('orderStartTime')
        localStorage.removeItem('orderEndTime')

        const bcToken = await this.api.getBCToken()
        const b2bToken = await this.setB3Token(bcToken)

        if (!b2bToken) {
          this.getuserEmail()
          return
        }
        await this.setUserRole()
      } catch {
        const errorMessage = this.locales.tips.globalError
        this.utils.Alert.error(errorMessage)
      }
    }

    const {
      B3Storage: {
        B3RoleId,
        B3isSetSalesRep,
        B3UserId,
      },
      constants: {
        B3Role,
      },

    } = this.utils
    const roleId = B3RoleId.value
    const userId = B3UserId.value
    // if begain in create quote
    this.initSelerRep()
    if (this.shouldGetCompanyInfo) {
      const companyData = await this.getCompanyInfo(userId)

      if (companyData.companyStatus === '0' || companyData.companyStatus === '2') {
        const $orderPage = document.querySelector('.account')
        if ($orderPage && $orderPage.parentElement.className.includes('container')) {
          $orderPage.style.display = 'block'
        }
        return
      }
    }

    if (roleId === B3Role.JUNIOR) {
      const hideAddBtnStyle = document.createElement('style')

      hideAddBtnStyle.innerHTML = `#form-action-addToCart,#add_to_cart,#add_to_cart_csv,[advqty-card-addtocart],[href*='/cart.php?action=add'],[href='/user-management/'],[href='/cart.php']
      {display:none!important}`

      if (document.querySelector('head')) document.querySelector('head').append(hideAddBtnStyle)
    }

    if (roleId === B3Role.SALESREP && !B3isSetSalesRep.value) {
      // end company msq
      await this.setSalesRep(userId, this.isQuotePage)
    }

    if (this.shouldShowAddressBook) {
      await this.getIsShowAddressBook()
    }

    this.renderNavs()
    this.clearQuoteCart(this.context.cartId)
    this.clearIPCart()
    this.addCreditTip()
    this.hideThemePayments()
  }

  getuserEmail() {
    const { customer: { email } } = window.jsContext
    const {
      B3Email,
    } = this.utils.B3Storage
    B3Email.setValue(email)
  }

  initSelerRep() {
    const {
      B3RoleId,
    } = this.utils.B3Storage
    const beginInCreateQuote = sessionStorage.getItem('beginInCreateQuote')
    const quotePagesArry = [
      '/create-quote/',
      '/quote-detail/',
      '/quote-edit/',
    ]
    const notInQuotePage = !quotePagesArry.includes(window.location.pathname)
    const shouldEndCompany = B3RoleId.value === '3' && beginInCreateQuote && notInQuotePage
    if (!shouldEndCompany) return
    this.handleEndMasqueradeCompany()
  }

  renderNavs() {
    const { pathname } = window.location
    const {
      B3Storage: {
        B3StorefrontConfig: B3StorefrontConfigs,
      },
    } = this.utils
    const B3StorefrontConfig = JSON.parse(B3StorefrontConfigs.value)

    if (!this.isHideMasqueradedB3Navs) {
      this.renderEndMasquerade()

      if (B3StorefrontConfig.quickOrderPad) {
        this.renderQuickOrderPadBtn()
      }
    }

    if ([
      '/',
      '/trade-professional-application/',
      '/rss-syndication/',
      '/shipping-returns/',
    ].includes(pathname) && !this.isMobile) return

    if (this.state.templatesWithoutNav.includes(this.context.template)) return

    const accountPageType = themeConfig.accountPages[this.context.template] ?? ''
    const templateLang = _.cloneDeep(this.lang)
    const {
      customer,
    } = this.context
    const {
      messages,
      wishlists,
    } = this.lang.account.nav

    templateLang.account.nav = {
      ...this.lang.account.nav,
      messages: _.template(messages)({
        num_new_messages: customer.num_new_messages ?? 0,
      }),
      wishlists: _.template(wishlists)({
        num_wishlists: customer.num_wishlists ?? 0,
      }),
    }

    if (this.navBarEls) this.navBarEls.forEach(navBarEl => navBarEl.parentNode.remove())

    if (!this.isInsearch || this.isMobile) {
      this.utils.renderTemplate({
        hbsTemplate: this.tpls.navigation,
        templateConfig: {
          ...this.context,
          accountPage: {
            [accountPageType]: true,
          },
          B3StorefrontConfig,
          isShowAccountSettings: B3StorefrontConfig.accountSettings && this.isB2BUser,
          accountOrder: this.context.page_type === 'account_order',
          lang: templateLang,
          isInvoicesPage: this.isInvoicesPage,
          isShowIP: this.canShowB2BNav && this.haveIPPermission,
          isInPages: this.isInPages,
        },
        containerSelector: '.page-heading',
        insertType: 'afterend',
      })
    }
    if (!this.isInsearch || this.isMobile) this.renderB3Navs()
    const $nav = document.querySelector('.navBar--account')
    if ($nav) {
      $nav.style.display = 'block'
    }

    // BB2BV30-1549: Hide account setting in header nav
    this.hideAccountSettings(B3StorefrontConfig.accountSettings)
  }

  hideAccountSettings(isShow) {
    if (isShow) return
    document.querySelector('a[href="/account.php?action=account_details"]')?.parentNode?.remove()
  }

  renderB3Navs({
    containerSelector = '',
    navItemClassName = '',
    navActionClassName = '',
    insertType = '',
  } = {}) {
    const {
      isShowAddressBook,
    } = this.state
    const {
      B3Storage: {
        B3RoleId,
        B3StorefrontConfig,
      },
      constants: {
        B3Role: {
          JUNIOR,
          SALESREP,
        },
      },
    } = this.utils
    const roleId = B3RoleId.value

    const {
      'nav.button.dashboard': Dashboard,
      'nav.button.quotes': Quotes,
      'nav.button.userManagement': UserManagement,
      'nav.button.addressBook': Addresses,
    } = this.text

    const isShowIP = this.canShowB2BNav && this.haveIPPermission
    let B3Navigations = Object.values(this.doms).filter(dom => {
      if (!(this.isMobile && isShowIP) && dom.url === '/invoices/') dom.isNav = false
      return dom.isNav
    })

    const getNavigations = (names, isEqual = true) => B3Navigations.filter(({ name }) => (isEqual ? names.includes(name) : !names.includes(name)))

    if (!this.isCompanyApproved || this.isHideMasqueradedB3Navs) {
      const filters = roleId === SALESREP ? [Dashboard, Quotes] : [Dashboard]
      B3Navigations = getNavigations(filters)
    }

    if (roleId !== SALESREP) B3Navigations = getNavigations([Dashboard], false)
    if (!isShowAddressBook) B3Navigations = getNavigations([Addresses], false)
    if (roleId === JUNIOR) B3Navigations = getNavigations([UserManagement], false)
    if (roleId === SALESREP && !this.isCompanyApproved) B3Navigations = getNavigations([Quotes], false)

    const defaultNavItemClassName = this.isMobile ? 'navPage-subMenu-item b3-navs' : 'navBar-item'
    const defaultNavActionClassName = this.isMobile ? 'navPages-action' : 'navBar-action'
    const defaultInsertType = this.isMobile ? 'afterbegin' : 'beforeend'

    document.querySelectorAll('.b3-navs').forEach(b3Nav => {
      b3Nav.remove()
    })

    B3Navigations.filter(nav => JSON.parse(B3StorefrontConfig.value)[PAGES[nav.pageName]]).forEach(({
      name,
      url,
      buttonContainer,
      mobileContainer,
    }) => {
      const defaultContainerSelector = this.isMobile ? mobileContainer : buttonContainer

      this.utils.renderTemplate({
        hbsTemplate: this.tpls.navItem,
        templateConfig: {
          url,
          name,
          navItemClassName: navItemClassName || defaultNavItemClassName,
          navActionClassName: navActionClassName || defaultNavActionClassName,
          isActive: window.location.pathname.includes(url) ? 'is-active' : '',
        },
        containerSelector: containerSelector || defaultContainerSelector,
        insertType: insertType || defaultInsertType,
      })

      if (this.isMobile) {
        this.renderB2BNavsWhenMobile({
          url,
          name,
          buttonContainer,
        })
      }
    })

    this.removeBCMenus()
  }

  removeBCMenus() {
    const {
      isShowAddressBook,
    } = this.state

    const wishlistsEls = document.querySelectorAll('[href^="/wishlist.php"]')
    const addressesEls = document.querySelectorAll('[href^="/account.php?action=address_book"]')
    const actionWishlists = document.querySelectorAll('[action^="/wishlist.php"]')
    wishlistsEls.forEach(wishlistsEl => {
      if (wishlistsEl.parentNode.children.length > 1) wishlistsEl.remove()
      else wishlistsEl.parentNode.remove()
    })
    if (isShowAddressBook) {
      addressesEls.forEach(addressesEl => {
        addressesEl.parentNode.remove()
      })
    }
    actionWishlists.forEach(actionWishlist => {
      actionWishlist.remove()
    })
  }

  renderB2BNavsWhenMobile({
    url,
    name,
    buttonContainer,
  }) {
    this.utils.renderTemplate({
      hbsTemplate: this.tpls.navItem,
      templateConfig: {
        url,
        name,
        navItemClassName: 'navBar-item',
        navActionClassName: 'navBar-action',
        isActive: window.location.pathname.includes(url) ? 'is-active' : '',
      },
      containerSelector: buttonContainer,
      insertType: 'beforeend',
    })
  }

  renderEndMasquerade() {
    const {
      B3Storage: {
        B3RoleId,
        B3CompanyName,
        B3CompanyId,
      },
      constants: {
        B3Role,
      },
    } = this.utils

    const {
      endMasqueradeContainerSelector,
      endMasqueradeSelector,
    } = this.state

    if (!(B3RoleId.value === B3Role.SALESREP && B3CompanyId.value)) return

    const prevEndMasqueradeContainer = document.querySelector(endMasqueradeContainerSelector)
    if (prevEndMasqueradeContainer) prevEndMasqueradeContainer.remove()

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.endMasquerade,
      templateConfig: {
        companyName: B3CompanyName.value,
      },
      containerSelector: containers['dashboard.endMasquerade.container'],
      insertType: 'afterend',
    })

    const $endMasqueradeContainer = document.querySelector(endMasqueradeContainerSelector)

    $endMasqueradeContainer.addEventListener('click', function handleClickEndMasqueradeContainer(e) {
      if (!e.target.classList.contains('button--primary')) this.classList.toggle('saler-extends')
    })

    document.querySelector(endMasqueradeSelector).addEventListener('click', () => {
      this.handleEndMasqueradeCompany()
    })
  }

  renderQuickOrderPadBtn() {
    if (!this.isCompanyApproved) return
    const {
      B3RoleId,
    } = this.utils.B3Storage

    const {
      quickOrderPad: {
        buttonContainer,
        mobileContainer,
        url,
        name,
      },
    } = this.doms

    const btnConfig = {
      // style,
      isVisible: B3RoleId.value !== '2',
      url,
      ButtonText: name,
    }

    this.removeQuickOrderPadBtn()

    this.utils.renderTemplate({
      hbsTemplate: this.isMobile ? this.tpls.mobileQuickOrderButton : this.tpls.pcQuickOrderButton,
      templateConfig: btnConfig,
      containerSelector: this.isMobile ? mobileContainer : buttonContainer,
    })
  }

  renderMyQuoteButton() {
    const {
      myQuote: {
        buttonContainer,
        mobileContainer,
        name,
      },
    } = this.doms

    const btnConfig = {
      ButtonText: name,
    }

    this.removeMyQuoteButton()

    this.utils.renderTemplate({
      hbsTemplate: this.isMobile ? this.tpls.mobileMyQuoteButton : this.tpls.myQuoteButton,
      templateConfig: btnConfig,
      containerSelector: this.isMobile ? mobileContainer : buttonContainer,
    })
  }

  removeQuickOrderPadBtn() {
    const mobileButton = document.querySelector('#m-quickorderpad-entry')
    const pcButton = document.querySelector('#pc-quickorderpad-entry')

    if (mobileButton) {
      mobileButton.remove()
    }

    if (pcButton) {
      pcButton.remove()
    }
  }

  removeMyQuoteButton() {
    const mobileButton = document.querySelector('#m-myquote-entry')
    const pcButton = document.querySelector('#my-quote-entry')

    if (mobileButton) {
      mobileButton.remove()
    }

    if (pcButton) {
      pcButton.remove()
    }
  }

  async clearQuoteCart(cartId) {
    const quoteCheckoutId = localStorage.getItem('quoteCheckoutId')
    const isIncludeCheckout = window.location.href.includes('checkout')
    if (quoteCheckoutId && !isIncludeCheckout) {
      try {
        window.B3Spinner.show()
        localStorage.removeItem('quoteCheckoutId')
        await axios.delete(`/api/storefront/carts/${cartId}`)
        this.api.triggerCartNumber()
        window.B3Spinner.hide()
      } catch (error) {
        localStorage.removeItem('quoteCheckoutId')
        window.B3Spinner.hide()
      }
    }
  }

  async getIsShowAddressBook() {
    const {
      B3AddressBook,
    } = this.utils.B3Storage

    if (B3AddressBook.isAllow.value) {
      this.setState({
        isShowAddressBook: true,
      })
      return
    }

    try {
      await this.getAddressBookPermission()
      if (B3AddressBook.isAllow.value) {
        this.setState({
          isShowAddressBook: true,
        })
      }
    } catch {
      const errorMessage = this.locales.tips.globalError
      this.utils.Alert.error(errorMessage)
    }
  }

  watch = {
    endMasqueradeCompany: () => {
      // remove the global endMasqueradeContainer
      const {
        endMasqueradeContainerSelector,
      } = this.state
      if (document.querySelector(endMasqueradeContainerSelector)) {
        document.querySelector(endMasqueradeContainerSelector).remove()
      }

      this.removeQuickOrderPadBtn()
      this.renderNavs()
    },
    beginMasqueradeCompany: async () => {
      const browserInformations = new this.utils.browserCheck()
      const { browser } = browserInformations.init()
      if (browser !== 'firefox') await this.getIsShowAddressBook()
      this.renderNavs()
    },
  }

  clearIPCart() {
    try {
      const isCheckoutPage = this.utils.urlHelper.path.has('checkout')
      const hasIPCartInfo = localStorage.IPCheckout || localStorage.IPCartId
      if (isCheckoutPage || !hasIPCartInfo) return

      if (localStorage.IPCheckout === '1' && localStorage.IPCartId) {
        this.deleteCarts(localStorage.IPCartId)
      }
      localStorage.removeItem('IPCheckout')
      localStorage.removeItem('IPCartId')
    } catch (error) {
      console.error(error)
    }
  }
}
