import b3request from './base'

export { default as addProducts, addProductsToCart } from './addProducts'
export { default as clearCart } from './clearCart'
export { default as getBCToken } from './getBCToken'
export { default as getCart } from './getCart'
export { default as getPrice } from './getPrice'
export { default as triggerCartNumber } from './triggerCartNumber'

export const getToken = data => b3request.post('/api/v2/login', data)
export const getUserRole = userId => b3request.get(`/api/v2/users/${userId}?isBcId=1`)
export const getAdvQtyState = () => b3request.get('/api/v2/store-configs/switch-status?key=interval_quantity')
export const getCompany = customerId => b3request.get(`/api/v2/customers/${customerId}/companies`)
export const getProductsBySku = params => b3request.get('/api/v2/catalogs/quickProduct', { params })
export const getAdvQtyBySkus = params => b3request.get('/api/v2/qty/quantity-limit-list', { params })
export const getAdvQtyBySkusNew = data => b3request.post('/api/v2/qty/quantity-limit-list', data)
export const getCartAdvQtyCheckState = data => b3request.get(`/api/v2/qty/cartLimit/${data}`)
export const getShoppingLists = params => b3request.get('/api/v2/shoppinglists/lists', { params })
export const getVariantsByProductId = params => b3request.get('/api/v2/catalogs/variants', { params })
export const getShoppingListsInfo = params => b3request.get('/api/v2/shoppinglists', { params })
export const addProductToShoppingList = data => b3request.post('/api/v2/shoppinglists/items', data)
export const createShopingList = data => b3request.post('/api/v2/shoppinglists', data)
export const duplicateShopingList = params => b3request.post('/api/v2/shoppinglists/duplicate', params)
export const deleteShopingList = shoppingListId => b3request.delete(`/api/v2/shoppinglists/${shoppingListId}`)
export const updateShoppingList = data => b3request.put('/api/v2/shoppinglists', data)
export const checkAvailableProducts = params => b3request.get('/api/v2/catalog/products/availablity', { params })
export const deleteShopingListItme = (shoppingListId, itemId) => b3request.delete(`/api/v2/shoppinglists/${shoppingListId}/items/${itemId}`)
export const updateShoppingListItme = data => b3request.put('/api/v2/shoppinglists/items', data)
export const getCompanyList = (salesRepId, params) => b3request.get(`/api/v2/sales-reps/${salesRepId}/companies`, { params })
export const beginMasqueradeCompany = (companyId, customerId) => b3request.put(`/api/v2/sales-reps/${customerId}/companies/${companyId}/begin-masq`)
export const endMasqueradeCompany = (customerId, companyId) => b3request.put(`/api/v2/sales-reps/${customerId}/companies/${companyId}/end-masq`)
export const getOrderList = params => b3request.get('/api/v2/orders', { params })
export const getOrderListImage = params => b3request.get('/api/v2/orders/images', { params })
export const getOrderProducts = orderId => b3request.get(`/api/v2/orders/${orderId}/products`)
export const getOrderDetail = orderId => b3request.get(`/api/v2/orders/${orderId}/details`)
export const getCompanyBasicInfo = companyId => b3request.get(`/api/v2/companies/${companyId}/basic-info`)
export const initPage = customerId => b3request.get(`/api/v2/customers/${customerId}/companies`)
export const creatCompany = (bypassStoreHash, data) => b3request.post(`/api/v2/companies/${bypassStoreHash}`, data)
export const updateCompanyInfo = (companyId, data) => b3request.put(`/api/v2/companies/${companyId}/basic-info`, data)
export const initCompanyRegisterPage = customerId => b3request.get(`/api/v2/customers/${customerId}/companies`)
export const createCompany = data => b3request.post('/api/v2/frontend/companies', data)
export const getCompanyUser = (companyId, params) => b3request.get(`/api/v2/companies/${companyId}/users`, { params })
export const inspectCustomerEmail = (email, params) => b3request.get(`/api/v2/companies/validations/backend/user-emails/${email}`, { params })
export const saveNewUser = data => b3request.post('/api/v2/users', data)
export const validateUserEmail = data => b3request.post('/api/v2/companies/user-email-info', data)
export const updateUserInfo = (userId, data) => b3request.put(`/api/v2/users/${userId}`, data)
export const deleteUser = userId => b3request.delete(`/api/v2/users/${userId}`)
export const getSelerep = customerId => b3request.get(`/api/v2/sales-reps/${customerId}/companies/masquerading`)
export const getAddressBookBySearch = (companyId, data) => b3request.post(`/api/v2/companies/${companyId}/addresses/searches`, data)
export const getBcAddressBook = params => b3request.get('/api/v2/customers/addresses', { params })
export const getCountries = storeHash => b3request.get(`/api/v2/companies/addresses/countries?storeHash=${storeHash}`)
export const createAddressBook = (companyId, data) => b3request.post(`/api/v2/companies/${companyId}/addresses`, data)
export const getAddressById = (companyId, addressId) => b3request.get(`/api/v2/companies/${companyId}/addresses/${addressId}`)
export const updateAddressBook = (companyId, addressId, data) => b3request.put(`/api/v2/companies/${companyId}/addresses/${addressId}`, data)
export const deleteAddressBook = (companyId, addressId, data) => b3request.put(`/api/v2/companies/${companyId}/addresses/${addressId}/activation`, data)
export const getDefaultAddressesByCompanyId = companyId => b3request.get(`/api/v2/companies/${companyId}/default-addresses`)
export const getAddressPermission = () => b3request.get('/api/v2/companies/addresses/permission')
export const getQuoteList = params => b3request.get('/api/v2/quotes', { params })
export const deleteQuoteList = quoteId => b3request.delete(`/api/v2/quotes/${quoteId}`)
export const quoteCheckout = quoteId => b3request.post(`/api/v2/quotes/${quoteId}/checkout`)
export const deleteCheckoutInfo = quoteId => b3request.delete(`/api/v2/quotes/${quoteId}/checkout`)
export const getDefaultAddresses = companyId => b3request.get(`/api/v2/quotes/info/store-company/${companyId}`)
export const createQuote = data => b3request.post('/api/v2/quotes', data)
export const getQuote = quoteId => b3request.get(`/api/v2/quotes/${quoteId}`)
export const sendEmail = data => b3request.post('/api/v2/emails/quotes', data)
export const getProductsBySkuQuick = params => b3request.get('/api/v2/catalogs/quick-order-pad', { params })
export const getProductsBySkuQuickByPost = data => b3request.post('/api/v2/catalogs/quick-order-pad', data)
export const getQuoteAdress = (companyId, params) => b3request.get(`/api/v2/companies/${companyId}/addresses/searches`, { params })
export const updateQuote = (quoteId, data) => b3request.put(`/api/v2/quotes/${quoteId}`, data)
export const exportPDF = (quoteId, currency) => b3request.post(`/api/v2/quotes/${quoteId}/pdf-export`, { currency })
export const getShoppingListItemsExtension = params => b3request.get('/api/v2/shoppinglists/items-extension', { params })
export const getOrderedList = params => b3request.get('/api/v2/ordered-products', { params })
export const uploadQuoteCSV = data => b3request.post('/api/v2/quotes/products/upload', data)
export const getOrderStatus = () => b3request.get('/api/v2/stores/order-statuses')
export const getExtraFields = params => b3request.get('/api/v2/companies/extra_fields/storefront', { params })
export const checkExistCompany = params => b3request.get('/api/v2/companies/existence', { params })
export const getCurrencies = params => b3request.get('/api/v2/stores/currencies', { params })
export const getInventory = data => b3request.post('/api/v2/products/inventory', data)
export const createRfq = data => b3request.post('/api/v2/rfq', data)
export const detectUserEmailExist = params => b3request.get('api/v2/users/validations/existence', { params })
export const getQuoteConfig = params => b3request.get('/api/v2/rfq/configs', { params })
export const getRfqStoreInfo = params => b3request.get('/api/v2/rfq/store-info', { params })
export const getStoreBasicInfo = params => b3request.get('/api/v2/rfq/store-basic-info', { params })
export const exportRfqPDF = (quoteId, data) => b3request.post(`api/v2/rfq/${quoteId}/pdf-export`, data)
export const exportNewRfqPDF = (quoteId, data) => b3request.post(`api/v2/rfq/${quoteId}/frontend/pdf`, data)
export const getRfqDetail = (quoteId, params) => b3request.get(`/api/v2/rfq/${quoteId}`, { params })
export const sendRfqMessage = (quoteId, data) => b3request.put(`/api/v2/rfq/${quoteId}`, data)
export const CheckoutRfqQuote = (quoteId, data) => b3request.post(`/api/v2/rfq/${quoteId}/checkout`, data)
export const getQuotes = params => b3request.get('/api/v2/rfq', { params })

// IP
export const getInvoices = params => b3request.get('/api/v2/ip/storefront/invoices', { params })
export const getInvoiceInfo = () => b3request.get('/api/v2/ip/storefront/invoices/invoice-stats?status=0')
export const getExportCSVFile = params => b3request.get('/api/v2/ip/storefront/invoices/export', { params })
// IP Invoice payment
export const getReceiptLines = params => b3request.get('/api/v2/ip/storefront/receipt-lines', { params })
export const getInvoicePayments = () => b3request.get('/api/v2/ip/storefront/payments/modules')
export const getStorefrontInformation = () => b3request.get('/api/v2/ip/storefront/information')
export const makeAPaymentBc = data => b3request.post('/api/v2/ip/storefront/payments/bigcommerce/carts', data)
export const makeAPaymentGetaway = data => b3request.post('/api/v2/ip/storefront/payments/test/pay', data)
export const getCurrentCart = () => b3request.get('/api/storefront/carts?include=lineItems.digitalItems.options')
export const downloadPdf = invoiceId => b3request.get(`/api/v2/ip/storefront/invoices/${invoiceId}/download-pdf`)
export const getInvoiceDetail = id => b3request.get(`/api/v2/ip/storefront/invoices/${id}`)

export const getInvoiceReceipt = id => b3request.get(`/api/v2/ip/storefront/receipts/${id}`)
export const getLinesInReceipt = (id, params) => b3request.get(`/api/v2/ip/storefront/receipts/${id}/lines`, { params })

// get Storefront config
export const getStoreFrontConfig = params => b3request.get('/api/v2/store-configs/storefront-configs', { params })

// upload Attachment files
export const uploadAttachmentFiles = params => b3request.post('/api/v2/media/upload', params)

export const getProductsBulkLoad = data => b3request.post('/api/v2/products/bulk-load', data)

// interface forwarding
export const getProductsPrice = data => b3request.post('/api/v2/bc/pricing/products', data)

// /api/v2/stores/limitations
export const getlimitations = params => b3request.post('/api/v2/stores/limitations', params)
// get storefront extra fields
export const getStorefrontExtraFields = (params, module) => b3request.get(`/api/v2/extra-fields/${module}/storefront`, { params })
// validate extra fields
export const validateStorefrontExtraFields = (params, module) => b3request.post(`/api/v2/extra-fields/${module}/validate`, params)

// storefront getChannels
export const getChannels = params => b3request.get('/api/v2/channels/msf-configs/storefront', { params })

// storefront getJuniorPlaceOrders
export const getAllowJuniorPlaceOrders = () => b3request.get('/api/v2/store-configs/switch-status?key=junior_place_order')

// storefront getTaxZoneRates
export const getTaxZoneRates = params => b3request.get('/api/v2/stores/tax/zones', { params })

export const getLanguageCode = params => b3request.get('/api/v2/stores/storefront/language', { params })

export const getCompanyCredit = companyId => b3request.get(`/api/v2/companies/${companyId}/credit`)

// get store products settings
export const getStoresProductSetting = (storeHash, channelId) => b3request.get(`/api/v2/stores/product/settings?storeHash=${storeHash}&channelId=${channelId}`)
