import text from '../../text'

export default {
  companyNameVoid: text['validation.companyNameVoid'],
  emailIncorrect: text['validation.emailIncorrect'],
  phoneIncorrect: text['validation.phoneIncorrect'],
  uploadNotCsv: text['validation.uploadNotCsv'],
  emptyFiles: text['validation.emptyFiles'],
  emptyQty: text['validation.emptyQty'],
  fistNameIncorrect: text['validation.fistNameIncorrect'],
  lastNameIncorrect: text['validation.lastNameIncorrect'],
  emptyShoppingListName: text['validation.emptyShoppingListName'],
  emptyShoppingListSelect: text['validation.emptyShoppingListSelect'],
  emptyShoppingListSubmit: text['validation.emptyShoppingListSubmit'],
  shoppingListQtyValidError: text['validation.shoppingListQtyValidError'],
  emptyShoppingList: text['validation.emptyShoppingList'],
  emptySelectedShoppingList: text['validation.emptySelectedShoppingList'],
  incompleteOptions: text['validation.incompleteOptions'],
  unValidProduct: text['validation.unValidProduct'],
  'addressForm[firstName]': text['validation.addressForm[firstName]'],
  'addressForm[lastName]': text['validation.addressForm[lastName]'],
  'addressForm[addressLine1]': text['validation.addressForm[addressLine1]'],
  'addressForm[city]': text['validation.addressForm[city]'],
  'addressForm[country]': text['validation.addressForm[country]'],
  'addressForm[zipCode]': text['validation.addressForm[zipCode]'],
  'addressForm[state]': text['validation.addressForm[state]'],
  'addressForm[phoneNumber]': text['validation.addressForm[phoneNumber]'],
  cannotAddToShoppingList: text['validation.cannotAddToShoppingList'],
  cannotAddToQuote: text['validation.cannotAddToQuote'],
  emailExist: text['validation.emailExist'],
}
