var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.tbody.tr",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":11},"end":{"line":2,"column":43}}}))
    + "\" id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"companyId") : depth0), depth0))
    + "\" data-company-id=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"companyId") : depth0), depth0))
    + "\">\n  <td class=\"company_name "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":52}}}))
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"companyName") : depth0), depth0))
    + "</td>\n  <td class=\"t-align-l "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":49}}}))
    + "\">\n    "
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"companyEmail") : depth0), depth0))
    + "\n  </td>\n  <td class=\"t-align-c col-sale-actions company_admin_impersonation "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":7,"column":68},"end":{"line":7,"column":94}}}))
    + "\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isSelected") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":12,"column":11}}})) != null ? stack1 : "")
    + "  </td>\n</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "    <span class=\"selected "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td.button.selected",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":68}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.company.selected",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":9,"column":70},"end":{"line":9,"column":109}}}))
    + "</span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <span class=\"button button--primary button--small view-action "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.td.button.begin",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":11,"column":66},"end":{"line":11,"column":105}}}))
    + "\" action-begin-masquerade data-company-id=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"companyId") : depth0), depth0))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.masquerade.begin.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":11,"column":165},"end":{"line":11,"column":211}}}))
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"companies") : depth0), depth0),{"name":"companies","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":15,"column":14}}})) != null ? stack1 : "");
},"useData":true});