var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"rfq-form-wrapper "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqFormWrapper",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":57}}}))
    + "\">\n  <div class=\"mask\"></div>\n  <div class=\"form-container\">\n    <div class=\"loadingOverlay\"></div>\n    <div class=\"title-container\">\n      <h3>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":10},"end":{"line":6,"column":35}}}))
    + "</h3>\n      <button type=\"button\" class=\"quote-modal-close\" data-click=\"hideForm\">x</button>\n    </div>\n    <div class=\"quote-edit-container\">\n      <div class=\"quote-edit-information\">\n        <div class=\"body-container\"></div>\n        <div class=\"message-container\"></div>\n        <div class=\"attach-file-container\"></div>\n      </div>\n      <div class=\"quote-edit-item\">\n        <div class=\"products-search-container\"></div>\n        <div class=\"products-container\"></div>\n        <div class=\"quote-submit-container\">\n          <div class=\"button button--primary submit\" data-click=\"handleSubmit\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"productList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),"==",0,{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":79},"end":{"line":19,"column":127}}})) != null ? stack1 : "")
    + ">\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.button.submit",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":20,"column":45}}}))
    + "\n          </div>\n        </div>\n      </div>\n    </div>\n    <div class=\"edit-mask fadeOut\"></div>\n    <div class=\"address-book-container hide\">\n\n    </div>\n    <div class=\"edit-options-container hide\">\n\n    </div>\n\n  </div>\n  <div class=\"quote-success-container\">\n\n  </div>\n\n</div>\n";
},"useData":true});