class IndexedDB {
  constructor(options = {}) {
    this.indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.OIndexedDB || window.msIndexedDB

    this.IDBTransaction = window.IDBTransaction || window.webkitIDBTransaction || window.OIDBTransaction || window.msIDBTransaction

    this.state = {
      version: 1.0,
      database: '',
      store: '',
      keyPath: '',
      ...options,
    }
  }

  get readWriteMode() {
    return typeof this.IDBTransaction.READ_WRITE === 'undefined' ? 'readwrite' : this.IDBTransaction.READ_WRITE
  }

  init() {
    const {
      version,
      database,
      store,
      keyPath,
    } = this.state

    return new Promise((resolve, reject) => {
      const request = this.indexedDB.open(database, version)

      this.request = request

      request.onsuccess = () => {
        this.db = request.result
        resolve()
      }

      request.onerror = () => {
        console.error('Error accessing IndexedDB database')
        reject()
      }

      request.addEventListener('upgradeneeded', e => {
        this.db = e.target.result
        this.db.createObjectStore(store, { keyPath })
      })
    })
  }

  create(file) {
    const {
      store,
    } = this.state

    return new Promise((resolve, reject) => {
      const request = this.db.transaction([store], this.readWriteMode)
        .objectStore(store)
        .add(file)

      request.onsuccess = () => {
        resolve()
      }

      request.onerror = () => {
        reject()
      }
    })
  }

  read(cb) {
    const {
      store,
    } = this.state

    return new Promise((resolve, reject) => {
      const request = this.db.transaction(store).objectStore(store).openCursor()

      request.onsuccess = event => {
        const cursor = event.target.result
        if (!cursor || !cursor.value) {
          console.error('No Data')
          resolve()
        } else {
          cb(cursor)
          cursor.continue()
        }
      }

      request.onerror = () => {
        reject()
      }
    })
  }

  delete(key) {
    const {
      store,
    } = this.state

    return new Promise((resolve, reject) => {
      const request = this.db.transaction([store], this.readWriteMode)
        .objectStore(store)
        .delete(key)

      request.onsuccess = () => {
        resolve()
      }

      request.onerror = () => {
        reject()
      }
    })
  }

  deleteAll() {
    const {
      store,
    } = this.state

    return new Promise((resolve, reject) => {
      const request = this.db.transaction([store], this.readWriteMode)
        .objectStore(store)
        .clear()

      request.onsuccess = () => {
        resolve()
      }

      request.onerror = () => {
        reject()
      }
    })
  }

  deleteDatabase() {
    const {
      database,
    } = this.state

    this.db.close()

    this.indexedDB.deleteDatabase(database)
  }

  getStoreLength() {
    const {
      store,
    } = this.state

    return new Promise((resolve, reject) => {
      const request = this.db.transaction(store).objectStore(store).getAll()

      request.onsuccess = () => {
        resolve(request.result.length)
      }

      request.onerror = () => {
        reject()
      }
    })
  }
}
export default IndexedDB
