var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"address-list-search-container\">\n  <div class=\"search-input-container\">\n    <input class=\"form-input\" type=\"text\" id=\"search-address-input\" placeholder=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.address.search.placeholder",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":8,"column":81},"end":{"line":8,"column":127}}}))
    + "\" name=\"search-company-input\" value=\""
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"q") : depth0), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"q") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n  <button class=\"button button--search\" type=\"button\" id=\"search-company\" name=\"search-company\" data-click=\"handleSearchAddress\">\n    <i class=\"fa fa-search\" data-click=\"handleSearchAddress\"></i>\n  </button>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"fa fa-close close\" data-click=\"handleClearAddress\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"address-list-container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"addressList") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":2},"end":{"line":45,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"address-list\" data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\" for=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":26,"column":11}}})) != null ? stack1 : "")
    + "    <p data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "\n    </p>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"companyName") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":34,"column":11}}})) != null ? stack1 : "")
    + "    <p data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0), depth0))
    + "\n    </p>\n    <p data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0), depth0))
    + "\n    </p>\n    <p data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressInfo") : depth0), depth0))
    + "\n    </p>\n  </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\n    </p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p data-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"addressId") : depth0), depth0))
    + "\" data-click=\"handleAddressItemClick\">\n      "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"companyName") : depth0), depth0))
    + "\n    </p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "<div class=\"no-data\">\n  No address\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"address-form-title-container\">\n  <h4>"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.form.address.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":39}}}))
    + "</h4>\n  <button type=\"button\" class=\"quote-modal-close\" data-click=\"hideForm\">x</button>\n</div>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isCanSearchAddress") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"addressList") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":18,"column":0},"end":{"line":51,"column":7}}})) != null ? stack1 : "");
},"useData":true});