var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"form-field\" id=\"tpa-email-filed\" data-type=\"TpaEmailFiled\">\n  <label class=\"form-label\" for=\"company_user_email\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"tpa.form.company.email.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":53},"end":{"line":2,"column":92}}}))
    + "\n    <small>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":43}}}))
    + "</small>\n  </label>\n  <input class=\"form-input\" type=\"text\" id=\"company_user_email\" name=\"company_user_email\"\n    data-field-type=\"TpaEmailFiled\">\n  <span style=\"display: none;\"></span>\n</div>\n<div class=\"form-field\" id=\"tpa-phone-filed\" data-type=\"TpaPhoneFiled\">\n  <label class=\"form-label\" for=\"company_user_phone\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"tpa.form.company.phone.number.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":53},"end":{"line":10,"column":99}}}))
    + "\n  </label>\n  <input class=\"form-input\" type=\"text\" id=\"company_user_phone\" name=\"company_user_phone\"\n    data-field-type=\"TpaPhoneFiled\">\n  <span style=\"display: none;\"></span>\n</div>\n";
},"useData":true});