var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"account b2b-order-details "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"b2bWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":59}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"OrderDetail",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":85}}}))
    + "\">\n  <div class=\"account-body "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"order.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":27},"end":{"line":2,"column":56}}}))
    + "\">\n    <div class=\"account-content account-content--fixedSmall "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"order.content.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":97}}}))
    + "\"></div>\n\n    <aside class=\"account-sidebar "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"order.aside.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":69}}}))
    + "\"></aside>\n  </div>\n</div>\n";
},"useData":true});