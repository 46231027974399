import swal from 'sweetalert2'
import text from '../../text'
import { limitConfig, limitsLevel, limitTextConfig } from './config'

export default class limiteErrorText {
  init(flag, type) {
    if (flag) {
      const limitTipText = this.limitDialogText()
      this.limitErrorDialog(limitTipText)
    } else {
      this.singleLimitErrorDialog(type)
    }
  }

  getB3singleLimitations(type) {
    const text = limitTextConfig[type]
    const B3singleLimitations = window?.limite?.singleLimiteContext || []
    const limit = B3singleLimitations.length && B3singleLimitations.find(item => text === item?.limitationTypeName)
    return limit
  }

  judgeSingleLimit(type) {
    const limit = this.getB3singleLimitations(type)
    return limit && limit?.hasLimitation && !limit?.canCreate
  }

  singleLimitErrorDialog(type) {
    const isOpenlimitText = this.judgeSingleLimit(type)
    if (isOpenlimitText) {
      const limit = this.getB3singleLimitations(type)
      let limitText = ''
      if (limit.limitationTypeName === 'company_count_limitation') {
        limitText = `${text['limitation.error.left.company']}`
      } else {
        const list = limitConfig.find(v => v?.singleChannel === limit.limitationTypeName)
        limitText = `${text[`limitation.error.left.${list.label}`]}`
      }
      limitText && this.limitErrorDialog(limitText)
    }
  }

  limitDialogText() {
    const { level, channel } = window?.limite?.wsContext || {}
    if (level === limitsLevel.WARNING) return ''
    const limitConfigItem = limitConfig.find(item => item.channel === channel)
    const { label } = limitConfigItem
    const limitText = `${text[`limitation.${level === limitsLevel.WARNING ? 'warning' : 'error'}.left.${label}`]}`
    return limitText
  }

  limitErrorDialog(content) {
    const html = this.contactDialogHtml(content)
    swal({
      title: `${text['limitation.error.dialog.title']}`,
      html,
      customClass: 'B3LimitDialog',
      width: '35%',
    })
  }

  contactDialogHtml(content) {
    const html = `<div class="B3LimitDialogContext">
      ${content ? `<p>${content}</p>` : ''}
    </div>`
    return html
  }
}
