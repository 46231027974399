import { limitConfig } from './config'

import { getlimitations } from '../api'

import { B3Storage } from '../utils'

export { default as B3LimitSocket } from './B3LimitSocket'
export { default as B3LimiteErrorText } from './B3LimiteErrorText'

export const isEnabledLimitSocket = name => {
  const isLogin = !!window.jsContext.customer
  const isEnabled = limitConfig.some(item => item.name === name)
  if (isLogin && isEnabled) return true

  return false
}

export const singleLimitation = async config => {
  const isSingleLimite = B3Storage.B3B2BSingleLimite.value ? JSON.parse(B3Storage.B3B2BSingleLimite.value) : ''
  if (isSingleLimite) {
    window.limite = window.limite || {}
    window.limite.singleLimiteContext = JSON.parse(B3Storage.B3B2BSingleLimite.value)
    return
  }

  const isLogin = !!window.jsContext.customer
  const storeHash = window.jsContext.store_hash

  const requests = []
  if (config && config?.quotes) {
    requests.push(getlimitations({ storeHash, limitationType: '2' }))
  }
  requests.push(getlimitations({ storeHash, limitationType: '1' }))

  if (isLogin) requests.push(getlimitations({ storeHash, limitationType: '6' }))
  const data = await Promise.all(requests)

  let singleLimitations = []

  if (data.length) {
    data.forEach(item => {
      if (item?.limitationConfigs && item.limitationConfigs.length) singleLimitations = [...singleLimitations, ...item.limitationConfigs]
    })
  }

  window.limite = window.limite || {}
  window.limite.singleLimiteContext = singleLimitations
  B3Storage.B3B2BSingleLimite.setValue(JSON.stringify(singleLimitations))
}
