var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"navPages-item\" id=\"m-myquote-entry\">\n  <a class=\"navPages-action "
    + alias1(__default(require("../../hbs/helpers/classes.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.mobile.entryButton",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":64}}}))
    + "\" href=\"javascript:void(0);\" data-click=\"renderForm\">"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"ButtonText") : depth0), depth0))
    + "</a>\n</li>\n";
},"useData":true});