import { isEmpty } from 'lodash'
import IndexedDB from './IndexedDB'
import BasePage from '../../common/BasePage'
import themeConfig from '../../themeConfig'
import createCompany from './createCompany'
import nod from '../../lib/nod'

import checkboxTemplate from './forms/checkbox.html'
import dateTemplate from './forms/date.html'
import multilineTemplate from './forms/multiline.html'
import numberTemplate from './forms/number.html'
import passwordTemplate from './forms/password.html'
import radioTemplate from './forms/radio.html'
import selectTemplate from './forms/select.html'
import selectorTextTemplate from './forms/selectortext.html'
import textTemplate from './forms/text.html'
import formFiles from './formFiles.html'
import tpaButton from './tpaButton.html'
import tpaMobileButton from './tpaMobileButton.html'
import tpaForm from './tpaForm.html'
import message from './message.html'
import attachFile from '../rfq/attach-file.html'

const {
  tpa,
} = themeConfig.js

export default class Tpa extends BasePage {
  constructor() {
    super()
    this.name = 'Tpa'
    const tpaContainerSelector = this.doms.tpa.container.default
    const stagedAttachment = sessionStorage.getItem('B3TPAAttachmentList')

    this.state = {
      isShowTpaQuery: 'show_tpa',
      createFormSelector: '.form[data-create-account-form]',
      tpaSubmitBtnId: 'tpa_submit_btn',
      isTpaFormVisible: true,
      isTpaFormHide: true,
      companyFormSelector: 'form[data-create-company-form]',
      tpaContainerSelector,
      tpaPageContainer: document.querySelector(tpaContainerSelector),
      extraFields: [],
      companyExtraFields: [],
      companyStatus: null,
      customFields: [],
      attachment: stagedAttachment ? JSON.parse(stagedAttachment) : [],
      myB3ExtraField: new window.B3ExtraField(),
      myB3CompanyUserExtraField: new window.B3ExtraField(),
      myIndexedDB: new IndexedDB({
        database: 'tpa',
        store: 'attachFiles',
        keyPath: 'name',
      }),
    }

    this.createCompany = createCompany
    this.nod = nod
    this.tpa = tpa
    this.IndexedDB = IndexedDB

    this.tpls = {
      formFiles,
      tpaButton,
      tpaMobileButton,
      checkboxTemplate,
      dateTemplate,
      multilineTemplate,
      numberTemplate,
      passwordTemplate,
      radioTemplate,
      selectTemplate,
      selectorTextTemplate,
      textTemplate,
      tpaForm,
      message,
      attachFile,
    }
  }

  get formPartials() {
    return {
      checkbox: this.tpls.checkboxTemplate,
      date: this.tpls.dateTemplate,
      multiline: this.tpls.multilineTemplate,
      number: this.tpls.numberTemplate,
      password: this.tpls.passwordTemplate,
      radio: this.tpls.radioTemplate,
      select: this.tpls.selectTemplate,
      selectorText: this.tpls.selectorTextTemplate,
      text: this.tpls.textTemplate,
    }
  }

  get isInpage() {
    return window.location.href.indexOf(this.doms.tpa.url.default) > -1
  }

  get shouldUpdateCompanyInfo() {
    return !!(document.querySelector('form[data-create-company-form] input[type=submit]').getAttribute('data-submit-type') === 'resubmit')
  }

  async init() {
    try {
      const {
        isShowTpaQuery,
        myIndexedDB,
      } = this.state

      const isShowTpa = this.utils.urlHelper.searchParams.has(isShowTpaQuery)
      const isRegisterFail = this.checkRegisterFail(this.context)

      await myIndexedDB.init()

      this.initTpaBtn()
      if (!this.isLogin) await this.guestInit()
      else await this.userInit()

      await this.validateB3TPAAttachmentList()

      if (isShowTpa || this.isInpage || isRegisterFail) {
        // await this.setExtraFields()
        // this.renderExtraFields()
        // this.renderCustomFields()
        this.initMyB3UserExtraField()
        if (document.querySelector('.page-heading')) {
          document.querySelector('.page-heading').innerHTML = this.text['tpa.button']
        }
        this.renderAttachFiles()
        this.initMyB3ExtraField()
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  async guestInit() {
    const {
      isShowTpaQuery,
    } = this.state

    const isShowTpa = this.utils.urlHelper.searchParams.has(isShowTpaQuery)
    const isRegisterFail = this.checkRegisterFail(this.context)

    this.utils.B3Storage.clear()

    if (!isShowTpa && !isRegisterFail) {
      window.B3Spinner.hide()
      return
    }
    this.initCreatePageUI()
    this.bindGuestEvent()
    await this.initGuestValidation()

    if (isRegisterFail) {
      this.handleRegisterFailed()
    }

    window.B3Spinner.hide()
    document.querySelector('#tpa_submit_btn').value = this.text['tpa.form.submit.button']
  }

  async userInit() {
    const container = this.doms?.tpa?.buttonContainer
    await this.initTAPSessionStorageData()
    if (!container || !document.querySelector(container)) return
    if (!this.isInpage) return
    this.initUserValidation()
    await this.initPage()
  }

  initTpaBtn() {
    let url
    const {
      tpa: {
        buttonContainer,
        mobileContainer,
        url: {
          default: tapUrl,
          guest: guestTpaUrl,
        },
      },
    } = this.doms

    if (this.isLogin) {
      if (this.isB2BUser) return
      url = tapUrl
    } else {
      url = guestTpaUrl
    }

    const btnConfig = {
      isVisible: true,
      url,
    }
    this.utils.renderTemplate({
      hbsTemplate: this.isMobile ? this.tpls.tpaMobileButton : this.tpls.tpaButton,
      templateConfig: btnConfig,
      containerSelector: this.isMobile ? mobileContainer : buttonContainer,
    })

    document.querySelector('body').addEventListener('click', async e => {
      if (e.target.id === 'validate-company') {
        if (this.isOpenSigleLimitError('company')) {
          this.sigleLimitErrorDialog('company')
          e.preventDefault()
          e.stopPropagation()
        }
      }
    })
  }

  checkRegisterFail() {
    const template = this.context.template_file ? this.context.template_file : this.context.template
    const TPAData = this.utils.B3Storage.TPACompanyInfo.value
    const registerStatus = (template === 'pages/auth/create-account') && TPAData && !this.isLogin
    return registerStatus
  }

  initCreatePageUI() {
    const {
      createFormSelector,
      tpaSubmitBtnId,
      isTpaFormVisible: isVisible,
      isTpaFormHide: hide,
    } = this.state

    const {
      tpa: {
        container: {
          guest: tpaContainer,
        },
      },
    } = this.doms

    const templates = [
      {
        containerSelector: `${createFormSelector} .form-row`,
        insertType: 'beforeend',
        hbsTemplate: this.tpls.formFiles,
      },
      {
        containerSelector: '[data-type=\'CompanyName\'] label',
        insertType: 'beforeend',
        template: `<small>${this.text['global.required.label']}</small>`,
      },
      {
        containerSelector: tpaContainer,
        insertType: 'beforeend',
        hbsTemplate: this.tpls.tpaForm,
        templateConfig: {
          isVisible,
          hide,
          tpaSubmitBtnId,
          isB2CUser: this.isB2CUser,
        },
      },
    ]

    const removeDefaultCompanyNameRequired = () => {
      const $small = document.querySelector('[data-type=\'CompanyName\'] label small')
      if ($small) $small.remove()
    }
    removeDefaultCompanyNameRequired()

    templates.forEach(template => this.utils.renderTemplate(template))
    document.querySelector('.page-heading').innerHTML = this.text['tpa.button']
    document.querySelector(".form[data-create-account-form] input[type='submit']").parentElement.style.display = 'none'

    const validation = {
      type: 'singleline', label: '', required: true, maxlength: 0,
    }
    const addAttributes = ["[data-type='CompanyName']", '#tpa-email-filed', '#tpa-phone-filed']

    addAttributes.forEach(addAttribute => document.querySelector(addAttribute).setAttribute('data-validation', JSON.stringify(validation)))
    this.utils.renderTemplate({
      containerSelector: '.form-row',
      insertType: 'afterend',
      template: '<div class="attach-file-container"></div>',
    })
  }

  renderAttachFiles() {
    const { attachment } = this.state
    const $attachFileContainer = document.querySelector('.attach-file-container')

    if (!$attachFileContainer) return

    const attachments = []
    if (!isEmpty(attachment)) {
      attachment.forEach(item => {
        attachments.push({
          ...item,
          isImage: !!item.fileType.includes('image'),
        })
      })
    }

    $attachFileContainer.innerHTML = this.tpls.attachFile({
      attachments,
      showB3themeConfig: true,
      attachFileLabel: window.b3themeConfig.tpaAttachFileLabel || '',
      attachFileDes: window.b3themeConfig.tpaAttachFileDes || '',
    })

    $attachFileContainer.querySelector('[data-upload-attachment]').addEventListener('change', e => {
      this.handleTPAUploadAttachment(e)
    })

    $attachFileContainer.querySelectorAll('[data-delete-file]').forEach(el => el.addEventListener('click', e => {
      this.handleDeleteTPAFile(e)
    }))
  }

  uploadAttachmentFiles = async file => {
    try {
      window.B3Spinner.show()
      const data = new FormData()
      data.append('mediaFile', file)
      data.append('requestType', 'companyAttachedFile')
      const fileData = await this.api.uploadAttachmentFiles(data)
      return fileData
    } catch (error) {
      this.utils.Alert.error(error.message)
    } finally {
      window.B3Spinner.hide()
    }
  }

  handleGuestUploadAttachmentFiles = async (TPAData, customerId) => {
    try {
      const originalFileData = []
      const handleDataCallBack = cursor => {
        originalFileData.push(cursor.value)
      }

      await this.state.myIndexedDB.read(handleDataCallBack)

      const uploadFilePromiseQueue = []
      originalFileData.forEach(file => {
        uploadFilePromiseQueue.push(this.uploadAttachmentFiles(file))
      })

      const allSettledPromise = await Promise.allSettled(uploadFilePromiseQueue)

      const fileList = allSettledPromise.filter(p => p.status === 'fulfilled').map(({ value }) => value)

      // Throw Errors
      allSettledPromise
        .filter(p => p.status === 'rejected')
        .forEach(p => {
          this.utils.Alert.error(p.reason)
        })

      const channelId = window.jsContext?.channelId
      await this.createCompany({
        ...JSON.parse(TPAData),
        customerId,
        channelId,
      }, true, {
        isGuest: true,
        fileList,
      })

      window.B3Spinner.hide()

      this.removeB3TPAAttachmentList()
    } catch (error) {
      console.error(error.message)
    }
  }

  async removeB3TPAAttachmentList() {
    try {
      await this.state.myIndexedDB.deleteAll()
      sessionStorage.removeItem('B3TPAAttachmentList')
    } catch (error) {
      console.error(error.message)
    }
  }

  async handleUploadAttachmentFiles(file) {
    const { attachment, myIndexedDB } = this.state
    const newAttachment = attachment

    try {
      let fileData = null
      if (this.isLogin) {
        fileData = await this.uploadAttachmentFiles(file)
      } else {
        // BB2BV30-1316: TPA upload for Guests
        await myIndexedDB.create(file)

        const {
          size: fileSize,
          name: fileName,
          type: fileType,
        } = file
        fileData = {
          fileName,
          fileSize,
          fileType,
        }
      }

      fileData && newAttachment.push(fileData)
      this.setState({
        attachment: newAttachment,
      })

      sessionStorage.setItem(
        'B3TPAAttachmentList',
        JSON.stringify(newAttachment),
      )
    } catch (e) {
      console.error(e)
    }

    this.renderAttachFiles()
  }

  handleTPAUploadAttachment(e) {
    const { attachment } = this.state
    const newFile = e.target.files[0]

    if (!newFile) return

    if (attachment.length >= 3) {
      this.utils.Alert.error(this.text['rfq.form.attach.file.limitNumTip'])
    } else {
      if (!this.utils.JudgeFileType(newFile.type)) {
        this.utils.Alert.error(this.text['rfq.form.attach.file.formatSupportTip'])
        return
      }

      if (newFile.size >= (1024 * 1024 * 2)) {
        this.utils.Alert.error(this.text['rfq.form.attach.file.fileSizeTip'])
        return
      }

      const sameTypeFile = attachment.filter(item => newFile.type.includes(item.fileType))

      if (sameTypeFile.find(item => item.fileName === newFile.name)) {
        const fileNameArr = newFile.name.split('.')
        const newFileArr = []
        if (fileNameArr.length > 2) {
          const fileSuffix = fileNameArr.pop()
          const fileName = fileNameArr.join('.')
          newFileArr.push(fileName, fileSuffix)
        } else {
          fileNameArr.forEach(item => {
            newFileArr.push(item)
          })
        }
        const nameArr = newFileArr[0].trim().split('(')
        const name = (nameArr.length > 1 && (typeof +newFileArr[0][newFileArr[0].length - 2]) === 'number') ? nameArr[0] : newFileArr[0]
        const nameLength = name.length
        const sameNameFile = sameTypeFile.filter(item => item.fileName.slice(0, nameLength) === name)

        if (sameNameFile.find(item => item.fileName === newFile.name)) {
          const fileIndex = sameNameFile.map(item => {
            const index = +item.fileName.slice(newFileArr[0].length + 1, newFileArr[0].length + 2)

            if (isNaN(index)) {
              return 0
            }
            return index
          })

          const newFileIndex = [];
          [0, 1, 2].forEach(item => {
            if (!fileIndex.includes(item)) {
              newFileIndex.push(item)
            }
          })

          const newFileName = `${newFileArr[0]}(${newFileIndex[0]}).${newFileArr[1]}`

          const newNameFile = new File([newFile], newFileName, {
            type: newFile.type,
          })

          this.handleUploadAttachmentFiles(newNameFile)
        } else {
          this.handleUploadAttachmentFiles(newFile)
        }
      } else {
        this.handleUploadAttachmentFiles(newFile)
      }
    }
  }

  async handleDeleteTPAFile(e) {
    try {
      const { attachment, myIndexedDB } = this.state
      const { id } = e.target

      const newAttachment = []

      for (let i = 0; i < attachment.length; i += 1) {
        if (attachment[i].fileName === id) {
          await myIndexedDB.delete(id)
        } else {
          newAttachment.push(attachment[i])
        }
      }

      this.setState({
        attachment: newAttachment,
      })

      sessionStorage.setItem(
        'B3TPAAttachmentList',
        JSON.stringify(newAttachment),
      )

      this.renderAttachFiles()
    } catch (error) {
      console.error(error.message)
    }
  }

  bindGuestEvent() {
    const {
      tpaSubmitBtnId,
      createFormSelector,
      myB3ExtraField,
      myB3CompanyUserExtraField,
    } = this.state
    document.querySelector('body').addEventListener('click', async e => {
      if (e.target.id === tpaSubmitBtnId) {
        e.preventDefault()
        e.stopPropagation()
        if (this.isOpenSigleLimitError('company')) {
          this.sigleLimitErrorDialog('company')
          return
        }
        window.createAccountValidator.performCheck()
        const canSubmit = window.createAccountValidator.areAll('valid')

        if (!canSubmit) return
        let isDuplicate = true
        try {
          await this.checkExistCompany()
          isDuplicate = false
        } finally {
          this.toggleCompanyDuplicate(isDuplicate)
        }
        if (isDuplicate) return

        if (typeof tpa.beforeSubmit === 'function') await tpa.beforeSubmit(this)

        this.setFormSessionStorage()

        const extraFieldsArr = this.getCompanyExtraFields()
        const userExtraFields = this.getCompanyUserExtraFields()
        const storeHash = this.context.settings.store_hash

        if (!myB3CompanyUserExtraField.isCompanyUserCanSubmit) return
        const extraFields = []
        extraFieldsArr.forEach(item => {
          if (item.fieldValue !== '') extraFields.push(item)
        })

        try {
          await this.api.validateStorefrontExtraFields({ extraFields, storeHash }, 'company')
          await this.api.validateStorefrontExtraFields({ extraFields: userExtraFields, storeHash }, 'user')
        } catch (error) {
          this.utils.Alert.error(error.message)

          if (error) return
        }

        if (!myB3ExtraField.isCompanyCanSubmit) return

        document.querySelector(createFormSelector).submit()
      }
    })
  }

  get companyDuplicateTip() {
    const $tip = document.querySelector('.company-duplicate-tip') ?? document.createElement('span')
    $tip.innerHTML = this.text['tpa.tip.company.name.duplicated']
    $tip.className = 'company-duplicate-tip form-inlineMessage'
    return $tip
  }

  toggleCompanyDuplicate(isDuplicate) {
    const $companyContainer = this.isLogin ? document.querySelector('#company_name').parentNode : document.querySelector("[data-type='CompanyName']")
    const classes = ['form-field--error', 'form-field--success']
    const classAdd = isDuplicate ? classes[0] : classes[1]
    const classRemove = isDuplicate ? classes[1] : classes[0]

    $companyContainer.classList.add(classAdd)
    $companyContainer.classList.remove(classRemove)

    if (isDuplicate) {
      $companyContainer.append(this.companyDuplicateTip)
    } else {
      $companyContainer.querySelector('.company-duplicate-tip')?.remove()
    }
  }

  getCompanyExtraFields() {
    const {
      extraFields,
      myB3ExtraField,
    } = this.state

    const oldVersionExtra = extraFields.map(({ name }) => {
      const fieldValue = document.querySelector(`[name=${name}]`).value
      return {
        fieldName: name,
        fieldValue,
      }
    })

    const newMyB3ExtraField = myB3ExtraField.getExtraFieldsValue()

    return [...oldVersionExtra, ...newMyB3ExtraField]
  }

  setFormSessionStorage() {
    const basicInfo = {
      storeHash: this.context.settings.store_hash,
    }

    const companyInfoFields = {
      companyName: "[data-field-type='CompanyName']",
      city: "[data-field-type='City']",
      state: "[data-field-type='State']",
      zipCode: "[data-field-type='Zip']",
      addressLine1: "[data-field-type='AddressLine1']",
      addressLine2: "[data-field-type='AddressLine2']",
      companyEmail: '#company_user_email',
      companyPhoneNumber: '#company_user_phone',
      country: "[data-field-type='Country']",
    }
    const companyInfo = Object.entries(companyInfoFields).reduce((result, [key, selector]) => {
      const value = document.querySelector(selector)?.value ?? ''
      result[key] = value
      return result
    }, {})
    this.utils.B3Storage.TPACompanyInfo.setValue(JSON.stringify({
      ...basicInfo,
      ...companyInfo,
      extraFields: this.getCompanyExtraFields(),
      userExtraFields: this.getCompanyUserExtraFields(),
    }))
  }

  async initGuestValidation() {
    const {
      state: {
        createFormSelector,
      },
      utils: {
        re,
      },
      locales: {
        validation,
      },
    } = this

    const validationSchema = [{
      selector: `${createFormSelector} [data-field-type='CompanyName']`,
      errorMessage: validation.companyNameVoid,
    }, {
      selector: `${createFormSelector} [data-field-type='TpaEmailFiled']`,
      errorMessage: validation.emailIncorrect,
      re: re.email,
    }, {
      selector: `${createFormSelector} [data-field-type='TpaPhoneFiled']`,
      errorMessage: validation.phoneIncorrect,
      re: re.phone,
    }]

    const waitingLoadingCreateAccountValidator = () => new Promise(resolve => {
      setTimeout(() => {
        resolve()
      }, 500)
    })

    const browserInformations = new this.utils.browserCheck()
    const { browser } = browserInformations.init()

    if (browser === 'safari') await waitingLoadingCreateAccountValidator()

    validationSchema.forEach(({
      selector,
      errorMessage,
      re,
    }) => {
      if (document.querySelectorAll(selector).length > 0) {
        window.createAccountValidator.remove(selector)
        window.createAccountValidator.add({
          selector,
          validate: (cb, val) => {
            if (re) return cb(re.test(val))
            if (val) {
              return cb(true)
            }
            return cb(false)
          },
          errorMessage,
        })
      }
    })
  }

  handleRegisterFailed() {
    this.renderTPAFrom()
    this.utils.B3Storage.TPACompanyInfo.removeValue()
  }

  renderTPAFrom() {
    const TPAData = this.utils.B3Storage.TPACompanyInfo.value
    const companyFields = JSON.parse(TPAData)

    document.querySelector("[data-field-type='TpaEmailFiled']").value = companyFields.companyEmail
    document.querySelector("[data-field-type='TpaPhoneFiled']").value = companyFields.companyPhoneNumber
  }

  async initTAPSessionStorageData() {
    try {
      const {
        TPACompanyInfo,
      } = this.utils.B3Storage
      const TPAData = TPACompanyInfo.value
      const customerId = this.context.customer.id

      const shouldSendTPAData = customerId && TPAData

      if (!shouldSendTPAData) {
        TPACompanyInfo.removeValue()
        return
      }

      window.B3Spinner.show()

      const { attachment } = this.state
      if (Array.isArray(attachment) && attachment.length) {
        await this.handleGuestUploadAttachmentFiles(TPAData, customerId)
      } else {
        const channelId = window.jsContext?.channelId
        await this.createCompany({
          ...JSON.parse(TPAData),
          customerId,
          channelId,
        }, true)
      }
    } catch (e) {
      console.error(e)
    }
    window.B3Spinner.hide()
  }

  async initPage() {
    const {
      tpaPageContainer,
      companyFormSelector,
    } = this.state
    const {
      constants: {
        B3CompanyStatus,
        getB3ConstantLabel,
      },
    } = this.utils
    try {
      const bcToken = await this.api.getBCToken()
      const b2bToken = await this.setB3Token(bcToken)

      if (!b2bToken) {
        this.renderFormHtml()
        this.bindUserEvent()
      } else {
        await this.setUserRole()
        const userId = this.utils.B3Storage.B3UserId.value
        const companyData = await this.getCompanyInfo(userId)
        const { companyStatus, extraFields } = companyData
        this.setState({
          companyExtraFields: extraFields,
          companyStatus,
        })

        const fieldsMap = [
          { selector: '#company_name', value: companyData.companyName },
          { selector: '#company_address_street', value: companyData.addressLine1 },
          { selector: '#company_address_street2', value: companyData.addressLine2 },
          { selector: '#company_address_country', value: companyData.country },
          { selector: '#company_address_city', value: companyData.city },
          { selector: '#company_address_state', value: companyData.state },
          { selector: '#company_address_zip', value: companyData.zipCode },
          { selector: '#company_user_phone', value: companyData.phone },
          { selector: '#company_user_email', value: companyData.email },
        ]
        const companyStatusLabel = getB3ConstantLabel(B3CompanyStatus, companyStatus).toLowerCase()
        const messageText = {
          hasCompany: this.text['tpa.message.company.exist'],
          pending: `${this.utils.text('tpa.message.company.pending', {
            hash: {
              companyStatusLabel,
            },
          })}`,
          reject: `${this.utils.text('tpa.message.company.reject', {
            hash: {
              companyStatusLabel,
            },
          })}`,
          others: this.text['tpa.message.company.others'],
        }

        switch (companyStatus) {
          case '0':
            tpaPageContainer.innerHTML = this.tpls.message({ message: messageText.pending })
            break
          case '1':
            this.utils.B3Storage.clear()
            tpaPageContainer.innerHTML = this.tpls.message({ message: messageText.hasCompany })
            window.location.href = '/'
            break
          case '2':
            this.renderFormHtml(this.text['tpa.form.resubmit.button'])
            this.bindUserEvent()
            tpaPageContainer.insertAdjacentHTML('afterbegin', this.tpls.message({ message: messageText.reject }))

            for (let i = 0; i < fieldsMap.length; i += 1) {
              if (fieldsMap[i].value) {
                document.querySelector(`${companyFormSelector} ${fieldsMap[i].selector}`).value = fieldsMap[i].value
              }
            }
            break
          // improvement: need to discuss about the solution for dealing with the code `3` and `4`
          case '3':
            tpaPageContainer.innerHTML = this.tpls.message({ message: messageText.others })
            break
          case '4':
            tpaPageContainer.innerHTML = this.tpls.message({ message: messageText.others })
            break
          default:
            this.renderFormHtml()
            this.bindUserEvent()
            break
        }
      }
    } catch {
      this.utils.Alert.error(this.locales.tips.notPermission)
    }
    this.initUserValidation()
  }

  renderFormHtml(type) {
    const {
      B3CompanyId,
    } = this.utils.B3Storage
    const { customer } = this.context
    const [userFirstName, userLastName] = customer.name.split(/\s+/g)
    const companyId = B3CompanyId.value

    const pageConfig = {
      isVisible: true,
      userFirstName,
      userLastName,
      customer,
      companyId,
      type,
      isB2CUser: this.isB2CUser,
    }

    const tpaFormHtml = tpaForm(pageConfig)
    this.state.tpaPageContainer.innerHTML = tpaFormHtml
  }

  bindUserEvent() {
    const {
      companyFormSelector,
      myB3ExtraField,
      myB3CompanyUserExtraField,
    } = this.state
    const storeHash = this.context.settings.store_hash
    document.querySelector(companyFormSelector).addEventListener('submit', async e => {
      e.preventDefault()
      this.newCompanyValidator.performCheck()
      const allValidStatus = this.newCompanyValidator.areAll('valid')

      const userExtraFields = this.getCompanyUserExtraFields()
      if (!(allValidStatus && myB3CompanyUserExtraField.isCompanyUserCanSubmit)) return

      let isDuplicate = true
      try {
        await this.checkExistCompany()
        isDuplicate = false
      } finally {
        this.toggleCompanyDuplicate(isDuplicate)
      }
      if (isDuplicate) return

      if (typeof tpa.beforeSubmit === 'function') await tpa.beforeSubmit(this)

      window.B3Spinner.show()
      if (!this.shouldUpdateCompanyInfo) {
        this.utils.B3Storage.clear()
      }

      try {
        if (this.shouldUpdateCompanyInfo) {
          this.updateCompany()
        } else {
          const companyInfo = this.getFormData()

          if (userExtraFields && userExtraFields.length > 0) {
            await this.api.validateStorefrontExtraFields({ extraFields: userExtraFields, storeHash }, 'user')
          }

          if (!myB3ExtraField.isCompanyCanSubmit) {
            window.B3Spinner.hide()
            return
          }

          await this.createCompany(companyInfo)
        }

        this.removeB3TPAAttachmentList()
      } catch (error) {
        //
      } finally {
        window.B3Spinner.hide()
      }

      window.B3Spinner.hide()
    })
  }

  initUserValidation() {
    const {
      state: {
        companyFormSelector,
      },
      utils: {
        re,
      },
      locales: {
        validation,
      },
    } = this

    this.newCompanyValidator = nod({
      button: `${companyFormSelector} input[type="submit"]`,
    })
    this.newCompanyValidator.add([{
      selector: `${companyFormSelector} input[name="company_name"]`,
      validate: (cb, val) => {
        cb(val.length)
      },
      errorMessage: validation.companyNameVoid,
    },
    {
      selector: `${companyFormSelector} input[name="company_user_phone"]`,
      validate: (cb, val) => {
        cb(re.phone.test(val))
      },
      errorMessage: validation.phoneIncorrect,
    },
    {
      selector: `${companyFormSelector} input[name="company_user_email"]`,
      validate: (cb, val) => {
        cb(re.email.test(val))
      },
      errorMessage: validation.emailIncorrect,
    }])
  }

  updateCompany() {
    const { myB3ExtraField, myB3CompanyUserExtraField } = this.state

    const updateCompanyInfoData = this.getFormData()
    if (!(myB3ExtraField.isCompanyCanSubmit && myB3CompanyUserExtraField.isCompanyUserCanSubmit)) {
      window.B3Spinner.hide()
      return
    }

    updateCompanyInfoData.companyStatus = '0'
    const companyId = document.querySelector('#company_id').value
    this.api.updateCompanyInfo(companyId, updateCompanyInfoData).then(() => {
      this.utils.Alert.success(this.locales.tips.createCompanyChecking)
      window.location.reload()
    })
  }

  getFormData() {
    return {
      storeHash: this.context.settings.store_hash,
      companyName: document.querySelector('#company_name').value,
      country: document.querySelector('#company_address_country').value,
      city: document.querySelector('#company_address_city').value,
      state: document.querySelector('#company_address_state').value,
      zipCode: document.querySelector('#company_address_zip').value,
      addressLine1: document.querySelector('#company_address_street').value,
      addressLine2: document.querySelector('#company_address_street2').value,
      companyEmail: document.querySelector('#company_user_email').value,
      companyPhoneNumber: document.querySelector('#company_user_phone').value,
      customerId: document.querySelector('#company_customer_id').value,
      extraFields: this.getCompanyExtraFields(),
      fileList: this.state.attachment,
      userExtraFields: this.getCompanyUserExtraFields(),
    }
  }

  async setExtraFields() {
    const {
      store_hash: storeHash,
    } = this.context.settings
    const {
      B3Storage: {
        B3ExtraFields,
      },
      constants: {
        b3ExtraFieldTypes,
      },
    } = this.utils
    if (this.state.extraFields.length) return

    if (B3ExtraFields.value) {
      this.setState({
        extraFields: JSON.parse(B3ExtraFields.value),
      })
      return
    }

    window.B3Spinner.show()
    try {
      const res = await this.api.getExtraFields({ storeHash })
      const extraFields = res.list.map(field => {
        const required = field.isRequired !== '0'
        const partial = b3ExtraFieldTypes[field.dataType]
        const label = field.labelName

        let validation = {
          type: 'singleline',
          label,
          required,
          value: field.value || '',
        }

        switch (partial) {
          case 'number': {
            validation = {
              ...validation,
              type: 'numberonly',
              limitfrom: 0,
              limitto: 10000000,
              value: field.value || 0,
            }
            break
          }
          case 'multiline': {
            validation = { ...validation, rows: field.rows || 3 }
            break
          }
          default:
        }

        return {
          ...field,
          name: field.fieldName,
          partial,
          required,
          label,
          visible: true,
          disabled: false,
          validation,
        }
      })

      this.setState({ extraFields })
      B3ExtraFields.setValue(JSON.stringify(extraFields))
    } finally {
      window.B3Spinner.hide()
    }
  }

  renderFields(fields, patchFields) {
    const {
      createFormSelector,
      tpaContainerSelector,
    } = this.state

    const template = fields.reduce((result, field) => {
      const { render } = field

      if (patchFields) {
        const { fieldValue } = patchFields.find(item => item.fieldName === field.name) || {}
        field.value = fieldValue || field.value
      }

      if (typeof render === 'function') return result += render.call(field)

      field.visible = field.visible === undefined ? true : field.visible

      const filedHtml = this.formPartials[field.partial](field)
      result += filedHtml
      return result
    }, '')

    const containerSelector = this.isLogin ? `${tpaContainerSelector} .form-actions` : `${createFormSelector} .form-row`

    this.utils.renderTemplate({
      containerSelector,
      insertType: this.isLogin ? 'beforeBegin' : 'beforeEnd',
      template,
    })
  }

  bindFieldsValidation(fields) {
    const validationSchema = fields.map(({
      validation = {},
      name,
      required,
      label,
      requiredError,
      errorMessage,
      selector,
    }) => {
      selector = selector || `[name=${name}]`
      const validate = []
      const errorMessages = []

      if (validation.required || required) {
        validate.push((cb, val) => {
          cb(val.length > 0)
        })
        errorMessages.push(requiredError || `${this.utils.text('tpa.field.validation.cannot.blank', {
          hash: {
            validationLabel: validation.label || label,
          },
        })}`)
      }

      if (validation.type === 'numberonly') {
        const invalidMessage = `${this.utils.text('tpa.field.validation.message.value.valid', {
          hash: {
            validationLabel: validation.label || label,
          },
        })}`
        const min = Number(validation.limitfrom)
        const max = Number(validation.limitto)
        validate.push((cb, val) => {
          const numberVal = Number(val)

          cb(numberVal >= min && numberVal <= max)
        })
        errorMessages.push(errorMessage || invalidMessage)
      }

      if (typeof validation === 'function') {
        validate.push((cb, val) => {
          const isValid = validation(val)

          cb(isValid)
        })
        errorMessages.push(errorMessage)
      }

      return {
        selector,
        validate,
        errorMessage: errorMessages,
      }
    })

    const validations = validationSchema.filter(validator => !!validator.validate.length)

    if (this.isLogin) this.newCompanyValidator.add(validations)
    else window.createAccountValidator.add(validations)
  }

  renderExtraFields() {
    const {
      extraFields,
      companyExtraFields,
    } = this.state

    this.renderFields(extraFields, companyExtraFields)
    this.bindFieldsValidation(extraFields)
  }

  renderCustomFields() {
    const {
      customFields,
    } = this.state

    this.renderFields(customFields)
    this.bindFieldsValidation(customFields)
  }

  async checkExistCompany() {
    const {
      store_hash: storeHash,
    } = this.context.settings
    const {
      state: {
        createFormSelector,
        companyFormSelector,
      },
    } = this

    const selector = this.isLogin ? `${companyFormSelector} input[name="company_name"]` : `${createFormSelector} [data-field-type='CompanyName']`
    const companyName = document.querySelector(selector).value

    let isValid = false
    window.B3Spinner.show()
    try {
      await this.api.checkExistCompany({ storeHash, companyName })
      isValid = true
    } finally {
      window.B3Spinner.hide()
    }

    return isValid
  }

  initMyB3ExtraField() {
    const {
      myB3ExtraField,
      companyStatus,
    } = this.state

    myB3ExtraField.init({
      moduleType: 'company',
      containerSelector: this.isB2CUser || +companyStatus === 2 ? '.form-actions' : '.account-body .form-row',
      insertType: this.isB2CUser || +companyStatus === 2 ? '' : 'beforeend',
    })
  }

  async validateB3TPAAttachmentList() {
    const { attachment } = this.state

    if (Array.isArray(attachment) && attachment.length) {
      const isTempFileList = attachment.filter(file => file.fileUrl).length !== attachment.length

      if (this.isLogin && isTempFileList) {
        this.removeB3TPAAttachmentList()
      }

      if (!this.isLogin && !isTempFileList) {
        this.removeB3TPAAttachmentList()
      }
    } else {
      this.removeB3TPAAttachmentList()
    }
  }

  initMyB3UserExtraField() {
    const {
      myB3CompanyUserExtraField,
      companyStatus,
    } = this.state

    myB3CompanyUserExtraField.init({
      moduleType: 'user',
      containerSelector: this.isB2CUser || +companyStatus === 2 ? '.user-information' : '#tpa-phone-filed',
      insertType: this.isB2CUser || +companyStatus === 2 ? 'beforeend' : 'afterend',
    })
  }

  getCompanyUserExtraFields() {
    const {
      myB3CompanyUserExtraField,
    } = this.state

    const newMyB3ExtraField = myB3CompanyUserExtraField.getExtraFieldsValue()

    return [...newMyB3ExtraField]
  }
}
