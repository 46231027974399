import BasePage from '../../../common/BasePage'
import {
  INVOICE_STATUS_MAP,
  INVOICE_SOURCE_MAP,
  FILTER_FIELDS,
} from '../constants'
import payment from './payment.html'
import paymentHeader from './payment-header.html'
import paymentSelect from './payment-select.html'
import paymentTable from './payment-table.html'
import pendingInvoicesTable from '../components/pending-invoices-table.html'

export default class InvoicesPayment extends BasePage {
  constructor() {
    super()
    this.name = 'InvoicesPayment'
    this.state = {
      invoiceId: '',
      INVOICE_STATUS_MAP,
      FILTER_FIELDS,
      INVOICE_SOURCE_MAP,
      isSelectedAll: 0,
      paymentTotal: 0,
      hasDueInvoice: 0,
      hasOverDueInvoice: 0,
      totalBalance: 0,
      priceInfo: {
        value: 0,
      },
      currency: {
        token_location: '',
      },
      currentDate: new Date(),
      currentCurrency: null,
      sortBy: '',
      orderBy: 'DESC',
      cardInfo: {
        customerId: '',
        payerId: '',
        payerName: '',
      },
      countries: null,
      paymentFormData: {
        storeID: '',
        id: '',
        profile: {
          num: '',
          code: '',
          exp_m: '',
          exp_y: '',
        },
        address: {
          first_name: '',
          last_name: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          phone: '',
          zip: '',
        },
      },
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      paymentAddModal: null,
      tableHeaders: [
        {
          label: this.text['invoices.table.thead.invoiceNumber'],
          isSort: true,
          sortKey: 'invoiceNumber',
        },
        {
          label: this.text['invoices.table.thead.dueDate'],
          isSort: true,
          sortKey: 'dueDate',
        }, {
          label: this.text['invoices.table.thead.status'],
          isSort: true,
          sortKey: 'status',
        }, {
          label: this.text['invoices.table.thead.total'],
          isSort: true,
          sortKey: 'originalBalance',
        }, {
          label: this.text['invoices.table.thead.totalPrice'],
          isSort: true,
          sortKey: 'openBalance',
        }, {
          label: this.text['invoices.table.thead.amount'],
          isSort: false,
          sortKey: 'type',
        },
      ],
      invoiceList: [],
      canSelectAll: false,
      paymentList: [],
      pendingPayments: [],
      finishedPayments: [],
      pageTemplateConfig: [
        {
          hbsTemplateName: 'paymentHeader',
          containerSelector: '.invoices-payment .payment-header',
        },
        {
          hbsTemplateName: 'paymentTable',
          containerSelector: '.invoices-payment .payment-table',
        },
        {
          hbsTemplateName: 'paymentSelect',
          containerSelector: '.invoices-payment .payment-select',
        },
      ],
    }
    this.tpls = {
      payment,
      paymentHeader,
      paymentSelect,
      paymentTable,
      pendingInvoicesTable,
    }
  }

  get isInPage() {
    return this.utils.leftIncludes(window.location.pathname, this.doms.invoicesPayment.url)
  }

  get isSelectAll() {
    const { invoiceList } = this.state || []
    const isAllCannotPay = invoiceList.every(invoice => invoice.status === 2)
    const isAllChecked = invoiceList.filter(invoice => invoice.status !== 2).every(invoice => invoice.checked)
    return invoiceList.length && !isAllCannotPay && isAllChecked
  }

  get canSelectAll() {
    const { invoiceList } = this.state
    if (!invoiceList.length) return
    const currencyToken = invoiceList[0].currency.token
    return invoiceList.every(({ currency }) => currency.token === currencyToken)
  }

  get paymentTotal() {
    const { invoiceList } = this.state
    let paymentTotal = 0
    invoiceList.forEach(invoice => {
      paymentTotal += Number(invoice.currentAmountValue || 0)
    })
    const decimalPlaces = this.utils.setDecimalPlaces(paymentTotal)
    return paymentTotal ? parseFloat(paymentTotal).toFixed(decimalPlaces) : 0
  }

  init() {
    if (!this.isB2BUser) return
    if (!this.isInPage) return
    const invoiceId = this.utils.urlHelper.searchParams.get('id')
    if (!invoiceId) {
      window.location.href = '/invoices/'
      return
    }
    this.setState({
      invoiceId,
    })
    this.render()
    this.fetchPaymentHeader()
    this.getInvoicePayments()
    this.getInvoiceTable()
    this.initMobileTable()
    this.getStorefrontInformation()
  }

  bindPaymentExpDatePicker() {
    const { DateTime, getStoreZoneDate } = this.utils
    const defaultStartDate = getStoreZoneDate()
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1)
    const $paymentExpDate = document.querySelector('#paymentExpDate')
    $paymentExpDate.value = window.B3DisplayFormat(defaultStartDate)
    const setDatePicker = $el => {
      window.B3DatePicker($el, {
        mode: 'dp-modal',
        clear: false,
        format(date) {
          return window.B3DisplayFormat(date)
        },
        parse(dateStr) {
          const date = new Date(DateTime.displayParse(dateStr))
          return isNaN(date) ? new Date() : date
        },
      }).on({
        close: () => {
          $el.blur()
        },
      })
    }

    setDatePicker($paymentExpDate, 'beginDateAt')
  }

  renderOrdersPaginator() {
    const {
      pagination: {
        offset,
        limit,
        totalCount,
      },
    } = this.state

    const currentPage = Math.ceil((offset + 1) / limit)
    const totalPages = Math.ceil(totalCount / limit)

    window.B3Paginator.init({
      container: '#payment-pagination',
      currentPage,
      totalPages,
      onPageChange: this.handlePaginationChange,
    })
  }

  handlePaginationChange = page => {
    const {
      pagination,
      pagination: {
        limit,
      },
    } = this.state

    this.setState({
      pagination: {
        ...pagination,
        offset: (page - 1) * limit,
      },
    })
    this.resetCurrencyInfo()
    this.getInvoiceTable()
  }

  handleShort(e) {
    const { sortkey } = e.target.dataset
    const { orderBy } = this.state
    this.setState({
      sortBy: sortkey,
      orderBy: orderBy === 'DESC' ? 'ASC' : 'DESC',
    })
    this.refreshTableInfo()
    this.getInvoiceTable()
  }

  async getInvoiceTable() {
    try {
      const {
        pagination,
        sortBy,
        orderBy,
        invoiceId,
      } = this.state
      const { getSpecifiedCurrency } = this.utils
      let paymentTotal = 0
      const {
        data,
        meta: {
          pagination: resPagination,
        },
      } = await this.api.getInvoices({
        ...pagination,
        sortBy,
        orderBy,
      })
      const b3StorageCurrentCurrency = localStorage.getItem('b3_current_currency')
      const currentCurrency = b3StorageCurrentCurrency ? JSON.parse(b3StorageCurrentCurrency) : {}
      const invoiceList = data.map(item => {
        const { INVOICE_STATUS_MAP, INVOICE_SOURCE_MAP } = this.state
        const {
          status,
          originalBalance: {
            code: currencyCode,
          },
          openBalance: {
            value: displayOpenBalance,
          },
          source,
        } = item
        const pastDays = this.getPastDays(item.dueDate)
        const currency = getSpecifiedCurrency(currencyCode)
        item.displayStatus = INVOICE_STATUS_MAP[status]
        item.displaySource = INVOICE_SOURCE_MAP[source]
        item.checked = +invoiceId === item.id
        if (item.checked) {
          const decimalPlaces = this.utils.setDecimalPlaces(displayOpenBalance)
          item.currentAmountValue = item.currentAmountValue || parseFloat(displayOpenBalance).toFixed(decimalPlaces)
          paymentTotal += Number(item.currentAmountValue)
          this.setState({
            currency,
          })
        }
        item.invoiceAging = pastDays > 0 ? pastDays : '-'
        item.displayDueDate = item.dueDate * 1000
        item.currency = currency
        return item
      })
      this.setState({
        pagination: {
          ...resPagination,
        },
        currentCurrency,
        paymentTotal,
        invoiceList,
      })
      this.refreshTableInfo()
    } catch (e) {
      console.error(e)
    }
  }

  async fetchPaymentHeader() {
    const params = {
      limit: 1000,
      offset: 0,
      paymentStatus: [1, 2],
    }
    const { data: payments } = await this.api.getReceiptLines(params)
    const { data: priceInfo } = await this.api.getInvoiceInfo()
    const finishedPayments = []
    const pendingPayments = []
    payments.forEach(item => {
      item.displayCreatedAt = item.createdAt * 1000
      item.paymentMethod = item.paymentType || this.text['invoices.paymentType.unknown']
      if ([1, 2].includes(+item.paymentStatus)) {
        pendingPayments.push(item)
      } else {
        finishedPayments.push(item)
      }
    })
    this.setState({
      pendingPayments,
      finishedPayments,
      priceInfo: {
        ...priceInfo,
        value: priceInfo.totalBalance,
      },
    })
    this.refreshTemplate('.invoices-payment .payment-header', 'paymentHeader')
    if (pendingPayments.length) {
      const $invoiceDetailPending = document.querySelector('#invoice-detail-pending')
      $invoiceDetailPending.innerHTML = this.tpls.pendingInvoicesTable({ ...this.state })
    }
  }

  handleSelectOneInvoice = e => {
    const { id } = e.target.dataset
    this.setInvoiceList(null, id, e.target.value !== 'true')
  }

  setInvoiceList(e) {
    const { value, dataset } = e.target
    const { getSpecifiedCurrency } = this.utils
    const { invoiceList } = this.state
    const { id, code: currencyCode } = dataset
    const currency = getSpecifiedCurrency(currencyCode)
    this.setState({
      currency,
      invoiceList: invoiceList.map(invoice => {
        if (!id) invoice.checked = !(value === 'true')
        if (id && invoice.id === +id) invoice.checked = !invoice.checked
        const displayOpenBalance = invoice.currentAmountValue || invoice.openBalance.value
        const decimalPlaces = this.utils.setDecimalPlaces(displayOpenBalance)
        invoice.currentAmountValue = invoice.checked ? parseFloat(displayOpenBalance).toFixed(decimalPlaces) : ''
        return invoice
      }),
    })
    this.resetCurrencyInfo()
    this.refreshTableInfo()
  }

  resetCurrencyInfo() {
    const { invoiceList } = this.state
    const hasSelectedInvoice = invoiceList.some(({ checked }) => checked)
    if (!hasSelectedInvoice) {
      this.setState({
        currency: {
          token: '',
        },
      })
    }
  }

  refreshTableInfo() {
    this.refreshTemplate('.invoices-payment .payment-table', 'paymentTable')
    this.renderOrdersPaginator()
  }

  handleSelectAll(e) {
    const isSelectedAll = +e.target.value ? 0 : 1
    this.setInvoiceList(isSelectedAll)
  }

  handleInputBlur = e => {
    if (!e.target.value) return
    const { id, code: currencyCode } = e.target.dataset
    const { getSpecifiedCurrency } = this.utils
    const { invoiceList } = this.state
    const currency = getSpecifiedCurrency(currencyCode)
    let paymentTotal = 0
    const newInvoiceList = invoiceList.map(invoice => {
      if (invoice.id === +id) {
        const decimalPlaces = this.utils.setDecimalPlaces(e.target.value)
        invoice.currentAmountValue = parseFloat(e.target.value).toFixed(decimalPlaces)
        invoice.checked = true
      }
      paymentTotal += Number(invoice.currentAmountValue || 0)
      return invoice
    })
    this.setState({
      invoiceList: [...newInvoiceList],
      paymentTotal,
      currency,
    })
    this.refreshTableInfo()
  }

  getPastDays(date) {
    if (!date) {
      return 0
    }
    const secPerDay = 60 * 60 * 24
    const msPerDay = 1000 * secPerDay
    const now = Date.now()
    const due = date * 1000
    const msOver = now - due
    return parseInt(msOver / msPerDay, 10)
  }

  async getInvoicePayments() {
    const { data } = await this.api.getInvoicePayments()
    const paymentList = data.filter(({ value }) => value.isEnabled && value.isStorefrontMethod)
    this.setState({ paymentList })
    this.refreshTemplate('.invoices-payment .payment-select', 'paymentSelect')
  }

  async getStorefrontInformation() {
    try {
      const { data } = await this.api.getStorefrontInformation()
      this.setState({
        cardInfo: {
          ...data,
        },
      })
      this.refreshTemplate('.invoices-payment .payment-select', 'paymentSelect')
    } catch (e) {
      this.utils.Alert.error(e.errors)
    }
  }

  async handleMakePayment(e) {
    const {
      currency: {
        currency_code: currencyCode,
      },
    } = this.state
    const { payment } = e.target.dataset

    const lineItems = this.getPaymentLineItems()
    const memo = document.querySelector('.invoice-memo')?.value
    if (!lineItems.length) {
      this.utils.Alert.error(this.text['invoices.payment.tip'])
      return
    }
    const data = {
      details: { memo },
      currency: currencyCode,
      lineItems,
    }
    switch (payment) {
      case 'payments_bigcommerce_sales_order':
        this.makeAPaymentBc(data)
        break
      case 'payments_test':
        this.makeAPaymentGetaway(data)
        break
      default: break
    }
  }

  async makeAPaymentGetaway(data) {
    try {
      window.B3Spinner.show()
      const { data: { receiptId } } = await this.api.makeAPaymentGetaway(data)
      if (receiptId) {
        window.location.href = `/invoice-payment-receipt/?id=${receiptId}`
      }
    } catch (e) {
      this.utils.Alert.error(e.errors)
    } finally {
      window.B3Spinner.hide()
    }
  }

  async makeAPaymentBc(data) {
    try {
      window.B3Spinner.show()
      const { data: { checkoutUrl, cartId } } = await this.api.makeAPaymentBc({ ...data })
      /**
      * if success, add a localStorage field;
      * when checkout, this fields is needed to do some customize requests
      */

      localStorage.setItem('IPCheckout', '1')
      localStorage.setItem('IPCartId', cartId)
      window.location.replace(checkoutUrl)
    } catch (e) {
      this.utils.Alert.error(e.errors)
    } finally {
      window.B3Spinner.hide()
    }
  }

  getPaymentLineItems() {
    const { invoiceList } = this.state
    const paymentItems = []
    invoiceList.forEach(invoice => {
      const {
        checked,
        currentAmountValue: amount,
        id: invoiceId,
      } = invoice
      // status: 2 => filter completed invoice
      if (checked && invoice.status !== 2) {
        paymentItems.push({
          amount,
          invoiceId,
        })
      }
    })
    return paymentItems || []
  }

  setPaymentDom() {
    const $invoiceWarper = document.querySelectorAll('.invoice-payment-page-wrap')
    $invoiceWarper.forEach($paymentDom => this.utils.toggleClass($paymentDom, 'hide'))
    // this.refreshTemplate('.invoices-payment .payment-select', 'paymentSelect')
  }

  async render() {
    const {
      invoicesPayment: {
        container,
      },
    } = this.doms
    this.utils.renderTemplate({
      hbsTemplate: this.tpls.payment,
      containerSelector: container,
      templateConfig: {
        ...this.state,
        canSelectAll: this.canSelectAll,
        isSelectAll: this.isSelectAll,
        paymentTotal: this.paymentTotal,
      },
      insertType: 'beforeend',
    })
    this.renderTemplate()
  }

  refreshTemplate(containerSelector, hbsTemplateName) {
    const $container = document.querySelector(containerSelector)
    $container.innerHTML = this.tpls[hbsTemplateName]({
      ...this.state,
      paymentTotal: this.paymentTotal,
      canSelectAll: this.canSelectAll,
      isSelectAll: this.isSelectAll,
    })
    const $tableContainer = document.querySelector('[invoices-payment-table]')
    $tableContainer
      .querySelectorAll('[data-blur]')
      .forEach(el => el.addEventListener('blur', this.handleInputBlur))
  }

  renderTemplate() {
    const { pageTemplateConfig } = this.state
    pageTemplateConfig.forEach(({ hbsTemplateName, containerSelector }) => this.utils.renderTemplate({
      hbsTemplate: this.tpls[hbsTemplateName],
      containerSelector,
      templateConfig: {
        ...this.state,
      },
      insertType: 'beforeend',
    }))
  }
}
