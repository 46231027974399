var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p class=\"rfq-attachment-file-title\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.quote.detail.attachment.file.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":41},"end":{"line":3,"column":90}}}))
    + "</p>\n    <div class=\"rfq-attachment-file-container\">\n      <div class=\"rfq-attachment-file-warp\">\n        <p class=\"rfq-attachment-file-warp-title\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.quote.detail.attachment.file.salesRep",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":50},"end":{"line":6,"column":102}}}))
    + "</p>\n        <div class=\"rfq-attachment-file-item-list\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"backendAttachFiles") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n\n      <div class=\"rfq-attachment-file-warp\">\n        <p class=\"rfq-attachment-file-warp-title\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.quote.detail.attachment.file.customer",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":27,"column":50},"end":{"line":27,"column":102}}}))
    + "</p>\n        <div class=\"rfq-attachment-file-item-list\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"storefrontAttachFiles") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":29,"column":10},"end":{"line":43,"column":17}}})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"backendAttachFiles") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":19,"column":21}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"rfq-attachment-file-item\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isImage") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":15,"column":23}}})) != null ? stack1 : "")
    + "                <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileName") : depth0), depth0))
    + "</p>\n                <a href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"fileUrl") : depth0), depth0))
    + "\" target=\\\"_blank\\\"><i class=\"fa fa-download\"></i></a>\n              </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                  <i class=\"fa fa-file-image-o\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                  <i class=\"fa fa-file\"></i>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "            <p>"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.quote.detail.attachment.file.noAttachments",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":21,"column":15},"end":{"line":21,"column":72}}}))
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"storefrontAttachFiles") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":40,"column":21}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"rfq-checkout-btn-wrap\">\n      <input class=\"button button--primary form-prefixPostfix-button--postfix\" type=\"submit\"\n        value=\""
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.checkout.btn",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":52,"column":15},"end":{"line":52,"column":42}}}))
    + "\">\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"noprint "
    + container.escapeExpression(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqDetailsWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":20},"end":{"line":1,"column":48}}}))
    + "\">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isShowAttachment") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":47,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"shouldShowSubmitBtn") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":2},"end":{"line":54,"column":9}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});