import stencilUtils from '../../common/utils/stencilUtils'
import handleStencilSearchInfo from '../../common/utils/handleStencilSearchInfo'
import BasePage from '../../common/BasePage'
import quickOrderPad from './quickOrderPad.html'
import row from './row.html'
import parseCsv from './uploadDealcsv'
import triggerCartNumber from '../../common/api/triggerCartNumber'
import currencyCodeFormat from '../../hbs/helpers/currencyCodeFormat'
import listenOptionChange from '../shoppingList/listenOptionChange'
import searchResultContainerTemplate from '../shoppingList/searchResultContainer.html'
import searchResultItemTemplate from '../shoppingList/searchResultItem.html'

import {
  setRectangle,
  swatch,
  date,
  inputCheckbox,
  inputFile,
  inputNumbers,
  inputText,
  productList,
  textarea,
  setSelect,
  setRadio,
} from '../../components'

export default class QuickOrderPad extends BasePage {
  constructor() {
    super()
    this.name = 'QuickOrderPad'
    this.state = {
      drapSelector: 'drag_upload_csv',
      upLoadFileSelector: 'file_name',
      uploadSelectors: ['csv_check_info', 'csv_products_list', 'customer_sku_csv', 'file_name', 'csv_err_message'],
      addNewRowSelector: '#add_new_row',
      addTocartCsvSelector: 'add_to_cart_csv',
      addToCartSelector: '#add_to_cart',
      successNumber: 0,
      skuInputItemNumber: 4,
      shopingListItemId: '',
      editOptionsModal: new window.B3Modal.modal({
        stickyFooter: true,
        closeMethods: ['overlay', 'escape'],
        closeLabel: this.text['global.form.close'],
      }),
    }
    this.tpls = {
      quickOrderPad,
      row,
      setRectangle,
      swatch,
      date,
      inputCheckbox,
      inputFile,
      inputNumbers,
      inputText,
      productList,
      textarea,
      setSelect,
      setRadio,
      searchResultContainerTemplate,
      searchResultItemTemplate,
    }
    this.stencilUtils = stencilUtils
    this.parseCsv = parseCsv
    this.triggerCartNumber = triggerCartNumber
    this.currencyCodeFormat = currencyCodeFormat
    this.listenOptionChange = listenOptionChange
  }

  get isInpage() {
    return this.utils.leftIncludes(window.location.pathname, this.doms.quickOrderPad.url)
  }

  get optionTemplateMap() {
    return {
      'set-rectangle': this.tpls.setRectangle,
      swatch: this.tpls.swatch,
      date: this.tpls.date,
      'input-checkbox': this.tpls.inputCheckbox,
      'input-file': this.tpls.inputFile,
      'input-numbers': this.tpls.inputNumbers,
      'input-text': this.tpls.inputText,
      'product-list': this.tpls.productList,
      'set-radio': this.tpls.setRadio,
      'set-select': this.tpls.setSelect,
      textarea: this.tpls.textarea,
    }
  }

  async init() {
    const {
      B3RoleId,
      B3CompanyStatus: B3CompanyStatu,
    } = this.utils.B3Storage

    if (!this.isB2BUser) return

    if (!this.isInpage) return

    const {
      constants: {
        B3CompanyStatus: {
          APPROVED,
        },
      },
    } = this.utils
    if ((!B3RoleId.value || B3CompanyStatu.value !== APPROVED) && this.isInpage) {
      this.utils.Alert.error(this.locales.tips.cannotEnterPage)
      this.utils.urlHelper.redirect('/')
    }
    window.B3Spinner.show()

    this.render()
    this.handleEvents()
    this.dragToUploadCsv()
    this.listenOptionChange()
    window.B3Spinner.hide()
  }

  initSkuTable() {
    for (let index = 0; index < 5; index += 1) {
      this.renderSkuTemplate(index)
    }
  }

  renderSkuTemplate(index) {
    this.utils.renderTemplate({
      hbsTemplate: this.tpls.row,
      containerSelector: '.quick-order-pad-table-body',
      templateConfig: {
        itemIndex: index,
      },
      insertType: 'beforeend',
    })
  }

  bindSelectProduct() {
    const $allSelectEle = document.querySelectorAll('[data-results-select]')
    $allSelectEle.forEach($selectEle => {
      $selectEle.addEventListener('click', e => {
        const $tr = e.target.parentNode.parentNode.parentNode
        const $skuSearchResults = $tr.parentNode.parentNode.parentNode
        const $productInfo = $skuSearchResults.parentNode
        const $skuInput = $productInfo.parentNode.parentNode.querySelector('[data-sku]')
        const sku = $tr.getAttribute('data-product-base-sku')
        $skuInput.setAttribute('data-sku', sku)
        $skuInput.value = sku
        $skuSearchResults.innerHTML = ''
        this.clearPagination($skuSearchResults.parentNode.querySelector('.more-results'))
      })
    })
  }

  bindAddRow() {
    const skuInputItemNumber = document.querySelectorAll('.col-sku').length
    this.renderSkuTemplate(skuInputItemNumber)
    this.bindSingleSearchEvent()
  }

  handleEvents() {
    document.querySelector('body').addEventListener('click', e => {
      if (e.target.hasAttribute('data-remove-cell')) {
        e.target.parentNode.parentNode.remove()
      }

      if (e.target.id === this.state.addTocartCsvSelector) {
        const productsData = this.getCSVProductsData()
        if (!productsData) return
        document.querySelector('#csv_err_message').innerHTML = ''
        this.state.successNumber = 0
        this.addQuickOrderToCart(productsData)
      }
    })

    document.querySelector('body').addEventListener('change', e => {
      const item = e.target.parentNode.parentNode.parentNode
      const itemTr = e.target.parentNode.parentNode
      const Arry = [item, itemTr]

      Arry.forEach(el => {
        if (el.classList.contains('err-data')) {
          el.className = ''
          el.querySelector('.th-col-message').innerHTML = ''
        }
      })
    })

    document.querySelector('#drag_upload_csv').addEventListener('click', () => {
      document.querySelector('#customer_sku_csv').click()
    })

    document.querySelector('#customer_sku_csv').addEventListener('change', e => {
      const that = e.target
      if (that.files && that.files[0]) {
        const uploadFile = that.files[0]

        this.createNewReader(uploadFile)
      }
    })
  }

  async bindAddToCartSelector() {
    try {
      const $table = document.querySelector('#quick_order_pad_table')
      const skus = $table.querySelectorAll('[data-sku]')
      const variantSkus = this.getProductsData(skus)
      if (!variantSkus) return window.B3Spinner.hide()
      window.B3Spinner.show()
      const products = await this.api.getProductsBySkuQuickByPost({ variantSkus })
      const elements = document.querySelectorAll('#quick_order_pad_table>tbody>tr:not(.err-qty):not(.err-data):not(.err-sku)')
      this.fiterProductsBysku(products, skus)
      const productsMap = {}
      products.forEach(product => {
        const { variantSku } = product
        productsMap[variantSku.toUpperCase()] = product
      })

      const validatedElement = []
      for (let i = 0; i < elements.length; i += 1) {
        const $trItem = elements[i]
        const productId = $trItem.getAttribute('data-element-id')
        const quantity = $trItem.querySelector('[data-qty]').value
        const $input = $trItem.querySelector('.form-input')
        const sku = ($input.getAttribute('data-sku') || $input.value)?.trim().toUpperCase()
        if (!productId || !quantity) {
          continue
        }

        const currentTrItemInfo = productsMap[sku]
        const $errMessage = document.querySelector(`[data-element-id='${productId}'] .th-col-message`)

        if (+currentTrItemInfo.isStock === 1) {
          if (+currentTrItemInfo.stock === 0) {
            $errMessage.innerHTML = this.utils.text('qop.sku.stock', {
              hash: {
                sku: currentTrItemInfo.variantSku,
              },
            })
            continue
          } else if (quantity > currentTrItemInfo.stock) {
            $errMessage.innerHTML = this.utils.text('qop.sku.exceed.stock', {
              hash: {
                SKU: currentTrItemInfo.variantSku,
                stockNumber: currentTrItemInfo.stock,
              },
            })
            continue
          }
        }

        if (+currentTrItemInfo.purchasingDisabled === 1) {
          $errMessage.innerHTML = this.text['qop.sku.purchasingDisabled']
          continue
        }

        if (+currentTrItemInfo.isVisible === 0) {
          $errMessage.innerHTML = this.utils.text('qop.sku.invisible', {
            hash: {
              sku: currentTrItemInfo.variantSku,
            },
          })
          continue
        }

        if (((currentTrItemInfo.modifiers && currentTrItemInfo.modifiers.length) && currentTrItemInfo.modifiers.find(modifier => modifier.required)) || (currentTrItemInfo.categories && !(currentTrItemInfo.categories.length))) {
          $errMessage.innerHTML = this.utils.text('qop.sku.cannotAdd', {
            hash: {
              sku: currentTrItemInfo.variantSku,
            },
          })
          continue
        }

        if (+currentTrItemInfo.maxQuantity !== 0 && currentTrItemInfo.maxQuantity < quantity) {
          $errMessage.innerHTML = this.utils.text('qop.sku.maximum', {
            hash: {
              sku: currentTrItemInfo.variantSku,
              maxQuantity: currentTrItemInfo.maxQuantity,
            },
          })
          continue
        }

        if (+currentTrItemInfo.minQuantity !== 0 && currentTrItemInfo.minQuantity > quantity) {
          $errMessage.innerHTML = this.utils.text('qop.sku.minimum', {
            hash: {
              sku: currentTrItemInfo.variantSku,
              minQuantity: currentTrItemInfo.minQuantity,
            },
          })
          continue
        }

        validatedElement.push($trItem)
      }

      const { isEnabled } = await this.api.getAdvQtyState()
      if (isEnabled === '1') {
        const qtyProcuts = await this.api.getAdvQtyBySkusNew({ variantSkus })
        this.filterQty(qtyProcuts.productQuantityList, skus, products)
      } else {
        this.filterQty([], skus, products)
      }
      if (elements.length === 0) return window.B3Spinner.hide()
      this.addToCartCotent(validatedElement)
    } catch {
      window.B3Spinner.hide()
    } finally {
      window.B3Spinner.hide()
    }
  }

  searchProduct(productId) {
    return new Promise(resolve => {
      this.stencilUtils.api.product.getById(productId, {
        template: 'b3/b3json',
      }, (error, res) => {
        const $productId = document.querySelector(`.sku-product-options-${productId}`)
        if (!$productId) return false
        let optionsHTML = ''
        if (error) return
        const {
          product,
        } = JSON.parse(res)

        const options = product.options.map(option => {
          const values = option.values ? option.values.map(value => ({
            ...value,
            checked: false,
            selected: false,
          })) : []
          return {
            ...option,
            checked: false,
            values,
          }
        })
        optionsHTML = this.renderOptions(options)
        $productId.innerHTML = optionsHTML
        $productId.setAttribute('serch-product-id', productId)
        resolve()
      })
    })
  }

  renderOptions(options) {
    return options.reduce((html, currentOption) => {
      const uniqueId = new Date().getTime()
      currentOption.uniqueId = uniqueId
      currentOption.values?.forEach(value => {
        value.uniqueId = new Date().getTime()
      })
      html += this.optionTemplateMap[currentOption.partial](currentOption)
      return html
    }, '')
  }

  guid() {
    function S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return (`${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`)
  }

  async bindSingleSearch($td) {
    const $input = $td.querySelector('[data-sku]')
    const $results = $td.querySelector('.sku-search-results')
    const setNotFound = () => {
      $results.innerHTML = (`<div style="margin-bottom:1.5rem;">${this.text['shopping.list.products.notFound']}</div>`)
    }
    const searchQuery = $input.value
    if (searchQuery.length >= 2) {
      window.B3Spinner.show()
      this.handleInputSearch(() => {
        if (searchQuery.length < 2) return null
        $results.innerHTML = ''
        this.clearPagination($results.parentNode.querySelector('.more-results'))
        this.stencilUtils.api.search.search(searchQuery, {
          template: 'b3/b3json',
        }, async (err, response) => {
          window.B3Spinner.hide()
          if (err) return setNotFound()
          const newResponse = handleStencilSearchInfo(response)
          const {
            product_results: {
              products = [],
            },
          } = JSON.parse(newResponse)
          const allProductIds = []
          products.forEach(({
            id,
          }) => allProductIds.push(id))
          const { list } = await this.api.getInventory({
            products: allProductIds.map(productId => ({
              productId,
            })),
          })

          const productIds = list.filter(({ purchasingDisabled, modifiers }) => (!purchasingDisabled && !modifiers.length)).map(({ productId }) => productId)
          if (!productIds.length) return setNotFound()

          const limit = 3
          const totalPages = Math.ceil(productIds.length / limit)
          const currentPage = 1

          const searchProduct = productId => new Promise(resolve => {
            const resultContainer = this.tpls.searchResultContainerTemplate({
              productId,
            })

            $results.insertAdjacentHTML('beforeend', resultContainer)

            this.stencilUtils.api.product.getById(productId, {
              template: 'b3/b3json',
            }, (error, res) => {
              const $productId = $results.querySelector(`.product-${productId}`)
              $productId.classList.remove('loading-span')
              let optionsHTML = ''
              if (error) return
              const {
                product,
              } = JSON.parse(res)

              const options = product.options.map(option => {
                const values = option.values ? option.values.map(value => ({
                  ...value,
                  checked: false,
                  selected: false,
                })) : []
                return {
                  ...option,
                  checked: false,
                  values,
                }
              })
              optionsHTML = this.renderOptions(options)

              const resultHtml = this.tpls.searchResultItemTemplate({
                showCheckBox: false,
                showSelect: true,
                product: {
                  ...product,
                  ...this.utils.getCorrectProductImage(product, 'main_image'),
                  optionsHTML,
                },

              })
              $productId.innerHTML = resultHtml
              this.bindSelectProduct()
              resolve()
            })
          })

          const uuid = this.guid()
          $results.parentNode.querySelector('.more-results').setAttribute('id', `more-results-${uuid}`)
          const paginationClass = `#more-results-${uuid}`
          const showProduct = page => {
            $results.innerHTML = ''
            const $active = $td.querySelector(`${paginationClass} .pagination-item--current`)
            const $pageItems = $td.querySelectorAll(`${paginationClass} .pagination-item`)
            const $prev = $td.querySelector(`${paginationClass} .pagination-item--previous`)
            const $next = $td.querySelector(`${paginationClass} .pagination-item--next`)
            $active && $active.classList.remove('pagination-item--current')
            $pageItems.length && $pageItems[page].classList.add('pagination-item--current')
            $prev && ($prev.setAttribute('data-page', +page - 1), +page === 1 ? $prev.classList.add('disabled') : $prev.classList.remove('disabled'))
            $next && ($next.setAttribute('data-page', +page + 1), +page === totalPages ? $next.classList.add('disabled') : $next.classList.remove('disabled'))
            productIds
              .filter((id, i) => i >= (page - 1) * limit && i < page * limit)
              .map(item => searchProduct(item))

            this.setPagination($results)
          }

          showProduct(1)

          window.B3Paginator.init({
            container: paginationClass,
            currentPage,
            totalPages,
            onPageChange: showProduct,
          })
        })
      })
    } else if (searchQuery.length === 0) {
      $results.innerHTML = ''
    }
  }

  renderDefaultOptions(itemOptions) {
    try {
      const options = JSON.parse(itemOptions)
      const optionsMap = {}
      options.forEach(option => {
        const {
          option_value,
          option_id,
        } = option
        optionsMap[option_id] = option_value
      })

      // render input fields
      const $allInputEle = document.querySelectorAll('.option-form input')
      for (let i = 0; i < $allInputEle.length; i += 1) {
        if (!Object.keys(optionsMap).length) break

        const $formField = $allInputEle[i]
        const { name, value, type } = $formField
        const optionValue = optionsMap[name]
        if (!optionValue) continue

        if (type === 'text') {
          $formField.value = optionValue
          delete optionsMap[name]
          continue
        }

        if (+value === +optionValue) {
          $formField.checked = true
          delete optionsMap[name]
          continue
        }
      }

      // render select fields
      const $allSelectEle = document.querySelectorAll('.option-form select')
      for (let i = 0; i < $allSelectEle.length; i += 1) {
        if (!Object.keys(optionsMap).length) break

        const $formField = $allSelectEle[i]
        const { name } = $formField
        const optionValue = optionsMap[name]
        if (optionValue) {
          $formField.value = optionValue
          delete optionsMap[name]
        }
      }
    } catch {
      //
    }
  }

  selectedProductSku(e) {
    const { productSku, index } = e.target.dataset
    const currentSkuInput = document.querySelectorAll('.col-sku [data-sku]')[index]
    currentSkuInput.value = productSku
  }

  clearPagination($pageList) {
    if (!$pageList) return false
    $pageList.removeAttribute('id')
    $pageList.innerHTML = ''
  }

  setPagination($results) {
    $results.insertAdjacentHTML('afterbegin', ` <p class="close-content">
    <i class="fa fa-close" data-click="bindClearProducts"></i>
  </p>`)
  }

  bindClearProducts(e) {
    const $skuSearchResults = e.target.parentNode.parentNode
    $skuSearchResults.innerHTML = ''
    this.clearPagination($skuSearchResults.parentNode.querySelector('.more-results'))
  }

  addToCartCotent(elements) {
    if (elements.length === 0) {
      return
    }
    const itemArr = []

    elements.forEach(item => {
      const productObj = {}
      productObj.elementId = item.getAttribute('data-element-id')
      productObj.productId = item.getAttribute('data-product-id')
      productObj.quantity = item.querySelector('[data-qty]').value
      const optionList = JSON.parse(item.getAttribute('data-product-options') || '[]')

      if (optionList.length > 0) {
        productObj.optionList = optionList
      }

      if (productObj.elementId && productObj.productId && productObj.quantity) {
        itemArr.push(productObj)
      }
    })
    this.initAddData()
    if (itemArr.length === 0) return
    this.addQuickOrderToCart(itemArr, true)
  }

  initAddData = () => {
    this.state.successNumber = 0
    document.querySelector('.result-message').innerHTML = ''
  }

  filterQty(data, skus, products) {
    const { trim } = this.utils
    skus.forEach(item => {
      const value = trim(item.getAttribute('data-sku') || item.value).toUpperCase()
      const qtyItem = data.find(v => trim(v.variantSku).toUpperCase() === value)
      const $inputQty = item.parentNode.parentNode.parentNode.parentNode.querySelector('[data-qty]')
      const inputQtyValue = parseInt($inputQty.value, 10)
      if (value) {
        if (!inputQtyValue || !(/(^[1-9]\d*$)/.test($inputQty.value))) {
          this.addErro(item, this.text['qop.invalid.quantity'], 'err-qty err-data')
        }
        if (qtyItem) {
          const minQty = parseInt(qtyItem.minOrderQty, 10)
          if (minQty > inputQtyValue || !inputQtyValue) {
            this.addErro(item, this.utils.text('qop.min.quantity', {
              hash: {
                minQty,
              },
            }), 'err-qty err-data')
          }
        }
      }
      const itemSku = item.parentNode.parentNode.parentNode.parentNode.querySelector('.col-sku [data-sku]').value
      const product = products.find(v => trim(v.variantSku).toUpperCase() === itemSku.toUpperCase())
      if (product && product.isStock === '1' && product.stock === 0) {
        this.addErro(item, this.text['qop.out.off.stock'], 'err-qty err-data')
      }
    })
  }

  addErro(item, message, styleClass) {
    if (!item.classList.contains('err-data')) {
      item.parentNode.parentNode.parentNode.parentNode.className += ` ${styleClass}`
    }
    item.parentNode.parentNode.parentNode.parentNode.querySelector('.th-col-message').innerHTML = message
  }

  fiterProductsBysku(data, skus) {
    const { trim } = this.utils
    skus.forEach((item, index) => {
      const value = trim(item.getAttribute('data-sku') || item.value).toUpperCase()
      if (!(data.some(items => trim(items.variantSku.toUpperCase()) === value)) && value) {
        this.addErro(item, this.text['qop.invalid.sku'], 'err-sku err-data')
      } else if (data.some(items => trim(items.variantSku.toUpperCase()) === value)) {
        const itemData = data.filter(v => trim(v.variantSku.toUpperCase()) === value)[0]
        const $tr = item.parentNode.parentNode.parentNode.parentNode
        $tr.setAttribute('data-product-id', itemData.productId)
        $tr.setAttribute('data-element-id', `${itemData.productId}-${index}`)
        $tr.setAttribute('data-product-options', JSON.stringify(itemData.option))
      } else if (!value) {
        this.addErro(item, '', 'err-data')
      }
    })
  }

  handleContinueAddQuickOrderToCart = (itemArr, _default) => {
    itemArr.pop()
    if (itemArr.length > 0) {
      if (_default) {
        this.addQuickOrderToCart(itemArr, true)
      } else {
        this.addQuickOrderToCart(itemArr)
      }
    } else {
      if (this.state.successNumber === 0) return
      this.triggerCartNumber()
      this.utils.Alert.success(this.locales.tips.addProductsSuccess)
      window.B3Spinner.hide()
      if (_default) {
        document.querySelector('.result-message').innerHTML = this.utils.text('qop.success.add', {
          hash: {
            successNumber: this.state.successNumber,
          },
        })
      }
    }
  }

  addQuickOrderToCart(itemArr, _default) {
    window.B3Spinner.show()

    const item = itemArr[itemArr.length - 1]
    const formData = new FormData()

    let optionList

    if (_default) {
      optionList = item.optionList ? item.optionList : []
    } else {
      optionList = item.optionList ? item.optionList[0] : []
    }

    formData.append('action', 'add')
    formData.append('product_id', item.productId)
    let qty = item.quantity

    for (let i = 0; i < optionList.length; i += 1) {
      if (_default) {
        formData.append(`attribute[${optionList[i].optionId || optionList[i].option_id}]`, optionList[i].id)
      } else {
        formData.append(`attribute[${optionList[i].optionId || optionList[i].option_id}]`, optionList[i].option_value)
      }
    }

    if (item.minLimit && +item.minLimit > +item.quantity) {
      this.handleContinueAddQuickOrderToCart(itemArr, _default)
      return
    }
    if (item.maxLimit && +item.maxLimit < +item.quantity) {
      qty = +item.maxLimit
    }

    formData.append('qty[]', qty)

    this.stencilUtils.api.cart.itemAdd(formData, (err, response) => {
      if (err || response?.data?.error) {
        window.B3Spinner.hide()
        if (_default) {
          document.querySelector(`[data-element-id='${item.elementId}'] .th-col-message`).innerHTML = response?.data?.error || this.text['qop.out.off.stock']
        } else {
          const $errMessage = document.createElement('div')

          $errMessage.append(
            this.utils.text('qop.sku.error', {
              hash: {
                sku: item.sku || item.variantSku,
                errorInfo: response?.data?.error || item.sku || item.variantSku,
              },
            }),
          )
          document.querySelector('#csv_err_message').append($errMessage)
        }
      } else {
        this.state.successNumber += 1
        if (_default) document.querySelector(`[data-element-id='${item.elementId}']`).remove()
      }

      this.handleContinueAddQuickOrderToCart(itemArr, _default)
    })
  }

  getProductsData(skus) {
    const skuArry = []
    document.querySelector('.result-message').innerHTML = ''

    skus.forEach((item, index) => {
      const value = this.utils.trim(item.getAttribute('data-sku') || item.value)
      if (value) {
        item.setAttribute('data-id', `${value}-${index}`)
        skuArry.push(value.toUpperCase())
      }
    })
    const variantSkus = Array.from(new Set(skuArry))
    return variantSkus
  }

  getCSVProductsData() {
    const items = document.querySelectorAll('#quick_order_pad_table_csv tbody tr')
    const data = []
    if (items.length === 0) return ''
    items.forEach(item => {
      const maxLimit = item.getAttribute('data-max-limit')
      const minLimit = item.getAttribute('data-min-limit')
      const op = JSON.parse(item.getAttribute('data-product-options'))
      const options = []
      options.push((op).map(data => ({ option_id: data.optionId || data.option_id, option_value: data.id })))
      data.push({
        productId: item.getAttribute('data-product-id'),
        variantId: item.getAttribute('data-variant-id'),
        variantSku: item.getAttribute('data-product-sku'),
        quantity: item.getAttribute('data-qty'),
        optionList: options,
        maxLimit,
        minLimit,
      })
    })
    return data
  }

  render() {
    const {
      quickOrderPad: {
        container,
      },
    } = this.doms

    const b2bWraper = document.querySelector('.page-conten')
    if (b2bWraper) b2bWraper.remove()

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.quickOrderPad,
      containerSelector: container,
      templateConfig: {
        ...this.state,
      },
      insertType: 'beforeend',
    })
    this.initSkuTable()
    this.bindSingleSearchEvent()
  }

  bindSingleSearchEvent() {
    const skuSearchBottons = document.querySelectorAll('.sku-search-button')
    skuSearchBottons.forEach(($skuSearchBotton, index) => {
      const $td = $skuSearchBotton.parentNode.parentNode.parentNode
      const current = document.querySelectorAll('.quick-order-pad-table-body tr')[index]

      $skuSearchBotton.addEventListener('click', () => {
        current.querySelector('.th-col-message').innerHTML = ''
        this.bindSingleSearch($td)
      })
      const $input = $td.querySelector('[data-sku]')
      if ($input) {
        $input.addEventListener('keydown', e => {
          if (e.keyCode === 13) {
            current.querySelector('.th-col-message').innerHTML = ''
            this.bindSingleSearch($td)
          }

          // RESET ERROR INFO (B3OAS-390)
          if (current?.classList?.contains('err-data')) {
            current.className = ''
            current.querySelector('.th-col-message').innerHTML = ''
          }
        })
        $input.addEventListener('change', e => {
          $input.setAttribute('data-sku', e.target.value)
        })
      }
    })
  }

  dragToUploadCsv() {
    const dragArea = document.getElementById(this.state.drapSelector)
    dragArea.ondragenter = e => {
      const { target } = e
      e.preventDefault()
      target.style.borderColor = '#000'
    }

    dragArea.ondragover = e => {
      const { target } = e
      e.preventDefault()
      target.style.borderColor = '#000'
    }

    dragArea.ondragleave = e => {
      const { target } = e
      e.preventDefault()
      target.style.borderColor = 'transparent'
    }

    dragArea.ondrop = e => {
      const { target } = e
      e.preventDefault()
      target.style.borderColor = 'transparent'
      const uploadFile = e.dataTransfer.files[0]

      this.createNewReader(uploadFile)
    }
  }

  resetCsvFileUpload() {
    this.state.uploadSelectors.forEach(item => {
      document.getElementById(item).innerHTML = ''
    })
  }

  createNewReader(uploadFile) {
    // chech file extension
    const reg = new RegExp('[.](csv)$')
    if (!reg.test(uploadFile.name)) {
      document.querySelector('#csv_check_info  .checking-tips').remove()
      return this.utils.Alert.error(this.locales.validation.uploadNotCsv)
    }

    const reader = new FileReader()
    reader.addEventListener('load', b => {
      this.resetCsvFileUpload()
      document.getElementById(this.state.upLoadFileSelector).innerHTML = uploadFile.name
      const csvdata = b.target.result
      this.parseCsv.validation(csvdata)
    })

    return reader.readAsBinaryString(uploadFile)
  }
}
