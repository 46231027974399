import { limitConfig } from '../limitation/config'

const limitSocket = () => {
  const limitSocketCollection = limitConfig.map(v => v.name)
  return limitSocketCollection
}

const socketConfig = ['orders', ...limitSocket()]

export default socketConfig
