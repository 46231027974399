import swal from 'sweetalert2'
import BasePage from '../../common/BasePage'
import userManagement from './userManagement.html'
import listsHtml from './lists.html'
import editUserForm from './editUserForm.html'
import lang from '../../common/lang/en.json'
import nod from '../../lib/nod'
import * as utils from '../../common/utils'

export default class UserManagement extends BasePage {
  constructor() {
    super()
    this.name = 'UserManagement'
    this.state = {
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      userLists: [],
      userInfo: {},
      editUserSelector: '#edit-user-container',
      updateUserFormSelector: '#modal-user-management-edit-form form',
      createUserFormSelector: '#modal-user-management-new-form form',
      roleOptions: [
        { value: '0', title: this.text['global.user.admin.label'] },
        { value: '1', title: this.text['global.user.senior.label'] },
        { value: '2', title: this.text['global.user.junior.label'] },
      ],
      q: '',
      isIncludeExtraFields: 1,
      myB3ExtraField: new window.B3ExtraField(),
    }
    this.tpls = {
      userManagement,
      listsHtml,
      editUserForm,
    }
    this.nod = nod

    this.emailChange = false
    this.emailUserType = ''
    this.emailCompanyName = ''
    this.userEmail = ''
    this.emailAddChannel = false
  }

  get isInpage() {
    return this.utils.leftIncludes(window.location.pathname, this.doms.userManagement.url)
  }

  async init() {
    const {
      B3Storage,
      B3Storage: {
        B3RoleId,
        B3CompanyStatus: currentB3CompanyStatus,
      },
      constants: {
        B3CompanyStatus: {
          APPROVED,
        },
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils

    this.setState({
      userInfo: { ...B3Storage },
    })
    if (!this.isB2BUser) return

    if (!this.isInpage) return

    const forbiden = Boolean((!B3RoleId?.value || currentB3CompanyStatus?.value !== APPROVED || B3RoleId?.value === JUNIOR))

    if (forbiden && this.isInpage) {
      this.utils.Alert.error(this.locales.tips.cannotEnterPage)
      this.utils.urlHelper.redirect('/')
      return
    }

    this.bindEmailEvents()
    this.render()
    this.handleEvents()
    this.bindSearchEvents()
  }

  bindEmailEvents() {
    document.body.addEventListener('change', e => {
      if (e.target.id === 'email') {
        this.emailChange = true
      }
    })
  }

  handleEvents() {
    const {
      roleOptions,
      myB3ExtraField,
    } = this.state
    document.querySelector('body').addEventListener('click', async e => {
      if (e.target.hasAttribute('data-edit-user')) {
        document.querySelector(this.state.editUserSelector).innerHTML = ''
        const userInfo = {}
        userInfo.email = e.target.getAttribute('data-email') || ''
        userInfo.lastName = e.target.getAttribute('data-last-name') || ''
        userInfo.firstName = e.target.getAttribute('data-first-name') || ''
        userInfo.phone = e.target.getAttribute('data-phone') || ''
        userInfo.userId = e.target.getAttribute('data-user-id') || ''
        userInfo.role = e.target.getAttribute('data-role') || ''
        userInfo.extrafields = JSON.parse(e.target.getAttribute('data-extrafields') || '[]')
        const editFormSelector = '#modal-user-management-edit-form .form-fieldset .form-actions'
        this.handleRemoveB3UserExtraField('.B3ExtraField__field')

        this.initMyB3ExtraField(userInfo.extrafields, editFormSelector)
        this.utils.renderTemplate({
          hbsTemplate: this.tpls.editUserForm,
          containerSelector: this.state.editUserSelector,
          templateConfig: {
            ...userInfo,
            classes: this.classes,
            roleOptions: roleOptions.map(item => {
              let selected = ''
              if (item.value === userInfo.role) selected = 'selected'
              return {
                ...item,
                selected,
              }
            }),
            lang,
          },
          insertType: 'beforeend',
        })
        this.addVlidation(this.state.updateUserFormSelector)
      }

      if (e.target.hasAttribute('data-delete-user')) {
        const userId = e.target.getAttribute('data-user-id')
        swal({
          text: this.locales.tips.confirmDeleteUser,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.text['global.alert.buttonText.confirm'],
          cancelButtonText: this.text['global.alert.buttonText.cancel'],
          closeOnConfirm: false,
        }).then(async result => {
          if (!result.value) return
          window.B3Spinner.show()
          await this.api.deleteUser(userId)
          this.utils.Alert.success(this.locales.tips.deleteUser)
          await this.renderLists()
        })
      }

      if (e.target.id === 'update_user') {
        try {
          this.updateUserValidator.performCheck()
          const isExtraFieldValid = myB3ExtraField.validationFieldAll()

          if (!this.updateUserValidator.areAll('valid') || !isExtraFieldValid) return
          const userId = e.target.getAttribute('data-user-id')
          this.handleUser(this.state.updateUserFormSelector, '#update_user', false, userId)
        } catch {
          window.B3Spinner.hide()
        }
      }

      if (e.target.id === 'save_new_user') {
        try {
          if (this.getEmailValidateMessage().toString()) {
            return
          }
          this.createUserValidator.performCheck()
          const isExtraFieldValid = myB3ExtraField.validationFieldAll()

          const isUserExtraFieldValid = await this.validateUserExtraFields()
          if (!(this.createUserValidator.areAll('valid') && isExtraFieldValid && isUserExtraFieldValid)) return
          this.handleUser(this.state.createUserFormSelector, '#save_new_user', true)
        } catch {
          window.B3Spinner.hide()
        }
      }
    })
  }

  bindSearchEvents() {
    const $searchBtn = document.querySelector('#userManage-searchButton')
    const $searchInput = document.querySelector('#userManage-keyword')
    const $searchInputClear = document.querySelector('#userManage-searchClear')

    const searchCompanyUsers = async () => {
      const {
        pagination,
      } = this.state

      this.setState({
        pagination: {
          ...pagination,
          offset: 0,
        },
      })
      await this.renderLists()
    }

    $searchBtn.addEventListener('click', () => {
      searchCompanyUsers()
    })

    $searchInput.addEventListener('keyup', e => this.handleInputSearch(() => {
      this.setState({ q: e.target.value })

      if (e.target.value) {
        $searchInputClear.classList.remove('hide')
      } else {
        $searchInputClear.classList.add('hide')
      }
      if (e.keyCode === 13) {
        searchCompanyUsers()
      }
    }))

    $searchInputClear.addEventListener('click', () => {
      $searchInputClear.classList.add('hide')
      $searchInput.value = ''

      this.setState({ q: '' })

      searchCompanyUsers()
    })
  }

  async handleUser(selector, BtnSelector, create, userId) {
    const { myB3ExtraField } = this.state
    const userData = this.getUserData(selector)
    const extraFields = myB3ExtraField.getExtraFieldsValue()
    if (create && this.emailAddChannel) {
      userData.addChannel = true
    }

    if (extraFields && extraFields.length > 0) {
      userData.extraFields = extraFields
    }

    document.querySelector(BtnSelector).setAttribute('disabled', 'true')
    try {
      if (create) {
        await this.api.saveNewUser(userData)
      } else {
        await this.api.updateUserInfo(userId, userData)
      }
      document.querySelector(`${selector} .modal-close`).click()
      this.state.pagination.offset = 0
      await this.renderLists()
      this.clearEmailValidateData()
    } finally {
      document.querySelector(BtnSelector).removeAttribute('disabled')
    }
  }

  getUserData(selector) {
    return {
      companyId: this.state.userInfo.B3CompanyId?.value,
      firstName: document.querySelector(`${selector} #first_name`).value,
      lastName: document.querySelector(`${selector} #last_name`).value,
      email: document.querySelector(`${selector} #email`).value,
      phoneNumber: document.querySelector(`${selector} #phone`).value,
      role: document.querySelector(`${selector} #role_id`).value,
    }
  }

  addVlidation(selector, create) {
    const {
      utils: {
        re,
      },
    } = this
    const updateUserValidator = this.nod({
      button: `${selector} input[type="button"]`,
    })
    updateUserValidator.add([
      {
        selector: `${selector} input[name="first_name"]`,
        validate: (cb, val) => {
          const result = val.length
          cb(result)
        },
        errorMessage: this.locales.validation.fistNameIncorrect,
      }, {
        selector: `${selector} input[name="last_name"]`,
        validate: (cb, val) => {
          const result = val.length
          cb(result)
        },
        errorMessage: this.locales.validation.lastNameIncorrect,
      }, {
        selector: `${selector} input[name="email"]`,
        validate: (cb, val) => {
          cb(re.email.test(val))
        },
        errorMessage: this.locales.validation.emailIncorrect,
      }, {
        selector: `${selector} input[name="email"]`,
        validate: async (cb, val) => {
          if (re.email.test(val) && this.emailChange) {
            const newButton = document.querySelector(`${selector} #save_new_user`) || {}
            newButton.disabled = true
            let isValid = true
            try {
              isValid = await this.validateEmail(val, selector)
            } finally {
              cb(isValid)
              this.emailChange = false
              newButton.disabled = false
            }
          } else {
            cb(true)
          }
        },
        errorMessage: this.getEmailValidateMessage(),
      }, {
        selector: `${selector} input[name="phone"]`,
        validate: (cb, val) => {
          if (val === '') {
            cb(true)
          } else {
            cb(re.phone.test(val))
          }
        },
        errorMessage: this.locales.validation.phoneIncorrect,
      },
    ])

    if (create) {
      this.createUserValidator = updateUserValidator
      return
    }

    this.updateUserValidator = updateUserValidator
  }

  getEmailValidateMessage() {
    const message = {}
    message.toString = () => {
      if (this.emailUserType === 3) {
        return utils.text('validation.userEmailMultipleUsed')
      }
      if (this.emailUserType === 4) {
        return utils.text('validation.userEmailOtherUsed', {
          hash: {
            companyName: this.emailCompanyName,
          },
        })
      }
      if (this.emailUserType === 5) {
        return utils.text('validation.userEmailOwnUsed', {
          hash: {
            userEmail: this.userEmail,
          },
        })
      }
      if (this.emailUserType === 6) {
        return utils.text('validation.userEmailSuperAdminUsed')
      }
      return ''
    }

    return message
  }

  async validateEmail(email, selector) {
    const {
      userInfo: {
        firstName = '',
        lastName = '',
        phoneNumber = '',
        companyName = '',
      } = {},
      userType,
    } = await this.api.validateUserEmail({
      email,
      companyId: this.state.userInfo.B3CompanyId?.value,
    })

    this.emailUserType = userType
    this.emailCompanyName = companyName
    this.emailAddChannel = userType === 7
    this.userEmail = email

    if (userType === 2) {
      document.querySelector(`${selector} input[name="phone"]`).value = phoneNumber
      document.querySelector(`${selector} input[name="last_name"]`).value = lastName
      document.querySelector(`${selector} input[name="first_name"]`).value = firstName
    }

    return [1, 2, 7].includes(userType)
  }

  clearEmailValidateData() {
    this.emailChange = false
    this.emailUserType = ''
    this.emailCompanyName = ''
    this.userEmail = ''
    this.emailAddChannel = false
  }

  async render() {
    const {
      userManagement: {
        container,
      },
    } = this.doms
    const {
      B3Storage: {
        B3RoleId,
      },
      constants: {
        B3Role,
      },
    } = this.utils
    const showAddBtn = [B3Role.ADMIN, B3Role.SALESREP].includes(B3RoleId.value)
    this.utils.renderTemplate({
      hbsTemplate: this.tpls.userManagement,
      containerSelector: container,
      templateConfig: {
        ...this.state,
        showAddBtn,
        classes: this.classes,
        lang,
      },
      insertType: 'beforeend',
    })

    await this.renderLists()
    this.addVlidation(this.state.createUserFormSelector, true)
    this.initMobileTable()
  }

  initPagination() {
    const {
      pagination: {
        offset,
        limit,
        totalCount,
      },
    } = this.state

    const currentPage = Math.ceil((offset + 1) / limit)
    const totalPages = Math.ceil(totalCount / limit)
    window.B3Paginator.init({
      container: '#B3pagination',
      currentPage,
      totalPages,
      onPageChange: this.handlePaginationChange.bind(this),
    })
  }

  resetForm(e) {
    if (this.isOpenLimitErrorDialog && this.isOpenLimitErrorDialog()) {
      this.limitErrorDialog()
      e.preventDefault()
      e && e.stopPropagation ? e.stopPropagation() : e.cancelBubble = true
    }
    const inputs = ['#email', '#first_name', '#last_name', '#phone']
    const container = document.querySelector('#modal-user-management-new-form')

    container.querySelector('#role_id').options[0].selected = true
    inputs.forEach(selector => {
      const item = container.querySelector(selector)
      item.value = ''

      if (!item.nextElementSibling) return
      const siblingSpan = item.nextElementSibling
      siblingSpan.innerHTML = ''
      siblingSpan.className = ''
      siblingSpan.style.display = 'none'
      const inintSpan = document.createElement('span')
      inintSpan.style.display = 'none'
      item.parentNode.append(inintSpan)
      item.parentNode.classList.remove('form-field--error')
      item.parentNode.classList.remove('form-field--success')
    })

    const editFormSelector = '#modal-user-management-new-form .form-fieldset .form-actions'
    this.handleRemoveB3UserExtraField('.B3ExtraField__field')
    this.initMyB3ExtraField([], editFormSelector)
  }

  async renderLists() {
    window.B3Spinner.show()
    const {
      constants: {
        B3Role,
      },
    } = this.utils
    const { list, pagination } = await this.getUsers()
    const showAction = [B3Role.ADMIN, B3Role.SALESREP].includes(this.state.userInfo.B3RoleId.value)
    this.state.pagination = pagination

    this.state.userLists = list.map(item => {
      if (item.role === '0') {
        item.roleName = this.text['global.user.admin.label']
      } else if (item.role === '1') {
        item.roleName = this.text['global.user.senior.label']
      } else if (item.role === '2') {
        item.roleName = this.text['global.user.junior.label']
      }

      item.extraFieldsValue = JSON.stringify(item.extraFields)
      return item
    })

    document.querySelector('#table-container').innerHTML = ''

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.listsHtml,
      containerSelector: '#table-container',
      templateConfig: {
        ...this.state,
        classes: this.classes,
        lang,
        showAction,
      },
      insertType: 'beforeend',
    })

    this.initPagination()
    window.B3Spinner.hide()
  }

  async handlePaginationChange(page) {
    const { limit } = this.state.pagination
    this.state.pagination.offset = (page - 1) * limit
    await this.renderLists()
  }

  getUsers() {
    const { pagination, q, isIncludeExtraFields } = this.state

    return this.api.getCompanyUser(
      this.state.userInfo.B3CompanyId?.value, {
        ...pagination,
        q,
        role: ['0', '1', '2'],
        isIncludeExtraFields,
      },
    )
  }

  initMyB3ExtraField(extraFieldsValue, containerSelector) {
    const {
      myB3ExtraField,
    } = this.state

    const params = {
      moduleType: 'user',
      containerSelector,
    }

    if (extraFieldsValue && extraFieldsValue.length > 0) {
      params.extraFieldsValue = extraFieldsValue
    }

    myB3ExtraField.init(params)
  }

  async validateUserExtraFields() {
    const { myB3ExtraField } = this.state

    try {
      const extraFields = myB3ExtraField.getExtraFieldsValue()

      const storeHash = this.context.settings.store_hash
      const res = await this.api.validateStorefrontExtraFields({ extraFields, storeHash }, 'user')

      if (res.code === 200) {
        return true
      }
    } catch (error) {
      this.utils.Alert.error(error.message)

      if (error) return false
    }

    return true
  }

  handleRemoveB3UserExtraField(selector) {
    const B3ExtraFieldHtml = document.querySelectorAll(selector)
    if (B3ExtraFieldHtml.length > 0) {
      B3ExtraFieldHtml.forEach(item => {
        item.remove()
      })
    }
  }
}
