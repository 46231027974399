var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <small>"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":51}}}))
    + "</small>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":13,"column":22},"end":{"line":13,"column":54}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-field\" data-product-attribute=\"textarea\">\n    <label class=\"form-label form-label--alternate form-label--inlineSmall\"\n      for=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "attribute_textarea_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uniqueId") : depth0), depth0))
    + "\">\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + ":\n\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n\n    <textarea data-label-name=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + "\" class=\"form-input\"\n      id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "attribute_textarea_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uniqueId") : depth0), depth0))
    + "\" name=\"attribute["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "]\"\n      "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":61}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"prefill") : depth0), depth0))
    + "</textarea>\n</div>\n";
},"useData":true,"useDepths":true});