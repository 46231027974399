var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          <th class=\"t-align-c\">"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"user.thead.action",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":9,"column":32},"end":{"line":9,"column":60}}}))
    + "</th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr data-user='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"item") : depth0), depth0))
    + "'>\n          <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "</td>\n          <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</td>\n          <td>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"roleName") : depth0), depth0))
    + "</td>\n\n          <td class=\"actions-field t-align-c flex-center "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias3,"userManagement.td.actions.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":106}}}))
    + "\" >\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"showAction") : depths[1]),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":33,"column":21}}})) != null ? stack1 : "")
    + "          </td>\n\n        </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <a href=\"#\" data-reveal-id=\"modal-user-management-edit-form\" class=\"button button--primary button--small "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"userManagement.td.button.edit",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":22,"column":119},"end":{"line":22,"column":162}}}))
    + "\" data-edit-user\n              data-email="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\n              data-first-name="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"firstName") : depth0), depth0))
    + "\n              data-last-name="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"lastName") : depth0), depth0))
    + "\n              data-user-id="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\n              data-phone=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"phoneNumber") : depth0), depth0))
    + "\"\n              data-role="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"role") : depth0), depth0))
    + "\n              data-extraFields=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"extraFieldsValue") : depth0), depth0))
    + "\"\n              >"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"user.form.edit.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":30,"column":15},"end":{"line":30,"column":47}}}))
    + "</a>\n              <span class=\"actions-split\">|</span>\n              <a href=\"javascript:void(0);\" data-delete-user class=\"button button--small "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"userManagement.td.button.delete",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":32,"column":89},"end":{"line":32,"column":134}}}))
    + "\" data-user-id="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + ">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"user.form.delete.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":32,"column":156},"end":{"line":32,"column":190}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"table-wrap\">\n  <table class=\"responsive-table user-management-table table-active-user\" id=\"user_management_table\">\n      <thead>\n        <tr>\n          <th>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.name.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":47}}}))
    + "</th>\n          <th>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.email.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":14},"end":{"line":6,"column":48}}}))
    + "</th>\n          <th>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.role.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":47}}}))
    + "</th>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"showAction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":10,"column":17}}})) != null ? stack1 : "")
    + "      </tr>\n      </thead>\n    <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"userLists") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":37,"column":17}}})) != null ? stack1 : "")
    + "\n\n    </tbody>\n  </table>\n</div>\n\n<div class=\"table-toolbar\">\n  <div class=\"number-items "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"userManagement.unit.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":45,"column":27},"end":{"line":45,"column":70}}}))
    + "\">\n    <span class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"userManagement.unit.num",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":46,"column":17},"end":{"line":46,"column":54}}}))
    + "\" id=\"num_items\">"
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"userLists") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span> "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.toolbar.unit",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":46,"column":99},"end":{"line":46,"column":129}}}))
    + "\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});