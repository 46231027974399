var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"salerep-infobox "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"Login",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":28},"end":{"line":1,"column":47}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.head.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":48},"end":{"line":1,"column":86}}}))
    + "\">\n  <div class=\"container "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.head.info.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":67}}}))
    + "\"><span class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.head.info.left",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":82},"end":{"line":2,"column":120}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"dashboard.top.salesInfo.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":122},"end":{"line":2,"column":164}}}))
    + " "
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"companyName") : depth0), depth0))
    + "</span><span\n      class=\"button button--primary "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"dashboard.head.info.right",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":36},"end":{"line":3,"column":75}}}))
    + "\" style=\"margin: 0;\" end-masquerade>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.masquerade.end.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":111},"end":{"line":3,"column":155}}}))
    + "</span></div>\n</div>\n";
},"useData":true});