var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li data-list-id='"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "' class=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"className") : depth0), depth0))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"buyAgain.shoppingListModal.list.item",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":101}}}))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.header",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":72}}}))
    + "\">\n  <h2 class=\"modal-header-title "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.header.title",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":85}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.chooseModal.title.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":87},"end":{"line":2,"column":132}}}))
    + "</h2>\n  <a href=\"#\" class=\"modal-close "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.header.close",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":86}}}))
    + "\" aria-label=\"close\" role=\"button\">\n    &#215;\n  </a>\n</div>\n<div class=\"modal-body "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.body",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":68}}}))
    + "\">\n  <ul class=\"shopping-list-wraper "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.list.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":89}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.listWraper",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":8,"column":90},"end":{"line":8,"column":141}}}))
    + "\">\n"
    + ((stack1 = container.hooks.blockHelperMissing.call(depth0,container.lambda((depth0 != null ? lookupProperty(depth0,"list") : depth0), depth0),{"name":"list","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + "  </ul>\n  <ul class=\"pagination-list "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.pagination.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":13,"column":29},"end":{"line":13,"column":90}}}))
    + "\" id=\"shoppingList-pagination\"\n    style=\"border-bottom:1px solid #e5e5e5;padding-bottom: 20px;\"></ul>\n  <div class=\"button button--small button--primary "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.button.addToNew",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":15,"column":51},"end":{"line":15,"column":107}}}))
    + "\" id=\"add-new-shopping-list\" style=\"margin: 0;\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"shopping.list.create.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":15,"column":155},"end":{"line":15,"column":193}}}))
    + "\n  </div>\n  <div class=\"buy-again form-actions text-left "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.actions.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":17,"column":47},"end":{"line":17,"column":105}}}))
    + "\">\n    <input type=\"submit\" class=\"button button--primary "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.button.add",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":18,"column":55},"end":{"line":18,"column":106}}}))
    + "\" value=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.modal.add",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":18,"column":115},"end":{"line":18,"column":146}}}))
    + "\" id=\"add_to_shoppingList\">\n    <a href=\"#\" class=\"button  modal-close modal-close--button "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"buyAgain.shoppingListModal.button.cancel",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":19,"column":63},"end":{"line":19,"column":117}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"buyAgain.modal.cancel",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":19,"column":119},"end":{"line":19,"column":153}}}))
    + "</a>\n  </div>\n</div>\n";
},"useData":true});