import * as locales from '../../common/locales'
import * as utils from '../../common/utils'

export const modifierParentIdSeparate = '&pId'

/**
 * @param {array} originOptionList
 * @param {string} optionKey default: 'optionId'
 * @param {string} optionValue default: 'optionValue'
 * @returns {array}
 * @example
 *  input:
 *     [
 *       {optionId: 133, optionLabel: "Red", optionName: "Color", optionValue: "133", type: "set-rectangle"},
 *       {optionId: 133, optionLabel: "oct 12 2021", optionName: "Date", optionValue: 1636041600000, type: "date"},
 *     ]
 *
 *  output:
 *     [
 *       {option_id: "attribute[133]", option_value:  "133"},
 *       {option_id: "attribute[141][month]",option_value: "10"},
 *       {option_id: "attribute[141][day]",option_value: "28"},
 *       {option_id: "attribute[141][year]",option_value: "2021"}
 *     ]
 */
export const getFormTypeOptionList = (originOptionList, optionKey = 'optionId', optionVal = 'optionValue') => {
  if (!Array.isArray(originOptionList) || !originOptionList.length) return []

  const optionList = []
  originOptionList.forEach(({
    [optionKey]: option_id,
    [optionVal]: option_value,
  }) => {
    // handle date type
    const isDateType = typeof option_value === 'object' && option_value.year && option_value.month && option_value.day
    if (isDateType) {
      Object.keys(option_value).forEach(key => {
        optionList.push({
          option_id: `attribute[${option_id}][${key}]`,
          option_value: option_value[key],
        })
      })
    } else {
      optionList.push({
        option_id: `attribute[${option_id}]`,
        option_value,
      })
    }
  })
  return optionList
}

export const isModifierTextValid = (option, optionVal) => {
  let isOptionValid = true
  let errMsg = ''

  const { max_length, display_name } = option
  if (max_length) {
    if (optionVal.length > max_length) {
      isOptionValid = false
      errMsg = utils.text('validation.modifier.limitedTextMaxLength', {
        hash: {
          display_name,
          max_length,
        },
      })
    }
  }

  return {
    isOptionValid,
    errMsg,
  }
}

export const isModifierNumberTextValid = (option, optionVal) => {
  let isOptionValid = true
  let errMsg = ''
  const {
    lowest, highest, display_name, integer_only,
  } = option

  if (typeof +optionVal !== 'number') {
    isOptionValid = false
    errMsg = utils.text('validation.modifier.invalidNumber', {
      hash: {
        display_name,
      },
    })
    return {
      isOptionValid,
      errMsg,
    }
  }

  if (integer_only && optionVal.includes('.')) {
    isOptionValid = false
    errMsg = utils.text('validation.modifier.invalidIntegerNumber', {
      hash: {
        display_name,
      },
    })
    return {
      isOptionValid,
      errMsg,
    }
  }

  const { limit_input } = option
  if (!limit_input) return { isOptionValid }

  if (optionVal !== '' && !Number.isNaN(+optionVal)) {
    if (lowest && +optionVal < lowest) {
      isOptionValid = false
      errMsg = utils.text('validation.modifier.limitedNumberLowestValue', {
        hash: {
          display_name,
          lowest,
        },
      })
    }

    if (highest && +optionVal > highest) {
      isOptionValid = false
      errMsg = utils.text('validation.modifier.limitedNumberHighestValue', {
        hash: {
          display_name,
          highest,
        },
      })
    }
  }

  return {
    isOptionValid,
    errMsg,
  }
}

/**
 *
 * @param {array} bcOriginalOptions
 * @param {array} optionList
 * @returns {boolean}
 * @example
 *  bcOriginalOptions:
 *     [
 *       {id: 133, partial: "set-rectangle", display_name: "Size"},
 *       {id: 201, partial: "input-numbers", display_name: "Numbers only text field", highest: 100, lowest: 20},
 *     ]
 *
 *  optionList:
 *     [
 *       {option_id: "attribute[133]", option_value:  "133"},
 *       {option_id: "attribute[141][month]",option_value: "10"},
 *     ]
 */
export const isAllRequiredOptionFilled = (bcOriginalOptions, optionList) => {
  const requiredOptions = bcOriginalOptions.filter(({ required }) => !!required)

  const isRequiredValid = requiredOptions.every(({ id, noValue }) => {
    const { option_value } = optionList.find(({ option_id }) => option_id.includes(id)) ?? {}

    return option_value && +option_value !== +noValue
  })

  if (!isRequiredValid) {
    utils.Alert.error(locales.validation.incompleteOptions)
    return false
  }

  const VALIDATION_MAP = {
    textarea: isModifierTextValid,
    'input-text': isModifierTextValid,
    'input-numbers': isModifierNumberTextValid,
  }

  for (let i = 0; i < bcOriginalOptions.length; i += 1) {
    const option = bcOriginalOptions[i]
    const { partial, id } = option

    if (VALIDATION_MAP[partial]) {
      const { option_value } = optionList.find(({ option_id }) => option_id.includes(id)) ?? {}

      const { isOptionValid, errMsg } = VALIDATION_MAP[partial](option, option_value)
      if (!isOptionValid) {
        utils.Alert.error(errMsg)
        return false
      }
    }
  }

  return true
}

/**
 *
 * @param {ArrayLike} form
 * @param {Boolean} isBCForm BCForm in pdp & quickView page is a little different from our product form
 * @returns {Object} arr
 * Output: {
 *           action: "add"
 *           attribute[134]: "text field"
 *           attribute[141][day]: "27"
 *           product_id: "113"
 *           qty[]: "1"
 *         }
 */
export const serialize = (form, isBCForm) => {
  const arr = {}
  if (!form) return arr
  for (let i = 0; i < form.elements.length; i += 1) {
    const file = form.elements[i]
    switch (file.type) {
      case undefined:
      case 'button':
      case 'file':
      case 'reset':
      case 'submit':
        break
      case 'checkbox':
        if (isBCForm && file.checked) {
          arr[file.name] = file.value
          break
        }
      // eslint-disable-next-line no-fallthrough
      case 'radio':
        if (!file.checked) {
          break
        } else {
          if (arr[file.name]) {
            arr[file.name] = `${arr[file.name]},${file.value}`
          } else {
            arr[file.name] = file.value
          }
          break
        }
      default:
        if (arr[file.name]) {
          arr[file.name] = `${arr[file.name]},${file.value}`
        } else {
          arr[file.name] = file.value
        }
    }
  }
  return arr
}

/**
 *
 * @param {Object} optionMap
 * @returns {Array} optionList
 * @example
 *  optionMap:
 *     {
 *       action: "add"
 *       attribute[139]: "144"
 *       attribute[141][day]: "27"
 *       qty[]: "1"
 *     }
 *
 *  optionList:
 *     [
 *       {option_id: "attribute[139]", option_value:  "144"},
 *       {option_id: "attribute[141][month]",option_value: "10"},
 *     ]
 */
export const getProductOptionList = optionMap => {
  const optionList = []
  Object.keys(optionMap).forEach(item => {
    if (item.includes('attribute') && item.match(/\[([0-9]+)\]/g)) {
      optionList.push({
        option_id: item,
        option_value: optionMap[item],
      })
    }
  })
  return optionList
}
