import axios from 'axios'
import getCart from './getCart'
import triggerCartNumber from './triggerCartNumber'
import Alert from '../utils/Alert'
import * as locales from '../locales'
import b3request from './base'

import text from '../text'

const getOrderDetail = orderId => b3request.get(`/api/v2/orders/${orderId}/details`)

const changeCurrency = (url, currencyCode) => axios({
  url,
  contentType: 'application/json',
  method: 'POST',
  data: { currencyCode },
})

const throwQtyError = async (orderId, data) => {
  try {
    const orderDate = await getOrderDetail(orderId)
    const { products } = orderDate
    if (products) {
      const productInfo = []
      products.forEach(item => {
        if (+item.quantity === 0) {
          productInfo.push({
            name: item.name,
            sku: item.sku,
          })
        }
      })
      let message = `${text['order.reorder.qtyErrorTip']}: `
      if (productInfo) {
        productInfo.forEach(item => {
          message += `\n${item.name} (${item.sku})`
        })

        Alert.error(message)
      } else {
        Alert.error(data?.detail || data?.title || locales.tips.globalError)
      }
    }
  } catch (error) {
    console.error(error.message)
  }
}

export function addProductsToCart(url, data, orderId) {
  return axios.post(url, data).then(response => Promise.resolve(response)).catch(async err => {
    const {
      response: {
        status,
        data,
      },
    } = err
    if (status !== 200) {
      if (status === 422 && data?.detail.includes('quantity')) {
        throwQtyError(orderId, data)
      } else {
        Alert.error(data?.detail || data?.title || locales.tips.globalError)
      }
      window.B3Spinner.hide()
    }
    return Promise.reject(err)
  })
}

export default async function (data, orderId) {
  const res = await getCart()
  const isOriginCartEmpty = res.length === 0
  const url = res[0] ? `/api/storefront/carts/${res[0].id}/items` : '/api/storefront/carts'
  await addProductsToCart(url, data, orderId)

  try {
    if (isOriginCartEmpty) {
      const { active_currency_code: activeCurrencyCode } = window.jsContext.currency_selector
      await changeCurrency('/cart/change-currency', activeCurrencyCode)
    }
  } catch (error) {
    console.error(error.message)
  }

  // BB2BV30-1393
  try {
    if (isOriginCartEmpty) {
      const cart = await getCart()

      const isCartEmpty = cart.length === 0

      if (!isCartEmpty) {
        const { lineItems, id: cartId } = cart[0]
        const { physicalItems } = lineItems || {}

        const pickListItemList = []
        const itemMap = {}
        physicalItems.forEach(({
          id, parentId, productId, variantId, sku,
        }) => {
          const key = `${sku}${productId}${variantId}`
          if (parentId) {
            pickListItemList.push(key)
          } else {
            itemMap[key] = id
          }
        })

        // remove item which are added twice
        for (const item of pickListItemList) {
          if (itemMap[item]) {
            await axios.delete(`/api/storefront/carts/${cartId}/items/${itemMap[item]}`)
          }
        }

        triggerCartNumber()
      }
    }
  } catch (error) {
    console.error(error.message)
  }

  triggerCartNumber()
}
