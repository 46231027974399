import { B3LimitSocket, isEnabledLimitSocket } from '../limitation'
import env from './env'

export default class B3Socket {
  constructor(instance) {
    this.name = instance.name
    this.api = instance.api
    this.socketId = ''
    this.wsData = null
    this.ws = null
    this.limitation = {}
  }

  async init(instance) {
    const { B3B2BSocktId } = instance.utils.B3Storage
    this.socketId = B3B2BSocktId.value

    const limitInstance = new B3LimitSocket(this.name)

    this.limitation.isOpenLimitErrorDialog = limitInstance.isOpenLimitErrorDialog

    const connectSocket = () => new Promise((resolve, reject) => {
        if (this.socketId) {
          const url = `${env.socketUrl}/${this.socketId}/?storefront=1`
          try {
            const ws = window.WebSocket
            ? new window.WebSocket(url)
            : new window.MozWebSocket(url)

            this.ws = ws
            this.closeWs()
            ws.onopen = () => { }
            ws.onerror = () => { }

            ws.onmessage = evt => {
              const {
                data = {},
                code,
              } = JSON.parse(evt.data)

              if (code === 200 && JSON.stringify(data) === '{}') {
                resolve()
              }

              this.wsData = data

              if (isEnabledLimitSocket(this.name)) limitInstance.init(data)
            }
          } catch (error) {
            reject(error)
          }
        }
      })

    await connectSocket()
  }

  closeWs() {
    window.onbeforeunload = () => {
      this.ws.close()
    }
  }
}
