import text from '../../text'

export const INVOICE_STATUS_MAP = {
  0: text['invoices.status.open'],
  1: text['invoices.status.partialPaid'],
  2: text['invoices.status.paid'],
}

export const INVOICE_SOURCE_MAP = {
  0: text['invoices.source.bc'],
  1: text['invoices.source.erp'],
}

export const FILTER_FIELDS = [
  {
    label: 'invoices.filters.dateCreatedBeginAt',
    value: 'beginDateAt',
    type: 'date',
    pairfields: 'beginDateAt|endDateAt',
    inputText: 'invoices.filters.dateCreatedBeginAt.text',
  },
  {
    label: 'invoices.filters.dateCreatedEndAt',
    value: 'endDateAt',
    type: 'date',
    pairfields: 'beginDateAt|endDateAt',
  },
]

export const INVOICE_SEARCH_FIELD = [
  {
    label: 'invoices.search.selectOptions.invoiceNum',
    type: 'invoiceNumber',
  },
  {
    label: 'invoices.search.selectOptions.salesOrderNum',
    type: 'orderNumber',
  },
]
