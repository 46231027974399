import stencilUtils from '../../common/utils/stencilUtils'
import Alert from '../../common/utils/Alert'
import * as api from '../../common/api'
import * as utils from '../../common/utils'
import {
  tips,
} from '../../common/locales'
import text from '../../text'
import { modifierParentIdSeparate, serialize } from '../../components'

const callback = e => {
  const $btnOptionUpdate = document.querySelector('#btn_option_update')
  $btnOptionUpdate?.setAttribute('disabled', 'true')
  const { target } = e
  window.target = target

  let frage = ''

  // original version
  // const searchTable = target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode

  // optimize
  const parentId = target.id.includes(modifierParentIdSeparate) ? target.id.split(modifierParentIdSeparate).pop() : null
  const searchTable = parentId ? document.querySelector(`.search-product-table [data-product-id="${parentId}"]`) : target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode

  const form = searchTable.querySelector('[data-option-form]')
  const productId = searchTable.querySelector('[name="product_id"]').value
  const optionChangeData = serialize(form)
  const skuConatainer = searchTable.querySelector('[data-product-sku]')
  const tr = parentId ? searchTable : searchTable.querySelector('tr') // original version
  Object.keys(optionChangeData).forEach(key => {
    frage += `${encodeURIComponent(key)}=${encodeURIComponent(optionChangeData[key])}&`
  })

  window.B3Spinner.show()
  stencilUtils.api.productAttributes.optionChange(productId, frage, async (err, result) => {
    try {
      $btnOptionUpdate?.removeAttribute('disabled')
      if (err) {
        window.B3Spinner.hide()
        return Alert.error(err)
      }
      const { data = {} } = result
      const variantId = data.v3_variant_id
      if (!variantId) {
        const item = tr.querySelector('input[type=checkbox]')
        const selectedItem = tr.querySelector('[data-results-select]')
        if (selectedItem) selectedItem.setAttribute('disabled', 'true')
        if (item) {
          item.setAttribute('disabled', 'true')
          item.checked = false
        }

        window.B3Spinner.hide()
        return false
      }

      const priceB2b = data.price.with_tax ? data.price.with_tax.formatted : data.price.without_tax.formatted
      const priceValue = data.price.with_tax ? data.price.with_tax.value : data.price.without_tax.value
      const priceContainer = document.querySelector('[product-search-result-table] .product-options .product-price')

      priceContainer.innerHTML = priceB2b
      searchTable.querySelector('[data-product-price-value]').setAttribute('data-product-price-value', priceValue)
      if (data.sku) {
        skuConatainer.innerHTML = `<b>${text['shopping.list.td.sku.label']}</b>${data.sku}`
        searchTable.querySelector('[data-product-base-sku]')?.setAttribute('data-product-base-sku', data.sku)
      }
      // page right option change

      if (variantId) {
        tr.setAttribute('data-variant-id', variantId)

        const products = [{
          productId,
          variantId,
        }]
        const { list } = await api.getInventory({ products })
        $btnOptionUpdate?.removeAttribute('disabled')
        if (list.purchasingDisabled) {
          utils.Alert.error(tips.buyAgainFailedNoLongerForSale)
        }
      }

      tr.querySelector('input[type=checkbox]')?.removeAttribute('disabled')
      tr.querySelector('[data-results-select]')?.removeAttribute('disabled')
      window.B3Spinner.hide()
      $btnOptionUpdate?.removeAttribute('disabled')
      return true
    } catch (error) {
      window.B3Spinner.hide()
    }
  })
}

export default function () {
  stencilUtils.hooks.on('product-option-change', callback)
}

export const offOptionChangeHook = () => {
  stencilUtils.hooks.off('product-option-change', callback)
}
