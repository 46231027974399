var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\""
    + alias1(__default(require("../../hbs/helpers/classes.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.header.icon.wrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":6,"column":18},"end":{"line":6,"column":52}}}))
    + "\">\n        <button>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"quoteStatus") : depth0), depth0))
    + "</button>\n      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"rfq-header-icon-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfq.header.icon.wrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":25,"column":39},"end":{"line":25,"column":73}}}))
    + "\">\n        <a class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"quote.button.copyQuoteLink",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":58}}}))
    + " copy_quote_link\" data-clipboard-text="
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"clipboardTextUrl") : depth0), depth0))
    + ">\n          <i class=\"fa fa-clipboard "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"quote.button.copyQuoteLink.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":27,"column":36},"end":{"line":27,"column":81}}}))
    + "\" data-click=\"copyQuoteLink\"></i>\n          <span data-click=\"copyQuoteLink\"> "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.header.linkCopy",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":28,"column":44},"end":{"line":28,"column":74}}}))
    + "</span>\n        </a>\n        <span class=\"rfq-copied-tips\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.header.copied",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":30,"column":38},"end":{"line":30,"column":66}}}))
    + " <i class=\"fa fa-check\"></i></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfqDetailsWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":12},"end":{"line":1,"column":40}}}))
    + "\">\n  <div class=\"rfq-header-icons noprint\">\n    <div class=\"rfq-header-icon-left "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfq.header.icon.left",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":37},"end":{"line":3,"column":71}}}))
    + "\">\n      <span class=\"rfq-quote-number\">"
    + alias2(container.lambda((depth0 != null ? lookupProperty(depth0,"quoteNumber") : depth0), depth0))
    + "</span>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"showStatusBtn") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":6},"end":{"line":9,"column":13}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"rfq-header-icon\">\n      <div class=\"rfq-header-icon-wrap rfq-header-icon-print "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfq.header.icon.wrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":12,"column":61},"end":{"line":12,"column":95}}}))
    + "\">\n        <a class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"quote.button.print",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":13,"column":18},"end":{"line":13,"column":50}}}))
    + " print_pdf\" data-click=\"printPDF\">\n          <i class=\"fa fa-print "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"quote.button.print.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":69}}}))
    + "\" data-click=\"printPDF\"></i>\n          <span data-click=\"printPDF\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.header.print",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":15,"column":38},"end":{"line":15,"column":65}}}))
    + "</span>\n        </a>\n      </div>\n      <div class=\"rfq-header-icon-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"rfq.header.icon.wrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":18,"column":39},"end":{"line":18,"column":73}}}))
    + "\">\n        <a class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"quote.button.pdf",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":19,"column":18},"end":{"line":19,"column":48}}}))
    + " export_pdf\" data-click=\"exportPDF\">\n          <i class=\"fa fa-file-powerpoint-o "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"quote.button.pdf.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":20,"column":44},"end":{"line":20,"column":79}}}))
    + " export_pdf\" data-click=\"exportPDF\"></i>\n          <span data-click=\"exportPDF\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"rfq.header.pdfdownload",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":21,"column":39},"end":{"line":21,"column":72}}}))
    + "</span>\n        </a>\n      </div>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"showCopyBtn") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":6},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "      </div>\n\n    </div>\n  </div>\n</div>";
},"useData":true});