var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"b2b-wrap "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"b2bWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":21},"end":{"line":1,"column":42}}}))
    + " "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceReceipt",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":71}}}))
    + "\">\n  <h1 class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceReceipt.title",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":13},"end":{"line":2,"column":47}}}))
    + "\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":49},"end":{"line":2,"column":80}}}))
    + "</h1>\n  <h2 class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceReceipt.payment.overview",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":58}}}))
    + "\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.overview",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":60},"end":{"line":3,"column":102}}}))
    + "</h2>\n  <div class=\"receipt-payment "
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoiceReceipt.payment.detail",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":30},"end":{"line":4,"column":73}}}))
    + "\">\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.id",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":11},"end":{"line":6,"column":47}}}))
    + "</div>\n      <div>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"paymentId") : depth0), depth0))
    + "</div>\n    </div>\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.receiveDate",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":11},"end":{"line":10,"column":56}}}))
    + "</div>\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/displayFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"createdAt") : depth0),{"name":"displayFormat","hash":{},"data":data,"loc":{"start":{"line":11,"column":11},"end":{"line":11,"column":38}}}))
    + "</div>\n    </div>\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.transactionType",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":14,"column":11},"end":{"line":14,"column":60}}}))
    + "</div>\n      <div>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"transactionType") : depth0), depth0))
    + "</div>\n    </div>\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.type",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":18,"column":11},"end":{"line":18,"column":49}}}))
    + "</div>\n      <div>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"paymentType") : depth0), depth0))
    + "</div>\n    </div>\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.total",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":22,"column":11},"end":{"line":22,"column":50}}}))
    + "</div>\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/currencyCodeFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"currencyCodeFormat","hash":{},"data":data,"loc":{"start":{"line":23,"column":11},"end":{"line":23,"column":39}}}))
    + "</div>\n    </div>\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.reference",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":26,"column":11},"end":{"line":26,"column":54}}}))
    + "</div>\n      <div>"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"referenceNumber") : depth0), depth0))
    + "</div>\n    </div>\n    <div class=\"payment-item\">\n      <div>"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoiceReceipt.payment.memo",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":30,"column":11},"end":{"line":30,"column":49}}}))
    + "</div>\n      <div>"
    + alias2(alias3(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"details") : depth0)) != null ? lookupProperty(stack1,"paymentDetails") : stack1)) != null ? lookupProperty(stack1,"memo") : stack1), depth0))
    + "</div>\n    </div>\n  </div>\n\n  <!-- receiptLines -->\n  <div class=\"invoice-receipt-lines\"></div>\n</div>\n";
},"useData":true});