var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"b2b-loading-overlay\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":37},"end":{"line":1,"column":45}}}) : helper)))
    + "\" style=\"\n    position: fixed;\n    background-color: rgba(0, 0, 0, 0.46);\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 999;\n    display: none;\n    transition: all .2s linear;\">\n  <img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"loadingSVG") || (depth0 != null ? lookupProperty(depth0,"loadingSVG") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"loadingSVG","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":28}}}) : helper)))
    + "\" style=\"position: absolute;\n            top: 50%;\n            left: 50%;\n            width: 100px;\n            height: 100px;\n            margin-top: -50px;\n            margin-left: -50px;\" />\n</div>\n";
},"useData":true});