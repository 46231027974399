var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"company-form-wrap2 "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"tpaWarp",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":31},"end":{"line":2,"column":52}}}))
    + "\">\r\n  <form action=\"\" data-create-company-form method=\"post\" class=\"form\">\r\n    <input type=\"hidden\" name=\"company_id\" id=\"company_id\" value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"companyId") : depth0), depth0))
    + "\">\r\n    <input type=\"hidden\" name=\"company_customer_role\" id=\"company_customer_role\" value=\"\">\r\n    <input type=\"hidden\" name=\"company_customer_id\" id=\"company_customer_id\" value=\""
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">\r\n\r\n    <div class=\"user-information\" id=\"tpa-user-information\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":60},"end":{"line":8,"column":100}}})) != null ? stack1 : "")
    + ">\r\n      <h6 class=\"b2b-title\" style=\"font-weight: 700;\">\r\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"tpa.form.company.admin.account.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":55}}}))
    + "\r\n      </h6>\r\n      <div>\r\n        <div class=\"form-row form-row--half\">\r\n          <div class=\"form-field\">\r\n            <label class=\"form-label\">\r\n              "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.firstName.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":16,"column":14},"end":{"line":16,"column":52}}}))
    + "\r\n            </label>\r\n            <input class=\"form-input\" type=\"text\" id=\"user-fist-name\" disabled value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"userFirstName") : depth0), depth0))
    + "\">\r\n          </div>\r\n          <div class=\"form-field\">\r\n            <label class=\"form-label\">\r\n              "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.lastName.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":51}}}))
    + "\r\n            </label>\r\n            <input class=\"form-input\" type=\"text\" id=\"user-last-name\" disabled value="
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"userLastName") : depth0), depth0))
    + ">\r\n          </div>\r\n\r\n        </div>\r\n        <div class=\"form-field\">\r\n          <label class=\"form-label\">\r\n           "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.companyEmail.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":30,"column":11},"end":{"line":30,"column":52}}}))
    + "\r\n          </label>\r\n          <input class=\"form-input\" type=\"text\" disabled value="
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"email") : stack1), depth0))
    + ">\r\n        </div>\r\n        <div class=\"form-field\">\r\n          <label class=\"form-label\">\r\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.phone.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":36,"column":46}}}))
    + "\r\n          </label>\r\n          <input class=\"form-input\" type=\"text\" disabled value="
    + alias2(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"customer") : depth0)) != null ? lookupProperty(stack1,"phone") : stack1), depth0))
    + ">\r\n        </div>\r\n      </div>\r\n    </div>\r\n\r\n    <!-- <p>Please fill out the following company contact information</p>\r\n        <p class=\"span-required\">*"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":44,"column":34},"end":{"line":44,"column":66}}}))
    + " Fields</p> -->\r\n    <h6 class=\"b2b-title\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":26},"end":{"line":45,"column":66}}})) != null ? stack1 : "")
    + " style=\"font-weight: 700;\">\r\n      "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"tpa.form.company.info.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":46,"column":6},"end":{"line":46,"column":44}}}))
    + "\r\n    </h6>\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_name\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"tpa.form.company.name.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":49,"column":51},"end":{"line":49,"column":89}}}))
    + "\r\n        <small>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":50,"column":15},"end":{"line":50,"column":47}}}))
    + "</small>\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_name\" name=\"company_name\">\r\n    </div>\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":28},"end":{"line":54,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_user_email\">\r\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.companyEmail.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":56,"column":8},"end":{"line":56,"column":51}}}))
    + "\r\n        <small>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":57,"column":15},"end":{"line":57,"column":47}}}))
    + "</small>\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_user_email\" name=\"company_user_email\">\r\n    </div>\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":61,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_user_phone\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.phone.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":62,"column":57},"end":{"line":62,"column":91}}}))
    + "\r\n        <small>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":63,"column":15},"end":{"line":63,"column":47}}}))
    + "</small>\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_user_phone\" name=\"company_user_phone\">\r\n    </div>\r\n\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":28},"end":{"line":68,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_address_street\">\r\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.address1.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":70,"column":8},"end":{"line":70,"column":47}}}))
    + "\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_address_street\" name=\"company_address_street\">\r\n    </div>\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":28},"end":{"line":74,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_address_street2\">\r\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.address2.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":76,"column":8},"end":{"line":76,"column":45}}}))
    + "\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_address_street2\" name=\"company_address_street2\">\r\n    </div>\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":28},"end":{"line":80,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_address_country\">\r\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.country.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":82,"column":44}}}))
    + "\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_address_country\" name=\"company_address_country\">\r\n    </div>\r\n    <div class=\"form-field\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":28},"end":{"line":86,"column":68}}})) != null ? stack1 : "")
    + ">\r\n      <label class=\"form-label\" for=\"company_address_city\">\r\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.city.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":88,"column":8},"end":{"line":88,"column":41}}}))
    + "\r\n      </label>\r\n      <input class=\"form-input\" type=\"text\" id=\"company_address_city\" name=\"company_address_city\">\r\n    </div>\r\n    <div class=\"form-row form-row--half\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":92,"column":41},"end":{"line":92,"column":81}}})) != null ? stack1 : "")
    + ">\r\n\r\n      <div class=\"form-field\">\r\n        <label class=\"form-label\" for=\"company_address_state\">\r\n          "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.state.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":96,"column":10},"end":{"line":96,"column":44}}}))
    + "\r\n        </label>\r\n        <input class=\"form-input\" type=\"text\" id=\"company_address_state\" name=\"company_address_state\">\r\n      </div>\r\n      <div class=\"form-field\">\r\n        <label class=\"form-label\" for=\"company_address_zip\">\r\n          "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.user.zipcode.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":102,"column":10},"end":{"line":102,"column":46}}}))
    + "\r\n        </label>\r\n        <input class=\"form-input\" type=\"text\" id=\"company_address_zip\" name=\"company_address_zip\">\r\n      </div>\r\n\r\n    </div>\r\n\r\n    <div class=\"form-actions\" style=\"text-align: left;\">\r\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2CUser") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":110,"column":6},"end":{"line":112,"column":13}}})) != null ? stack1 : "")
    + "      <input type=\"submit\" class=\"button button--primary\" "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":113,"column":58},"end":{"line":113,"column":113}}})) != null ? stack1 : "")
    + "\r\n        value=\""
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":114,"column":15},"end":{"line":114,"column":110}}})) != null ? stack1 : "")
    + "\" id='"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"tpaSubmitBtnId") : depth0), depth0))
    + "' />\r\n    </div>\r\n  </form>\r\n</div>\r\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\" ";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"attach-file-container\"></div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "data-submit-type='resubmit' ";
},"8":function(container,depth0,helpers,partials,data) {
    return "";
},"10":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpa.form.resubmit.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":114,"column":27},"end":{"line":114,"column":62}}}));
},"12":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpa.form.submit.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":114,"column":70},"end":{"line":114,"column":103}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isVisible") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":118,"column":7}}})) != null ? stack1 : "");
},"useData":true});