var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <small>"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":51}}}))
    + "</small>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":54}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"createQuote.options.currently.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":54}}}))
    + " <a href=\\\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"file_url") : depth0), depth0))
    + "\\\" target=\\\"_blank\\\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"original_name") : depth0), depth0))
    + "</a>\n        <label>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"createQuote.options.remove.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":15,"column":15},"end":{"line":15,"column":58}}}))
    + "<input type=\"checkbox\" name=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"file_remove") : depth0), depth0))
    + "\"></label>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"createQuote.options.maximum.fileSize",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":100}}}))
    + " <strong>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"file_size") : depth0), depth0))
    + "</strong>, ";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias1(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"createQuote.options.fileType",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":17,"column":158},"end":{"line":17,"column":197}}}))
    + " <strong>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"file_types") : depth0), depth0))
    + "</strong>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-field\" data-product-attribute=\"input-file\">\n    <label class=\"form-label form-label--alternate form-label--inlineSmall\"\n      for=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "attribute_file_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uniqueId") : depth0), depth0))
    + "\">\n        "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + ":\n\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n\n    <input class=\"form-file\" type=\"file\" id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "attribute_file_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uniqueId") : depth0), depth0))
    + "\" name=\"attribute["
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "]\"\n      "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":12,"column":61}}})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"original_name") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "    <p class=\"form-fileDescription\">"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"file_size") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":36},"end":{"line":17,"column":140}}})) != null ? stack1 : "")
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"file_types") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":140},"end":{"line":17,"column":236}}})) != null ? stack1 : "")
    + "</p>\n\n</div>\n";
},"useData":true,"useDepths":true});