import {
  setRectangle,
  swatch,
  date,
  inputCheckbox,
  inputFile,
  inputNumbers,
  inputText,
  productList,
  textarea,
  setSelect,
  setRadio,
  serialize,
} from './index'
import * as utils from '../common/utils'
import stencilUtils from '../common/utils/stencilUtils'

class B3Modifiers {
  constructor(options) {
    this.options = {
      modifiersContainer: '.edit-product-options-container',
      modifiersFormContainer: '[data-option-form]',
      productIdContainer: '[name="product_id"]',
      skuContainer: '.edit-product-sku',
      priceContainer: '.edit-product-price',
      cbBefore: null,
      cb: null,
      ...options,
    }

    this.tpls = {
      setRectangle,
      swatch,
      date,
      inputCheckbox,
      inputFile,
      inputNumbers,
      inputText,
      productList,
      textarea,
      setSelect,
      setRadio,
    }
    this.serialize = serialize
    this.utils = utils || {}
    this.stencilUtils = stencilUtils
  }

  get optionTemplateMap() {
    return {
      'set-rectangle': this.tpls.setRectangle,
      swatch: this.tpls.swatch,
      date: this.tpls.date,
      'input-checkbox': this.tpls.inputCheckbox,
      'input-file': this.tpls.inputFile,
      'input-numbers': this.tpls.inputNumbers,
      'input-text': this.tpls.inputText,
      'product-list': this.tpls.productList,
      'set-radio': this.tpls.setRadio,
      'set-select': this.tpls.setSelect,
      textarea: this.tpls.textarea,
    }
  }

  renderOptions(options) {
    return options.reduce((html, currentOption) => {
      const uniqueId = new Date().getTime()
      currentOption.uniqueId = uniqueId
      currentOption.values?.forEach(value => {
        value.uniqueId = new Date().getTime()
      })
      html += this.optionTemplateMap[currentOption.partial](currentOption)
      return html
    }, '')
  }

  render(containerSelector, insertType, options) {
    const template = this.renderOptions(options)

    const {
      utils: {
        renderTemplate,
      },
    } = this

    renderTemplate({
      containerSelector,
      insertType: insertType || 'beforeend',
      template,
    })
  }

  listenOptionChange(type = 'on') {
    const optionChangeCallback = (e = {}) => {
      window.target = e.target

      let fragment = ''
      const {
        modifiersContainer,
        modifiersFormContainer,
        productIdContainer,
        skuContainer,
        priceContainer,
        cbBefore,
        cb,
      } = this.options
      cbBefore && cbBefore()

      const $modifiersContainer = document.querySelector(modifiersContainer)
      const $form = $modifiersContainer.querySelector(modifiersFormContainer)
      const productId = $modifiersContainer.querySelector(productIdContainer).value

      const optionChangeData = serialize($form)
      Object.keys(optionChangeData).forEach(key => {
        fragment += `${encodeURIComponent(key)}=${encodeURIComponent(optionChangeData[key])}&`
      })

      if (!fragment.includes('attribute')) return

      const showInclusiveTaxPrice = JSON.parse(sessionStorage.getItem('showInclusiveTaxPrice')) || false

      this.stencilUtils.api.productAttributes.optionChange(productId, fragment, async (err, result) => {
        if (err) {
          return this.utils.Alert.error(err)
        }

        const { data = {} } = result

        const $skuContainer = $modifiersContainer.querySelector(skuContainer)
        const $priceContainer = $modifiersContainer.querySelector(priceContainer)
        if (data.sku && $skuContainer) {
          $skuContainer.innerHTML = data.sku
        }

        if (data.price && $priceContainer) {
          const { price } = data
          $priceContainer.innerHTML = showInclusiveTaxPrice ? price?.with_tax?.formatted : price?.without_tax?.formatted
        }

        cb && cb(data)
      })
    }

    this.optionChangeCallback = this.optionChangeCallback || optionChangeCallback

    this.stencilUtils.hooks[type]('product-option-change', this.optionChangeCallback)
  }
}

export default B3Modifiers
