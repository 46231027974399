import BasePage from '../../common/BasePage'
import invoicesHtml from './invoices.html'
import listHtml from './list.html'
import pendingInvoicesTable from './components/pending-invoices-table.html'

import {
  INVOICE_STATUS_MAP,
  INVOICE_SOURCE_MAP,
  FILTER_FIELDS,
  INVOICE_SEARCH_FIELD,
} from './constants'

export default class Invoices extends BasePage {
  constructor() {
    super()
    this.name = 'Invoices'
    this.state = {
      pagination: {
        offset: 0,
        limit: 10,
        totalCount: 0,
      },
      invoicesInfo: {
        hasDueInvoice: '',
        hasOverDueInvoice: '',
      },
      lists: [],
      pendingPayments: [],
      finishedPayments: [],
      isShowPaymentList: true,
      paymentList: [],
      sortBy: 'updatedAt',
      orderBy: 'DESC',
      INVOICE_STATUS_MAP,
      FILTER_FIELDS,
      INVOICE_SOURCE_MAP,
      INVOICE_SEARCH_FIELD,
    }

    // dom
    this.tpls = {
      invoicesHtml,
      listHtml,
      pendingInvoicesTable,
    }
  }

  get isInPage() {
    return this.utils.leftIncludes(window.location.pathname, this.doms.invoices.url)
  }

  async init() {
    if (!this.isB2BUser) return

    const {
      B3RoleId,
    } = this.utils.B3Storage

    const {
      constants: {
        B3Role: {
          JUNIOR,
        },
      },
    } = this.utils
    if (B3RoleId.value === JUNIOR) {
      this.utils.Alert.error(this.locales.tips.cannotEnterPage)
      this.utils.urlHelper.redirect('/')
      return
    }

    if (!this.isInPage) return
    this.render()
    this.getInvoicesPaymentInfo()
    this.handleFilterOperation()
    this.initBtn()
    this.hideInvoiceActions()
    this.initMobileTable()
  }

  initBtn() {
    const $filterSwitchBtn = document.querySelector('.button-filter')
    const $sortThs = document.querySelectorAll('th[data-sort-th]')
    const $sortThsParent = document.querySelector('.invoices-lists-table thead tr')

    $sortThs.forEach($sortTh => {
      $sortTh.addEventListener('click', () => {
        const isAsc = $sortTh.classList.contains('asc')
        const sortBy = $sortTh.dataset.sortFilter

        const $asc = $sortThsParent.querySelector('.asc')

        let orderBy

        if (isAsc) {
          $sortTh.classList.remove('asc')
          orderBy = 'DESC'
        } else {
          orderBy = 'ASC'
          if ($asc) $asc.classList.remove('asc')
          $sortTh.classList.add('asc')
        }

        this.setState({
          orderBy,
          sortBy,
        })

        this.renderLists()
      })
    })

    $filterSwitchBtn.addEventListener('click', e => {
      e.stopPropagation()
      this.toggleFilters()
    })
  }

  toggleDropdown(e) {
    const item = e.target.nextElementSibling

    if (item.classList.contains('show-action')) {
      item.classList.remove('show-action')
    } else {
      const hasShowItem = document.querySelector('.show-action')
      if (hasShowItem) document.querySelector('.show-action').classList.remove('show-action')
      item.classList.add('show-action')
    }
  }

  toggleFilters() {
    const $filterForm = document.querySelector('.invoices-filter-form')
    const DISPLAY_STATUS = {
      none: 'block',
      block: 'none',
      '': 'block',
    }
    $filterForm.style.display = DISPLAY_STATUS[$filterForm.style.display]
  }

  hideInvoiceActions() {
    document.querySelector('body').addEventListener('click', e => {
      if (e.target.hasAttribute('invoices-list-action')) return
      if (document.querySelector('.show-action')) {
        document.querySelector('.show-action').classList.remove('show-action')
      }
    })
  }

  async render() {
    const {
      invoices: {
        container,
      },
    } = this.doms

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.invoicesHtml,
      containerSelector: container,
      templateConfig: {
        ...this.state,
      },
      insertType: 'beforeend',
    })

    await this.renderLists()
  }

  initPagination() {
    const {
      pagination: {
        offset,
        limit,
        totalCount,
      },
    } = this.state

    const currentPage = Math.ceil((offset + 1) / limit)
    const totalPages = Math.ceil(totalCount / limit)
    window.B3Paginator.init({
      container: '#B3pagination',
      currentPage,
      totalPages,
      visiblePages: limit,
      onPageChange: this.handlePaginationChange.bind(this),
    })
  }

  async renderLists() {
    window.B3Spinner.show()
    const { data, meta: { pagination } } = await this.getInvoices()

    this.state.pagination = pagination
    this.state.lists = data

    this.state.lists = data.map(item => {
      const { INVOICE_STATUS_MAP, INVOICE_SOURCE_MAP } = this.state
      const {
        status,
        notAllowedPay,
        originalBalance: {
          code: currencyCode,
        },
        source,
        details,
      } = item

      const {
        B3Storage: {
          B3RoleId: {
            value: B3RoleIdValue,
          },
        },
        constants: {
          B3Role: {
            ADMIN,
            SALESREP,
          },
        },
      } = this.utils

      item.displayStatus = INVOICE_STATUS_MAP[status]
      item.displaySource = INVOICE_SOURCE_MAP[source]

      item.discountTotal = this.calcDiscountTotal(details, currencyCode)

      item.isShowPay = !notAllowedPay && [ADMIN, SALESREP].includes(B3RoleIdValue)

      item.currencyCode = currencyCode
      return item
    })

    document.querySelector('#invoices-list-container').innerHTML = ''

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.listHtml,
      containerSelector: '#invoices-list-container',
      templateConfig: {
        ...this.state,
        classes: this.classes,
      },
      insertType: 'beforeend',
    })

    this.initPagination()
    window.B3Spinner.hide()
  }

  async handlePaginationChange(page) {
    const { limit } = this.state.pagination
    this.state.pagination.offset = (page - 1) * limit
    await this.renderLists()
  }

  async getInvoicesPaymentInfo() {
    try {
      const params = {
        limit: 1000,
        offset: 0,
        paymentStatus: [1, 2],
      }

      const { data: payments } = await this.api.getReceiptLines(params)
      const finishedPayments = []
      const pendingPayments = []

      payments.forEach(item => {
        item.displayCreatedAt = item.createdAt * 1000
        item.paymentMethod = item.paymentType || this.text['invoices.paymentType.unknown']
        if ([1, 2].includes(+item.paymentStatus)) {
          pendingPayments.push(item)
        } else {
          finishedPayments.push(item)
        }
      })

      this.setState({
        pendingPayments,
        finishedPayments,
      })

      if (pendingPayments.length) {
        const $invoiceDetailPending = document.querySelector('#invoice-detail-pending')
        $invoiceDetailPending.innerHTML = this.tpls.pendingInvoicesTable({ ...this.state })
      }
    } catch (e) {
      console.error(e)
    }
  }

  getRequestData() {
    const {
      FILTER_FIELDS,
      sortBy,
      orderBy,
    } = this.state

    const q = document.querySelector('#search-invoices-input').value.trim()
    const status = document.querySelector('#filter-invoices-status-input').value.trim()

    const searchBy = document.querySelector('#search-invoices-select').value.trim()

    const params = {
      q,
      status,
      sortBy,
      orderBy,
      searchBy,
    }

    const { store_time_zone: storeTimeZone } = window.jsContext.settings

    FILTER_FIELDS.forEach(field => {
      const { value: fieldName, type } = field
      let fieldValue = document.querySelector(`#filter-invoices-${fieldName}-input`).value.trim()
      if (type === 'date' && fieldValue) {
        if (fieldName === 'beginDateAt') {
          fieldValue = new Date(fieldValue).setHours(0)
        }
        if (fieldName === 'endDateAt') {
          fieldValue = new Date(fieldValue).setHours(23, 59, 59)
        }
        fieldValue = fieldValue - +storeTimeZone * 60 * 60 * 1000 - new Date().getTimezoneOffset() * 1000 * 60
        fieldValue /= 1000
      }

      if (fieldValue) {
        params[fieldName] = fieldValue
      }
    })

    const pagination = {
      offset: this.state.pagination.offset,
      limit: this.state.pagination.limit,
    }

    return { params, pagination }
  }

  getInvoices() {
    const { params, pagination } = this.getRequestData()

    return this.api.getInvoices({ ...params, ...pagination })
  }

  getInvoiceInfo() {
    return this.api.getInvoiceInfo()
  }

  getReceiptLines() {
    const pagination = {
      offset: this.state.pagination.offset,
      limit: this.state.pagination.limit,
    }

    return this.api.getReceiptLines({ ...pagination })
  }

  getExportCSVFile() {
    const { params, pagination } = this.getRequestData()
    const res = this.api.getExportCSVFile({ ...params, ...pagination })

    res.then(data => {
      this.utils.exportCSVFile(data, 'invoices')
    })
  }

  calcDiscountTotal(details, code) {
    const { lineItems } = details.details
    if (!lineItems || !lineItems.length) {
      return 0
    }
    let value = 0

    lineItems.forEach(item => {
      value += (item.unitDiscount ? +item.unitDiscount.value : 0) * item.quantity
    })

    return { value, code }
  }

  resetPagination() {
    const {
      pagination,
    } = this.state
    this.setState({
      pagination: {
        ...pagination,
        offset: 0,
      },
    })
    this.renderLists()
  }

  handleFilterOperation() {
    document.querySelector('#filter_cancel_button').addEventListener('click', () => {
      const {
        FILTER_FIELDS,
      } = this.state

      // reset fields input
      FILTER_FIELDS.forEach(field => {
        const { value: fieldName } = field
        document.querySelector(`#filter-invoices-${fieldName}-input`).value = ''
      })

      document.querySelector('#filter-invoices-status-input').value = ''

      document.querySelector('#search-invoices-input').value = ''

      document.querySelector('#search-invoices-select').value = ''

      this.resetPagination()
    })
  }

  // handle Date range validate
  handleDateChange(e) {
    const { dataset: { pairfields } } = e.target

    const pairfieldsArr = pairfields.split('|')

    const $beginField = document.querySelector(`#filter-invoices-${pairfieldsArr[0]}-input`)
    const $endField = document.querySelector(`#filter-invoices-${pairfieldsArr[1]}-input`)

    const $beginFieldVal = $beginField.value
    const $endFieldVal = $endField.value
    if (!$beginFieldVal || !$endFieldVal) return

    const $beginFieldTimestamp = new Date($beginFieldVal).getTime()
    const $endFieldTimestamp = new Date($endFieldVal).getTime()

    const diff = $beginFieldTimestamp - $endFieldTimestamp

    if (diff < 0) return
    $beginField.value = $endFieldVal
    $endField.value = $beginFieldVal
  }
}
