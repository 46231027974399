var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<table class=\"quick-order-pad-table\" id=\"quick_order_pad_table_csv\">\n  <thead class=\"has-line\">\n    <tr>\n      <th>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"qop.thead.variantSku",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":4,"column":10},"end":{"line":4,"column":43}}}))
    + "</th>\n      <th class=\"th-col-quantity\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"qop.thead.quantity",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":65}}}))
    + "</th>\n      <th class=\"th-col-message\"></th>\n    </tr>\n  </thead>\n  <tbody></tbody>\n</table>";
},"useData":true});