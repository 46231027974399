var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-exclamation-circle fa-lg\"></i> "
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"invoices.payment.dueTip",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":9,"column":87},"end":{"line":9,"column":121}}}))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return "<i class=\"fa fa-exclamation-circle fa-lg\"></i> "
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"invoices.payment.overDueTip",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":10,"column":91},"end":{"line":10,"column":129}}}))
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <option value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\" data-status=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"text","hash":{},"data":data,"loc":{"start":{"line":21,"column":57},"end":{"line":21,"column":71}}}))
    + "</option>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"filter-field\">\n        <label class=\"form-label\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"text","hash":{},"data":data,"loc":{"start":{"line":50,"column":34},"end":{"line":50,"column":48}}}))
    + "</label>\n        <input\n          class=\"form-input form-input--small\"\n          type=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"type") : depth0), depth0))
    + "\"\n          id=\"filter-invoices-"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "-input\"\n          name=\"search-invoices-input\"\n          data-change=\"handleDateChange\"\n          data-pairfields=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"pairfields") : depth0), depth0))
    + "\"\n        />\n        <span>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"inputText") : depth0),{"name":"text","hash":{},"data":data,"loc":{"start":{"line":59,"column":14},"end":{"line":59,"column":32}}}))
    + "</span>\n      </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <option value=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\" data-status=\""
    + alias2(alias1((data && lookupProperty(data,"key")), depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"b2b-wrap invoices-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"b2bWrap",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":53}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":54},"end":{"line":2,"column":76}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":77},"end":{"line":2,"column":109}}}))
    + "\"\n>\n  <div class=\"title-wrap "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.head.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":25},"end":{"line":4,"column":62}}}))
    + "\">\n    <h2 class=\"page-title title "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.head.title",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":65}}}))
    + "\">\n      "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":6},"end":{"line":6,"column":31}}}))
    + "\n    </h2>\n    <span class=\"invoices-overdue-info\">\n      "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"invoicesInfo") : depth0)) != null ? lookupProperty(stack1,"hasDueInvoice") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":6},"end":{"line":9,"column":129}}})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"invoicesInfo") : depth0)) != null ? lookupProperty(stack1,"hasOverDueInvoice") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":137}}})) != null ? stack1 : "")
    + "\n    </span>\n <!-- Check the abnormal status of payment -->\n  <div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoicePending",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":13,"column":14},"end":{"line":13,"column":42}}}))
    + "\" id=\"invoice-detail-pending\"></div>\n  </div>\n  <!-- search area -->\n  <div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.search.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":16,"column":14},"end":{"line":16,"column":53}}}))
    + "\">\n    <div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"common.search.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":53}}}))
    + "\">\n      <select class=\"form-select\" id=\"search-invoices-select\">\n        <option value=\"\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.search.selectOptions.all",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":19,"column":25},"end":{"line":19,"column":69}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"INVOICE_SEARCH_FIELD") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":22,"column":17}}})) != null ? stack1 : "")
    + "      </select>\n      <input class=\"form-input\" type=\"text\" id=\"search-invoices-input\" name=\"search-invoices-input\"\n        placeholder=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.search.placeholder",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":25,"column":21},"end":{"line":25,"column":59}}}))
    + "\" data-change=\"resetPagination\" />\n      <button class=\"button button--search\" type=\"button\" id=\"search-invoices\" name=\"search-invoices\"\n        data-click=\"resetPagination\">\n        <i class=\"fa fa-search\"></i>\n      </button>\n      <div class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"common.button.filter",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":30,"column":18},"end":{"line":30,"column":52}}}))
    + " button-filter-container\" data-click=\"toggleFilters\">\n        <button class=\"button button--small button-filter\" href=\"javascript:void(0);\" data-click=\"toggleFilters\">\n          <i class=\"fa fa-filter\"></i> "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.filters",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":32,"column":39},"end":{"line":32,"column":66}}}))
    + "\n        </button>\n      </div>\n    </div>\n    <div class=\"invoices-list-export\">\n      <a class=\"invoices-list-export_csv\" href=\"javascript:void(0);\" data-click=\"getExportCSVFile\">\n        <i class=\"fa fa-download\"></i>\n        "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.list.exportCSV",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":39,"column":42}}}))
    + "\n      </a>\n    </div>\n  </div>\n  <!-- filter area -->\n  <div\n    class=\"invoices-filter-form b2b-hide "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"common.filter.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":45,"column":41},"end":{"line":45,"column":78}}}))
    + " "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.filter.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":45,"column":79},"end":{"line":45,"column":118}}}))
    + "\"\n  >\n    <div class=\"filter-fields button-filter\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"FILTER_FIELDS") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":6},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + "      <div class=\"filter-field\">\n        <label class=\"form-label\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.list.status",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":63,"column":34},"end":{"line":63,"column":65}}}))
    + "</label>\n        <select\n          class=\"form-select form-input--small\"\n          id=\"filter-invoices-status-input\"\n        >\n          <option value=\"\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.filters.placeholder",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":68,"column":27},"end":{"line":68,"column":68}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"INVOICE_STATUS_MAP") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":10},"end":{"line":71,"column":19}}})) != null ? stack1 : "")
    + "        </select>\n      </div>\n    </div>\n    <div class=\"filter-actions\">\n      <a\n        class=\"button button--small "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.search.filters.button.clear",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":77,"column":36},"end":{"line":77,"column":86}}}))
    + "\"\n        id=\"filter_cancel_button\"\n        href=\"javascript:void(0);\"\n        >"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.filters.clear",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":80,"column":9},"end":{"line":80,"column":42}}}))
    + "</a\n      >\n      <a\n        class=\"button button--primary button--small "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.search.filters.button.apply",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":83,"column":52},"end":{"line":83,"column":102}}}))
    + "\"\n        id=\"filter_apply_button\"\n        href=\"javascript:void(0);\"\n        data-click=\"resetPagination\"\n        >"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.filters.apply",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":87,"column":9},"end":{"line":87,"column":42}}}))
    + "</a\n      >\n    </div>\n  </div>\n  <div class=\"table-wrap\">\n    <table\n      class=\"responsive-table invoices-lists-table "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.table",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":93,"column":51},"end":{"line":93,"column":79}}}))
    + "\"\n      id=\"quote_list_table\"\n    >\n      <thead>\n        <tr>\n          <th\n            class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":99,"column":19},"end":{"line":99,"column":50}}}))
    + "\"\n            data-sort-th\n            data-sort-filter=\"invoiceNumber\"\n          >\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.invoiceNumber",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":103,"column":12},"end":{"line":103,"column":57}}}))
    + "\n            <span\n              class=\"common-sort-icon "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.sort.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":105,"column":38},"end":{"line":105,"column":70}}}))
    + "\"\n              data-sort-th\n              data-sort-filter=\"invoiceNumber\"\n            ></span>\n          </th>\n          <th class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":110,"column":21},"end":{"line":110,"column":52}}}))
    + "\">\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.orderNumber",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":111,"column":12},"end":{"line":111,"column":55}}}))
    + "\n          </th>\n          <th\n            class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":114,"column":19},"end":{"line":114,"column":50}}}))
    + "\"\n            data-sort-th\n            data-sort-filter=\"createdAt\"\n          >\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.createdAt",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":118,"column":12},"end":{"line":118,"column":53}}}))
    + "\n            <span\n              class=\"common-sort-icon "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.sort.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":120,"column":38},"end":{"line":120,"column":70}}}))
    + "\"\n              data-sort-th\n              data-sort-filter=\"createdAt\"\n            ></span>\n          </th>\n          <th\n            class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":126,"column":19},"end":{"line":126,"column":50}}}))
    + "\"\n            data-sort-th\n            data-sort-filter=\"dueDate\"\n          >\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.dueDate",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":130,"column":12},"end":{"line":130,"column":51}}}))
    + "\n            <span\n              class=\"common-sort-icon "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.sort.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":132,"column":38},"end":{"line":132,"column":70}}}))
    + "\"\n              data-sort-th\n              data-sort-filter=\"dueDate\"\n            ></span>\n          </th>\n          <th\n            class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":138,"column":19},"end":{"line":138,"column":50}}}))
    + "\"\n            data-sort-th\n            data-sort-filter=\"originalBalance\"\n          >\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.originalBalance",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":142,"column":12},"end":{"line":142,"column":59}}}))
    + "\n            <span\n              class=\"common-sort-icon "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.sort.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":144,"column":38},"end":{"line":144,"column":70}}}))
    + "\"\n              data-sort-th\n              data-sort-filter=\"originalBalance\"\n            ></span>\n          </th>\n          <th class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":149,"column":21},"end":{"line":149,"column":52}}}))
    + "\">\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.discountTotal",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":150,"column":12},"end":{"line":150,"column":57}}}))
    + "\n          </th>\n          <th\n            class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":153,"column":19},"end":{"line":153,"column":50}}}))
    + "\"\n            data-sort-th\n            data-sort-filter=\"openBalance\"\n          >\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.openBalance",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":157,"column":12},"end":{"line":157,"column":55}}}))
    + "\n            <span\n              class=\"common-sort-icon "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.sort.icon",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":159,"column":38},"end":{"line":159,"column":70}}}))
    + "\"\n              data-sort-th\n              data-sort-filter=\"openBalance\"\n            ></span>\n          </th>\n          <th class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":164,"column":21},"end":{"line":164,"column":52}}}))
    + "\">\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.status",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":165,"column":12},"end":{"line":165,"column":50}}}))
    + "\n          </th>\n          <th\n            class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"invoices.thead.th",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":168,"column":19},"end":{"line":168,"column":50}}}))
    + "\"\n          >\n            "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"invoices.table.thead.actions",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":170,"column":12},"end":{"line":170,"column":51}}}))
    + "\n          </th>\n        </tr>\n      </thead>\n      <tbody id=\"invoices-list-container\"></tbody>\n    </table>\n  </div>\n  <ul class=\"pagination-list\" id=\"B3pagination\"></ul>\n</div>\n";
},"useData":true});