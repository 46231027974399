import { limitConfig, limitsLevel } from './config'

import text from '../../text'

const getCurrentConfigItem = name => {
  const item = limitConfig.find(item => item.name === name) || {}
  return item
}

export default class B3LimitSocket {
  constructor(name) {
    this.name = name
    this.wsContext = {}
    this.limitConfigItem = getCurrentConfigItem(name)
    this.insertNode = null
  }

  async init(data) {
    if (data.channel && data.channel === this.limitConfigItem?.channel) {
        const { percent } = data
        let level = limitsLevel.PRAMARY
        if (+percent >= 80 && +percent < 100) {
          level = limitsLevel.WARNING
        } else if (+percent >= 100) {
          level = limitsLevel.ERROR
        }

        data.level = level

        const isLastTimeContextEqual = this.wsContext?.level ? Object.keys(data).every(item => data[item] === this.wsContext?.[item]) : false
        if (data.level === limitsLevel.PRAMARY) {
          this.closeTip()
        } else if (!isLastTimeContextEqual) {
          window.limite = window.limite || {}
          window.limite.wsContext = data
          this.wsContext = data
          this.closeTip()
          this.createTip()
        }
      }
  }

  insertAfter(newEl, targetEl) {
      const parentEl = targetEl.parentNode

      if (parentEl.lastChild === targetEl) {
          parentEl.appendChild(newEl)
      } else {
          parentEl.insertBefore(newEl, targetEl.nextSibling)
      }
  }

  tipContent() {
    const { level } = this.wsContext
    const { label } = this.limitConfigItem
    const html = `${text[`limitation.${level === limitsLevel.WARNING ? 'warning' : 'error'}.left.${label}`]}`
    const path = level === limitsLevel.ERROR ? 'M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' : 'M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z'
    return `<p class="B3LimitTipText">
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path d="${path}">
        </path>
      </svg>
      <span>${html}</span>
    </p>
    <p class="B3LimitTipClose">X</p>`
  }

  createTip() {
    const { level } = this.wsContext
    const dom = window?.limite?.tipNode || '#main-content nav.navBar'
    this.insertNode = document.querySelector(dom)
    const limitDiv = document.createElement('div')
    const limitId = document.createAttribute('id')
    limitId.value = 'B3LimitTip'
    limitDiv.classList.add(level === limitsLevel.WARNING ? 'warnTip' : 'errorTip')
    limitDiv.setAttributeNode(limitId)
    limitDiv.innerHTML = this.tipContent()
    this.insertAfter(limitDiv, this.insertNode)

    const $close = document.querySelector('#B3LimitTip .B3LimitTipClose')
    $close.addEventListener('click', e => {
      e.stopPropagation()
      this.closeTip()
    })
  }

  closeTip() {
    const self = document.querySelector('#B3LimitTip')
    if (self) {
      const parent = self.parentElement
      this.wsContext = {}
      parent.removeChild(self)
    }
  }

  isOpenLimitErrorDialog() {
    return window?.limite?.wsContext?.level === limitsLevel.ERROR
  }
}
