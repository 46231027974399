var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h4 class=\"quote-message\">"
    + alias1(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"rfq.form.message.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":59}}}))
    + "</h4>\n<textarea\n  name=\"message\"\n  id=\"rfq-message\"\n  class=\"form-input\"\n  cols=\"30\"\n  rows=\"4\"\n  data-change=\"handleMessageChange\"\n>"
    + alias1(container.lambda((depth0 != null ? lookupProperty(depth0,"message") : depth0), depth0))
    + "</textarea>\n";
},"useData":true});