import socketConfig from './config'

export const isEnabledSocket = instance => {
  const isLogin = !!window.jsContext.customer
  const isEnabled = socketConfig.some(item => item === instance.name)
  if (isLogin && isEnabled) return true

  return false
}

export { default as B3Socket } from './B3Socket'
