import './index.global.scss'
import './lib'

import themeConfig, { PAGES } from './themeConfig'
import { leftIncludes } from './common/utils/util'
import { Alert } from './common/utils'
import Tpa from './views/tpa'
import Login from './views/login'
import Dashboard from './views/dashboard'
import B3Storage from './common/utils/B3Storage'
import QuickOrderPad from './views/quickOrderPad'
import UserManagement from './views/userManagement'
import BuyAgain from './views/buyAgain'
import ShoppingLists from './views/shoppinglists'
import ShoppingList from './views/shoppingList'
import AddressBook from './views/addressBook'
import Orders from './views/orders'
import Orderdetail from './views/orderDetail'
import AccountSetting from './views/accountSetting'
import Rfq from './views/rfq'
import Quotes from './views/quotes'
import Pdp from './views/pdp'
import * as locales from './common/locales'
import * as utils from './common/utils'

// IP
import Invoices from './views/invoices'
import InvoicesPayment from './views/invoices/payment'
import InvoiceDetail from './views/invoices/invoiceDetail'
import InvoiceReceipt from './views/invoices/invoiceReceipt'

import {
 getCurrencies,
 getStoreFrontConfig,
 getTaxZoneRates,
 getLanguageCode,
} from './common/api'
import { getCurrency } from './common/utils/currencyFormat'
import RfqQuote from './views/rfqQuote'

import { singleLimitation } from './common/limitation'

const {
  dashboard,
  userManagement,
  buyAgain,
  shoppinglists,
  shoppinglist,
  addressBook,
  orders,
  orderDetail,
  quote,
  quotes,
  invoices,
  invoicesPayment,
  invoiceDetail,
  invoiceReceipt,
} = themeConfig.doms

const pages = {
  [dashboard.url]: Dashboard,
  [userManagement.url]: UserManagement,
  [buyAgain.url]: BuyAgain,
  [shoppinglists.url]: ShoppingLists,
  [shoppinglist.url]: ShoppingList,
  [addressBook.url]: AddressBook,
  [orders.url]: Orders,
  [orderDetail.url]: Orderdetail,
  [quotes.url]: Quotes,
  [invoices.url]: Invoices,
  [invoicesPayment.url]: InvoicesPayment,
  [invoiceDetail.url]: InvoiceDetail,
  [invoiceReceipt.url]: InvoiceReceipt,
}

const fixCurrencyRoutes = () => {
  const $currencies = document.querySelectorAll('#currencySelection li a')
  const query = location.search.substr(1).replace(/setCurrencyId=\d&/, '')

  $currencies.forEach($currency => {
    const defaultCurrencyQuery = $currency.getAttribute('href')
    $currency.setAttribute('href', `${defaultCurrencyQuery}&${query}`)
  })
}

const bootstrap = async () => {
  try {
    window.money = window.jsContext.settings.money

    if (!window.jsContext?.channelId) window.jsContext.channelId = window.jsContext?.settings?.channel_id || 1

    const isLogin = !!window.jsContext.customer

    const channelId = window.jsContext?.channelId
    sessionStorage.setItem(
      'channelId',
      JSON.stringify(channelId),
    )

    const storeHash = window.jsContext.store_hash
    const $navBar = document.querySelector('.navBar--account')
    if ($navBar && $navBar.parentElement.className.includes('container')) {
      $navBar.style.display = 'none'
    }
    const { config } = await getStoreFrontConfig({ storeHash })
    await singleLimitation(config)

    const {
      language,
    } = await getLanguageCode({
      channelId,
      storeHash,
    })

    let langCode = language || 'en'

    if (language && language.includes('-')) {
      const [lang] = language.split('-')
      langCode = lang
    }

    sessionStorage.setItem('langCode', langCode)

    if (config) {
      const { B3StorefrontConfig } = B3Storage
      B3StorefrontConfig.setValue(JSON.stringify(config))
    }

    if (!sessionStorage.getItem('currencyInfo')) {
      const currencyInfo = await getCurrencies({ storeHash })

      // handle channel defaultCurrency
      if (currencyInfo?.channelCurrencies && JSON.stringify(currencyInfo.channelCurrencies) !== '{}') {
        const {
          channelCurrencies: {
            default_currency,
            enabled_currencies,
          },
        } = currencyInfo
        currencyInfo.currencies = currencyInfo.currencies.map(currency => ({
          ...currency,
          enabled: enabled_currencies.includes(currency.currency_code),
          is_default: default_currency === currency.currency_code,
        }))
      }

      sessionStorage.setItem(
        'currencyInfo',
        JSON.stringify(currencyInfo),
      )
      getCurrency()
      fixCurrencyRoutes()
    }

    if (!sessionStorage.getItem('showInclusiveTaxPrice')) {
      const { list: taxZoneRates = [] } = await getTaxZoneRates({ storeHash })
      if (taxZoneRates.length) {
        const defaultTaxZone = taxZoneRates.find(taxZone => taxZone.id === 1)
        if (defaultTaxZone) {
          const {
            price_display_settings: { show_inclusive: showInclusive },
          } = defaultTaxZone
          sessionStorage.setItem('showInclusiveTaxPrice', showInclusive)
        }
      }
    }

    if (isLogin) {
      await Login.mount()
    }
    if (isLogin) {
      const $navBar = document.querySelector('.navBar--account')
      const isBCUser = !!utils.B3Storage.B3IsB2CUser.value
      const $AccountSetting = document.querySelector('[data-edit-account-form]')
      const $BCPages = document.querySelectorAll('.account')
      const $OrderPage = document.querySelector('.page_type_account_orderstatus')
      if ($navBar) {
        $navBar.style.display = 'block'
      }
      if (isBCUser) {
        $BCPages.forEach($BCPage => {
          if ($BCPage.parentElement.className.includes('container')) $BCPage.style.display = 'block'
          if ($BCPage.className.includes('navUser-item')) $BCPage.style.display = 'inline-flex'
        })
      }
      if (!isBCUser) {
        if (!$OrderPage && $BCPages) {
          $BCPages.forEach($BCPage => {
            if ($BCPage.parentElement.className.includes('container')) $BCPage.style.display = 'block'
            if (typeof ($BCPage.className) === 'string' && $BCPage.className.includes('navUser-item')) $BCPage.style.display = 'inline-flex'
          })
        }
      }
      if ($AccountSetting) {
        $AccountSetting.style.display = 'block'
      }
    }

    const { pathname, search } = window.location
    let lockUrl = pathname

    // handle 'APP Navigation Bar Management' permission
    const configs = Object.values(themeConfig.doms)
    for (let i = 0; i < configs.length; i += 1) {
      const { pageName } = configs[i]
      if (typeof pageName !== 'string') continue

      const { url } = configs[i]
      if (typeof url !== 'string') continue
      let isAccessible = config[PAGES[pageName]]
      if (pageName === 'INVOICE') {
        const { value, enabledStatus } = config[PAGES[pageName]]
        isAccessible = enabledStatus ? value : enabledStatus
      }
      if (url?.includes(lockUrl)) {
        if (isAccessible) {
          break
        } else {
          Alert.warning(locales.tips.notPermission)
          window.location.href = window.location.origin
          return
        }
      }
    }

    if (config.tradeProfessionalApplication) {
      await Tpa.mount()
    }
    QuickOrderPad.mount()
    AccountSetting.mount()
    Rfq.mount()
    Pdp.mount()

    // get current Channels and Save the B2BEnable state of the current channel
    sessionStorage.setItem(
      'currentB2BEnabled',
      JSON.stringify(true),
    )

    // if (leftIncludes(lockUrl, quote.url)) RfqQuote.mount() // last version
    if (lockUrl === quote.url) RfqQuote.mount()

    Object.entries(pages).forEach(async ([pageUrl, Page]) => {
      if (pageUrl === orders.url) lockUrl += search

      const isLeftIncludes = leftIncludes(lockUrl, pageUrl)

      if (isLogin && isLeftIncludes && !B3Storage.B3IsB2CUser.value) { Page.mount() }
    })
  } catch (error) {
    console.error(error)
  }
}

bootstrap()
