var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input type=\"hidden\" name=\"action\" value=\"add\">\n        <input type=\"hidden\" id=\"defaultQty\" name=\"qty[]\" value=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"productQty") : depth0), depth0))
    + "\" min=\"1\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " \"handleSaveNewOptions\" ";
},"5":function(container,depth0,helpers,partials,data) {
    return " \"handleSaveSearchProducts\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"edit-options-title\">\n  <h4>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"moduleTitle") : depth0), depth0))
    + "</h4>\n</div>\n<div class=\"edit-product-options-container\">\n  <img src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"imageUrl") : depth0), depth0))
    + "\">\n  <div class=\"edit-product\">\n    <p>"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productName") : depth0), depth0))
    + "</p>\n    <p>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias3,"rfq.items.price",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":8,"column":7},"end":{"line":8,"column":33}}}))
    + ": <span class=\"edit-product-price\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"price") : depth0), depth0))
    + "</span></p>\n    <p data-product-sku>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias3,"rfq.form.item.sku",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":9,"column":24},"end":{"line":9,"column":52}}}))
    + ": <span class=\"edit-product-sku\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"sku") : depth0), depth0))
    + "</span></p>\n\n    <form class=\"form option-form\" data-option-form>\n      <input type=\"hidden\" name=\"product_id\" value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"productId") : depth0), depth0))
    + "\"/>\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"isSearchProduct") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":16,"column":13}}})) != null ? stack1 : "")
    + "      <div data-product-option-change>\n      </div>\n    </form>\n\n    <div class=\"rfq-productTip-container\">\n      <div class=\"rfq-unavailable-product-combination-tip\">\n        <i class=\"fa fa-exclamation-circle\" aria-hidden=\"true\"></i>\n        <span>"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias3,"rfq.form.edit.options.selectTip",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":24,"column":56}}}))
    + "</span>\n      </div>\n    </div>\n  </div>\n</div>\n<div class=\"edit-button-group\">\n  <button type=\"button\" class=\"cancel-edit-options button button--small\" data-click=\"hideForm\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias3,"rfq.form.edit.options.cancel",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":30,"column":95},"end":{"line":30,"column":136}}}))
    + "</button>\n  <button\n    type=\"button\"\n    class=\"cancel-save-options button button--primary button--small\"\n    data-click="
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"isEdit") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":34,"column":15},"end":{"line":34,"column":96}}})) != null ? stack1 : "")
    + "\n  >\n    "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias3,"rfq.form.edit.options.save",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":36,"column":43}}}))
    + "\n  </button>\n</div>\n";
},"useData":true});