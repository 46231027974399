var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"navItemClassName") || (depth0 != null ? lookupProperty(depth0,"navItemClassName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"navItemClassName","hash":{},"data":data,"loc":{"start":{"line":1,"column":11},"end":{"line":1,"column":31}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"isActive") || (depth0 != null ? lookupProperty(depth0,"isActive") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"isActive","hash":{},"data":data,"loc":{"start":{"line":1,"column":32},"end":{"line":1,"column":44}}}) : helper)))
    + "\">\n  <a class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"navActionClassName") || (depth0 != null ? lookupProperty(depth0,"navActionClassName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"navActionClassName","hash":{},"data":data,"loc":{"start":{"line":2,"column":12},"end":{"line":2,"column":34}}}) : helper)))
    + "\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"url") || (depth0 != null ? lookupProperty(depth0,"url") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":49}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":2,"column":51},"end":{"line":2,"column":59}}}) : helper)))
    + "</a>\n</li>";
},"useData":true});