var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"modal-header "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.header.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":78}}}))
    + "\">\n  <h2 class=\"modal-header-title "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.header.title",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":81}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"shopping.list.edit.modal.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":83},"end":{"line":2,"column":124}}}))
    + "</h2>\n  <a href=\"#\" class=\"modal-close "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.header.close",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":3,"column":33},"end":{"line":3,"column":82}}}))
    + "\" role=\"button\">\n    <span aria-hidden=\"true\">&#215;</span>\n  </a>\n</div>\n<div class=\"modal-body "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.body",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":7,"column":23},"end":{"line":7,"column":64}}}))
    + "\">\n  <form class=\"form\" action=\"\" method=\"post\">\n    <fieldset class=\"form-fieldset "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":86}}}))
    + "\">\n      <div class=\"form-field "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":10,"column":29},"end":{"line":10,"column":85}}}))
    + "\">\n        <label class=\"form-label "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.label",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":11,"column":33},"end":{"line":11,"column":85}}}))
    + "\" for=\"list_name\"\n          >\n          "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"shopping.list.edit.modal.form.name.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":13,"column":61}}}))
    + "\n          <small class=\""
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.required",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":14,"column":79}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":14,"column":81},"end":{"line":14,"column":114}}}))
    + "</small>\n        </label>\n        <input\n          class=\"form-input "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.input",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":17,"column":28},"end":{"line":17,"column":80}}}))
    + "\"\n          type=\"text\"\n          name=\"list_name\"\n          id=\"list_name\"\n          value=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"name") : depth0), depth0))
    + "\"\n        />\n      </div>\n      <div class=\"form-field "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":24,"column":29},"end":{"line":24,"column":85}}}))
    + "\">\n        <label class=\"form-label "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.label",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":25,"column":33},"end":{"line":25,"column":85}}}))
    + "\" for=\"list_comment\">\n          "
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"shopping.list.edit.modal.form.description.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":26,"column":10},"end":{"line":26,"column":68}}}))
    + "\n        </label>\n        <textarea\n          class=\"form-input "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.form.item.textarea",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":29,"column":28},"end":{"line":29,"column":83}}}))
    + "\"\n          name=\"list_comment\"\n          id=\"list_comment\"\n          cols=\"30\"\n          rows=\"3\"\n        >"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"description") : depth0), depth0))
    + "</textarea>\n      </div>\n\n      <div class=\"form-field "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.button.container",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":37,"column":29},"end":{"line":37,"column":82}}}))
    + "\">\n        <input\n          type=\"button\"\n          class=\"button button--primary "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.button.save",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":40,"column":88}}}))
    + "\"\n          value=\""
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"shopping.list.save.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":41,"column":17},"end":{"line":41,"column":53}}}))
    + "\"\n          id=\"rename_list\"\n        />\n\n        <a href=\"#\" class=\"button modal-close modal-close--button "
    + alias2(__default(require("../../hbs/helpers/classes.js")).call(alias1,"shoppinglist.editModal.button.cancel",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":45,"column":66},"end":{"line":45,"column":116}}}))
    + "\">"
    + alias2(__default(require("../../hbs/helpers/text.js")).call(alias1,"shopping.list.cancel.button",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":45,"column":118},"end":{"line":45,"column":156}}}))
    + "</a>\n      </div>\n    </fieldset>\n  </form>\n</div>\n";
},"useData":true});