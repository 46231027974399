import BasePage from '../../common/BasePage'
import quickOrderCsvTable from './quickOrderCsvTable.html'
import quickOrderPadCSVRow from './quickOrderPadCSVRow.html'

class UploadDealcsv extends BasePage {
  constructor() {
    super()
    this.tpls = {
      quickOrderCsvTable,
      quickOrderPadCSVRow,
    }
  }

  get checkLodingHtml() {
    return `<p class="checking-tips">${this.text['qop.checking.file']}</p>`
  }

  async validation(content) {
    const $filesInput = document.querySelector('#customer_sku_csv')
    const $csvCheckInfoContainer = document.querySelector('#csv_check_info')
    const checkInformationSerlector = '#csv_check_info .checking-tips'
    $csvCheckInfoContainer.innerHTML = this.checkLodingHtml
    const parsedata = []
    let originArr = []
    let errorCounter = 0
    let columns = 0

    if (content) {
      originArr = content.split('\n')
    }

    const unEmptyArr = this.removeEmptyRow(originArr)

    if (unEmptyArr.length > 300) {
      errorCounter += 1
      this.insertMessage('#csv_check_info', this.text['qop.limit.lines'])
    }

    if (unEmptyArr && unEmptyArr.length > 0) {
      const headerRow = unEmptyArr[0]
      const headerArr = headerRow.split(',')

      this.removeEmptyRow(headerArr)
      columns = headerArr.length
    } else {
      $csvCheckInfoContainer.innerHTML = `<div class="checking-info-box">${this.locales.validation.emptyFiles}</div>`
    }

    for (let i = 1; i < unEmptyArr.length; i += 1) {
      const productIdsArr = ''
      const dataItem = unEmptyArr[i].split(',')

      if (dataItem.length > 1) {
        this.removeEmptyRow(dataItem)
        let errorInfo = ''

        if (dataItem.length > columns) {
          errorInfo += this.text['qop.redundant.data']
        } else {
          dataItem.length = columns
        }
        if (!dataItem[0]) {
          errorInfo += this.text['qop.empty.sku']
        }
        if (!(dataItem[1]).replace(/[\r\n]/g, '')) {
          errorInfo += this.text['qop.empty.qty']
        }
        if (/\./.test(dataItem[1]) || /\\-/.test(dataItem[1])) {
          errorInfo += this.text['qop.integer.qty']
        }
        const csvCheckErrorMsg = `<div>${this.utils.text('qop.row.errorInfo', {
          hash: {
            index: i + 1,
            errorInfo,
          },
        })}</div>`
        if ((dataItem[1]).replace(/[\r\n]/g, '') === '0') {
          errorInfo += this.text['qop.ignored.qty']
          this.insertMessage('#csv_check_info', csvCheckErrorMsg)
        } else if (errorInfo.trim() !== '') {
          errorCounter += 1
          this.insertMessage('#csv_check_info', csvCheckErrorMsg)
        }
        const productDataArr = productIdsArr.concat(dataItem)
        parsedata.push(productDataArr)
      }
    }

    if (errorCounter === 0) {
      // advQty check
      const csvdataArr = parsedata.map(item => ({
        sku: item.split(',')[0],
        qty: Number.parseInt(item.split(',')[1], 10),
      }))

      const keywords = []
      parsedata.forEach(item => {
        keywords.push(item.split(',')[0])
      })

      const variantSku = []
      const newData = []
      csvdataArr.forEach(item => {
        variantSku.push((item.sku).toUpperCase())
      })

      const variantSkus = Array.from(new Set(variantSku))
      const products = await this.getProductsBySkuQuickByPost(variantSkus)

      const { isEnabled } = await this.api.getAdvQtyState()
      csvdataArr.forEach(cItem => {
        const hasSku = products.some(dataItem => (cItem.sku).toUpperCase() === (dataItem.variantSku).toUpperCase())
        if (hasSku) {
          const item = products.find(i => (cItem.sku).toUpperCase() === (i.variantSku).toUpperCase())
          if (+item.isVisible === 0) {
            this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.invisible', {
              hash: {
                sku: cItem.sku,
              },
            })}</div>`)
            $filesInput.value = ''
          } else if (+item.purchasingDisabled === 1) {
            this.insertMessage('#csv_err_message', `<div>${this.text['qop.sku.purchasingDisabled']}</div>`)
            $filesInput.value = ''
          } else if (!item.categories.length) {
            this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.notBelongAnyCategory', {
              hash: {
                sku: cItem.sku,
              },
            })}</div>`)
            $filesInput.value = ''
          } else if (item.isStock === '0' || (item.isStock === '1' && item.stock !== 0 && cItem.qty <= item.stock)) {
            if (+item.maxQuantity !== 0 && item.maxQuantity < cItem.qty) {
              this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.maximum', {
                hash: {
                  sku: cItem.sku,
                  maxQuantity: item.maxQuantity,
                },
              })}</div>`)
              $filesInput.value = ''
            } else if (+item.minQuantity !== 0 && item.minQuantity > cItem.qty) {
              this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.minimum', {
                hash: {
                  sku: cItem.sku,
                  minQuantity: item.minQuantity,
                },
              })}</div>`)
              $filesInput.value = ''
            }
            if (Array.isArray(item.modifiers) && item.modifiers.length > 0 && item.modifiers.find(modifier => modifier.required)) {
              this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.cannotAdd', {
                hash: {
                  sku: cItem.sku,
                },
              })}</div>`)
              $filesInput.value = ''
            } else {
              // BUN-1938 When the quantity entered is greater than the maximum purchase quantity, the product can still be added to the shopping cart, but the quantity needs to be changed to the maximum purchase quantity.
              newData.push([
                item.productId,
                item.variantId,
                item.variantSku,
                cItem.qty,
                item.option ? JSON.stringify(item.option) : '',
                (+item.maxQuantity !== 0 && item.maxQuantity < cItem.qty) ? item.maxQuantity : '',
                (+item.minQuantity !== 0 && item.minQuantity > cItem.qty) ? item.minQuantity : '',
              ])
            }
          } else if (item.isStock === '1' && item.stock !== 0 && cItem.qty > item.stock) {
            this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.exceed.stock.csv', {
              hash: {
                SKU: cItem.sku,
                stockNumber: item.stock,
              },
            })}</div>`)
            $filesInput.value = ''
          } else {
            this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.stock', {
              hash: {
                sku: cItem.sku,
              },
            })}</div>`)
            $filesInput.value = ''
          }
        } else {
          this.insertMessage('#csv_err_message', `<div>${this.utils.text('qop.sku.valid', {
            hash: {
              sku: cItem.sku,
            },
          })}</div>`)
          $filesInput.value = ''
        }
      })

      if (newData.length > 0) {
        if (isEnabled === '1') {
          const qtyProducts = await this.getAdvQtyBySkusNew(variantSkus)
          const data = qtyProducts.productQuantityList
          const csvNewData = []

          newData.forEach(newDataItem => {
            const newDataSku = newDataItem[2]
            const newDataQty = newDataItem[3]
            const respItem = data.find(dataItem => dataItem.variantSku === newDataSku)
            const reQty = /^(0|\+?[1-9][0-9]*)$/
            const quantityValidError = `<div>${this.utils.text('qop.quantity.valid', {
              hash: {
                newDataSku,
              },
            })}</div>`

            if (reQty.test(newDataQty)) {
              if (respItem) {
                const minQty = respItem.minOrderQty
                if (newDataQty >= minQty) {
                  csvNewData.push(newDataItem)
                } else {
                  this.insertMessage('#csv_err_message', quantityValidError)
                  $filesInput.value = ''
                }
              } else {
                csvNewData.push(newDataItem)
              }
            } else {
              this.insertMessage('#csv_err_message', quantityValidError)
              $filesInput.value = ''
            }
          })

          if (csvNewData.length > 0) {
            this.displayCsvProducts(csvNewData)
          }
        } else {
          this.displayCsvProducts(newData)
        }
      }
    } else {
      $filesInput.value = ''
    }
    if (document.querySelector(checkInformationSerlector)) document.querySelector(checkInformationSerlector).remove()
  }

  insertMessage(selector, text) {
    const el = document.createElement('div')
    el.innerHTML = text
    document.querySelector(selector).append(el)
  }

  getAdvQtyBySkusNew(variantSkus) {
    return this.api.getAdvQtyBySkusNew({ variantSkus })
  }

  displayCsvProducts(products) {
    document.querySelector('#add_to_cart_csv').setAttribute('disabled', true)
    const $csvCheckInfoContainer = document.querySelector('#csv_check_info')
    $csvCheckInfoContainer.innerHTML = this.text['qop.loading.products']

    this.utils.renderTemplate({
      hbsTemplate: this.tpls.quickOrderCsvTable,
      containerSelector: '#csv_products_list',
      insertType: 'beforeend',
    })

    products.forEach((item, idx) => {
      const configData = {
        vrarintId: item[1],
        productId: item[0],
        options: item[4] ? item[4] : '',
        productSku: item[2],
        qty: item[3],
        idx,
        maxLimit: item[5] ? item[5] : '',
        minLimit: item[6] ? item[6] : '',
      }

      this.utils.renderTemplate({
        hbsTemplate: this.tpls.quickOrderPadCSVRow,
        containerSelector: '#quick_order_pad_table_csv tbody',
        templateConfig: configData,
        insertType: 'beforeend',
      })
    })

    document.querySelector('#add_to_cart_csv').removeAttribute('disabled')
    $csvCheckInfoContainer.innerHTML = ''
  }

  getProductsBySkuQuickByPost(variantSkus) {
    return this.api.getProductsBySkuQuickByPost({ variantSkus })
  }

  removeEmptyRow(arr) {
    const tmpArr = arr
    if (this.isEmptyRow(tmpArr[tmpArr.length - 1])) {
      tmpArr.pop()
      return this.removeEmptyRow(tmpArr)
    }
    return tmpArr
  }

  isEmptyRow(arr) {
    const tmpArr = arr.split(',')
    for (let k = 0; k < tmpArr.length; k += 1) {
      if (tmpArr[k]) {
        return false
      }
    }
    return true
  }
}
export default new UploadDealcsv()
