import TinyDatePicker from 'tiny-date-picker'
import { merge } from 'lodash'
import text from '../../text'

window.B3DatePicker = ($el, options) => {
  return TinyDatePicker($el, merge(options, {
    lang: {
      days: [
        text['global.date.Sun'],
        text['global.date.Mon'],
        text['global.date.Tue'],
        text['global.date.Wed'],
        text['global.date.Thu'],
        text['global.date.Fri'],
        text['global.date.Sat'],
      ],
      months: [
        text['global.longMonths.January'],
        text['global.longMonths.February'],
        text['global.longMonths.March'],
        text['global.longMonths.April'],
        text['global.longMonths.May'],
        text['global.longMonths.June'],
        text['global.longMonths.July'],
        text['global.longMonths.August'],
        text['global.longMonths.September'],
        text['global.longMonths.October'],
        text['global.longMonths.November'],
        text['global.longMonths.December'],
      ],
      today: text['global.form.today'],
      clear: text['global.form.clear'],
      close: text['global.form.close'],
    },
  }))
}
