var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <small>"
    + container.escapeExpression(__default(require("../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"global.required.label",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":51}}}))
    + "</small>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <input\n            class=\"form-radio\"\n            type=\"radio\"\n            id=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "attribute_radio_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uniqueId") : depth0), depth0))
    + "\"\n            name=\"attribute["
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "]\"\n            value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\"\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "            "
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias3,(depths[1] != null ? lookupProperty(depths[1],"required") : depths[1]),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":12},"end":{"line":21,"column":46}}})) != null ? stack1 : "")
    + ">\n        <label data-product-attribute-value=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" class=\"form-label\"\n          for=\""
    + alias2(alias1((depths[1] != null ? lookupProperty(depths[1],"id") : depths[1]), depth0))
    + "attribute_radio_"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"uniqueId") : depth0), depth0))
    + "\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</label>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                checked\n                data-default\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "required";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"form-field\" data-product-attribute=\"set-radio\">\n    <label class=\"form-label form-label--alternate form-label--inlineSmall\">\n        "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"display_name") : depth0), depth0))
    + ":\n\n"
    + ((stack1 = __default(require("../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"required") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"values") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":24,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useDepths":true});