var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"notice-error\">\n      <i class=\"fa fa-exclamation-circle fa-lg icon\"></i>\n      "
    + container.escapeExpression(__default(require("../../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"invoices.payment.overDueTip",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":7,"column":6},"end":{"line":7,"column":44}}}))
    + "\n    </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasDueInvoice") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":15,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"notice-error\">\n      <i class=\"fa fa-exclamation-circle fa-lg icon\"></i>\n      "
    + container.escapeExpression(__default(require("../../../hbs/helpers/text.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),"invoices.payment.dueTip",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":13,"column":6},"end":{"line":13,"column":40}}}))
    + "\n    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"invoice-heading\">\n  <h2 class=\"invoice-title\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoices.payment.title",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":61}}}))
    + "</h2>\n  <span class=\"invoice-title-notice\">\n"
    + ((stack1 = __default(require("../../../hbs/helpers/if.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"hasOverDueInvoice") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "")
    + "\n  </span>\n  <p class=\"invoice-title-desc\">\n    "
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoices.payment.desc",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":20,"column":36}}}))
    + "\n  </p>\n</div>\n<div class=\"invoice-openbalance\">\n  <span class=\"openbalance-title\">"
    + alias2(__default(require("../../../hbs/helpers/text.js")).call(alias1,"invoices.payment.totalBalance",{"name":"text","hash":{},"data":data,"loc":{"start":{"line":24,"column":34},"end":{"line":24,"column":74}}}))
    + "</span>\n  <span class=\"openbalance-value\">"
    + alias2(__default(require("../../../hbs/helpers/currencyCodeFormat.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"priceInfo") : depth0),{"name":"currencyCodeFormat","hash":{},"data":data,"loc":{"start":{"line":25,"column":34},"end":{"line":25,"column":66}}}))
    + "</span>\n</div>\n<div class=\""
    + alias2(__default(require("../../../hbs/helpers/classes.js")).call(alias1,"invoicePending",{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":27,"column":40}}}))
    + "\" id=\"invoice-detail-pending\"></div>";
},"useData":true});